import React from "react";

function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="d18afb72155b381bcfdaa494049b01d4"
        d="M12,2h0A10,10,0,1,0,22,12,10.029,10.029,0,0,0,12,2Zm1,14a1,1,0,0,1-2,0V12a1,1,0,0,1,2,0ZM12,9a.945.945,0,0,1-1-1,1,1,0,0,1,2,0A.945.945,0,0,1,12,9Z"
        transform="translate(-2 -2)"
        fill="#7b57e0"
      />
    </svg>
  );
}

function Warning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="caef313a8c663ad5b4db80ad09d056e4"
        d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Zm1.25-8.75a1.25,1.25,0,0,1-2.5,0V5a1.25,1.25,0,0,1,2.5,0ZM10,13.73a1.25,1.25,0,1,1-1.251,1.25A1.25,1.25,0,0,1,10,13.73Z"
        fill="#f79d24"
      />
    </svg>
  );
}

function Error() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="_08c1e11a5cf14f111b13c341f61a66f9"
        data-name="08c1e11a5cf14f111b13c341f61a66f9"
        d="M1.32,22H18.8a1.244,1.244,0,0,0,1.079-.647,1.4,1.4,0,0,0,.031-1.314L11.191,2.705A1.248,1.248,0,0,0,10.084,2h0a1.248,1.248,0,0,0-1.108.7L.212,20.037a1.406,1.406,0,0,0,.029,1.315A1.248,1.248,0,0,0,1.32,22Zm8.763-5.333A1.336,1.336,0,1,1,8.824,18a1.3,1.3,0,0,1,1.258-1.333ZM8.825,14.021V8.688a1.26,1.26,0,1,1,2.515,0v5.333a1.26,1.26,0,1,1-2.515,0Z"
        transform="translate(-0.063 -2)"
        fill="#ee5a6c"
      />
    </svg>
  );
}

function Close() {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        id="Rectangle_8974"
        data-name="Rectangle 8974"
        width="32"
        height="32"
        rx="4"
        fill="none"
      />
      <g id="cross-big" transform="translate(4 4)">
        <rect
          id="Rectangle_8951"
          data-name="Rectangle 8951"
          width="24"
          height="24"
          fill="none"
        />
        <path
          id="close_FILL0_wght400_GRAD0_opsz48"
          d="M11.735,28.35,10.35,26.965l7.615-7.615L10.35,11.735l1.385-1.385,7.615,7.615,7.615-7.615,1.385,1.385L20.735,19.35l7.615,7.615L26.965,28.35,19.35,20.735Z"
          transform="translate(-7.35 -7.35)"
          fill="#8d959e"
        />
      </g>
    </svg>
  );
}

export {Info, Warning, Error, Close};
