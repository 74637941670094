function removeUnderscore(value: string) {
  const camelCaseValue = () => {

    if (value.split("_").length === 2) {
      return value.split("_")[0].charAt(0).toUpperCase() +
        value.split("_")[0].slice(1).toLowerCase() +
        " " +
        value.split("_")[1].charAt(0).toUpperCase() +
        value.split("_")[1].slice(1).toLowerCase()
    } else if (value.split("_").length === 3) {
      return value.split("_")[0].charAt(0).toUpperCase() +
        value.split("_")[0].slice(1).toLowerCase() +
        " " +
        value.split("_")[1].charAt(0).toUpperCase() +
        value.split("_")[1].slice(1).toLowerCase() +
        " " +
        value.split("_")[2].charAt(0).toUpperCase() +
        value.split("_")[2].slice(1).toLowerCase()
    } else {
      return value.split("_")[0].charAt(0).toUpperCase() +
        value.split("_")[0].slice(1).toLowerCase() +
        " " +
        value.split("_")[1].charAt(0).toUpperCase() +
        value.split("_")[1].slice(1).toLowerCase()
    }
  }

  const result = value.includes("_")
    ? camelCaseValue()
    : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

  return result;
}

export default removeUnderscore;