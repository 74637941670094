import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {Link, useLocation, useParams} from "react-router-dom";

// Components
import Input from "components/Common/Input";
import DropdownCategory from "components/Common/DropdownCategory";
import DatePicker from "components/Common/Input/DatePicker";
import Select from "components/Common/Input/Select";
import Subtitle from "components/Common/Subtitle";
import Dropzone from "components/Common/Input/Dropzone";
import Button from "components/Common/Button";
import Info from "page/Configuration/Info";
import Textarea from "components/Common/Textarea";

// Assets
import Upload from "assets/Upload";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  useCreateGrandPrizeMutation,
  useGetCategoriesQuery,
  useGetIndividualGrandPrizeByMerchantIDQuery,
  useLoadRedemptionCodeCSVMutation,
  useUpdateGrandPrizeImageItemMutation,
} from "store/api/itemApi";

// Utils
import routes from "utils/routesByRole";
import {RootState} from "store/store";
import Search from "assets/Search";
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import {ArrowNext} from "assets/Arrows";
import {Tooltip} from "react-tooltip";
import removeDuplicatesCategories from "utils/removeDuplicatesCategories";

function Details({
  itemInfo,
  isLoadingItem,
  itemData,
  setItemData,
  itemsTypeRedemption,
  setItemsTypeRedemption,
  itemsTypeShipping,
  setItemsTypeShipping,
  merchantID,
  objectImages,
  setObjectImages,
  imageCropModalType,
  setImageCropModalType,
  handleUploadPhoto,
  coverGrandPrize,
  setCoverGrandPrize,
  firstRegularGrandPrize,
  setFirstRegularGrandPrize,
  secondRegularGrandPrize,
  setSecondRegularGrandPrize,
  thridRegularGrandPrize,
  setThirdRegularGrandPrize,
  fourthRegularGrandPrize,
  setFourthRegularGrandPrize,
  fifthRegularGrandPrize,
  setFifthRegularGrandPrize,
  sixthRegularGrandPrize,
  setSixthRegularGrandPrize,
  seventhRegularGrandPrize,
  setSeventhRegularGrandPrize,
  eighthRegularGrandPrize,
  setEighthRegularGrandPrize,
  videoGrandPrize,
  setVideoGrandPrize,
  handleChangeBtnAllChecked,
  setDeclinedItems,
  setApprovedItems,
  newDataCheckbox,
  setNewDataCheckbox,
}: any) {
  const user = useSelector((state: RootState) => state.user.user.user);

  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [elementsParentName, setElementsParentName] = useState<any[]>([]);

  const [inputError, setInputError] = useState(false);

  const [newImageUpload, setNewImageUpload] = useState<string[]>([]);

  const [qtyPhotos, setQtyPhotos] = useState(4);

  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.gp?.includes("add")
      ? false
      : true;
  });

  const [createGrandPrize] = useCreateGrandPrizeMutation();

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  const [
    loadCVS,
    {
      data: dataCVS,
      isLoading: isLoadingCVS,
      isError: isErrorCVS,
      // @ts-ignore
    },
  ] = useLoadRedemptionCodeCSVMutation();

  const [updateGrandPrizeImage] = useUpdateGrandPrizeImageItemMutation();

  useEffect(() => {
    if (
      itemInfo?.hasOwnProperty("newData") ||
      itemInfo?.medias?.some(
        (media: any) =>
          media.hasOwnProperty("newName") ||
          media.hasOwnProperty("newDescription")
      )
    ) {
      let resultDeclinedItems: any = [];
      let resultApprovedItems: any = [];

      if (itemInfo?.newData) {
        resultDeclinedItems = Object?.keys(itemInfo?.newData).filter((val) =>
          newDataCheckbox?.includes(val)
        );

        resultApprovedItems = Object?.keys(itemInfo?.newData).filter(
          (val) => !newDataCheckbox?.includes(val)
        );
      }

      const resultNewImages: any[] = [];

      itemInfo?.medias?.forEach((image: any) => {
        if (image.hasOwnProperty("newName")) {
          if (image.type === "COVER_PHOTO") {
            resultNewImages.push("media#COVER_PHOTO");
          } else if (image.type === "VIDEO") {
            resultNewImages.push("media#VIDEO");
          } else if (image.type === "REGULAR1") {
            resultNewImages.push("media#REGULAR1");
          } else if (image.type === "REGULAR2") {
            resultNewImages.push("media#REGULAR2");
          } else if (image.type === "REGULAR3") {
            resultNewImages.push("media#REGULAR3");
          } else if (image.type === "REGULAR4") {
            resultNewImages.push("media#REGULAR4");
          } else if (image.type === "REGULAR5") {
            resultNewImages.push("media#REGULAR5");
          } else if (image.type === "REGULAR6") {
            resultNewImages.push("media#REGULAR6");
          } else if (image.type === "REGULAR7") {
            resultNewImages.push("media#REGULAR7");
          } else if (image.type === "REGULAR8") {
            resultNewImages.push("media#REGULAR8");
          }
        }
        if (image.hasOwnProperty("newDescription")) {
          if (image.type === "COVER_PHOTO") {
            resultNewImages.push("media#COVER_PHOTO#description");
          } else if (image.type === "VIDEO") {
            resultNewImages.push("media#VIDEO#description");
          } else if (image.type === "REGULAR1") {
            resultNewImages.push("media#REGULAR1#description");
          } else if (image.type === "REGULAR2") {
            resultNewImages.push("media#REGULAR2#description");
          } else if (image.type === "REGULAR3") {
            resultNewImages.push("media#REGULAR3#description");
          } else if (image.type === "REGULAR4") {
            resultNewImages.push("media#REGULAR4#description");
          } else if (image.type === "REGULAR5") {
            resultNewImages.push("media#REGULAR5#description");
          } else if (image.type === "REGULAR6") {
            resultNewImages.push("media#REGULAR6#description");
          } else if (image.type === "REGULAR7") {
            resultNewImages.push("media#REGULAR7#description");
          } else if (image.type === "REGULAR8") {
            resultNewImages.push("media#REGULAR8#description");
          }
        }
      });

      const resultDeclinedImages = resultNewImages.filter((image: any) =>
        newDataCheckbox.includes(image)
      );
      const resultApprovedImages = resultNewImages.filter(
        (image: any) => !newDataCheckbox.includes(image)
      );

      if (newDataCheckbox.length > 0) {
        handleChangeBtnAllChecked(false);
      } else {
        handleChangeBtnAllChecked(true);
      }

      setDeclinedItems(resultDeclinedItems.concat(resultDeclinedImages));
      setApprovedItems(resultApprovedItems.concat(resultApprovedImages));
    }
  }, [itemInfo, newDataCheckbox]);

  useEffect(() => {
    const arrCategoriesID = elementsChecked?.map((el: any) => {
      if (el === "all") {
        return dataCategories.map((category: {id: number; name: string}) => {
          // @ts-ignore
          return {id: parseInt(category.id)};
        });
      } else {
        return {id: parseInt(el)};
      }
    });

    let categoryArr = [];

    if (arrCategoriesID[0]?.length > 1) {
      categoryArr = [...arrCategoriesID[0]];
    } else {
      categoryArr = arrCategoriesID;
    }

    setItemData({
      ...itemData,
      categories: categoryArr,
    });
  }, [elementsChecked]);

  useEffect(() => {
    if (itemInfo) {
      setItemData({
        prizeId: itemInfo?.prizeId || "",
        name: itemInfo?.newData?.name || itemInfo?.name || "",
        price: itemInfo?.newData?.price || itemInfo?.price || "",
        prizeCount: itemInfo?.newData?.prizeCount || itemInfo?.prizeCount || "",
        description:
          itemInfo?.newData?.description || itemInfo?.description || "",
        startDate:
          itemInfo?.newData?.startDate?.substring(0, 10) ||
          itemInfo?.startDate?.substring(0, 10) ||
          "",
        endDate:
          itemInfo?.newData?.endDate?.substring(0, 10) ||
          itemInfo?.endDate?.substring(0, 10) ||
          "",
        prizeType: itemInfo?.newData?.prizeType || itemInfo?.prizeType || "",
        winrateType:
          itemInfo?.newData?.winrateType || itemInfo?.winrateType || "",
        terms: itemInfo?.newData?.terms || itemInfo?.terms || "",
        status: itemInfo?.newData?.status || itemInfo?.status || "",
        state: itemInfo?.newData?.state || itemInfo?.state || "",
        purchaseUrl:
          itemInfo?.newData?.purchaseUrl || itemInfo?.purchaseUrl || "",
        discountCode:
          itemInfo?.newData?.discountCode || itemInfo?.discountCode || "",
        categories: itemInfo?.newData?.categories || itemInfo?.categories || [],
        prizes: itemInfo?.prizes,
      });
    }

    setItemsTypeShipping({
      businessWebsite:
        itemInfo?.newData?.businessWebsite || itemInfo?.businessWebsite || "",
      businessEmail:
        itemInfo?.newData?.businessEmail || itemInfo?.businessEmail || "",
      shippingTime:
        itemInfo?.newData?.shippingTime || itemInfo?.shippingTime || "",
    });

    setItemsTypeRedemption({
      redemptionCodes:
        itemInfo?.newData?.redemptionCodes
          ?.map((codes: any) => codes)
          ?.join(", ") ||
        itemInfo?.redemptionCodes?.map((codes: any) => codes)?.join(", ") ||
        "",
      redemptionUrl:
        itemInfo?.newData?.redemptionUrl || itemInfo?.redemptionUrl || "",
    });

    if (itemInfo?.categories || itemInfo?.newData?.categories) {
      const newCategoriesId: any = [];
      const newCategoriesName: any = [];

      // Check if categories exist on newData, if exist iterate it if not iterate itemInfo.categories
      if (itemInfo?.newData?.categories) {
        itemInfo?.newData?.categories?.map(
          (category: {id: number; name: string}) => {
            // push category to newCategory arr
            newCategoriesId.push(category.id.toString());
            newCategoriesName.push(category.name);
          }
        );
      } else {
        itemInfo?.categories.map((category: {id: number; name: string}) => {
          // push category to newCategory arr
          newCategoriesId.push(category.id.toString());
          newCategoriesName.push(category.name);
        });
      }

      setElementsChecked(newCategoriesId);
      setElementsName(newCategoriesName);
    }

    setQtyPhotos(itemInfo?.medias?.length < 4 ? 4 : itemInfo?.medias?.length);

    itemInfo?.medias?.map(
      (image: {
        mediaId: number;
        name: string;
        type: string;
        newName: string;
        newUrl: string;
        newDescription: string;
        url: string;
        description: string;
      }) => {
        if (image.type.toLowerCase() === "cover_photo") {
          setCoverGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular1") {
          setFirstRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular2") {
          setSecondRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular3") {
          setThirdRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular4") {
          setFourthRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular5") {
          setFifthRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular6") {
          setSixthRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular7") {
          setSeventhRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "regular8") {
          setEighthRegularGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        } else if (image.type.toLowerCase() === "video") {
          setVideoGrandPrize({
            src: image?.newUrl || image?.url,
            description: image?.newDescription || image?.description,
          });
        }
      }
    );

    // setFirstImg(itemInfo?.images[0]?.url || "");
    // setSecondImg(itemInfo?.images[1]?.url || "");
  }, [itemInfo]);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement?.name,
          rootId: currentElement?.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement?.name,
          rootId: rootParentElement?.id,
          parent: parentElement?.name,
          parentId: parentElement?.id,
          child: currentElement?.name,
          childId: currentElement?.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement?.name,
          parentId: parentElement?.id,
          child: currentElement?.name,
          childId: currentElement?.id,
        };
      }
    });

    // Clean res array from duplicates by id
    const cleanArr = res.filter(
      (item: any, index: number, self: any) =>
        index ===
        self.findIndex((t: any) => t.id === item.id && t.id === item.id)
    );

    setElementsParentName(cleanArr);
  }, [elementsChecked, dataCategories]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const handleClickNewDataCheckbox = (e: any) => {
    const {id, checked} = e.target;
    setNewDataCheckbox([...newDataCheckbox, id]);

    if (!checked) {
      setNewDataCheckbox(newDataCheckbox.filter((item: any) => item !== id));
    }
  };

  const handleSendCSV = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);

    const toastPromise = toast.promise(loadCVS(formData), {
      pending: "Reading CSV",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while reading CSV</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("CSV Read");
          if (res.data.codes) {
            setItemsTypeRedemption({
              ...itemsTypeRedemption,
              redemptionCodes: res.data.codes.join(", "),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while reading CSV");
      });
  };

  const checkStatus = () => {
    if (itemData?.state) {
      if (itemData?.state.toLowerCase() === "active") {
        return (
          <p className="text-sm font-medium text-green-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "paused") {
        return (
          <p className="text-sm font-medium text-gray-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "archived") {
        return (
          <p className="text-sm font-medium text-red-500 capitalize">
            {itemData?.state}
          </p>
        );
      } else if (itemData?.state.toLowerCase() === "pending_review") {
        return (
          <p className="text-sm font-medium text-yellow-500">Pending Review</p>
        );
      } else if (itemData?.state.toLowerCase() === "declined") {
        return (
          <p className="text-sm font-medium text-red-500">{itemData?.state}</p>
        );
      } else {
        return <p className="text-sm font-medium text-purple-500">Complete</p>;
      }
    }
  };

  function addDays(date: any, days: any) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    const localISOTime = result.toISOString();
    return localISOTime.split("T")[0];
  }

  const roleUnavailable = user.data.role === "MODERATOR" ? true : false;

  const handleTypeGrandPrize = () => {
    if (itemData.prizeType === "REDEMPTION_CODE") {
      return (
        <>
          <div className="flex flex-col mb-6">
            <Textarea
              label="Enter a generic redemption code"
              alert={itemInfo?.newData?.redemptionCodes ? true : false}
              placeholder="Input your generic redemption code Ех. code1, code2, code3, code4"
              classes="mb-5"
              rows={7}
              required={true}
              disabled={roleUnavailable}
              error={
                !itemsTypeRedemption.redemptionCodes && inputError
                  ? true
                  : false
              }
              value={itemsTypeRedemption.redemptionCodes}
              onChange={(e: any) => {
                setItemsTypeRedemption({
                  ...itemsTypeRedemption,
                  redemptionCodes: e.target.value,
                });
              }}
              classesCheckbox="checkbox-cross"
              checkboxID="redemptionCodes"
              handleClickCheckbox={handleClickNewDataCheckbox}
              checkedValues={newDataCheckbox}
            />
            <p className="text-xs text-gray-500 mt-2">
              * # of codes needs to be greater than the number of prizes
              available*
            </p>
            <Info hasArrow={false} classes="mt-4">
              <p className="text-sm text-gray-500">
                <b>Please enter each code separately</b>, be sure to put a comma
                between the codes. Example: Tsode23, Code21, Dog51, Medium15.
              </p>
            </Info>
          </div>
          <hr className="my-5" />
          <div className="flex flex-col mb-5">
            <p className="text-xs mb-5 mt-1 text-gray-400">
              Or bulk upload CSV file with a list of unique redemption code
            </p>
            <input
              type="file"
              id="csv_file"
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e: any) => handleSendCSV(e.target.files[0])}
            />
            <Button
              variant={roleUnavailable ? "disabled" : "normal"}
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("csv_file")?.click();
              }}
            >
              <Upload />
              <p className="ml-2 whitespace-nowrap">Upload CSV</p>
            </Button>
            <Info hasArrow={false} classes="mt-4">
              <p className="text-sm text-gray-500">
                You can also Upload a CSV file that can be created on the basis
                of Excel, Google Sheets. To do this, download the example file
                from the link, open it and replace the example codes codes
                provided by the Merchant.{" "}
                <Link
                  to="/example_csv_winspin.csv"
                  className="text-blue-600 underline underline-offset-2 underline-blue-600"
                  target="_blank"
                  download
                >
                  DOWNLOAD EXAMPLE FILE
                </Link>
              </p>
            </Info>
          </div>
          <hr className="mb-5" />
          <div className="flex flex-col mb-5">
            <Textarea
              label="Terms & Conditions"
              error={!itemData.terms && inputError ? true : false}
              placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
              classes="mb-5"
              rows={7}
              maxLength={2500}
              disabled={roleUnavailable}
              required={true}
              value={itemData.terms}
              onChange={(e: any) => {
                setItemData({...itemData, terms: e.target.value});
              }}
              alert={itemInfo?.newData?.terms ? true : false}
              classesCheckbox="checkbox-cross"
              checkboxID="terms"
              handleClickCheckbox={handleClickNewDataCheckbox}
              checkedValues={newDataCheckbox}
            />
          </div>
          <Input
            label="Enter website"
            classes="mb-5"
            placeholder="Add url for prize"
            disabled={roleUnavailable}
            value={itemsTypeRedemption.redemptionUrl}
            onChange={(e: any) =>
              setItemsTypeRedemption({
                ...itemsTypeRedemption,
                redemptionUrl: e.target.value,
              })
            }
            required={true}
            error={!itemsTypeRedemption.redemptionUrl && inputError}
            alert={itemInfo?.newData?.redemptionUrl ? true : false}
            classesCheckbox="checkbox-cross"
            checkboxID="redemptionUrl"
            handleClickCheckbox={handleClickNewDataCheckbox}
            checkedValues={newDataCheckbox}
          />
        </>
      );
    } else if (itemData.prizeType === "QR_CODE") {
      return (
        <div className="flex flex-col mb-6">
          <Textarea
            label="Terms & Conditions"
            disabled={roleUnavailable}
            error={!itemData.terms && inputError ? true : false}
            placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
            classes="mb-5"
            rows={7}
            required={true}
            value={itemData.terms}
            onChange={(e: any) => {
              setItemData({...itemData, terms: e.target.value});
            }}
            alert={itemInfo?.newData?.terms ? true : false}
            classesCheckbox="checkbox-cross"
            checkboxID="terms"
            handleClickCheckbox={handleClickNewDataCheckbox}
            checkedValues={newDataCheckbox}
          />
        </div>
      );
    } else {
      // SHIP_TO_ADDRESS
      return (
        <>
          <Input
            label="Business website"
            classes="mb-5"
            required={true}
            disabled={roleUnavailable}
            value={itemsTypeShipping.businessWebsite}
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                businessWebsite: e.target.value,
              })
            }
            error={!itemsTypeShipping.businessWebsite && inputError}
            alert={itemInfo?.newData?.businessWebsite ? true : false}
            classesCheckbox="checkbox-cross"
            checkboxID="businessWebsite"
            handleClickCheckbox={handleClickNewDataCheckbox}
            checkedValues={newDataCheckbox}
          />

          <Input
            label="Customer service email"
            classes="mb-5"
            required={true}
            disabled={roleUnavailable}
            value={itemsTypeShipping.businessEmail}
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                businessEmail: e.target.value,
              })
            }
            error={!itemsTypeShipping.businessEmail && inputError}
            alert={itemInfo?.newData?.businessEmail ? true : false}
            classesCheckbox="checkbox-cross"
            checkboxID="businessEmail"
            handleClickCheckbox={handleClickNewDataCheckbox}
            checkedValues={newDataCheckbox}
          />
          <Input
            label="Estimated shipping time"
            classes="mb-5"
            required={true}
            disabled={roleUnavailable}
            value={itemsTypeShipping.shippingTime}
            placeholder="Ex. 3-4 weeks"
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                shippingTime: e.target.value,
              })
            }
            error={!itemsTypeShipping.shippingTime && inputError}
            alert={itemInfo?.newData?.shippingTime ? true : false}
            classesCheckbox="checkbox-cross"
            checkboxID="shippingTime"
            handleClickCheckbox={handleClickNewDataCheckbox}
            checkedValues={newDataCheckbox}
          />
          <div className="flex flex-col mb-6">
            <Textarea
              label="Terms & Conditions"
              error={!itemData.terms && inputError ? true : false}
              placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
              classes="mb-5"
              rows={7}
              required={true}
              disabled={roleUnavailable}
              value={itemData.terms}
              onChange={(e: any) => {
                setItemData({...itemData, terms: e.target.value});
              }}
              alert={itemInfo?.newData?.terms ? true : false}
              classesCheckbox="checkbox-cross"
              checkboxID="terms"
              handleClickCheckbox={handleClickNewDataCheckbox}
              checkedValues={newDataCheckbox}
            />
          </div>
        </>
      );
    }
  };

  return (
    <section>
      <div className="flex mt-5 flex-wrap">
        <div className="bg-white p-5 pr-0 w-full xl:w-10/12">
          <form>
            <div className="flex items-center justify-between mb-5 pr-5">
              <Subtitle classes="mt-1">Item Details (Grand Prize)</Subtitle>
              <div className="flex">
                <div className="flex items-center px-5 border-r-2 border-gray-200">
                  {checkStatus()}
                </div>
                <div className="flex items-center px-5 border-r-2 border-gray-200">
                  <div className="rounded px-3 py-1 bg-primary-white flex items-center">
                    {/* <AddOffer classes="h-5 w-5 mr-1" /> */}
                    <p className="text-sm font-medium capitalize">
                      Grand Prize
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="flex w-100">
              <div className="mt-5 w-full">
                <div className="flex">
                  <div className="flex flex-col  w-6/7">
                    <div className="flex mt-8 w-full ">
                      <div className="w-1/2 mr-4">
                        <>
                          <Textarea
                            label="Grand Prize headline"
                            error={!itemData.name && inputError ? true : false}
                            placeholder="Name or short description of GP"
                            classes="mb-5"
                            disabled={roleUnavailable}
                            rows={7}
                            maxLength={58}
                            required={true}
                            value={itemData.name}
                            onChange={(e: any) => {
                              setItemData({
                                ...itemData,
                                name: e.target.value,
                              });
                            }}
                            alert={itemInfo?.newData?.name ? true : false}
                            classesCheckbox="checkbox-cross"
                            checkboxID="name"
                            handleClickCheckbox={handleClickNewDataCheckbox}
                            checkedValues={newDataCheckbox}
                          />
                        </>
                        <Input
                          label="How many prizes are available"
                          required={true}
                          error={!itemData.prizeCount && inputError}
                          disabled={roleUnavailable}
                          value={
                            itemData.prizeCount === 0 ? "" : itemData.prizeCount
                          }
                          onChange={(e: any) =>
                            setItemData({
                              ...itemData,
                              prizeCount: !isNaN(e.target.value)
                                ? Math.abs(e.target.value).toString()
                                : "0",
                            })
                          }
                          placeholder="Ex. 10"
                          classes="my-5"
                          alert={itemInfo?.newData?.prizeCount ? true : false}
                          classesCheckbox="checkbox-cross"
                          checkboxID="prizeCount"
                          handleClickCheckbox={handleClickNewDataCheckbox}
                          checkedValues={newDataCheckbox}
                        />
                        <DatePicker
                          label="Start Date of Grand Prize"
                          classes="mb-5"
                          disabled={roleUnavailable}
                          error={!itemData.startDate && inputError}
                          required={true}
                          value={itemData.startDate}
                          onChange={(e: any) =>
                            setItemData({
                              ...itemData,
                              startDate: e.target.value,
                            })
                          }
                          min="today"
                          max={90}
                          alert={itemInfo?.newData?.startDate ? true : false}
                          classesCheckbox="checkbox-cross"
                          checkboxID="startDate"
                          handleClickCheckbox={handleClickNewDataCheckbox}
                          checkedValues={newDataCheckbox}
                        />
                        <DatePicker
                          label="End Date of Grand Prize"
                          classes="mb-5"
                          required={true}
                          disabled={!itemData.startDate || roleUnavailable}
                          error={!itemData.endDate && inputError}
                          value={itemData.endDate}
                          onChange={(e: any) =>
                            setItemData({
                              ...itemData,
                              endDate: e.target.value,
                            })
                          }
                          min={
                            itemData?.startDate ? itemData.startDate : "today"
                          }
                          alert={itemInfo?.newData?.endDate ? true : false}
                          classesCheckbox="checkbox-cross"
                          checkboxID="endDate"
                          handleClickCheckbox={handleClickNewDataCheckbox}
                          checkedValues={newDataCheckbox}
                        />
                        <Input
                          label="Retail value of Grand prize"
                          value={itemData.price === 0 ? "" : itemData.price}
                          required={true}
                          error={!itemData.price && inputError}
                          disabled={roleUnavailable}
                          onChange={(e: any) =>
                            setItemData({
                              ...itemData,
                              price: !isNaN(e.target.value)
                                ? Math.abs(e.target.value).toString()
                                : "0",
                            })
                          }
                          placeholder="Provide retail value"
                          classes="mb-5"
                          alert={itemInfo?.newData?.price ? true : false}
                          classesCheckbox="checkbox-cross"
                          checkboxID="price"
                          handleClickCheckbox={handleClickNewDataCheckbox}
                          checkedValues={newDataCheckbox}
                        />
                      </div>
                      <div className="w-1/2 mr-4">
                        {/*
                         *
                         *
                         *
                         */}
                        <DropdownCategory
                          hasLabel={true}
                          label="Categories"
                          checkboxID="categories"
                          openDropdown={openDropdown}
                          dropdown={dropdown}
                          elementsChecked={elementsChecked}
                          elementsName={elementsName}
                          handleClickCheckbox={handleClickCheckbox}
                          dataCategories={getFilteredNames(
                            dataCategories,
                            dropdownSearchFilter,
                            ""
                          )}
                          handleClickDropdown={handleClickDropdown}
                          required={true}
                          isError={!elementsChecked.length && inputError}
                          disabled={isInputDisabled}
                          isErrorCategories={isErrorCategories}
                          isLoadingCategories={isLoadingCategories}
                          classNameSelect="w-full"
                          error={error}
                          showNames={false}
                          expandItems={dropdownSearchFilter}
                          hasSearchValue={{
                            show: true,
                            content: (
                              <div className="flex mb-3 justify-between">
                                <div className="flex items-center w-full">
                                  <label htmlFor="search">
                                    <Search classes="h-6 w-6 mr-2" />
                                  </label>
                                  <input
                                    id="search"
                                    name="search"
                                    type="text"
                                    placeholder="Search category"
                                    className="decoration-none outline-none w-full"
                                    onChange={(e: any) =>
                                      setDowpdownSearchFilter(e.target.value)
                                    }
                                    value={dropdownSearchFilter}
                                  />
                                </div>
                              </div>
                            ),
                          }}
                          alert={itemInfo?.newData?.categories ? true : false}
                          classesCheckbox="checkbox-cross"
                          handleClickNewDataCheckbox={
                            handleClickNewDataCheckbox
                          }
                          checkedValues={newDataCheckbox}
                        />
                        <div
                          className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                            elementsChecked.length > 0
                              ? "border border-gray-400"
                              : "border-none"
                          } ${
                            isInputDisabled &&
                            "bg-gray-200 !border-gray-200 text-gray-500"
                          } rounded-md`}
                        >
                          {elementsParentName.map((element, index) => (
                            <div
                              className={`${
                                isInputDisabled
                                  ? "!border-b-gray-300"
                                  : "border-b-slate-200"
                              } flex justify-between gap-2 items-center border-b-2  p-2`}
                              key={index}
                            >
                              <Tooltip id="my-tooltip"></Tooltip>
                              <div className="flex flex-wrap">
                                {element?.root && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.rootId}`}
                                    className="flex items-center text-sm"
                                  >
                                    {element?.root}{" "}
                                    <ArrowNext classes="h-4 w-4" />
                                  </div>
                                )}
                                {element?.parentId && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.parentId}`}
                                    className="flex items-center text-sm"
                                  >
                                    {element?.parent}{" "}
                                    <ArrowNext classes="h-4 w-4" />
                                  </div>
                                )}
                                {element?.childId && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.childId}`}
                                    className="font-medium text-sm"
                                  >
                                    {element?.child}
                                  </div>
                                )}
                              </div>
                              <Button
                                type="button"
                                onClick={() => {
                                  if (isInputDisabled) return;
                                  setElementsChecked(
                                    (prevElementsChecked: string[]) =>
                                      prevElementsChecked.filter(
                                        (item: string) => item !== element.id
                                      )
                                  );
                                  setElementsName(
                                    (prevElementsChecked: string[]) =>
                                      prevElementsChecked.filter(
                                        (item: string) => item !== element.child
                                      )
                                  );
                                }}
                                classes={`${
                                  isInputDisabled &&
                                  "opacity-50 cursor-not-allowed"
                                } ml-6 text-blue-400 text-sm`}
                              >
                                Remove
                              </Button>
                            </div>
                          ))}
                        </div>
                        {/*
                         *
                         *
                         *
                         */}
                        <Select
                          label="Type of grand prize"
                          disabled={roleUnavailable}
                          value={itemData.winrateType}
                          onChange={(e: any) =>
                            setItemData({
                              ...itemData,
                              winrateType: e.target.value,
                            })
                          }
                          classes="mt-5"
                          required
                          classesSelect="border border-gray-400 py-2.5 pl-2.5 pr-9"
                          alert={itemInfo?.newData?.winrateType ? true : false}
                          classesCheckbox="checkbox-cross"
                          checkboxID="winrateType"
                          handleClickCheckbox={handleClickNewDataCheckbox}
                          checkedValues={newDataCheckbox}
                          error={!itemData.winrateType && inputError}
                        >
                          <>
                            <option value="" disabled className="">
                              Select type of grand prize
                            </option>
                            <option value={"STANDARD"}>
                              Standard (1/500 spins)
                            </option>
                            <option value={"PREMIUM"}>
                              Premium (1/1,000 spins)
                            </option>
                            <option value={"EXCLUSIVE"}>
                              Exclusive (1/2,000 spins)
                            </option>
                            <option value={"EVENT"}>Event (1/20 spins)</option>
                            <option value={"TIMES_UP"}>
                              Timesup (Next spin wins)
                            </option>
                            <option value={"PRESTIGE"}>
                              Prestige (1/1,000,000 spins)
                            </option>
                          </>
                        </Select>
                      </div>
                    </div>
                    <hr className="w-11/12 text-center mt-6 mb-8" />
                    <Subtitle>Other Information</Subtitle>
                    <Input
                      label="Link to the product for purchase"
                      value={itemData.purchaseUrl}
                      disabled={roleUnavailable}
                      onChange={(e: any) =>
                        setItemData({
                          ...itemData,
                          purchaseUrl: e.target.value,
                        })
                      }
                      placeholder="Add a link to purchase on your website"
                      classes="my-5 w-96"
                      required={itemData.discountCode ? true : false}
                      alert={itemInfo?.newData?.discountCode ? true : false}
                      classesCheckbox="checkbox-cross"
                      checkboxID="discountCode"
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                    />
                    <Input
                      label="Referral code"
                      value={itemData.discountCode}
                      disabled={roleUnavailable}
                      maxLength={12}
                      max={12}
                      onChange={(e: any) =>
                        setItemData({
                          ...itemData,
                          discountCode: e.target.value,
                        })
                      }
                      placeholder="Provide your referral code for discount"
                      classes="mb-5 w-96"
                      required={itemData.purchaseUrl ? true : false}
                      alert={itemInfo?.newData?.purchaseUrl ? true : false}
                      classesCheckbox="checkbox-cross"
                      checkboxID="purchaseUrl"
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                    />
                    <hr className="w-11/12 text-center mt-6 mb-8" />
                    <div className="flex w-full flex-col">
                      <div className="pr-4">
                        <Subtitle>Video Section</Subtitle>
                        <p className="text-sm text-gray-500 mt-2">
                          In the video section you can show video material and
                          provide more info about your grand prize.
                        </p>
                        <div>
                          <Info hasArrow={false} classes="mt-4 !w-full">
                            <p className="text-sm text-gray-500">
                              Video: file size 100mb, duration 45sec , HD
                            </p>
                          </Info>
                        </div>
                      </div>
                      {/* Video */}
                      <div className="flex w-full">
                        <div className="w-1/2 mr-4">
                          <div className="w-fit mt-5">
                            <Dropzone
                              handleUploadPhoto={handleUploadPhoto}
                              image={videoGrandPrize.src}
                              imageSetter={setVideoGrandPrize}
                              required={!coverGrandPrize.src}
                              label="Video"
                              id="media#VIDEO"
                              type="VIDEO"
                              typeItem="grand prize"
                              isVideo={true}
                              disabledInput={
                                coverGrandPrize.src || roleUnavailable
                                  ? true
                                  : false
                              }
                              imageCropModalType={imageCropModalType}
                              setImageCropModalType={setImageCropModalType}
                              newImageUpload={newImageUpload}
                              setNewImageUpload={setNewImageUpload}
                              sizesContainer={{
                                minHeight: "12rem",
                                maxHeight: "12rem",
                                minWidth: "calc(100% - 20px)",
                                maxWidth: "calc(100% - 20px)",
                              }}
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (image.type.toLowerCase() === "video") {
                                  if (
                                    image.hasOwnProperty("newName") ||
                                    image.hasOwnProperty("newUrl")
                                  ) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                        </div>
                        <div className="w-1/2 mr-4">
                          <div className="flex flex-col mb-8 mt-5">
                            <Textarea
                              checkboxID="media#VIDEO#description"
                              label="Provide descriptions"
                              placeholder="Provide text descriptions"
                              classes="mb-5"
                              classesInput="mt-2"
                              required={true}
                              error={
                                !videoGrandPrize.description && inputError
                                  ? true
                                  : false
                              }
                              rows={7}
                              disabled={
                                coverGrandPrize.src || roleUnavailable
                                  ? true
                                  : false
                              }
                              value={videoGrandPrize.description}
                              onChange={(e: any) => {
                                setVideoGrandPrize({
                                  ...videoGrandPrize,
                                  description: e.target.value,
                                });
                              }}
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (image.type.toLowerCase() === "video") {
                                  if (image.hasOwnProperty("newDescription")) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pr-4">
                        <Subtitle>Photo Of The Grand Prize</Subtitle>
                        <p className="text-sm text-gray-500  mt-2">
                          In the photo section you can show photo material and
                          provide more info about your grand prize.
                        </p>
                      </div>
                      {/* First Image (Cover Photo) */}
                      <div className="flex w-full">
                        <div className="w-1/2 mr-4">
                          <div className="w-fit mt-5">
                            <Dropzone
                              handleUploadPhoto={handleUploadPhoto}
                              image={coverGrandPrize.src}
                              imageSetter={setCoverGrandPrize}
                              required={!videoGrandPrize.src}
                              label="Grand Prize Photo (1320 x 1972)"
                              sizePlaceholder={"picture \n 1:1.5 @ 1320 x 1972"}
                              id="media#COVER_PHOTO"
                              type="COVER_PHOTO"
                              classesBorder={`${
                                !coverGrandPrize.src &&
                                inputError &&
                                "!border-red-500"
                              }`}
                              typeItem="grand prize"
                              disabledInput={
                                videoGrandPrize.src || roleUnavailable
                                  ? true
                                  : false
                              }
                              imageCropModalType={imageCropModalType}
                              setImageCropModalType={setImageCropModalType}
                              newImageUpload={newImageUpload}
                              setNewImageUpload={setNewImageUpload}
                              sizes={{width: 1320, height: 1972}}
                              aspect={330 / 493}
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (
                                  image.type.toLowerCase() === "cover_photo"
                                ) {
                                  if (
                                    image.hasOwnProperty("newName") ||
                                    image.hasOwnProperty("newUrl")
                                  ) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                          <p className="mb-8 mt-2 text-xs text-gray-400">
                            Cover photo which will be shown in the carousel on
                            the customers app
                          </p>
                        </div>
                        <div className="w-1/2 mr-4">
                          <div className="flex flex-col mb-8 mt-5">
                            <Textarea
                              checkboxID="media#COVER_PHOTO#description"
                              label="Provide title"
                              placeholder="Enter a description"
                              classes="mb-5"
                              classesInput="mt-2"
                              rows={7}
                              required={true}
                              error={
                                !coverGrandPrize.description && inputError
                                  ? true
                                  : false
                              }
                              value={coverGrandPrize.description}
                              onChange={(e: any) => {
                                setCoverGrandPrize({
                                  ...coverGrandPrize,
                                  description: e.target.value,
                                });
                              }}
                              disabled={
                                videoGrandPrize.src || roleUnavailable
                                  ? true
                                  : false
                              }
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (
                                  image.type.toLowerCase() === "cover_photo"
                                ) {
                                  if (image.hasOwnProperty("newDescription")) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pr-4">
                        <Subtitle>Provide Long Description</Subtitle>
                        <p className="text-sm text-gray-500  mt-2">
                          Long description should include all details about your
                          grand prize
                        </p>
                      </div>
                      {/* Long description - item */}
                      <div className="flex w-full">
                        <div className="w-1/2 mr-4">
                          <div className="flex flex-col mb-8 mt-5">
                            <Textarea
                              label="Provide long descriptions"
                              error={
                                !itemData.description && inputError
                                  ? true
                                  : false
                              }
                              placeholder="Enter a description"
                              classes="mb-5"
                              classesInput="mt-2"
                              rows={7}
                              required={true}
                              disabled={roleUnavailable}
                              value={itemData.description}
                              onChange={(e: any) => {
                                setItemData({
                                  ...itemData,
                                  description: e.target.value,
                                });
                              }}
                              alert={
                                itemInfo?.newData?.description ? true : false
                              }
                              classesCheckbox="checkbox-cross"
                              checkboxID="description"
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pr-4">
                        <Subtitle>Image Section</Subtitle>
                        <p className="text-sm text-gray-500 mt-2">
                          In this section, you upload 3 images as mandatory, and
                          5 optional images. After your upload all mandatory
                          images, additional images will add automatically, if
                          you don’t need them, just ignore them.
                        </p>
                      </div>
                      {/* Second Image (REGULAR1) */}
                      <div className="flex w-full">
                        <div className="w-1/2 mr-4">
                          <div className="w-fit mt-5">
                            <Dropzone
                              handleUploadPhoto={handleUploadPhoto}
                              image={firstRegularGrandPrize.src}
                              imageSetter={setFirstRegularGrandPrize}
                              required={true}
                              disabledInput={roleUnavailable}
                              label="Grand Prize Photo (1080 x 1080)"
                              id="media#REGULAR1"
                              type="REGULAR1"
                              classesBorder={`${
                                !firstRegularGrandPrize.src &&
                                inputError &&
                                "!border-red-500"
                              }`}
                              typeItem="grand prize"
                              imageCropModalType={imageCropModalType}
                              setImageCropModalType={setImageCropModalType}
                              newImageUpload={newImageUpload}
                              setNewImageUpload={setNewImageUpload}
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (image.type.toLowerCase() === "regular1") {
                                  if (
                                    image.hasOwnProperty("newName") ||
                                    image.hasOwnProperty("newUrl")
                                  ) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                          <p className="mb-8 mt-2 text-xs text-gray-400">
                            Big photo which will be shown in the carousel on the
                            customers app
                          </p>
                        </div>
                        <div className="w-1/2 mr-4">
                          <div className="flex flex-col mb-8 mt-5">
                            <Textarea
                              checkboxID="media#REGULAR1#description"
                              label="Provide descriptions"
                              placeholder="Provide descriptions"
                              classes="mb-5"
                              classesInput="mt-2"
                              required={true}
                              disabled={roleUnavailable}
                              error={
                                !firstRegularGrandPrize.description &&
                                inputError
                                  ? true
                                  : false
                              }
                              rows={7}
                              value={firstRegularGrandPrize.description}
                              onChange={(e: any) => {
                                setFirstRegularGrandPrize({
                                  ...firstRegularGrandPrize,
                                  description: e.target.value,
                                });
                              }}
                              handleClickCheckbox={handleClickNewDataCheckbox}
                              checkedValues={newDataCheckbox}
                              alert={itemInfo?.medias?.find((image: any) => {
                                if (image.type.toLowerCase() === "regular1") {
                                  if (image.hasOwnProperty("newDescription")) {
                                    return true;
                                  }
                                }
                              })}
                              classesCheckbox="checkbox-cross"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Third Image (REGULAR2) */}
                      {qtyPhotos >= 3 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={secondRegularGrandPrize.src}
                                imageSetter={setSecondRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR2"
                                type="REGULAR2"
                                classesBorder={`${
                                  !secondRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular2") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR2#description"
                                label="Provide descriptions"
                                placeholder="Provide descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                disabled={roleUnavailable}
                                required={true}
                                error={
                                  !secondRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                rows={7}
                                value={secondRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setSecondRegularGrandPrize({
                                    ...secondRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular2") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Fourth Image (REGULAR3) */}
                      {qtyPhotos >= 4 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={thridRegularGrandPrize.src}
                                imageSetter={setThirdRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR3"
                                type="REGULAR3"
                                classesBorder={`${
                                  !thridRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular3") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR3#description"
                                label="Provide descriptions"
                                placeholder="Provide descriptions"
                                classes="mb-5"
                                disabled={roleUnavailable}
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                error={
                                  !thridRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={thridRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setThirdRegularGrandPrize({
                                    ...thridRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular3") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Fifth Image (REGULAR4) */}
                      {qtyPhotos >= 5 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={fourthRegularGrandPrize.src}
                                imageSetter={setFourthRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR4"
                                type="REGULAR4"
                                classesBorder={`${
                                  !fourthRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular4") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR4#description"
                                label="Provide descriptions"
                                placeholder="Provide descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                disabled={roleUnavailable}
                                error={
                                  !fourthRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={fourthRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setFourthRegularGrandPrize({
                                    ...fourthRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular4") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Sixth Image (REGULAR5) */}
                      {qtyPhotos >= 6 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={fifthRegularGrandPrize.src}
                                imageSetter={setFifthRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR5"
                                type="REGULAR5"
                                classesBorder={`${
                                  !fifthRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular5") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR5#description"
                                label="Provide descriptions"
                                placeholder="Provide descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                disabled={roleUnavailable}
                                error={
                                  !fifthRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={fifthRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setFifthRegularGrandPrize({
                                    ...fifthRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular5") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Seventh Image (REGULAR6) */}
                      {qtyPhotos >= 7 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={sixthRegularGrandPrize.src}
                                imageSetter={setSixthRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR6"
                                type="REGULAR6"
                                classesBorder={`${
                                  !sixthRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular6") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR6#description"
                                label="Provide descriptions"
                                placeholder="Provide descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                disabled={roleUnavailable}
                                error={
                                  !sixthRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={sixthRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setSixthRegularGrandPrize({
                                    ...sixthRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular6") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Eighth Image (REGULAR7) */}
                      {qtyPhotos >= 8 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={seventhRegularGrandPrize.src}
                                imageSetter={setSeventhRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR7"
                                type="REGULAR7"
                                classesBorder={`${
                                  !seventhRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular7") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR7#description"
                                label="Provide text descriptions"
                                placeholder="Provide text descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                disabled={roleUnavailable}
                                error={
                                  !seventhRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={seventhRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setSeventhRegularGrandPrize({
                                    ...seventhRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular7") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Ninth Image (REGULAR8) */}
                      {qtyPhotos >= 9 && (
                        <div className="flex w-full">
                          <div className="w-1/2 mr-4">
                            <div className="w-fit mt-5">
                              <Dropzone
                                handleUploadPhoto={handleUploadPhoto}
                                image={eighthRegularGrandPrize.src}
                                imageSetter={setEighthRegularGrandPrize}
                                required={true}
                                disabledInput={roleUnavailable}
                                label="Grand Prize Photo (1080 x 1080)"
                                id="media#REGULAR8"
                                type="REGULAR8"
                                classesBorder={`${
                                  !eighthRegularGrandPrize.src &&
                                  inputError &&
                                  "!border-red-500"
                                }`}
                                typeItem="grand prize"
                                imageCropModalType={imageCropModalType}
                                setImageCropModalType={setImageCropModalType}
                                newImageUpload={newImageUpload}
                                setNewImageUpload={setNewImageUpload}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular8") {
                                    if (
                                      image.hasOwnProperty("newName") ||
                                      image.hasOwnProperty("newUrl")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                          <div className="w-1/2 mr-4">
                            <div className="flex flex-col mb-8 mt-5">
                              <Textarea
                                checkboxID="media#REGULAR8#description"
                                label="Provide text descriptions"
                                placeholder="Provide text descriptions"
                                classes="mb-5"
                                classesInput="mt-2"
                                rows={7}
                                required={true}
                                disabled={roleUnavailable}
                                error={
                                  !eighthRegularGrandPrize.description &&
                                  inputError
                                    ? true
                                    : false
                                }
                                value={eighthRegularGrandPrize.description}
                                onChange={(e: any) => {
                                  setEighthRegularGrandPrize({
                                    ...eighthRegularGrandPrize,
                                    description: e.target.value,
                                  });
                                }}
                                handleClickCheckbox={handleClickNewDataCheckbox}
                                checkedValues={newDataCheckbox}
                                alert={itemInfo?.medias?.find((image: any) => {
                                  if (image.type.toLowerCase() === "regular8") {
                                    if (
                                      image.hasOwnProperty("newDescription")
                                    ) {
                                      return true;
                                    }
                                  }
                                })}
                                classesCheckbox="checkbox-cross"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Buttons */}
                      <div className="flex mt-8">
                        <Button
                          type="button"
                          variant={roleUnavailable ? "disabled" : "normal"}
                          onClick={(e: any) => {
                            if (roleUnavailable) return;
                            e.preventDefault();
                            if (qtyPhotos >= 9) return;
                            setQtyPhotos((prev) => prev + 1);
                            setTimeout(() => {
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                              });
                            }, 0);
                          }}
                        >
                          Add Item Photo
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="lg:border-l lg:w-2/7 lg:justify-self-end px-4">
                    <Select
                      label="Type of Grand Prize"
                      value={itemData.prizeType}
                      disabled={roleUnavailable}
                      onChange={(e: any) =>
                        setItemData({...itemData, prizeType: e.target.value})
                      }
                      classes="mb-6 mt-8"
                      classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
                    >
                      <>
                        <option value="REDEMPTION_CODE" selected>
                          Redemption code
                        </option>
                        <option value="QR_CODE">QR Code</option>
                        <option value="SHIP_TO_ADDRESS">
                          Will be shipped to customer
                        </option>
                      </>
                    </Select>
                    {handleTypeGrandPrize()}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Details;
