import { TypeClasses } from "./types";

function Alert({ classes }: TypeClasses) {
  return (
    <svg
      id="alert"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <path
        fill="#F8CB01"
        d="M1 21 12 2l11 19Zm11-3q.425 0 .713-.288Q13 17.425 13 17t-.287-.712Q12.425 16 12 16t-.712.288Q11 16.575 11 17t.288.712Q11.575 18 12 18Zm-1-3h2v-5h-2Z"
      />
    </svg>
  );
}

export default Alert;
