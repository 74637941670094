import React, {useEffect, useState} from "react";
import Subtitle from "components/Common/Subtitle";
import Searchbar from "components/Common/Searchbar";
import Table from "components/Table/Table";

import {TABLE_CATEGORY_ITEMS_HEADER} from "utils/categoriesHeader";

// Assets (Icon)
import Button from "components/Common/Button";
import {useDispatch, useSelector} from "react-redux";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import {
  filterObjectsById,
  getNamesFromCategories,
  removeObjectsById,
  segmentationByPrizeOffer,
} from "utils/categoriesUtils";

// Types
import {ItemsCategoryI} from "../types";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {EyeView} from "assets/Eye";
import {ButtonCircleSubstract} from "assets/ButtonCircle";
import routes from "utils/routesByRole";
import {RootState} from "store/store";
import DeleteModal from "components/Modal/DeleteModal";
import {setOpenModal, setType} from "store/modal/modalSlice";

function Overview({
  itemsAdded,
  isLoadingItemsInCategory,
  isErrorItemsInCategory,
  setItemsAdded,
  itemsAddedOverview,
  setItemsAddedOverview,
  updateItemsAddedRemove,
  handleOpenSidebarCategory,
}: {
  itemsAdded: {prize: any[]; offer: any[]};
  isLoadingItemsInCategory: any;
  isErrorItemsInCategory: any;
  setItemsAdded: any;
  itemsAddedOverview: any;
  setItemsAddedOverview: any;
  updateItemsAddedRemove: Function;
  handleOpenSidebarCategory: any;
}) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.user?.user?.user);
  const modal = useSelector((state: RootState) => state.modal);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [overviewData, setOverviewData] = useState<ItemsCategoryI[]>([
    ...itemsAdded?.offer,
    ...itemsAdded?.prize,
  ]);

  const [fullyOverviewData, setFullyOverviewData] = useState<ItemsCategoryI[]>([
    ...itemsAddedOverview,
  ]);

  const [searchValueItems, setSearchValueItems] = useState("");

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  useEffect(() => {
    setOverviewData([...itemsAdded?.offer, ...itemsAdded?.prize]);
  }, [itemsAdded]);
  useEffect(() => {
    setFullyOverviewData([...itemsAddedOverview]);
  }, [itemsAddedOverview]);

  const handleDeleteItemsFromOverview = () => {
    const result = removeObjectsById(overviewData, elementsChecked);
    const resultFullyData = removeObjectsById(
      fullyOverviewData,
      elementsChecked
    );

    const organizedData = segmentationByPrizeOffer(result);

    const getNames = getNamesFromCategories(result);

    setItemsAdded(organizedData);
    setItemsAddedOverview(resultFullyData);

    dispatch(setResetElChecked(true));
    dispatch(setMerchantsName(getNames));
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const getID = (id: number) => {
    const itemsFlatted = [...itemsAdded.prize, ...itemsAdded.offer];

    if (!id || id === 0) {
      return setOverviewData(itemsFlatted);
    }

    const filteredObject = filterObjectsById(itemsFlatted, [id]);

    setOverviewData(filteredObject);
  };

  const getFilteredNamesPrize = () => {
    if (searchValueItems?.length === 0) {
      return [...overviewData, ...fullyOverviewData];
    }
    return [...overviewData, ...fullyOverviewData]?.filter((item: any) =>
      item.name.toLowerCase().includes(searchValueItems.toLowerCase())
    );
  };

  const handleCheckNames = (origin: string) => {
    if (origin === "local-search") {
      if (itemsAdded.prize.length > 0 || itemsAdded.offer.length > 0) {
        const itemsName = getNamesFromCategories([
          ...itemsAdded.prize,
          ...itemsAdded.offer,
        ]);

        dispatch(setMerchantsName(itemsName));
      }
    }
  };

  const getPrize = (items: any) => {
    const prizes = items?.filter((item: ItemsCategoryI) => {
      if (item.prizeType === "REGULAR") {
        return true;
      }
    });

    return prizes.length;
  };

  const getOffer = (items: any) => {
    const offer = items?.filter((item: ItemsCategoryI) => {
      if (item.prizeType === "OFFER") {
        return true;
      }
    });

    return offer.length;
  };

  const ITEMS = [
    // @ts-ignore
    routes[user.data.role].urlAllowed?.item?.includes("view") && {
      title: "asd",
      svg: <EyeView />,
      type: "openSidebarCategory",
      onClick: (e: any) => handleOpenSidebarCategory(e),
    },
    // @ts-ignore
    routes[user.data.role].urlAllowed?.categories?.includes("add") && {
      title: "dsa",
      svg: <ButtonCircleSubstract classes="text-gray-400" />,
      type: "addPrizeListToSidebar",
      onClick: (e: any) => updateItemsAddedRemove(e),
    },
  ];

  return (
    <div className="flex bg-white mt-6">
      <div className="flex-1">
        <div className="flex items-center px-4 justify-between">
          <div className="flex">
            <Subtitle classes="flex items-center">Category name list</Subtitle>
            <div className="flex items-center ml-8">
              <div className="flex gap-8 my-3 px-4">
                <div className="flex flex-col">
                  <div className="text-sm text-gray-400">Offer</div>
                  <div className="text-lg font-semibold">
                    {getOffer([...overviewData, ...fullyOverviewData])}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-sm text-gray-400">Prize</div>
                  <div className="text-lg font-semibold">
                    {getPrize([...overviewData, ...fullyOverviewData])}
                  </div>
                </div>
              </div>
              <div className="ml-4">
                <Searchbar
                  placeholder="Search items"
                  useTags={false}
                  getIDMerchant={getID}
                  classesContainer="w-96 h-9 outline-1 border border-gray-300 rounded-r-sm rounded"
                  classes="w-80"
                  callback={(value: string) => setSearchValueItems(value)}
                  origin="local-search"
                  onClick={handleCheckNames}
                  hasAutocomplete={false}
                />
              </div>
            </div>
          </div>
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.categories?.includes("save") && (
              <Button
                variant={elementsChecked.length <= 0 ? "disabled" : "danger"}
                onClick={() => {
                  dispatch(setType("deleteCategoryOverview"));
                  dispatch(setOpenModal(true));
                }}
              >
                - Delete from the list
              </Button>
            )
          }
        </div>
        <hr />

        <hr />
        <div className="">
          <Table
            headers={TABLE_CATEGORY_ITEMS_HEADER}
            data={getFilteredNamesPrize() || []}
            loadingData={isLoadingItemsInCategory}
            errorData={isErrorItemsInCategory}
            origin="prize-list-overview"
            hasPagination={false}
            show={[
              "name",
              "prizeType",
              "merchantName",
              "expiredAt",
              "remaining",
              "claimed",
              "redeemed",
            ]}
            sort={{
              name: null,
              prizeType: null,
              merchantName: null,
              expiredAt: null,
              remaining: null,
              claimed: null,
              redeemed: null,
            }}
            itemOptions={ITEMS}
            typeOptions="individual"
            handleItemChecked={handleItemChecked}
            orderingByAPI={false}
            customError={{
              show: true,
              content: (
                <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
                  There are no items in this category
                </h2>
              ),
            }}
          />
        </div>
      </div>
      {modal.type === "deleteCategoryOverview" && (
        <DeleteModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          dataSource={getFilteredNamesPrize()}
          matchWidhId="prizeId"
          pendingMsg="Deleting category"
          returnValue="name"
          toastMessageId="name"
          type="category"
          typeOfDelete="reward"
          deleteCallback={handleDeleteItemsFromOverview}
        />
      )}
    </div>
  );
}

export default Overview;
