import {TypeClasses} from "./types";

function QRCode({classes}: TypeClasses) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`text-gray-300" ${classes}`}
      height="24"
      viewBox="0 96 960 960"
      width="24"
      style={{fill: "rgb(151 151 151)"}}
    >
      <path d="M120 536V216h320v320H120Zm80-80h160V296H200v160Zm-80 480V616h320v320H120Zm80-80h160V696H200v160Zm320-320V216h320v320H520Zm80-80h160V296H600v160Zm160 480v-80h80v80h-80ZM520 696v-80h80v80h-80Zm80 80v-80h80v80h-80Zm-80 80v-80h80v80h-80Zm80 80v-80h80v80h-80Zm80-80v-80h80v80h-80Zm0-160v-80h80v80h-80Zm80 80v-80h80v80h-80Z" />
    </svg>
  );
}

export default QRCode;
