import { useEffect } from "react";
import { useGetSubItemsFromItemsByMerchantIDQuery } from "store/api/merchantsApi";
import SubtableBody from "./SubtableBodyX";
import SubtableHeader from "./SubtableHeaderX";

interface SubTableTypes {
  subHeaders: string[];
  element: any;
  additionalData: any;
}

function TableSub({ subHeaders, element, additionalData }: SubTableTypes) {
  const {
    data: dataSubItemsMerchants,
    isLoading: isLoadingSubItemsMerchants,
    isError: isErrorSubItemsMerchants,
  } = useGetSubItemsFromItemsByMerchantIDQuery({
    idMerchant: additionalData?.idMerchant,
    idPrize: element?.prizeId,
  });

  return (
    <>
      <SubtableHeader subHeaders={subHeaders} />
      <SubtableBody
        idOrigin={element?.prizeId}
        subData={dataSubItemsMerchants}
        loading={isLoadingSubItemsMerchants}
        error={isErrorSubItemsMerchants}
      />
    </>
  );
}

export default TableSub;
