import {Suspense, lazy, useState, useEffect} from "react";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useChangeStatusItemMutation,
  useGetAllItemsNameQuery,
  useGetAllItemsQuery,
} from "store/api/itemApi";
import {setRefetchEndpoint} from "store/user/userSlice";

// Utils
import {TABLE_MERCHANT_ITEMS_ARCHIVED_HEADER} from "utils/itemsHeaders";
import FormSqueleton from "utils/FormSqueleton";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import RecoveryModal from "components/Modal/RecoveryModal";

const FilterFormItems = lazy(() => import("components/Filter/FilterFormItems"));

function ArchivedItems({setItemsSelected}: any) {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state.filters.filters);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id&status=ARCHIVED");

  const [setItemsStatus] = useChangeStatusItemMutation();

  const {
    data: dataItems,
    isLoading: isLoadingItems,
    isError: isErrorItems,
    refetch,
  } = useGetAllItemsQuery(params);

  useEffect(() => {
    setParams(
      `?${filters.sorting}&status=ARCHIVED${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  const {
    data: dataArchivedItemsName,
    isLoading: isLoadingArchivedItemsName,
    isError: isErrorArchivedItemsName,
  } = useGetAllItemsNameQuery("&status=ARCHIVED");

  useEffect(() => {
    if (dataArchivedItemsName && dataArchivedItemsName.length) {
      dispatch(setMerchantsName(dataArchivedItemsName));
    }
  }, [dataArchivedItemsName]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  const handleItemChecked = (items: number[]) => {
    setItemsSelected(items);
    setElementsChecked(items);
  };

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          showDropdownCheckbox={false}
          setMerchantsStatus={setItemsStatus}
          origin="items"
          searchBarPlaceholder="Search in Item name,Merchants name, Item ID, Merchant ID"
        />

        {showFilter && (
          <Suspense fallback={<FormSqueleton />}>
            <FilterFormItems handleShowFilter={setShowFilter} />
          </Suspense>
        )}
      </div>
      <Table
        data={dataItems !== undefined && dataItems.data ? dataItems?.data : []}
        loadingData={isLoadingItems}
        errorData={isErrorItems}
        refetch={refetch}
        results={dataItems !== undefined ? dataItems?.count : 1}
        origin="items"
        headers={TABLE_MERCHANT_ITEMS_ARCHIVED_HEADER}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeName",
          "prizeType",
          "merchantName",
          "merchantId",
          "startQty",
          "remaining",
          "claimed",
          "redeemed",
          "createdAt",
          "expiredAt",
          "daysToExpire",
        ]}
        sort={{
          prizeId: null,
          prizeName: null,
          prizeType: null,
          merchantName: null,
          merchantId: null,
          startQty: null,
          remaining: null,
          claimed: null,
          redeemed: null,
          createdAt: null,
          expiredAt: null,
          daysToExpire: null,
        }}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        orderingByAPI={true}
      />
      {modal.type === "recoveryItem" && (
        <RecoveryModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          promise={setItemsStatus}
          type={"Item"}
        />
      )}
    </>
  );
}

export default ArchivedItems;
