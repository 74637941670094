import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Dialog} from "@headlessui/react";

// Components
import Input from "components/Common/Input";
import Dropzone from "components/Common/Input/Dropzone";
import Label from "components/Common/Label";
import Subtitle from "components/Common/Subtitle";

// Assets
import Copy from "assets/Copy";
import {useLazyGetMerchantByIDQuery} from "store/api/merchantsApi";

function Merchant({id}: any) {
  const [userData, setUserData] = useState({
    id: 0,
    name: "",
    email: "",
    state: "",
    city: "",
    zip: "",
    address: "",
    socialReview: {url: ""},
    phone: "",
    geoLocation: "",
  });
  const [firstImage, setFirstImg] = useState("");
  const [secondImage, setSecondImg] = useState("");
  const [thirdImage, setThirdImg] = useState("");

  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [
    getMerchantByID,
    {
      data: dataMerchantByID,
      isLoading: isLoadingMerchantByID,
      isError: isErrorMerchantByID,
    },
  ] = useLazyGetMerchantByIDQuery();

  useEffect(() => {
    if (id) {
      getMerchantByID(id);
    }
  }, [id]);

  useEffect(() => {
    if (dataMerchantByID && !isLoadingMerchantByID && !isErrorMerchantByID) {
      setUserData({
        id: dataMerchantByID?.id,
        name: dataMerchantByID?.name,
        email: dataMerchantByID?.email,
        state: dataMerchantByID?.state,
        city: dataMerchantByID?.city,
        zip: dataMerchantByID?.zip,
        address: dataMerchantByID?.address,
        socialReview: {url: dataMerchantByID?.socialReview?.url},
        phone: dataMerchantByID?.phone,
        geoLocation: dataMerchantByID?.geoLocation,
      });

      dataMerchantByID?.images.map(
        (image: {
          imageId: number;
          name: string;
          type: string;
          newName: string;
          newUrl: string;
          url: string;
        }) => {
          if (image.type.toLowerCase() === "logo_large") {
            setFirstImg(image?.newUrl || image?.url);
          } else if (image.type.toLowerCase() === "profile") {
            setSecondImg(image?.newUrl || image?.url);
          } else if (image.type.toLowerCase() === "interior") {
            setThirdImg(image?.newUrl || image?.url);
          }
        }
      );
    }
  }, [dataMerchantByID, isLoadingMerchantByID, isErrorMerchantByID]);

  return (
    <div className="bg-white rounded px-5 pb-2">
      <div className="flex justify-between items-center">
        <Subtitle classes={"py-5"}>Merchant Details</Subtitle>
      </div>
      <hr />
      <form>
        <div className="grid gap-6 mb-6 lg:grid-cols-2 my-6">
          <div className="lg:w-9/12">
            <div className="flex items-end">
              <Input
                label={"Merchant ID"}
                value={userData?.id}
                disabled={true}
                readOnly={true}
                classes="w-full"
              />
              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.id.toString());
                  toast.success("Merchant ID coppied to clipboard");
                }}
              />
            </div>
            <div className="flex items-end">
              <Input
                label={"Merchant Name"}
                value={userData?.name}
                disabled={true}
                readOnly={true}
                classes={"mt-4 w-full"}
              />
              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.name);
                  toast.success("Name coppied to clipboard");
                }}
              />
            </div>
            <div className="flex items-end">
              <Input
                label={"Email"}
                value={userData?.email}
                disabled={true}
                readOnly={true}
                classes={"mt-4 w-full"}
              />

              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.email);
                  toast.success("Email coppied to clipboard");
                }}
              />
            </div>
            <div className="flex items-end">
              <Input
                label={"State"}
                classes={"mt-4 w-full"}
                value={userData?.state}
                disabled={true}
                readOnly={true}
              />
              <div>
                <Copy
                  classes="mb-3 ml-3 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(userData?.state);
                    toast.success("State coppied to clipboard");
                  }}
                />
              </div>
            </div>
            <div className="flex items-end">
              <Input
                label={"City / Town"}
                classes={"mt-4 w-full"}
                value={userData?.city}
                disabled={true}
                readOnly={true}
              />

              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.city);
                  toast.success("City coppied to clipboard");
                }}
              />
            </div>
            <div className="flex items-end">
              <Input
                label={"Zip code"}
                value={userData?.zip}
                disabled={true}
                readOnly={true}
                classes={"mt-4 w-full"}
              />

              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.zip);
                  toast.success("Zip Code coppied to clipboard");
                }}
              />
            </div>
            <div className="flex items-end">
              <Input
                label={"Address 1."}
                value={userData?.address}
                disabled={true}
                readOnly={true}
                classes={"mt-4 w-full"}
              />

              <Copy
                classes="mb-3 ml-3 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userData?.address);
                  toast.success("Address coppied to clipboard");
                }}
              />
            </div>
          </div>
          <div className="lg:border-l lg:max-w-md lg:justify-self-end px-8">
            <Dropzone
              label="Merchant exterior photo"
              handleUploadPhoto={setSecondImg}
              image={secondImage}
              id="2"
              classes="mt-4"
              imageCropModalType={imageCropModalType}
              setImageCropModalType={setImageCropModalType}
              type="PROFILE"
            />
            <Dropzone
              label="Merchant interior photo"
              classTopOption="mt-8"
              type="INTERIOR"
              handleUploadPhoto={setThirdImg}
              image={thirdImage}
              id="3"
              imageCropModalType={imageCropModalType}
              setImageCropModalType={setImageCropModalType}
            />

            <Dropzone
              label="Merchant logo"
              classTopOption="mt-8"
              handleUploadPhoto={setFirstImg}
              image={firstImage}
              id="1"
              imageCropModalType={imageCropModalType}
              setImageCropModalType={setImageCropModalType}
              type="LOGO_LARGE"
              sizePlaceholder={"16:9 @ 1920 x 1080"}
              sizesContainer={{
                minHeight: "172px",
                maxHeight: "220px",
                minWidth: "306px",
                maxWidth: "306px",
              }}
              sizes={{height: 1920, width: 1080}}
              aspect={16 / 9}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Merchant;
