import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import Input from "components/Common/Input";
import Spinner from "components/Common/Spinner";

// Assets
import {EyeHide, EyeView} from "assets/Eye";

// Store
import {useResetPasswordMutation} from "store/api/authApi";

interface User {
  password: string;
  confirmPassword: string;
}

interface ErrorRegister {
  show: boolean;
  msg: string;
}

const regexPassword =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const errorMessages = {
  required: "All fields are required",
  password:
    "Password must contain at least eight characters (including at least 1 capital letter, 1 digit and 1 special symbol)",
  notEqual: "Passwords do not match",
};

function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();

  const [
    resetPassword,
    {data: resetData, isLoading: isLoadingReset, isError: isErrorReset, error},
  ] = useResetPasswordMutation();

  const [user, setUser] = useState<User>({
    password: "",
    confirmPassword: "",
  });

  const [errorRegister, setErrorRegister] = useState<ErrorRegister>({
    show: false,
    msg: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  useEffect(() => {
    if (errorRegister.show && errorRegister.msg.length > 0) {
      toast.error(errorRegister.msg);
    }
  }, [errorRegister]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      password: user.password.trim(),
      confirmPassword: user.confirmPassword.trim(),
    };

    const isValidPassword = regexPassword.test(data.password);
    const isValidConfirmPassword = regexPassword.test(data.confirmPassword);

    if (data.password.length <= 0 || data.confirmPassword.length <= 0) {
      setErrorRegister({show: true, msg: errorMessages.required});
      return;
    }

    if (!isValidPassword || !isValidConfirmPassword) {
      setErrorRegister({
        show: true,
        msg: errorMessages.password,
      });
      return;
    }

    if (user.password !== user.confirmPassword) {
      setErrorRegister({
        show: true,
        msg: errorMessages.notEqual,
      });
      return;
    }

    const toastPromise = toast.promise(
      resetPassword({data, token: params.token}),
      {
        pending: "Reseting password",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while reseting the password</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Your password has been successfully reset");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error("Error while reseting the password");
      });
  };

  return (
    <div className="flex h-full">
      <div className="sm:w-0 md:w-2/5 lg:w-3/5 h-screen bg-gradient-to-bl from-primary-purple to-tertiary-purple-dark"></div>
      <div className="w-full md:w-3/5 lg:w-2/5 h-screen bg-slate-100 flex justify-center items-center">
        <form
          action=""
          className="bg-white rounded p-6 pr-20 w-4/5 border-t-8 border-primary-purple drop-shadow-[0_10px_10px_#9965d04b]"
        >
          <h1 className="text-3xl font-semibold mb-3">Reset your password</h1>
          <p className="text-sm text-gray-400 mb-12">
            Please create new password <br />
            Password must contain at least eight characters (including at least
            1 capital letter, 1 digit and 1 special symbol)
          </p>
          {isErrorReset && (
            <span className="bg-red-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
              {error?.data?.message || "An error occurred, try again later"}
            </span>
          )}
          {!isErrorReset && resetData && (
            <span className="bg-green-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
              Password changed successfully, you will be redirected to login
              page
            </span>
          )}
          <Input
            label="Password"
            value={user.password}
            required
            classes={`my-6`}
            placeholder="Enter your password"
            error={errorRegister.show && user.password.length <= 0 && true}
            classesInput={`pl-4 w-full ${
              errorRegister.show &&
              user.password?.length <= 0 &&
              "!border-red-500"
            }`}
            type={showPass ? "text" : "password"}
            onChange={(e: any) => {
              setUser({
                ...user,
                password: e.target.value,
              });
              setErrorRegister({show: false, msg: ""});
            }}
            icon={
              showPass ? (
                <EyeHide
                  classes="absolute right-3 top-3 cursor-pointer"
                  onClick={() => setShowPass((prev: any) => !prev)}
                />
              ) : (
                <EyeView
                  classes="absolute right-3 top-3 cursor-pointer"
                  onClick={() => setShowPass((prev: any) => !prev)}
                />
              )
            }
            classesLabel={`${
              user.password?.length > 0
                ? "text-primary-purple"
                : "text-gray-400"
            }`}
          />

          <Input
            label="Confirm Password"
            value={user.confirmPassword}
            required
            classes={`my-6`}
            placeholder="Confirm your password"
            error={
              errorRegister.show && user.confirmPassword?.length <= 0 && true
            }
            classesInput={`pl-4 w-full ${
              errorRegister.show &&
              user.confirmPassword?.length <= 0 &&
              "!border-red-500"
            }`}
            type={showPassConfirm ? "text" : "password"}
            onChange={(e: any) => {
              setUser({
                ...user,
                confirmPassword: e.target.value,
              });
              setErrorRegister({show: false, msg: ""});
            }}
            icon={
              showPassConfirm ? (
                <EyeHide
                  classes="absolute right-3 top-3 cursor-pointer"
                  onClick={() => setShowPassConfirm((prev: any) => !prev)}
                />
              ) : (
                <EyeView
                  classes="absolute right-3 top-3 cursor-pointer"
                  onClick={() => setShowPassConfirm((prev: any) => !prev)}
                />
              )
            }
            classesLabel={`${
              user.confirmPassword?.length > 0
                ? "text-primary-purple"
                : "text-gray-400"
            }`}
          />
          <Button
            classes="bg-primary-purple text-white mr-2 mt-2 text-sm font-base hover:bg-secondary-purple w-full"
            onClick={(e: any) => handleSubmit(e)}
          >
            {isLoadingReset ? (
              <Spinner classes="border-l-white border-r-white border-t-white w-5 h-5" />
            ) : (
              "Reset my password"
            )}
          </Button>
          <div className="flex justify-center mt-7 mb-2">
            <Button
              type="button"
              onClick={() => navigate("/")}
              classes="text-sm text-blue-400"
            >
              Back to login page
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
