interface TitleTypes {
  classes?: string;
  children: string | any[];
}

function Title({ children, classes }: TitleTypes) {
  return (
    <h1 className={`font-bold text-2xl mb-4 tracking-normal ${classes}`}>
      {children}
    </h1>
  );
}

export default Title;
