import { TypeClasses } from "./types";

function Pause({ classes }: TypeClasses) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <path d="M14 19V5h4v14Zm-8 0V5h4v14Z" fill="currentColor" />
    </svg>
  );
}

export default Pause;
