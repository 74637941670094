import { TypeClasses } from "./types";

function Play({ classes }: TypeClasses) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <path
        d="M8 19V5l11 7Zm2-7Zm0 3.35L15.25 12 10 8.65Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Play;
