import {EyeHide, EyeView} from "assets/Eye";
import Button from "components/Common/Button";
import Checkbox from "components/Common/Checkbox";
import Input from "components/Common/Input";
import Select from "components/Common/Input/Select";
import Spinner from "components/Common/Spinner";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useForgotPasswordMutation} from "store/api/authApi";

interface ErrorRegister {
  show: boolean;
  msg: string;
}

const errorMessages = {
  required: "All fields are required",
  email: "Invalid email, only Swiggle team members can reset passwords",
};

const regexEmail = /@(theswiggleapp|swiggleit|swiggle-it|swigglesavings)\b/;

function ForgotPassword({setBackTo}: any) {
  const [email, setEmail] = useState({value: "", error: false});

  const [errorForgotPass, setErrorForgotPass] = useState<ErrorRegister>({
    show: false,
    msg: "",
  });

  const [
    forgotPassword,
    {
      data: dataForgotPassword,
      isLoading: isLoadingForgotPassword,
      isError: isErrorForgotPassword,
    },
  ] = useForgotPasswordMutation();

  useEffect(() => {
    if (errorForgotPass.show && errorForgotPass.msg.length > 0) {
      toast.error(errorForgotPass.msg);
    }
  }, [errorForgotPass]);

  useEffect(() => {
    if (email.error) {
      const isValidEmail = regexEmail.test(email.value);

      if (isValidEmail) {
        setEmail({
          ...email,
          error: false,
        });
      }
    }
  }, [email]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorForgotPass({show: false, msg: ""});

    const isValidEmail = regexEmail.test(email.value);

    const data = {
      email: email?.value?.trim()?.toLowerCase(),
    };

    if (email.value.length <= 0) {
      setErrorForgotPass({show: true, msg: "Fields can't be empty"});
      return;
    }
    if (!isValidEmail) {
      setErrorForgotPass({
        show: true,
        msg: errorMessages.email,
      });
      setEmail({
        ...email,
        error: true,
      });
      return;
    }

    const toastPromise = toast.promise(forgotPassword(data.email), {
      pending: "Processing your request",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while processing your request</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(
            "An email has been sent to your registered email address, if you don't receive the email within a few minutes, please check your spam folder"
          );
        }
      })
      .catch((err) => {
        toast.error("Error while processing your request");
      });
  };

  return (
    <form
      action=""
      className="bg-white rounded p-6 pr-20 w-4/5 border-t-8 border-primary-purple drop-shadow-[0_10px_10px_#9965d04b]"
    >
      <h1 className="text-3xl font-semibold mb-3">Password Reset</h1>
      <p className="text-sm text-gray-400 mb-12">
        Enter your email to reset your password
      </p>
      {/* {errorLogin.show && (
        <span className="bg-red-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
          {errorLogin.msg}
        </span>
      )} */}
      <Input
        label="Email address"
        value={email?.value}
        required
        onChange={(e: any) =>
          setEmail({
            ...email,
            value: e.target.value,
          })
        }
        type="email"
        placeholder="name@theswiggleapp.com"
        classes={`my-6`}
        classesInput={`pl-4 w-full ${
          (errorForgotPass.show && email?.value.length <= 0) ||
          (email?.error && "!border-red-500")
        }`}
        error={errorForgotPass.show && email?.value.length <= 0 && email?.error}
        classesLabel={`${
          email?.value.length > 0 ? "text-primary-purple" : "text-gray-400"
        }`}
      />
      <Button
        classes="bg-primary-purple text-white mr-2 mt-2 text-sm font-base hover:bg-secondary-purple w-full"
        onClick={(e: any) => handleSubmit(e)}
      >
        {isLoadingForgotPassword ? (
          <Spinner classes="border-l-white border-r-white border-t-white w-5 h-5" />
        ) : (
          "Reset my password"
        )}
      </Button>
      <div className="flex justify-center mt-7 mb-2">
        <Button
          type="button"
          onClick={() => setBackTo("login")}
          classes="text-sm text-blue-400"
        >
          Back to login page
        </Button>
      </div>
    </form>
  );
}

export default ForgotPassword;
