import {useState, useRef, useEffect} from "react";
import Checkbox from "components/Common/Checkbox";

// Hooks
import {useClickOutside} from "hooks/useClickOutside";

// Redux
import {useDispatch, useSelector} from "react-redux";
import type {RootState} from "store/store";
import {
  setPage,
  setStatus,
  setURLFilters,
  setURLSearch,
} from "store/filters/filtersSlice";
import {setLastVisitedPage} from "store/tabs/tabsSlice";

// Assets
import {ArrowDown} from "assets/Arrows";

function DropdownCheckbox({options, titleAll, origin = "merchant"}: any) {
  const wrapperRef = useRef(null);
  const status = useSelector(
    (state: RootState) => state.filters.filters.status
  );
  const filters = useSelector((state: RootState) => state.filters.filters);
  const dispatch = useDispatch();

  const [fetchStatus, setFetchStatus] = useState<any>([]);
  const [dropdown, openDropdown] = useState(false);

  useClickOutside(wrapperRef, openDropdown);

  useEffect(() => {
    if (fetchStatus.length) {
      let params = "";
      // Status
      if (origin === "items" || origin === "grandprize") {
        if (filters.status.includes("all")) {
          params = params.concat(
            "&status_state=ACTIVE&status_state=PAUSED&status_state=COMPLETED"
          );
        } else {
          const newStatus = fetchStatus.map((el: string) => el.toUpperCase());
          params = params.concat(
            "&",
            `status_state=${newStatus.join("&status_state=")}`
          );
        }
      } else if (origin === "merchants") {
        if (filters.status.includes("all")) {
          params = params.concat(
            "&status=NEW&status=PUBLISHED&status=UNVERIFIED"
          );
        } else {
          const newStatus = fetchStatus.map((el: string) => el.toUpperCase());
          params = params.concat("&", `status=${newStatus.join("&status=")}`);
        }
      } else if (
        origin === "items_need_to_approve" ||
        origin === "grandprize_need_to_approve"
      ) {
        if (filters.status.includes("all")) {
          params = params.concat(
            "&status_state=PENDING_REVIEW&status_state=DECLINED"
          );
        } else {
          const newStatus = fetchStatus.map((el: string) => {
            if (el === "pending review") {
              return "pending_review";
            }
            return el.toUpperCase();
          });
          params = params.concat(
            "&",
            `status_state=${newStatus.join("&status_state=")}`
          );
        }
      }

      dispatch(setURLFilters(params));
      dispatch(setPage("&page=1"));
    }
  }, [fetchStatus]);

  const handleClickCheckbox = (e: any) => {
    dispatch(setLastVisitedPage(""));
    const {id, checked} = e.target;
    setFetchStatus([...status, id]);
    dispatch(setStatus([...status, id]));

    if (!checked) {
      setFetchStatus(status.filter((item: any) => item !== id));
      dispatch(setStatus(status.filter((item: any) => item !== id)));
    }
  };

  return (
    <div className="relative">
      <div
        onClick={() => openDropdown((prev) => !prev)}
        className={`${
          dropdown ? "border-primary-purple" : "border-transparent"
        } h-9  items-center rounded-l-lg border-2  hover:bg-primary-white cursor-pointer p-2.5 pl-4 z-0 bg-white rounded-l-t font-medium text-sm flex justify-between`}
      >
        <span className="flex capitalize status-filter whitespace-nowrap">
          {status.length > 0 ? status.join(", ", status) : `${titleAll}`}
        </span>
        <span>
          <ArrowDown />
        </span>
      </div>
      {dropdown && (
        <div
          className="bg-white py-3 px-4 absolute l-0 rounded-b drop-shadow-xl z-30 w-max"
          ref={wrapperRef}
        >
          {options?.map((opt: string) => (
            <div className="flex items-center mb-3">
              <Checkbox
                id={opt}
                key={opt}
                type="checkbox"
                name={opt}
                onChange={handleClickCheckbox}
                isChecked={status?.includes(opt)}
              />
              <label
                htmlFor={opt}
                className="ml-3 font-medium text-sm capitalize cursor-pointer"
              >
                {opt}
              </label>
            </div>
          ))}
          <div className="flex items-center mb-2">
            <Checkbox
              id="all"
              key="all"
              type="checkbox"
              name="all"
              onChange={handleClickCheckbox}
              isChecked={status?.includes("all")}
            />
            <label
              htmlFor="all"
              className="ml-3 font-medium text-sm cursor-pointer"
            >
              {titleAll}
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownCheckbox;
