// Routes
import {Routes, Route} from "react-router-dom";

// Pages
import Auth from "page/Auth";

// Components
import Sidebar from "components/Sidebar";
import ResetPasswordModal from "components/Modal/ResetPasswordModal";
import LogoutTimeout from "components/LogoutTimeout";
import ResetPassword from "page/Auth/ResetPassword";

// Templates
import Header from "templates/Header";

// Redux
import {useDispatch, useSelector} from "react-redux";
import type {RootState} from "store/store";

// Utils
import UserRoutes from "utils/UserRoutes";
import {useEffect} from "react";
import {useLazyGetProfileDataQuery} from "store/api/authApi";
import {setData} from "store/user/userSlice";

function App() {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const [getDataProfile, {data: dataProfile}] = useLazyGetProfileDataQuery();

  useEffect(() => {
    if (
      user?.user &&
      user?.user?.isAuth &&
      (!user.user.hasOwnProperty("data") || user?.user?.data?.id === 0)
    ) {
      getDataProfile();
    }
  }, [user]);

  useEffect(() => {
    if (dataProfile) {
      dispatch(setData(dataProfile));
    }
  }, [dataProfile]);

  const loggedComponent = () => (
    <section className="flex w-full">
      <LogoutTimeout />
      <Sidebar />
      <div className="flex w-full flex-col">
        <Header />
        <div className="p-7">
          <UserRoutes />
        </div>
      </div>
      <ResetPasswordModal />
    </section>
  );

  const notLoggedComponent = () => (
    <section className="w-full">
      <Routes>
        <Route path="/user/:token/reset-password" element={<ResetPassword />} />
        <Route path="/*" element={<Auth />} />
      </Routes>
    </section>
  );

  return user?.user?.isAuth ? loggedComponent() : notLoggedComponent();
}

export default App;
