import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {setSidebarCategoryOpenModal} from "store/modal/modalSlice";

function CategoriesInfoSidebar({
  children,
  fnClean,
  showCloseButton = true,
  setShowCloseButton,
}: any) {
  const modal = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setSidebarCategoryOpenModal(false));

    if (fnClean) {
      fnClean();
    }
    setShowCloseButton && setShowCloseButton(true);
  };

  return (
    <Transition appear show={modal.isSidebarCategoryOpen} as={Fragment}>
      <Dialog
        open={modal.isSidebarCategoryOpen}
        onClose={() => handleCloseModal()}
        as="div"
        className={`fixed inset-0 z-10 flex justify-center items-center overflow-y-auto ${
          modal.isSidebarCategoryOpen && "bg-seconday-purple-dark bg-opacity-80"
        }
          `}
      >
        <div className="absolute top-0 right-0 h-full">
          <span
            className="absolute top-2 right-3 cursor-pointer text-gray-600 font-bold"
            onClick={() => handleCloseModal()}
          >
            X
          </span>
          {children}
        </div>
      </Dialog>
    </Transition>
  );
}

export default CategoriesInfoSidebar;
