import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ItemsType {
    id: number,
    item_name: string,
    type: string,
    merchant: string,
    merchant_id: number,
    start_qty: number,
    qty_remaining: number,
    claimed: number,
    redeemed: number,
    created: string,
    exp_date: string,
    exp_days: string,
    status: string
}

interface ItemsState {
    items: ItemsType[]
}

// Define the initial state using that type
const initialState: ItemsState = {
    items: [
        {
            id: 62513,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "11 days",
            status: "Paused",
        },
        {
            id: 62514,
            item_name: "Item Name",
            type: "Offer",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "24 Aug. 2022",
            exp_days: "0 days",
            status: "Completed",
        },
        {
            id: 62515,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "24 Aug. 2022",
            exp_days: "11 days",
            status: "Active",
        },
        {
            id: 62516,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "11 days",
            status: "Active",
        },
        {
            id: 62517,
            item_name: "Item Name",
            type: "Offer",
            merchant: "Merchant Name 2",
            merchant_id: 24532,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "15 days",
            status: "Active",
        },
        {
            id: 62518,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 2",
            merchant_id: 24532,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "27 Aug. 2022",
            exp_days: "0 days",
            status: "Completed",
        },
        {
            id: 62523,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "11 days",
            status: "Archived",
        },
        {
            id: 62524,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "11 days",
            status: "Archived",
        },
        {
            id: 62525,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 1",
            merchant_id: 24531,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "11 days",
            status: "Archived",
        },
        {
            id: 62526,
            item_name: "Item Name",
            type: "Prize",
            merchant: "Merchant Name 3",
            merchant_id: 24533,
            start_qty: 100,
            qty_remaining: 63,
            claimed: 105,
            redeemed: 37,
            created: "17 Aug. 2022",
            exp_date: "20 Aug. 2022",
            exp_days: "5 days",
            status: "Archived",
        },
    ]
}

export const itemsSlice = createSlice({
    name: 'items',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        increment: (state) => {
            return state;
        },
    },
})

export const { increment } = itemsSlice.actions

export const itemsArchivedStore = (state: RootState) => state.items.items.filter((el: any) => el.status.toLowerCase() === 'archived');
export const itemssStore = (state: RootState) => state.items.items.filter((el: any) => el.status.toLowerCase() !== 'archived');
export const getItemByID = (state: RootState, value: string | undefined) => {
    if (typeof value !== 'undefined') {
        return state.items.items.find((el: any) => el.id === parseInt(value))
    }
};

export default itemsSlice.reducer