import { memo } from "react";

// Routes
import { useLocation } from "react-router-dom";

// Assets
import Admin from "assets/Admin";
import Configuration from "assets/Configuration";
import Dashboard from "assets/Dashboard";
import Items from "assets/Items";
import Merchants from "assets/Merchant";
import Users from "assets/Users";

function Header() {
  const params = useLocation();

  const decodeUrlPathname = () => {
    let decodeURL = params.pathname.split("/");
    decodeURL.shift();

    if (decodeURL === undefined) return;

    if (decodeURL.length >= 2) {
      return (
        <div className="flex">
          {decodeURL[0] === "merchants" && (
            <Merchants classes={"h-5 w-5 mr-2"} />
          )}
          {decodeURL[0] === "items" ||
            (decodeURL[0] === "grandprize" && (
              <Items classes={"h-5 w-5 mr-2"} />
            ))}
          <span className="mr-2">{decodeURL[0]}</span>
          <span className="mr-2">&gt;</span>

          {decodeURL[0] === "merchants" && decodeURL[1] === "create" && (
            <span>Create Merchant</span>
          )}
          {decodeURL[0] === "items" && decodeURL[1] === "create" && (
            <span>Create Item</span>
          )}
          {decodeURL[0] === "items" && decodeURL[1].match(/\d+/g) && (
            <span>Item Details</span>
          )}
          {decodeURL[0] === "grandprize" && decodeURL[1].match(/\d+/g) && (
            <span>Grandprize Details</span>
          )}
          {decodeURL[0] === "merchants" && decodeURL[1].match(/\d+/g) && (
            <span>Merchant Details</span>
          )}
        </div>
      );
    }

    if (params.pathname === "/")
      return (
        <div className="flex items-center">
          <Dashboard classes={"h-5 w-5 mr-2"} />
          Dashboard
        </div>
      );
    return (
      <div className="flex items-center">
        {params.pathname === "/merchants" && (
          <Merchants classes={"h-5 w-5 mr-2"} />
        )}
        {params.pathname === "/items" && <Items classes={"h-5 w-5 mr-2"} />}
        {params.pathname === "/users" && <Users classes={"h-5 w-5 mr-2"} />}
        {params.pathname === "/admins" && <Admin classes={"h-5 w-5 mr-2"} />}
        {params.pathname === "/configuration" && (
          <Configuration classes={"h-5 w-5 mr-2"} />
        )}

        {params.pathname.slice(1, params.pathname.length)}
      </div>
    );
  };

  return (
    <div className="bg-tertiary-purple font-sm capitalize text-sm text-gray-400 p-1 px-7">
      {decodeUrlPathname()}
    </div>
  );
}

export default memo(Header);
