// @ts-nocheck
import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'store/store'
import customFetchBase from './customFetchBase';


export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: customFetchBase,
  tagTypes: ['Admins'],
  endpoints: (builder) => ({
    getAdminByID: builder.query({
      query: (id) => ({
        url: `/administrator/user/${id}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    getAllAdmins: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/administrator/user`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["Admins"]
    }),
    changeAdminPassword: builder.mutation<any, any>({
      // @ts-ignore
      query: (data) => ({
        url: `administrator/change-password`,
        method: 'PATCH',
        body: {
          ...data
        },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    changeAdminStatus: builder.mutation<any, any>({
      // @ts-ignore
      query: ({ id, data }) => ({
        url: `administrator/user/${id}/change-status`,
        method: 'POST',
        body: {
          ...data
        },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Admins']
    }),
  }),
})

export const {
  useGetAdminByIDQuery,
  useGetAllAdminsQuery,
  useChangeAdminPasswordMutation,
  useChangeAdminStatusMutation
} = adminApi