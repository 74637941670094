import {useEffect, useState} from "react";
import {ArrowBack} from "assets/Arrows";
import Button from "components/Common/Button";
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import LocationCategories from "./LocationCategories";
import LocationAreas from "./LocationAreas";
import {useGetLocationByIDQuery} from "store/api/locationApi";
import Modal from "components/Modal";
import {transformDate} from "utils/transformDate";
import ShopFilters from "./ShopFilters";
import TrandingPlaces from "./TrandingPlaces";

function LocationDetail() {
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(location?.state?.origin || null);
  const [dataCardArea, setDataCardArea] = useState({
    cityCount: 0,
    zipCount: 0,
    merchantCount: 0,
    itemCount: 0,
    redemptionCount: 0,
  });

  const {
    data: dataLocation,
    isLoading: isLoadingLocation,
    isError: isErrorLocation,
    refetch,
  } = useGetLocationByIDQuery({id});

  const handleSelectedTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="text-sm text-gray-400 flex hover:text-primary-purple max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to location list
        </Link>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full  md:w-6/12">
          <Title classes="flex items-center mb-0">
            {dataLocation?.dmaDescription}
          </Title>
          <p className="text-xs text-gray-500 mt-2">
            Created: {transformDate(dataLocation?.createdAt)} / Modify date:{" "}
            {transformDate(dataLocation?.updatedAt)}/ Publish:{" "}
            {dataLocation?.isPublished ? "Yes" : "No"}
          </p>
        </div>
        {currentTab === "Zip Codes" && (
          <div className="w-full  md:w-6/12 justify-end flex items-center">
            <div className="flex gap-3">
              <div className="p-3 md:w-24 rounded bg-purple-100 border-2 border-primary-purple">
                <p className="text-xs text-gray-500">Cities</p>
                <p className="text-xl">{dataCardArea?.cityCount}</p>
              </div>
              <div className="p-3 md:w-24 rounded bg-purple-100 border-2 border-primary-purple">
                <p className="text-xs text-gray-500">Zip Codes</p>
                <p className="text-xl">{dataCardArea?.zipCount}</p>
              </div>
              <div className="p-3 md:w-24 rounded bg-purple-100 border-2 border-primary-purple">
                <p className="text-xs text-gray-500">Merchants</p>
                <p className="text-xl">{dataCardArea?.merchantCount}</p>
              </div>
              <div className="p-3 md:w-24 rounded bg-purple-100 border-2 border-primary-purple">
                <p className="text-xs text-gray-500">Items</p>
                <p className="text-xl">{dataCardArea?.itemCount}</p>
              </div>
              <div className="p-3 md:w-24 rounded bg-purple-100 border-2 border-primary-purple">
                <p className="text-xs text-gray-500">Redemption</p>
                <p className="text-xl">{dataCardArea?.redemptionCount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <Tabs
          tabsName={[
            "Categories",
            "Shop Filters",
            "Zip Codes",
            "Trending Places",
          ]}
          setSelectedTab={handleSelectedTab}
          setCurrentTab={currentTab === "Zip Codes" ? 2 : 0}
        >
          <>
            <hr />
            <LocationCategories id={!id ? "" : id} />
          </>
          <>
            <hr />
            <ShopFilters id={!id ? "" : id} />
          </>
          <>
            <hr />
            <LocationAreas
              id={!id ? "" : id}
              setDataCardArea={setDataCardArea}
            />
          </>
          <>
            <hr />
            <TrandingPlaces id={!id ? "" : id} />
          </>
        </Tabs>
      </div>
      {(isLoadingLocation || isErrorLocation) && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            {isErrorLocation ? (
              <div className="flex flex-col w-full justify-center items-center mt-5 px-4">
                <p className="">
                  An error occurred while trying to retrieve the item
                </p>
                <Button onClick={refetch} classes="font-semibold">
                  Try again
                </Button>
              </div>
            ) : (
              <p>Loading Location...</p>
            )}
          </div>
        </Modal>
      )}
    </section>
  );
}

export default LocationDetail;
