import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

// Components
import Input from "components/Common/Input";
import DropdownCategory from "components/Common/DropdownCategory";
import DatePicker from "components/Common/Input/DatePicker";
import Select from "components/Common/Input/Select";
import Subtitle from "components/Common/Subtitle";
import Dropzone from "components/Common/Input/Dropzone";
import Button from "components/Common/Button";
import Info from "page/Configuration/Info";

// Assets
import Upload from "assets/Upload";

// Redux
import {
  useCreateGrandPrizeMutation,
  useLoadRedemptionCodeCSVMutation,
  useUpdateGrandPrizeImageItemMutation,
} from "store/api/itemApi";
import {Link} from "react-router-dom";
import Textarea from "components/Common/Textarea";
import {addDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";
import Search from "assets/Search";
import {ArrowNext} from "assets/Arrows";
import {Tooltip} from "react-tooltip";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import routes from "utils/routesByRole";
import {useSelector} from "react-redux";
import {RootState} from "store/store";

interface IGrandPrize {
  sendForm: boolean;
  setSendForm: Function;
  checked: string;
  merchantID: string | number | null;
}

function GrandPrize({sendForm, setSendForm, checked, merchantID}: IGrandPrize) {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user.user);

  const [itemData, setItemData] = useState({
    prizeType: "REDEMPTION_CODE",
    name: "",
    description: "",
    prizeCount: 0,
    startDate: "",
    endDate: "",
    price: 0,
    categories: [{}],
    winrateType: "STANDARD",
    purchaseUrl: "",
    discountCode: "",
    redemptionUrl: "",
    terms: "",
  });

  const [itemsTypeRedemption, setItemsTypeRedemption] = useState({
    redemptionCodes: "",
    redemptionUrl: "",
  });

  const [itemsTypeShipping, setItemsTypeShipping] = useState({
    businessWebsite: "",
    businessEmail: "",
    shippingTime: "",
  });

  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [elementsParentName, setElementsParentName] = useState<any[]>([]);
  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.gp?.includes("add")
      ? false
      : true;
  });

  const [inputError, setInputError] = useState(false);

  const [imageCropModalType, setImageCropModalType] = useState("default");

  // Images
  const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);

  const [coverGrandPrize, setCoverGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [firstRegularGrandPrize, setFirstRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [secondRegularGrandPrize, setSecondRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [thridRegularGrandPrize, setThirdRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [fourthRegularGrandPrize, setFourthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [fifthRegularGrandPrize, setFifthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [sixthRegularGrandPrize, setSixthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [seventhRegularGrandPrize, setSeventhRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [eighthRegularGrandPrize, setEighthRegularGrandPrize] = useState({
    src: "",
    description: "",
  });
  const [videoGrandPrize, setVideoGrandPrize] = useState({
    src: "",
    description: "",
  });

  const [objectImages, setObjectImages] = useState<{
    coverPhoto: any;
    regular1: any;
    regular2: any;
    regular3: any;
    regular4: any;
    regular5: any;
    regular6: any;
    regular7: any;
    regular8: any;
    video: any;
  }>({
    coverPhoto: "",
    regular1: "",
    regular2: "",
    regular3: "",
    regular4: "",
    regular5: "",
    regular6: "",
    regular7: "",
    regular8: "",
    video: "",
  });

  const [qtyPhotos, setQtyPhotos] = useState(4);

  const [createGrandPrize] = useCreateGrandPrizeMutation();
  const [updateGrandPrizeImage] = useUpdateGrandPrizeImageItemMutation();

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  const [
    loadCVS,
    {
      data: dataCVS,
      isLoading: isLoadingCVS,
      isError: isErrorCVS,
      // @ts-ignore
    },
  ] = useLoadRedemptionCodeCSVMutation();

  useEffect(() => {
    if (sendForm && checked === "grandprize") {
      handleCreateGrandPrize();
    }
  }, [sendForm, checked]);

  useEffect(() => {
    const arrFormData = [
      {data: objectImages.coverPhoto},
      {data: objectImages.regular1},
      {data: objectImages.regular2},
      {data: objectImages.regular3},
      {data: objectImages.regular4},
      {data: objectImages.regular5},
      {data: objectImages.regular6},
      {data: objectImages.regular7},
      {data: objectImages.regular8},
      {data: objectImages.video},
    ];

    setArrayImageToUpload(arrFormData);
  }, [objectImages]);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement.id,
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      }
    });

    setElementsParentName(res);
  }, [elementsChecked, dataCategories]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const updateItemImages = async (
    imageToUpload: any,
    id: number,
    key: number,
    imageType: string
  ) => {
    const toastPromiseImage = toast.promise(
      updateGrandPrizeImage({
        ...imageToUpload,
        idMerchant: merchantID,
        idItem: id,
      }),
      {}
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key}</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          const message = () => {
            if (imageType === "COVER_PHOTO" || imageType === "VIDEO")
              return imageType.toLowerCase();
            return imageType.toLowerCase() + " photo";
          };

          toast.success(`${message()} uploaded`);
          // MerchantItems
        }
      })
      .catch((err) => {
        toast.error(`Error while uplading image ${key}`);
      });
  };

  const handleCreateGrandPrize = async () => {
    setSendForm(false);

    if (!merchantID) return toast.error("You must select a merchant");

    if (
      !itemData.name ||
      !itemData.price ||
      !itemData.prizeCount ||
      !itemData.startDate ||
      !itemData.endDate ||
      !itemData.description ||
      !itemData.winrateType ||
      !itemData.terms ||
      (itemData.discountCode && !itemData.purchaseUrl) ||
      (!itemData.discountCode && itemData.purchaseUrl) ||
      !(
        (coverGrandPrize.src && coverGrandPrize.description) ||
        (videoGrandPrize.src && videoGrandPrize.description)
      ) ||
      !(firstRegularGrandPrize.src && firstRegularGrandPrize.description) ||
      !(secondRegularGrandPrize.src && secondRegularGrandPrize.description) ||
      !(thridRegularGrandPrize.src && thridRegularGrandPrize.description) ||
      (fourthRegularGrandPrize.src && !fourthRegularGrandPrize.description) ||
      (fifthRegularGrandPrize.src && !fifthRegularGrandPrize.description) ||
      (sixthRegularGrandPrize.src && !sixthRegularGrandPrize.description) ||
      (seventhRegularGrandPrize.src && !seventhRegularGrandPrize.description) ||
      (eighthRegularGrandPrize.src && !eighthRegularGrandPrize.description)
    ) {
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    if (itemData.discountCode && itemData.discountCode.length < 4) {
      toast.error("The referral code cannot be shorter than 4 characters");
      return;
    }

    if (!itemData?.prizeCount) {
      toast.error("Prizes available cannot be 0");
      return;
    }

    const arrCategoriesID = elementsChecked?.map((el: any) => {
      if (el === "all") {
        return dataCategories.map((category: {id: number; name: string}) => {
          // @ts-ignore
          return {id: parseInt(category.id)};
        });
      } else {
        return {id: parseInt(el)};
      }
    });

    let categoryArr = [];

    if (arrCategoriesID[0]?.length > 1) {
      categoryArr = [...arrCategoriesID[0]];
    } else {
      categoryArr = arrCategoriesID;
    }

    let dataToSend;

    if (itemData.prizeType === "REDEMPTION_CODE") {
      if (!itemsTypeRedemption.redemptionCodes) {
        setInputError(true);
        toast.error("You must fill all the required fields");
        return;
      }

      const cleanupArr = itemsTypeRedemption.redemptionCodes
        .split(",")
        .map((el) => el.trim());

      dataToSend = {
        ...itemData,
        ...itemsTypeRedemption,
        redemptionCodes: cleanupArr,
        categories: categoryArr,
        price: parseFloat(itemData.price.toFixed(1)),
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
      };
    }

    if (itemData.prizeType === "QR_CODE") {
      dataToSend = {
        ...itemData,
        categories: categoryArr,
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
      };
    }

    if (itemData.prizeType === "SHIP_TO_ADDRESS") {
      if (!itemsTypeShipping.businessEmail || !itemsTypeShipping.shippingTime) {
        setInputError(true);
        toast.error("You must fill all the required fields");
        return;
      }

      dataToSend = {
        ...itemData,
        ...itemsTypeShipping,
        categories: categoryArr,
        startDate: new Date(itemData.startDate)?.toISOString(),
        endDate: new Date(itemData.endDate)?.toISOString(),
      };
    }

    const toastPromise = toast.promise(
      createGrandPrize({
        idMerchant: merchantID,
        data: dataToSend,
      }),
      {
        pending: "Creating Grand Prize",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating grand prize</h2>
              <p className="text-xs">
                {" "}
                {res?.error?.data?.message || res?.error?.data?.code}{" "}
              </p>
            </div>
          );
        } else {
          toast.success("Grand Prize Created");

          const imagesUpload = arrayImageToUpload.map(
            (image: {data: {data: any; type: string}}, key: number) => {
              if (!image.data.data) return;

              let formData = new FormData();

              const imageType = image?.data?.type;

              if (imageType === "COVER_PHOTO") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append("description", coverGrandPrize.description);

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR1") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  firstRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR2") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  secondRegularGrandPrize.description
                );

                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );

                // console.log("Send cover photo", formData);
              } else if (imageType === "REGULAR3") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  thridRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR4") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  fourthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR5") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  fifthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR6") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  sixthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR7") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  seventhRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "REGULAR8") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append(
                  "description",
                  eighthRegularGrandPrize.description
                );

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              } else if (imageType === "VIDEO") {
                formData.append("media", image.data.data);
                formData.append("name", image.data.data.name);
                formData.append("type", image.data.type);
                formData.append("description", videoGrandPrize.description);

                // console.log("Send cover photo", formData);
                updateItemImages(
                  {data: formData},
                  res?.data?.prizeId,
                  key,
                  imageType
                );
              }
            }
          );

          Promise.all(imagesUpload).then(() => {
            setTimeout(() => {
              navigate("/grandprize/" + res?.data?.prizeId, {
                state: {
                  idMerchant: res?.data?.retailerId,
                  idItem: res?.data?.prizeId,
                },
              });
            }, 3000);
          });
        }
      })
      .catch((err) => {
        toast.error("Error while creating grand prize");
      });
  };

  const handleSendCSV = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);

    const toastPromise = toast.promise(loadCVS(formData), {
      pending: "Reading CSV",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while reading CSV</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("CSV Read");
          if (res.data.codes) {
            setItemsTypeRedemption({
              ...itemsTypeRedemption,
              redemptionCodes: res.data.codes.join(", "),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while reading CSV");
      });
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();
    formData.append("media", data);
    formData.append("name", data.name);
    formData.append("type", type);
    formData.append("description", type);

    const imgUrl = URL.createObjectURL(data);

    if (type === "COVER_PHOTO") {
      showImage({src: imgUrl, description: coverGrandPrize.description});
      setObjectImages({
        ...objectImages,
        coverPhoto: {data: data, type: type},
      });
    }

    if (type === "REGULAR1") {
      showImage({
        src: imgUrl,
        description: firstRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular1: {data: data, type: type},
      });
    }

    if (type === "REGULAR2") {
      showImage({
        src: imgUrl,
        description: secondRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular2: {data: data, type: type},
      });
    }
    if (type === "REGULAR3") {
      showImage({
        src: imgUrl,
        description: thridRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular3: {data: data, type: type},
      });
    }
    if (type === "REGULAR4") {
      showImage({
        src: imgUrl,
        description: fourthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular4: {data: data, type: type},
      });
    }
    if (type === "REGULAR5") {
      showImage({
        src: imgUrl,
        description: fifthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular5: {data: data, type: type},
      });
    }
    if (type === "REGULAR6") {
      showImage({
        src: imgUrl,
        description: sixthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular6: {data: data, type: type},
      });
    }
    if (type === "REGULAR7") {
      showImage({
        src: imgUrl,
        description: seventhRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular7: {data: data, type: type},
      });
    }
    if (type === "REGULAR8") {
      showImage({
        src: imgUrl,
        description: eighthRegularGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        regular8: {data: data, type: type},
      });
    }
    if (type === "VIDEO") {
      showImage({
        src: imgUrl,
        description: videoGrandPrize.description,
      });
      setObjectImages({
        ...objectImages,
        video: {data: data, type: type},
      });
    }
  };

  const handleTypeGrandPrize = () => {
    if (itemData.prizeType === "REDEMPTION_CODE") {
      return (
        <>
          <div className="flex flex-col mb-6">
            <Textarea
              label="Enter a generic redemption code"
              placeholder="Input your generic redemption code Ех. code1, code2, code3, code4"
              classes="mb-5"
              rows={7}
              required={true}
              error={
                !itemsTypeRedemption.redemptionCodes && inputError
                  ? true
                  : false
              }
              value={itemsTypeRedemption.redemptionCodes}
              onChange={(e: any) => {
                setItemsTypeRedemption({
                  ...itemsTypeRedemption,
                  redemptionCodes: e.target.value,
                });
              }}
            />
            <p className="text-xs text-gray-500 mt-2">
              * # of codes needs to be greater than the number of prizes
              available*
            </p>
            <Info hasArrow={false} classes="mt-4 max-w-lg">
              <p className="text-sm text-gray-500">
                <b>Please enter each code separately</b>, be sure to put a comma
                between the codes. Example: Tsode23, Code21, Dog51, Medium15.
              </p>
            </Info>
          </div>
          <hr className="my-5" />
          <div className="flex flex-col mb-5">
            <p className="text-xs mb-5 mt-1 text-gray-400">
              Or bulk upload CSV file with a list of unique redemption code
            </p>
            <input
              type="file"
              id="csv_file"
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e: any) => handleSendCSV(e.target.files[0])}
            />
            <Button
              variant="normal"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("csv_file")?.click();
              }}
            >
              <Upload />
              <p className="ml-2 whitespace-nowrap">Upload CSV</p>
            </Button>
            <Info hasArrow={false} classes="mt-4 max-w-lg">
              <p className="text-sm text-gray-500">
                You can also Upload a CSV file that can be created on the basis
                of Excel, Google Sheets. To do this, download the example file
                from the link, open it and replace the example codes codes
                provided by the Merchant.{" "}
                <Link
                  to="/example_csv_winspin.csv"
                  className="text-blue-600 underline underline-offset-2 underline-blue-600"
                  target="_blank"
                  download
                >
                  DOWNLOAD EXAMPLE FILE
                </Link>
              </p>
            </Info>
          </div>
          <hr className="mb-5" />
          <div className="flex flex-col mb-5">
            <Textarea
              label="Terms & Conditions"
              error={!itemData.terms && inputError ? true : false}
              placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
              classes="mb-5"
              maxLength={2500}
              rows={7}
              required={true}
              value={itemData.terms}
              onChange={(e: any) => {
                setItemData({...itemData, terms: e.target.value});
              }}
            />
          </div>
          <Input
            label="Enter website"
            classes="mb-5"
            placeholder="Add url for prize"
            value={itemsTypeRedemption.redemptionUrl}
            onChange={(e: any) =>
              setItemsTypeRedemption({
                ...itemsTypeRedemption,
                redemptionUrl: e.target.value,
              })
            }
            required={true}
            error={!itemsTypeRedemption.redemptionUrl && inputError}
          />
        </>
      );
    } else if (itemData.prizeType === "QR_CODE") {
      return (
        <div className="flex flex-col mb-6">
          <Textarea
            label="Terms & Conditions"
            error={!itemData.terms && inputError ? true : false}
            placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
            classes="mb-5"
            rows={7}
            required={true}
            value={itemData.terms}
            onChange={(e: any) => {
              setItemData({...itemData, terms: e.target.value});
            }}
          />
        </div>
      );
    } else {
      // SHIP_TO_ADDRESS
      return (
        <>
          <Input
            label="Business website"
            classes="mb-5"
            required={true}
            value={itemsTypeShipping.businessWebsite}
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                businessWebsite: e.target.value,
              })
            }
            error={!itemsTypeShipping.businessWebsite && inputError}
          />

          <Input
            label="Customer service email"
            classes="mb-5"
            required={true}
            value={itemsTypeShipping.businessEmail}
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                businessEmail: e.target.value,
              })
            }
            error={!itemsTypeShipping.businessEmail && inputError}
          />
          <Input
            label="Estimated shipping time"
            classes="mb-5"
            required={true}
            value={itemsTypeShipping.shippingTime}
            placeholder="Ex. 3-4 weeks"
            onChange={(e: any) =>
              setItemsTypeShipping({
                ...itemsTypeShipping,
                shippingTime: e.target.value,
              })
            }
            error={!itemsTypeShipping.shippingTime && inputError}
          />
          <div className="flex flex-col mb-6">
            <Textarea
              label="Terms & Conditions"
              error={!itemData.terms && inputError ? true : false}
              placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
              classes="mb-5"
              rows={7}
              required={true}
              value={itemData.terms}
              onChange={(e: any) => {
                setItemData({...itemData, terms: e.target.value});
              }}
            />
          </div>
        </>
      );
    }
  };

  return (
    <div className="flex">
      <div className="flex flex-col  w-6/7">
        <div className="flex mt-8 w-full ">
          <div className="w-1/2 mr-4">
            <>
              <Textarea
                label="Grand Prize headline"
                error={!itemData.name && inputError ? true : false}
                placeholder="Name or short description of GP"
                classes="mb-5"
                rows={7}
                maxLength={58}
                required={true}
                value={itemData.name}
                onChange={(e: any) => {
                  setItemData({...itemData, name: e.target.value});
                }}
              />
            </>
            <Input
              label="How many prizes are available"
              required={true}
              value={itemData.prizeCount === 0 ? "" : itemData.prizeCount}
              onChange={(e: any) =>
                setItemData({
                  ...itemData,
                  prizeCount: !isNaN(e.target.value)
                    ? Math.abs(e.target.value)
                    : 0,
                })
              }
              placeholder="Ex. 10"
              classes="my-5"
              error={!itemData.prizeCount && inputError}
            />
            <DatePicker
              label="Start Date of Grand Prize"
              classes="mb-5"
              required={true}
              value={itemData.startDate}
              onChange={(e: any) =>
                setItemData({...itemData, startDate: e.target.value})
              }
              error={!itemData.startDate && inputError}
              min="today"
              max={addDays(getTodayDate(), 90)}
            />
            <DatePicker
              label="End Date of Grand Prize"
              classes="mb-5"
              required={true}
              value={itemData.endDate}
              onChange={(e: any) =>
                setItemData({...itemData, endDate: e.target.value})
              }
              disabled={!itemData.startDate}
              error={!itemData.endDate && inputError}
              min={
                itemData?.startDate
                  ? addDays(itemData.startDate, 1)
                  : addDays(getTodayDate(), 1)
              }
              max={itemData?.startDate && addDays(itemData?.startDate, 180)}
            />
            <Input
              label="Retail value of Grand prize"
              value={itemData.price === 0 ? "" : itemData.price}
              required={true}
              onChange={(e: any) =>
                setItemData({
                  ...itemData,
                  price: !isNaN(e.target.value) ? Math.abs(e.target.value) : 0,
                })
              }
              placeholder="Provide retail value"
              classes="mb-5"
              error={!itemData.price && inputError}
            />
          </div>
          <div className="w-1/2 mr-4">
            {/*
             *
             *
             *
             */}
            <DropdownCategory
              hasLabel={true}
              label="Categories"
              openDropdown={openDropdown}
              dropdown={dropdown}
              elementsChecked={elementsChecked}
              elementsName={elementsName}
              handleClickCheckbox={handleClickCheckbox}
              dataCategories={getFilteredNames(
                dataCategories,
                dropdownSearchFilter,
                ""
              )}
              handleClickDropdown={handleClickDropdown}
              required={true}
              isError={!elementsChecked.length && inputError}
              disabled={isInputDisabled}
              isErrorCategories={isErrorCategories}
              isLoadingCategories={isLoadingCategories}
              classNameSelect="w-full"
              error={error}
              showNames={false}
              expandItems={dropdownSearchFilter}
              hasSearchValue={{
                show: true,
                content: (
                  <div className="flex mb-3 justify-between">
                    <div className="flex items-center w-full">
                      <label htmlFor="search">
                        <Search classes="h-6 w-6 mr-2" />
                      </label>
                      <input
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Search category"
                        className="decoration-none outline-none w-full"
                        onChange={(e: any) =>
                          setDowpdownSearchFilter(e.target.value)
                        }
                        value={dropdownSearchFilter}
                      />
                    </div>
                  </div>
                ),
              }}
            />
            <div
              className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                elementsChecked.length > 0
                  ? "border border-gray-400"
                  : "border-none"
              } rounded-md`}
            >
              {elementsParentName.map((element, index) => (
                <div
                  className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                  key={index}
                >
                  <Tooltip id="my-tooltip"></Tooltip>
                  <div className="flex flex-wrap">
                    {element?.root && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.rootId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.root} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.parentId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.parentId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.parent} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.childId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.childId}`}
                        className="font-medium text-sm"
                      >
                        {element?.child}
                      </div>
                    )}
                  </div>
                  <Button
                    type="button"
                    onClick={() => {
                      setElementsChecked((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.id
                        )
                      );
                      setElementsName((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.child
                        )
                      );
                    }}
                    classes="ml-6 text-blue-400 text-sm"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
            <Select
              label="Type of grand prize"
              value={itemData.winrateType}
              onChange={(e: any) =>
                setItemData({...itemData, winrateType: e.target.value})
              }
              classes="mt-2"
              classesSelect="border border-gray-400 py-2.5 pl-2.5 pr-9"
              required={true}
              error={!itemData.winrateType && inputError}
            >
              <>
                <option value={"STANDARD"} selected>
                  Standard (1/500 spins)
                </option>
                <option value={"PREMIUM"}>Premium (1/1,000 spins)</option>
                <option value={"EXCLUSIVE"}>Exclusive (1/2,000 spins)</option>
                <option value={"EVENT"}>Event (1/20 spins)</option>
                <option value={"TIMES_UP"}>Timesup (Next spin wins)</option>
                <option value={"PRESTIGE"}>Prestige (1/1,000,000 spins)</option>
              </>
            </Select>
          </div>
        </div>
        <hr className="w-11/12 text-center mt-6 mb-8" />
        <Subtitle>Other Information</Subtitle>
        <Input
          label="Link to the product for purchase"
          value={itemData.purchaseUrl}
          onChange={(e: any) =>
            setItemData({...itemData, purchaseUrl: e.target.value})
          }
          placeholder="Add a link to purchase on your website"
          classes="my-5 w-96"
          required={itemData.discountCode ? true : false}
          error={
            itemData.discountCode && !itemData.purchaseUrl && inputError
              ? true
              : false
          }
        />
        <Input
          label="Referral code"
          value={itemData.discountCode}
          onChange={(e: any) =>
            setItemData({...itemData, discountCode: e.target.value})
          }
          placeholder="Provide your referral code for discount"
          classes="mb-5 w-96"
          maxLength={12}
          max={12}
          required={itemData.purchaseUrl ? true : false}
          error={
            !itemData.discountCode && itemData.purchaseUrl && inputError
              ? true
              : false
          }
        />
        <hr className="w-11/12 text-center mt-6 mb-8" />
        <div className="flex w-full flex-col">
          <div className="pr-4">
            <Subtitle>Video Section</Subtitle>
            <p className="text-sm text-gray-500 mt-2">
              In the video section you can show video material and provide more
              info about your grand prize.
            </p>
            <div>
              <Info hasArrow={false} classes="mt-4 !w-full">
                <p className="text-sm text-gray-500">
                  Video: file size 100mb, duration 45sec , HD
                </p>
              </Info>
            </div>
          </div>
          {/* Video */}
          <div className="flex w-full">
            <div className="w-1/2 mr-4">
              <Dropzone
                handleUploadPhoto={handleUploadPhoto}
                image={videoGrandPrize.src}
                imageSetter={setVideoGrandPrize}
                required={!coverGrandPrize.src}
                label="Video 16:9"
                id="VIDEO"
                classesLabel="mt-5"
                type="VIDEO"
                typeItem="grand prize"
                isVideo={true}
                classesBorder={`${
                  !coverGrandPrize.src &&
                  !videoGrandPrize.src &&
                  inputError &&
                  "!border-red-500"
                }`}
                disabledInput={coverGrandPrize.src ? true : false}
                imageCropModalType={imageCropModalType}
                setImageCropModalType={setImageCropModalType}
                sizesContainer={{
                  minHeight: "12rem",
                  maxHeight: "12rem",
                  minWidth: "calc(100% - 20px)",
                  maxWidth: "calc(100% - 20px)",
                }}
              />
            </div>
            <div className="w-1/2 mr-4">
              <div className="flex flex-col mb-8 mt-5">
                <Textarea
                  label="Provide descriptions"
                  placeholder="Provide text descriptions"
                  classes="mb-5"
                  classesInput="mt-2"
                  required={true}
                  error={
                    !videoGrandPrize.description && inputError ? true : false
                  }
                  rows={7}
                  disabled={coverGrandPrize.src ? true : false}
                  value={videoGrandPrize.description}
                  onChange={(e: any) => {
                    setVideoGrandPrize({
                      ...videoGrandPrize,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pr-4">
            <Subtitle>Photo Of The Grand Prize</Subtitle>
            <p className="text-sm text-gray-500  mt-2">
              In the photo section you can show photo material and provide more
              info about your grand prize.
            </p>
          </div>
          {/* First Image (Cover Photo) */}
          <div className="flex w-full">
            <div className="w-1/2 mr-4">
              <Dropzone
                handleUploadPhoto={handleUploadPhoto}
                image={coverGrandPrize.src}
                imageSetter={setCoverGrandPrize}
                required={!videoGrandPrize.src}
                label="Grand Prize Photo (1320 x 1972)"
                sizePlaceholder={"picture \n 1:1.5 @ 1320 x 1972"}
                id="COVER_PHOTO"
                classesLabel="mt-5"
                type="COVER_PHOTO"
                classesBorder={`${
                  !coverGrandPrize.src &&
                  !videoGrandPrize.src &&
                  inputError &&
                  "!border-red-500"
                }`}
                typeItem="grand prize"
                disabledInput={videoGrandPrize.src ? true : false}
                imageCropModalType={imageCropModalType}
                setImageCropModalType={setImageCropModalType}
                sizes={{width: 1320, height: 1972}}
                aspect={330 / 493}
              />
              <p className="mb-8 mt-2 text-xs text-gray-400">
                Cover photo which will be shown in the carousel on the customers
                app
              </p>
            </div>
            <div className="w-1/2 mr-4">
              <div className="flex flex-col mb-8 mt-5">
                <Textarea
                  label="Provide title"
                  placeholder="Enter a description"
                  classes="mb-5"
                  classesInput="mt-2"
                  rows={7}
                  value={coverGrandPrize.description}
                  required={true}
                  error={
                    !coverGrandPrize.description && inputError ? true : false
                  }
                  onChange={(e: any) => {
                    setCoverGrandPrize({
                      ...coverGrandPrize,
                      description: e.target.value,
                    });
                  }}
                  disabled={videoGrandPrize.src ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="pr-4">
            <Subtitle>Provide Long Description</Subtitle>
            <p className="text-sm text-gray-500  mt-2">
              Long description should include all details about your grand prize
            </p>
          </div>
          {/* Long description - item */}
          <div className="flex w-full">
            <div className="w-1/2 mr-4">
              <div className="flex flex-col mb-8 mt-5">
                <Textarea
                  label="Provide long descriptions"
                  required={true}
                  error={!itemData.description && inputError ? true : false}
                  placeholder="Enter a description"
                  classes="mb-5"
                  classesInput="mt-2"
                  rows={7}
                  value={itemData.description}
                  onChange={(e: any) => {
                    setItemData({...itemData, description: e.target.value});
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pr-4">
            <Subtitle>Image Section</Subtitle>
            <p className="text-sm text-gray-500 mt-2">
              In this section, you upload 3 images as mandatory, and 5 optional
              images. After your upload all mandatory images, additional images
              will add automatically, if you don’t need them, just ignore them.
            </p>
          </div>
          {/* Second Image (REGULAR1) */}
          <div className="flex w-full">
            <div className="w-1/2 mr-4">
              <Dropzone
                handleUploadPhoto={handleUploadPhoto}
                image={firstRegularGrandPrize.src}
                imageSetter={setFirstRegularGrandPrize}
                required={true}
                label="Grand Prize Photo (1080 x 1080)"
                id="REGULAR1"
                classesLabel="mt-5"
                type="REGULAR1"
                classesBorder={`${
                  !firstRegularGrandPrize.src && inputError && "!border-red-500"
                }`}
                typeItem="grand prize"
                imageCropModalType={imageCropModalType}
                setImageCropModalType={setImageCropModalType}
              />
              <p className="mb-8 mt-2 text-xs text-gray-400">
                Big photo which will be shown in the carousel on the customers
                app
              </p>
            </div>
            <div className="w-1/2 mr-4">
              <div className="flex flex-col mb-8 mt-5">
                <Textarea
                  label="Provide descriptions"
                  placeholder="Provide descriptions"
                  classes="mb-5"
                  classesInput="mt-2"
                  required={true}
                  error={
                    !firstRegularGrandPrize.description && inputError
                      ? true
                      : false
                  }
                  rows={7}
                  value={firstRegularGrandPrize.description}
                  onChange={(e: any) => {
                    setFirstRegularGrandPrize({
                      ...firstRegularGrandPrize,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {/* Third Image (REGULAR2) */}
          {qtyPhotos >= 3 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={secondRegularGrandPrize.src}
                  imageSetter={setSecondRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR2"
                  classesLabel="mt-5"
                  type="REGULAR2"
                  classesBorder={`${
                    !secondRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide descriptions"
                    placeholder="Provide descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !secondRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={secondRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setSecondRegularGrandPrize({
                        ...secondRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Fourth Image (REGULAR3) */}
          {qtyPhotos >= 4 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={thridRegularGrandPrize.src}
                  imageSetter={setThirdRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR3"
                  classesLabel="mt-5"
                  type="REGULAR3"
                  classesBorder={`${
                    !thridRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide descriptions"
                    placeholder="Provide descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !thridRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={thridRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setThirdRegularGrandPrize({
                        ...thridRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Fifth Image (REGULAR4) */}
          {qtyPhotos >= 5 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={fourthRegularGrandPrize.src}
                  imageSetter={setFourthRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR4"
                  classesLabel="mt-5"
                  type="REGULAR4"
                  classesBorder={`${
                    !fourthRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide descriptions"
                    placeholder="Provide descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !fourthRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={fourthRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setFourthRegularGrandPrize({
                        ...fourthRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Sixth Image (REGULAR5) */}
          {qtyPhotos >= 6 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={fifthRegularGrandPrize.src}
                  imageSetter={setFifthRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR5"
                  classesLabel="mt-5"
                  type="REGULAR5"
                  classesBorder={`${
                    !fifthRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide descriptions"
                    placeholder="Provide descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !fifthRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={fifthRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setFifthRegularGrandPrize({
                        ...fifthRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Seventh Image (REGULAR6) */}
          {qtyPhotos >= 7 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={sixthRegularGrandPrize.src}
                  imageSetter={setSixthRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR6"
                  classesLabel="mt-5"
                  type="REGULAR6"
                  classesBorder={`${
                    !sixthRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide descriptions"
                    placeholder="Provide descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !sixthRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={sixthRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setSixthRegularGrandPrize({
                        ...sixthRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Eighth Image (REGULAR7) */}
          {qtyPhotos >= 8 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={seventhRegularGrandPrize.src}
                  imageSetter={setSeventhRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR7"
                  classesLabel="mt-5"
                  type="REGULAR7"
                  classesBorder={`${
                    !seventhRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide text descriptions"
                    placeholder="Provide text descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !seventhRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={seventhRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setSeventhRegularGrandPrize({
                        ...seventhRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Nineth Image (REGULAR8) */}
          {qtyPhotos >= 9 && (
            <div className="flex w-full">
              <div className="w-1/2 mr-4">
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={eighthRegularGrandPrize.src}
                  imageSetter={setEighthRegularGrandPrize}
                  required={true}
                  label="Grand Prize Photo (1080 x 1080)"
                  id="REGULAR8"
                  classesLabel="mt-5"
                  type="REGULAR8"
                  classesBorder={`${
                    !eighthRegularGrandPrize.src &&
                    inputError &&
                    "!border-red-500"
                  }`}
                  typeItem="grand prize"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                />
              </div>
              <div className="w-1/2 mr-4">
                <div className="flex flex-col mb-8 mt-5">
                  <Textarea
                    label="Provide text descriptions"
                    placeholder="Provide text descriptions"
                    classes="mb-5"
                    classesInput="mt-2"
                    rows={7}
                    required={true}
                    error={
                      !eighthRegularGrandPrize.description && inputError
                        ? true
                        : false
                    }
                    value={eighthRegularGrandPrize.description}
                    onChange={(e: any) => {
                      setEighthRegularGrandPrize({
                        ...eighthRegularGrandPrize,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Buttons */}
          <div className="flex">
            <Button
              variant="normal"
              onClick={(e: any) => {
                e.preventDefault();
                if (qtyPhotos >= 9) return;
                setQtyPhotos((prev) => prev + 1);
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Add Item Photo
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:border-l lg:w-3/8 lg:justify-self-end px-4">
        <Select
          label="Type of Grand Prize"
          value={itemData.prizeType}
          onChange={(e: any) =>
            setItemData({...itemData, prizeType: e.target.value})
          }
          classes="mb-6 mt-8"
          classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
        >
          <>
            <option value="REDEMPTION_CODE" selected>
              Redemption code
            </option>
            <option value="QR_CODE">QR Code</option>
            <option value="SHIP_TO_ADDRESS">Will be shipped to customer</option>
          </>
        </Select>
        {handleTypeGrandPrize()}
      </div>
    </div>
  );
}

export default GrandPrize;
