// Logos
import logo from "assets/logo.svg";

function Logo({hide}: any) {
  return (
    <div className="flex w-full pt-9 pb-2 items-center space-x-3 justify-center">
      <img
        // src="../../../assets/logo.png"
        src={"https://storage.googleapis.com/swiggle-logos/logoSmall.png"}
        alt="Logo - WinSpin"
        className={`${!hide ? "px-4 w-52" : "w-20"} `}
      />
      {/* {!hide && <p className="text-2xl leading-6 text-white">WinSpin</p>} */}
    </div>
  );
}

export default Logo;
