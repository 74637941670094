import {lazy, memo, Suspense, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {Dialog} from "@headlessui/react";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";
import Input from "components/Common/Input";
import Label from "components/Common/Label";
import Button from "components/Common/Button";
import Modal from "components/Modal";

// Assets
import Delete from "assets/Delete";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {setRefetchEndpoint} from "store/user/userSlice";

import {
  useChangeStatusMerchantMutation,
  useGetAllMerchantsQuery,
  useGetAllMerchantsArchivedNameQuery,
} from "store/api/merchantsApi";
import {setMerchantsName} from "store/merchants/merchantsSlice";

import {
  setEmployeeSelectedToDelete,
  setIdSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";

// Utils
import {TABLE_MERCHANT_ARCHIVED_HEADER} from "utils/merchantHeaders";
import FormSqueleton from "utils/FormSqueleton";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import getNamesFromCurrentPage from "utils/getNamesFromCurrentPage";
import RecoveryModal from "components/Modal/RecoveryModal";
import Profile from "assets/Profile";
import Recovery from "assets/Recovery";
import routes from "utils/routesByRole";
import {setSorting} from "store/filters/filtersSlice";

const FilterFormMerchants = lazy(
  () => import("components/Filter/FilterFormMerchants")
);

function ArchivedMerchants() {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state.filters.filters);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);

  const [consentDelete, setConsentDelete] = useState("");
  const [inputError, setInputError] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id&status=ARCHIVED");

  useEffect(() => {
    dispatch(setSorting("ordering=id"));
  }, []);

  const {
    data: dataMerchantsArchived,
    isLoading: isLoadingMerchantsArchived,
    isError: isErrorMerchantsArchived,
    refetch,
  } = useGetAllMerchantsQuery(params);

  const [setMerchantsStatus] = useChangeStatusMerchantMutation();

  const {
    data: dataMerchantsArchivedName,
    isLoading: isLoadingMerchantsArchivedName,
    isError: isErrorMerchantsArchivedName,
  } = useGetAllMerchantsArchivedNameQuery();

  useEffect(() => {
    // console.log(dataMerchantsName);
    if (dataMerchantsArchivedName && dataMerchantsArchivedName.length) {
      dispatch(setMerchantsName(dataMerchantsArchivedName));
    }
  }, [dataMerchantsArchivedName]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}&status=ARCHIVED${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const handleDeleteMerchant = async () => {
    if (consentDelete.toLowerCase() !== "delete") {
      setInputError(true);
    } else {
      setInputError(false);

      if (modal.id) {
        const toastPromise = toast.promise(
          setMerchantsStatus({id: modal.id, status: "DELETED"}),
          {
            pending: "Deleting merchant",
          }
        );

        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while deleting merchant</h2>
                  <p className="text-xs"> {res?.error?.data?.code} </p>
                </div>
              );
            } else {
              toast.success("Merchant Deleted");
              setConsentDelete("");
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              dispatch(setIdSelectedToDelete(""));
              // Reseting elements checkeds
              dispatch(setResetElChecked(true));
            }
          })
          .catch((err) => {
            toast.error("Error while deleting merchant");
          });
      } else {
        elementsChecked?.map(async (merchantID) => {
          // @ts-ignore
          const id = parseInt(merchantID);
          const toastPromise = toast.promise(
            setMerchantsStatus({id, status: "DELETED"}),
            {
              pending: "Deleting merchant",
            }
          );

          await toastPromise
            .then((res: any) => {
              if (res.error) {
                toast.error(
                  <div>
                    <h2>Error while deleting merchant</h2>
                    <p className="text-xs"> {res?.error?.data?.code} </p>
                  </div>
                );
              } else {
                toast.success("Merchant Deleted");
                setConsentDelete("");
                dispatch(setType(""));
                dispatch(setOpenModal(false));
                dispatch(setIdSelectedToDelete(""));
              }
            })
            .catch((err) => {
              toast.error("Error while deleting merchant");
            });
        });
      }
    }
  };

  const getNames = () => {
    if (modal.id) {
      const employeeFiltered = dataMerchantsArchivedName?.filter(
        (employee: any) => {
          if (employee.id == parseInt(modal.id)) {
            return employee;
          }
        }
      );

      // console.log(employeeFiltered);
      if (employeeFiltered?.length > 0) {
        return <b>{employeeFiltered[0]?.name} </b>;
      } else {
        return <b></b>;
      }
    } else {
      const employees = elementsChecked.map((el) => {
        return dataMerchantsArchivedName?.filter((employee: any) => {
          if (employee.id === el) {
            return employee;
          }
        });
      });

      const employeesFlatted = employees?.flat();

      return employeesFlatted?.map((employee: any) => {
        if (employee) {
          return <b>{employee.name}, </b>;
        } else {
          return <b></b>;
        }
      });

      // const elements = employees.map((el) => {
      //   return el[0];
      // });

      // dispatch(setEmployeeSelectedToDelete({ element: elements, id: null }));
    }
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.merchant?.includes("view") && {
        title: "View Profile",
        svg: <Profile classes="mr-3" />,
        type: "redirectToMerchant",
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.item?.includes("add") && {
        title: "Recover",
        svg: <Recovery classes="mr-3" />,
        type: "recover",
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.merchant?.includes("delete") && {
        title: "Delete",
        svg: <Delete classes="mr-3" />,
        type: "delete",
      },
    ],
    []
  );

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          setMerchantsStatus={setMerchantsStatus}
          showDropdownCheckbox={false}
          origin="merchants"
          searchBarPlaceholder="Search by Merchants name, location, ID, zip-code"
        />
        {showFilter && (
          <Suspense fallback={<FormSqueleton />}>
            <FilterFormMerchants handleShowFilter={setShowFilter} />
          </Suspense>
        )}
      </div>
      <Table
        data={
          dataMerchantsArchived !== undefined &&
          dataMerchantsArchived.retailers &&
          !user.refetchEndpoint
            ? dataMerchantsArchived?.retailers
            : []
        }
        loadingData={isLoadingMerchantsArchived}
        errorData={isErrorMerchantsArchived}
        refetch={refetch}
        results={
          dataMerchantsArchived !== undefined ? dataMerchantsArchived?.count : 1
        }
        origin="merchants"
        headers={TABLE_MERCHANT_ARCHIVED_HEADER}
        itemOptions={ITEMS}
        show={[
          "id",
          "name",
          "zip",
          "createdAt",
          "itemsCount",
          "lastActivityDate",
          "archivedAt",
          "status",
        ]}
        sort={{
          id: null,
          name: null,
          zip: null,
          createdAt: null,
          itemsCount: null,
          lastActivityDate: null,
          archivedAt: null,
          status: null,
        }}
        typeOptions="group"
        handleItemChecked={handleItemChecked}
        urlPagination={{
          origin: "merchantArchived",
          url: "?ordering=id&status=ARCHIVED",
        }}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        setMerchantsStatus={setElementsChecked}
        orderingByAPI={true}
      />
      {modal.type === "recoveryMerchant" && (
        <RecoveryModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          promise={setMerchantsStatus}
          type={"Merchant"}
        />
      )}

      {modal.type === "deleteMerchant" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                  <Delete classes="text-red-500 h-10 w-10" />
                </div>
                <h1 className="text-red-500 mt-4">Delete</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to delete merchant {getNames()}
                  from archive?
                </p>
              </div>
              <Label classes="pl-6 mt-5 mb-0 ">Type DELETE to confirm</Label>
              <Input
                inputID="input-modal-delete-merchant"
                classes="px-5 font-normal mb-6"
                classesInput=""
                label=""
                value={consentDelete}
                onChange={(e) => setConsentDelete(e.target.value)}
                placeholder=""
                error={consentDelete !== "delete" && inputError}
              />

              <hr />

              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                    dispatch(setIdSelectedToDelete(""));
                    setConsentDelete("");
                  }}
                >
                  Close
                </Button>
                <Button variant="danger" onClick={() => handleDeleteMerchant()}>
                  Delete
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </>
  );
}

export default memo(ArchivedMerchants);
