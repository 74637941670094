import Notification from "components/Notification";
import React from "react";

function Notifications() {
  return (
    <div>
      <Notification />
    </div>
  );
}

export default Notifications;
