import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {useGenerateQrMutation} from "store/api/merchantsApi";

// Assets
import Archive from "assets/Archive";

function ChangeQRCode({userData, typeQRCode}: any) {
  const dispatch = useDispatch();

  const [generateQR] = useGenerateQrMutation();

  const handleGenerateQR = async () => {
    const toastPromise = toast.promise(
      generateQR({idMerchant: userData.id, typeQr: typeQRCode}),
      {
        pending: "Generating QR Code",
      }
    );

    await toastPromise.then((res: any) => {
      if (res.error) {
        toast.error(
          <div>
            <h2>Error while generating QR Code</h2>
            <p className="text-xs"> {res?.error?.data?.code} </p>
          </div>
        );
      } else {
        toast.success("QR Code Updated");

        setTimeout(() => {
          dispatch(setType(""));
          dispatch(setOpenModal(false));
        }, 1000);
      }
    });
  };

  return (
    <Modal>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <h1 className="text-yellow-500 mt-4">Warning</h1>
          <p className="text-sm mt-4 text-center">
            Confirm that you want to change a
            {typeQRCode === "LOYALTY" && " Loyalty"} QR code for this merchant,
            if so, the previous {typeQRCode === "LOYALTY" && "Loyalty"} QR codes
            will be deactivated
          </p>
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="alert"
            onClick={() => {
              handleGenerateQR();
            }}
          >
            Change
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeQRCode;
