import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

// Components
import Button from "components/Common/Button";
import Checkbox from "components/Common/Checkbox";
import Input from "components/Common/Input";
import Spinner from "components/Common/Spinner";

// Assets
import {EyeHide, EyeView} from "assets/Eye";

// Redux
import {useLazySetAuthLongQuery, useSetAuthMutation} from "store/api/authApi";
import {useDispatch, useSelector} from "react-redux";
import {setAuth, setLongLife} from "store/user/userSlice";
import {RootState} from "store/store";
import {setOpenModal, setType} from "store/modal/modalSlice";

function Login({setBackTo}: any) {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const [
    setLogin,
    {
      data: dataAuth,
      isLoading: isLoadingAuth,
      isError: isErrorAuth,
      error: errorAuth,
    },
  ] = useSetAuthMutation();

  const [
    setLoginLong,
    {
      data: dataAuthLong,
      isLoading: isLoadingAuthLong,
      isError: isErrorAuthLong,
      error: errorAuthLong,
    },
  ] = useLazySetAuthLongQuery();

  const [username, setUser] = useState("");
  const [password, setPass] = useState("");
  const [errorLogin, setErrorLogin] = useState({show: false, msg: ""});
  const [showPass, setShowPass] = useState(false);
  const [longLifeT, setLongLifeT] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrorLogin({show: false, msg: ""});

    const data = {
      username: username?.trim()?.toLowerCase(),
      password: password?.trim(),
    };

    if (data.username.length <= 0 || data.password.length <= 0) {
      setErrorLogin({show: true, msg: "Fields can't be empty"});
      return;
    } else {
      setLogin(data);
    }
  };

  useEffect(() => {
    if (dataAuth) {
      // console.log(user.user.longLife);
      try {
        dispatch(setAuth(dataAuth));
        if (user.user.longLife) {
          setLoginLong();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [dataAuth]);

  useEffect(() => {
    // console.log(dataAuthLong);
    if (dataAuthLong && user.longLife) {
      const data = {...dataAuth, dataAuthLong};
      // console.log(data);
      // dispatch(setAuth(dataAuthLong));
    }
  }, [dataAuthLong]);

  useEffect(() => {
    if (isErrorAuth) {
      // @ts-ignore
      if (errorAuth?.data?.status === 401 || errorAuth?.data?.status === 404) {
        setErrorLogin({
          show: true,
          // @ts-ignore
          msg: errorAuth?.data?.message,
        });
      } else if (
        errorAuth?.data?.status === 403 &&
        errorAuth?.data?.message ===
          "Your user account is created, please wait for the superadmin to approve your account"
      ) {
        dispatch(setType("awaitApproveRegister"));
        dispatch(setOpenModal(true));
      } else if (
        errorAuth?.data?.status === 403 &&
        errorAuth?.data?.message ===
          "You can not sign in with this email. Please contact with superadmins"
      ) {
        dispatch(setType("awaitRejectRegister"));
        dispatch(setOpenModal(true));
      } else if (errorAuth?.data?.message) {
        setErrorLogin({
          show: true,
          // @ts-ignore
          msg: errorAuth?.data?.message,
        });
      } else {
        setErrorLogin({
          show: true,
          msg: "Invalid email or password, try again.",
        });
      }
    }
  }, [isErrorAuth]);

  const handleClickCheckbox = (e: any) => {
    const {checked} = e.target;
    setLongLifeT(true);
    dispatch(setLongLife(true));
    if (!checked) {
      setLongLifeT(false);
      dispatch(setLongLife(false));
    }
  };

  return (
    <form
      action=""
      className="bg-white rounded p-6 pr-20 w-4/5 border-t-8 border-primary-purple drop-shadow-[0_10px_10px_#9965d04b]"
    >
      <h1 className="text-3xl font-semibold mb-3">Login</h1>
      <p className="text-sm text-gray-400 mb-12">
        Enter your credentials to access your account.
      </p>
      {errorLogin.show && (
        <span className="bg-red-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
          {errorLogin.msg}
        </span>
      )}
      <Input
        label="Email address"
        value={username}
        onChange={(e: any) => setUser(e.target.value)}
        type="email"
        placeholder="name@theswiggleapp.com"
        classes={`my-6`}
        classesInput={`pl-4 w-full ${
          errorLogin.show && username.length <= 0 && "!border-red-500"
        }`}
        error={errorLogin.show && username.length <= 0}
        classesLabel={`${
          username.length > 0 ? "text-primary-purple" : "text-gray-400"
        }`}
      />
      <Input
        label="Password"
        value={password}
        placeholder="Enter your password"
        error={errorLogin.show && password.length <= 0}
        classesInput={`pl-4 w-full ${
          errorLogin.show && password.length <= 0 && "!border-red-500"
        }`}
        type={showPass ? "text" : "password"}
        onChange={(e: any) => setPass(e.target.value)}
        icon={
          showPass ? (
            <EyeHide
              classes="absolute right-3 top-3 cursor-pointer"
              onClick={() => setShowPass((prev) => !prev)}
            />
          ) : (
            <EyeView
              classes="absolute right-3 top-3 cursor-pointer"
              onClick={() => setShowPass((prev) => !prev)}
            />
          )
        }
        classesLabel={`${
          password.length > 0 ? "text-primary-purple" : "text-gray-400"
        }`}
      />
      <div className="mt-8 mb-6 flex items-center justify-between">
        <div className="flex">
          <Checkbox
            id="rememberme"
            onChange={(e: any) => handleClickCheckbox(e)}
            isChecked={longLifeT}
          />
          <label htmlFor="rememberme" className="text-sm ml-2">
            Remember me
          </label>
        </div>
        <Button
          type="button"
          onClick={() => setBackTo("forgot")}
          classes="text-sm text-blue-400"
        >
          Forgot password?
        </Button>
      </div>

      <Button
        classes="bg-primary-purple text-white mr-2 mt-2 text-sm font-base hover:bg-secondary-purple w-full"
        onClick={(e: any) => handleSubmit(e)}
      >
        {isLoadingAuth ? (
          <Spinner classes="border-l-white border-r-white border-t-white w-5 h-5" />
        ) : (
          "Sign in"
        )}
      </Button>
      <div className="flex justify-center mt-7 mb-2">
        <Button
          type="button"
          onClick={() => setBackTo("register")}
          classes="text-sm text-blue-400"
        >
          Create account
        </Button>
      </div>
    </form>
  );
}

export default Login;
