import { TypeClasses } from "./types";

export function ArrowBack({ classes }: TypeClasses) {
  return (
    <svg
      id="back"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="trending_flat_FILL0_wght400_GRAD0_opsz48"
        d="M10.216,23.582l1.018-.995L8.724,20.076H24V18.655H8.7l2.511-2.511-.995-.995L6,19.366Z"
        transform="translate(-3 -7.15)"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowDown({ classes }: TypeClasses) {
  return (
    <svg
      id="arrow-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`w-4 h-4 ${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="arrow_drop_down_FILL0_wght400_GRAD0_opsz48"
        d="M19,25.05l-5-5H24Z"
        transform="translate(-7 -10.05)"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowPrev({ classes }: TypeClasses) {
  return (
    <svg
      aria-hidden="true"
      className={`${classes}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function ArrowNext({ classes }: TypeClasses) {
  return (
    <svg
      aria-hidden="true"
      className={`${classes}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
