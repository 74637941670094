import {Fragment, useEffect, useState, FocusEvent} from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, Switch, Tab} from "@headlessui/react";
import debounce from "lodash.debounce";
import {toast} from "react-toastify";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";

// Types
import {IState, ICity} from "country-state-city";

// Components
import Title from "components/Common/Title";
import Input from "components/Common/Input";
import Dropzone from "components/Common/Input/Dropzone";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import PlacesAutocomplete from "components/Common/Input/PlacesAutoComplete";

// Assets
import {ArrowBack, ArrowNext} from "assets/Arrows";
import Subtitle from "components/Common/Subtitle";
import AddPerson from "assets/AddPerson";

// Utils

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
  useCancelInviteMutation,
  useChangeImageMerchantMutation,
  useCreateMerchantMutation,
  useLazyGetMerchantInfoYelpGoogleQuery,
  useSendInviteMutation,
} from "store/api/merchantsApi";
import {setPage, setURLFilters} from "store/filters/filtersSlice";
import Delete from "assets/Delete";
import Checkbox from "components/Common/Checkbox";
import {RootState} from "store/store";
import {useGetFormattedAdress} from "hooks/useGetFormattedAddress";
import DropdownCategory from "components/Common/DropdownCategory";
import Select from "components/Common/Input/Select";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import {setImageModal, setOpenModal, setType} from "store/modal/modalSlice";
import Label from "components/Common/Label";
import {
  findItemById,
  findMatches,
  formatArrayToAMPM,
  getFilteredNames,
  transformTimeFormat,
} from "utils/workingHoursUtils";
import Tabs from "components/Common/Tabs";
import Search from "assets/Search";
import routes from "utils/routesByRole";
import {Tooltip} from "react-tooltip";
import {uniqueArray} from "utils/arraysHelper";
import DatePicker from "components/Common/Input/DatePicker";
import {addDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import {getRoundedDate} from "utils/diffenceDate";

function AddNewMerchant() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {ready: autoCompleteAddressReady} = usePlacesAutocomplete();

  const [getFormattedAddress, addressDetais] = useGetFormattedAdress();

  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [userData, setUserData] = useState({
    name: "",
    ownerFirstName: "",
    ownerLastName: "",
    email: "",
    businessPhone: "",
    businessWeb: "",
    sizeType: "",
    state: "",
    city: "",
    isgp: false,
    zipCode: "",
    address1: "",
    address2: "",
    yelp: "",
    geoLocation: "",
  });
  const [workingHours, setWorkingHours] = useState<any>([
    {
      day: 0,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 1,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 2,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 3,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 4,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 5,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
    {
      day: 6,
      start: "",
      end: "",
      isOvernight: false,
      isClosed: true,
    },
  ]);

  const [extraOption, setExtraOption] = useState<{
    isGp: boolean;
    isInTrendingPlace: boolean;
    placement: string;
    isInLoyalityProgram: boolean;
    loyalityProgram: string;
    loyaltyStartDate: string | null;
    loyaltyEndDate: string | null;
  }>({
    isGp: false,
    isInTrendingPlace: false,
    placement: "",
    isInLoyalityProgram: false,
    loyalityProgram: "",
    loyaltyStartDate: "",
    loyaltyEndDate: "",
  });
  const [googleZipCode, setHasGoogleZipCode] = useState(true);
  const [addressAutoComplete, setAddressAutoComplete] = useState("");

  const [photosCategories, setPhotosCategories] = useState([]);
  const [typePhotoToSaveCategory, setTypePhotoToSaveCategory] = useState("");
  const [firstImage, setFirstImg] = useState("");
  const [secondImage, setSecondImg] = useState("");
  const [thirdImage, setThirdImg] = useState("");
  const [inputError, setInputError] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    email: "",
    name: "",
    lastName: "",
    qr: false,
    role: "captain",
  });

  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  const [dataMerchant, setDataMerchant] = useState(null);

  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [elementsParentName, setElementsParentName] = useState<any[]>([]);
  const [isExactSearchActive, setIsExactSearchActive] = useState(false);

  const [typeInputModalDropzone, setTypeInputModalDropzone] = useState("");

  const [employeeInvitedList, setEmployeeInvitedList] = useState<
    {email: string; userRole: string; idUser: number; idRetailer: number}[]
  >([]);

  const [states, setStates] = useState<{selected: string; values: IState[]}>({
    selected: "",
    values: [],
  });
  const [cities, setCities] = useState<{selected: string; values: ICity[]}>({
    selected: "",
    values: [],
  });

  const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
  const [objectImages, setObjectImages] = useState<{
    logoLarge: any;
    profile: any;
    interior: any;
  }>({
    logoLarge: "",
    profile: "",
    interior: "",
  });

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.merchant?.includes("add")
      ? false
      : true;
  });

  const [
    createNewMerchant,
    {
      data: dataCreateNewMerchant,
      isLoading: isLoadingCreateNewMerchant,
      isError: isErrorCreateNewMerchant,
    },
  ] = useCreateMerchantMutation();

  const [
    getMerchantInfoYelpGoogle,
    {
      data: dataInfoYelpGoogle,
      isLoading: isLoadingInfoYelpGoogle,
      isError: isErrorInfoYelpGoogle,
    },
  ] = useLazyGetMerchantInfoYelpGoogleQuery();

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  const [updateImageMerchant] = useChangeImageMerchantMutation();

  const [sendInvite] = useSendInviteMutation();
  const [cancelInvitationMutation] = useCancelInviteMutation();

  const [imageCropModalType, setImageCropModalType] = useState("default");

  useEffect(() => {
    //@ts-ignore
    if (!addressDetais?.address_components?.length) return;

    getFullAddress();
  }, [addressDetais]);

  const getFullAddress = async () => {
    //@ts-ignore
    const addressData = addressDetais.address_components;

    const getAddressData = (fieldName: string): string => {
      const data = addressData.find((item: any) =>
        item.types.includes(fieldName)
      )?.long_name;
      return data || "";
    };

    const stateAbbreviation = addressData.find((item: any) =>
      item.types.includes("administrative_area_level_1")
    ).short_name;

    const data = {
      street: getAddressData("route"),
      streetNumber: getAddressData("street_number"),
      state: getAddressData("administrative_area_level_1"),
      city: getAddressData("locality"),
      zipCode: getAddressData("postal_code"),
    };

    const description =
      data.streetNumber +
      " " +
      data.street +
      ", " +
      data.city +
      ", " +
      data.state;

    await getGeocode({address: description}).then((results) => {
      const {lat, lng} = getLatLng(results[0]);

      setHasGoogleZipCode(data.zipCode ? true : false);

      setStates({...states, selected: stateAbbreviation});

      setUserData({
        ...userData,
        address1:
          data.streetNumber + `${data.streetNumber ? " " : ""}` + data.street,
        state: data.state,
        city: data.city,
        zipCode: data.zipCode,
        geoLocation: `${lat},${lng}`,
      });
    });
  };

  useEffect(() => {
    if (!addressAutoComplete) return;
    //@ts-ignore
    getFormattedAddress(addressAutoComplete);
  }, [addressAutoComplete]);

  useEffect(() => {
    if (!dataInfoYelpGoogle || !Object.keys(dataInfoYelpGoogle).length) return;

    const {
      name,
      phoneNumber,
      address1,
      address2,
      state,
      city,
      zipCode,
      workingHours: workingHoursApi,
      location,
      photos,
      categories,
    } = dataInfoYelpGoogle;

    setUserData({
      ...userData,
      name,
      businessPhone: phoneNumber,
      address1,
      address2,
      state,
      city,
      zipCode,
      geoLocation: location,
    });

    const mappedWorkingHours = workingHours.map((day: any) => {
      const matchingDay = workingHoursApi.find(
        (item: any) => item.day === day.day
      );
      if (matchingDay) {
        // If the received working hours include the current day, transform the time format
        return {
          ...day,
          start: transformTimeFormat(matchingDay.start),
          end: transformTimeFormat(matchingDay.end),
          isClosed: false,
        };
      } else {
        // If the received working hours don't include the current day, keep the default values
        return day;
      }
    });

    setHasGoogleZipCode(zipCode ? true : false);

    setWorkingHours(mappedWorkingHours);

    setPhotosCategories(photos);

    const arrayIds: string[] = [];
    const arrayNames: string[] = [];

    findMatches(dataCategories, categories, arrayIds, arrayNames);

    setElementsChecked(arrayIds);
    setElementsName(arrayNames);

    // getMerchantCategoryInfoYelpGoogle("Bubble Tea");
  }, [dataInfoYelpGoogle]);

  useEffect(() => {
    //@ts-ignore
    if (!addressDetais?.address_components?.length) return;
    //@ts-ignore
    const addressData = addressDetais.address_components;

    const getAddressData = (fieldName: string): string => {
      const data: string = addressData.find((item: any) =>
        item.types.includes(fieldName)
      )?.long_name;
      return data || "";
    };

    const stateAbbreviation = addressData.find((item: any) =>
      item.types.includes("administrative_area_level_1")
    ).short_name;

    const data = {
      street: getAddressData("route"),
      streetNumber: getAddressData("street_number"),
      state: getAddressData("administrative_area_level_1"),
      city:
        getAddressData("locality") ||
        getAddressData("administrative_area_level_2"),
      zipCode: getAddressData("postal_code"),
    };

    setHasGoogleZipCode(data.zipCode ? true : false);

    setStates({...states, selected: stateAbbreviation});

    // setUserData({
    //   ...userData,
    //   address1:
    //     data.streetNumber + `${data.streetNumber ? " " : ""}` + data.street,
    //   state: data.state,
    //   city: data.city,
    //   zipCode: data.zipCode,
    // });
  }, [addressDetais]);

  useEffect(() => {
    if (dataCreateNewMerchant) {
      setDataMerchant(dataCreateNewMerchant);
      arrayImageToUpload.map((imageToUpload: any, key: number) => {
        updateMerchantImages(imageToUpload, dataCreateNewMerchant?.id, key);
      });

      if (employeeData.email) {
        setTimeout(() => {
          sendInviteEmployee();
        }, 1000);
      }
    }
  }, [dataCreateNewMerchant]);

  // useEffect(() => {
  //   if (userData.zipCode.length === 5 && !userData.geoLocation) {
  //     debounceChange();
  //   }
  // }, [userData.zipCode]);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement.id,
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      }
    });

    setElementsParentName(res);
  }, [elementsChecked, dataCategories]);

  const clearAddressDetails = (inputValue: string) => {
    setUserData({
      ...userData,
      address1: inputValue,
      state: "",
      city: "",
      zipCode: "",
      geoLocation: "",
    });
  };

  const handleSubmit = async () => {
    if (
      !userData.name ||
      !userData.ownerFirstName ||
      !userData.ownerLastName ||
      !userData.email ||
      !userData.zipCode ||
      !userData.sizeType ||
      !userData.address1 ||
      elementsChecked.length === 0 ||
      !secondImage ||
      !thirdImage ||
      !firstImage
    ) {
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    if (employeeData.email) {
      if (!regex.test(employeeData.email.trim())) {
        toast.error("You must provide a valid email to send invitation");
        return;
      }
    }

    if (userData.email) {
      if (!regex.test(userData.email.trim())) {
        toast.error("You must provide a valid merchant email");
        return;
      }
    } else {
      toast.error("You must provide a merchant email");
      return;
    }

    const newWorkingHours = formatArrayToAMPM(workingHours);

    const arrCategoriesID = elementsChecked?.map((el: any, key: any) => {
      if (el === "all") {
        return dataCategories.map((category: {id: number; name: string}) => {
          // @ts-ignore
          return {id: parseInt(category.id), name: category.name};
        });
      } else {
        return {id: parseInt(el), name: elementsName[key]};
      }
    });

    let categoryArr = [];

    if (arrCategoriesID[0]?.length > 1) {
      categoryArr = [...arrCategoriesID[0]];
    } else {
      categoryArr = arrCategoriesID;
    }

    const merchant: {
      address: string;
      address2: string;
      state: string;
      city: string;
      email: string;
      name: string;
      ownerFirstName: string;
      ownerLastName: string;
      phone: string;
      website: string;
      sizeType: string;
      socialReview: {url: string};
      zip: string;
      geoLocation: string;
      isGp: boolean;
      loyaltyToggle?: boolean;
      loyaltyDuration?: number;
      placement?: number;
      categories: any;
      workingHours?: any; // Propiedad opcional
    } = {
      address: userData.address1,
      address2: userData.address2,
      state: userData.state,
      city: userData.city,
      email: userData.email.toLowerCase(),
      name: userData.name,
      ownerFirstName: userData.ownerFirstName,
      ownerLastName: userData.ownerLastName,
      phone: userData.businessPhone,
      website: userData.businessWeb,
      sizeType: userData.sizeType,
      socialReview: {
        url: userData.yelp,
      },
      zip: userData.zipCode,
      geoLocation: userData.geoLocation,
      isGp: extraOption.isGp,
      categories: categoryArr,
      loyaltyToggle: false,
      loyaltyDuration: 0,
    };

    const sendWorkingHours = workingHours.some(
      (day: any) => day.isClosed === false
    );

    if (extraOption.isInTrendingPlace) {
      merchant.placement = parseInt(extraOption.placement);
    }

    if (extraOption.isInLoyalityProgram) {
      merchant.loyaltyToggle = true;
      merchant.loyaltyDuration = parseInt(extraOption.loyalityProgram);
    }

    if (sendWorkingHours) {
      merchant.workingHours = newWorkingHours;
    }

    const toastPromise = toast.promise(createNewMerchant(merchant), {
      pending: "Creating Merchant",
    });
    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating merchant</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Merchant created");
          setTimeout(() => {
            navigate("/merchants/" + res?.data?.id);
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error("Error while creating merchant");
      });
  };

  const updateMerchantImages = async (
    imageToUpload: any,
    id: number,
    key: number
  ) => {
    if (!imageToUpload.data) return;

    const toastPromiseImage = toast.promise(
      updateImageMerchant({...imageToUpload, id}),
      {}
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key}</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          const messages = ["exterior photo", "interior photo", "logo"];
          toast.success(`Merchant ${messages[key]} uploaded`);
        }
      })
      .catch((err) => {
        toast.error(`Error while uplading image ${key}`);
      });
  };

  const sendInviteEmployee = async () => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!employeeData.email || !regex.test(employeeData.email.trim())) {
      toast.error("You must provide a valid email to send invitation");
      return;
    }

    if (dataCreateNewMerchant) {
      const toastPromiseEmail = toast.promise(
        sendInvite({
          // @ts-ignore
          id: dataCreateNewMerchant?.id,
          data: {
            email: employeeData.email,
            userRole: employeeData.role.toUpperCase(),
          },
        }),
        {}
      );

      await toastPromiseEmail
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while sending invitation</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Invitation sent successfully");
            setEmployeeInvitedList([
              ...employeeInvitedList,
              {
                email: employeeData.email,
                userRole: employeeData.role,
                idUser: res?.data?.id,
                idRetailer: res?.data?.retailerID,
              },
            ]);
          }
        })
        .catch((err) => {
          toast.error("Error while sending invitation");
        });
    } else {
      if (dataMerchant) {
        const toastPromiseEmail = toast.promise(
          sendInvite({
            // @ts-ignore
            id: dataMerchant?.id,
            data: {
              email: employeeData.email,
              userRole: employeeData.role.toUpperCase(),
            },
          }),
          {}
        );

        await toastPromiseEmail
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while sending invitation</h2>
                  <p className="text-xs"> {res?.error?.data?.code} </p>
                </div>
              );
            } else {
              toast.success("Invitation sent successfully");
              setEmployeeInvitedList([
                ...employeeInvitedList,
                {
                  email: employeeData.email,
                  userRole: employeeData.role,
                  idUser: res?.data?.id,
                  idRetailer: res?.data?.retailerID,
                },
              ]);
            }
          })
          .catch((err) => {
            toast.error("Error while sending invitation");
          });
      }
    }
  };

  const handleCancelInvitation = async (
    employeeID: number,
    retailerID: number
  ) => {
    if (!employeeID || !retailerID)
      return toast.error("Error you must select an existing employee");

    const toastPromise = toast.promise(
      cancelInvitationMutation({
        idRetailer: retailerID,
        idUser: employeeID,
      }),
      {
        pending: "Canceling Invite",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while canceling invite</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Invite canceled successfully");
          const newEmployeeList = employeeInvitedList.filter(
            (employee) => employee.idUser !== employeeID
          );
          setEmployeeInvitedList(newEmployeeList);
        }
      })
      .catch((err) => {
        toast.error("Error while canceling invite");
      });
  };

  useEffect(() => {
    const arrFormData = [
      {data: objectImages.logoLarge},
      {data: objectImages.profile},
      {data: objectImages.interior},
    ];

    setArrayImageToUpload(arrFormData);
  }, [objectImages]);

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();
    if (typeof data !== "string") {
      formData.append("image", data);
    } else {
      formData.append("imageUrl", data);
    }
    formData.append("name", data.name);
    formData.append("type", type);

    let imgUrl;

    if (typeof data !== "string") {
      imgUrl = URL.createObjectURL(data);
    } else {
      imgUrl = data;
    }

    showImage(imgUrl);

    if (type === "LOGO_LARGE") {
      setObjectImages({...objectImages, logoLarge: formData});
    }
    if (type === "PROFILE") {
      setObjectImages({...objectImages, profile: formData});
    }
    if (type === "INTERIOR") {
      setObjectImages({...objectImages, interior: formData});
    }
  };

  const redirectTo = () => {
    dispatch(setPage("&page=1"));
    dispatch(setURLFilters(""));
    // dispatch(setURLSearch(""));
    navigate(`/merchants`);
  };

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id === "all") {
      if (checked) {
        setElementsChecked(["all"]);
        setElementsName(["All"]);
      } else {
        setElementsChecked([]);
        setElementsName([]);
      }
    } else {
      const subItemIds = [id];
      const subItemNames = [name];

      const toggleCheckbox = (items: any[]) => {
        items.forEach((item: any) => {
          subItemIds.push(item.id.toString());
          subItemNames.push(item.name);

          // if (item.subItems && item.subItems.length > 0) {
          //   toggleCheckbox(item.subItems); // Recursively call toggleCheckbox for subItems
          // }
        });
      };

      const findItemById = (items: any[], itemId: string): any | null => {
        for (const item of items) {
          if (item.id.toString() === itemId) {
            return item;
          }
          if (item.subItems && item.subItems.length > 0) {
            const foundItem = findItemById(item.subItems, itemId); // Recursively search in subItems
            if (foundItem) {
              return foundItem;
            }
          }
        }
        return null;
      };

      const category = findItemById(dataCategories, id);

      if (category) {
        toggleCheckbox([category]);
      }

      if (checked) {
        if (uniqueArray(elementsChecked).length >= 3) {
          toast.info("You can only select up to 3 categories");
          return;
        }
        setElementsChecked((prevElementsChecked: string[]) => {
          return uniqueArray([...prevElementsChecked, ...subItemIds]);
        });
        setElementsName((prevElementsName: string[]) => {
          return uniqueArray([...prevElementsName, ...subItemNames]);
        });
      } else {
        setElementsChecked((prevElementsChecked: string[]) => {
          return uniqueArray(
            prevElementsChecked.filter(
              (item: string) => !subItemIds?.includes(item)
            )
          );
        });
        setElementsName((prevElementsName: string[]) => {
          return uniqueArray(
            prevElementsName.filter(
              (item: string) => !subItemNames?.includes(item)
            )
          );
        });
      }
    }
  };

  const handleClickCheckboxQREmployee = (e: any) => {
    const {checked} = e.target;
    setEmployeeData({...employeeData, qr: true});
    if (!checked) {
      setEmployeeData({...employeeData, qr: false});
    }
  };

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const getYelpGoogleInfoMerchant = async () => {
    const toastPromise = toast.promise(
      getMerchantInfoYelpGoogle(encodeURIComponent(`${userData.yelp}`)),
      {
        pending: "Getting Merchant Information",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while getting merchant information</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
        }
      })
      .catch((err) => {
        toast.error("Error while getting merchant information");
      });
    // Fetch data
  };

  function getDayString(day: number): string {
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
      default:
        return "";
    }
  }

  const handleSelectChange = (e: any, day: any) => {
    const selectedValue = e.target.value;
    const updatedWorkingHours = [...workingHours];
    const selectedDay = updatedWorkingHours.find((item) => item.day === day);
    selectedDay.isClosed = selectedValue === "closed";
    setWorkingHours(updatedWorkingHours);
  };

  const handleChangePlacement = (checked: any) => {
    setExtraOption((prev: any) => ({
      ...prev,
      placement: checked === false ? extraOption.placement : "",
      isInTrendingPlace: checked === false ? true : false,
    }));
  };

  const handleChangeLoyalityProgram = (checked: any) => {
    setExtraOption((prev: any) => ({
      ...prev,
      loyalityProgram: checked === false ? extraOption.loyalityProgram : "",
      isInLoyalityProgram: checked === false ? true : false,
    }));
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <p
          onClick={() => redirectTo()}
          className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to merchants list
        </p>
      </div>
      <div className="flex justify-between">
        <Title classes="flex items-center">Create Merchant</Title>
        <Button
          variant={isInputDisabled ? "disabled" : "add"}
          onClick={isInputDisabled ? () => {} : () => handleSubmit()}
        >
          <AddPerson classes="mr-2" />
          Create profile and send invite
        </Button>
      </div>
      <div className="flex mt-5 flex-wrap">
        <div className="bg-white p-5 xl:w-5/7 w-full">
          <form>
            <div className="gap-8 mb-6 flex flex-wrap max-w-full">
              <Subtitle>
                {" "}
                Does This Merchant Have An Account On Google Or Yelp?
              </Subtitle>
              <hr />
              <div className="w-full flex items-end">
                <Input
                  label={"Merchant link Yelp or Google"}
                  placeholder="Put the merchant’s link profile from Google or Yelp."
                  value={userData.yelp}
                  disabled={isInputDisabled}
                  onChange={(e: any) =>
                    setUserData({...userData, yelp: e.target.value})
                  }
                  classes="min-w-[60%] mr-6"
                />
                <Button
                  type="button"
                  variant={
                    !userData.yelp ||
                    userData.yelp.length < 10 ||
                    isInputDisabled
                      ? "disabled"
                      : "add"
                  }
                  onClick={
                    !userData.yelp ||
                    userData.yelp.length < 10 ||
                    isInputDisabled
                      ? () => {}
                      : () => getYelpGoogleInfoMerchant()
                  }
                >
                  Download Data
                </Button>
              </div>
              <Subtitle> Provide Details</Subtitle>
              <hr />
              <div className="w-full flex gap-4">
                <div className="md:w-full lg:w-4/12">
                  <Input
                    label={"Merchant Name"}
                    placeholder="Enter Business Name"
                    value={userData.name}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, name: e.target.value})
                    }
                    error={userData.name.length === 0 && inputError}
                    required={true}
                    classes={"mt-4"}
                  />
                  <Input
                    label={"Owners First Name"}
                    placeholder="Enter the Owners First Name"
                    value={userData.ownerFirstName}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, ownerFirstName: e.target.value})
                    }
                    error={userData.ownerFirstName.length === 0 && inputError}
                    required={true}
                    classes={"mt-4"}
                  />
                  <Input
                    label={"Owners Last Name"}
                    placeholder="Enter the Owners Last Name"
                    value={userData.ownerLastName}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, ownerLastName: e.target.value})
                    }
                    error={userData.ownerLastName.length === 0 && inputError}
                    required={true}
                    classes={"mt-4"}
                  />
                </div>
                <div className="md:w-full lg:w-4/12">
                  <Input
                    label={"Email"}
                    placeholder="Provide Merchant Email"
                    value={userData.email}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setUserData({...userData, email: e.target.value})
                    }
                    required={true}
                    error={userData.email.length === 0 && inputError}
                    classes={"mt-4"}
                  />
                  <Input
                    label={"Business Phone Number"}
                    placeholder="Enter Business Phone Number"
                    disabled={isInputDisabled}
                    // error={userData.yelp.length === 0 && inputError}
                    onChange={(e: any) => {
                      const inputValue = e.target.value;
                      // const numericRegex = /^[0-9]*$/;

                      // if (numericRegex.test(inputValue)) {
                      // }
                      setUserData({...userData, businessPhone: inputValue});
                    }}
                    value={userData.businessPhone}
                    classes={"mt-4"}
                  />
                  <Input
                    label={"Business Web Address"}
                    placeholder="Enter Merchants Web Address"
                    disabled={isInputDisabled}
                    // error={userData.yelp.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, businessWeb: e.target.value})
                    }
                    value={userData.businessWeb}
                    classes={"mt-4"}
                  />
                </div>
                <div className="md:w-full lg:w-4/12">
                  <PlacesAutocomplete
                    label={"Address 1"}
                    id={"address"}
                    placeholder="Provide Business Address"
                    disabled={isInputDisabled}
                    required
                    error={userData.address1.length === 0 && inputError}
                    classes={"mt-4"}
                    clearAddressDetails={clearAddressDetails}
                    userData={userData}
                    setUserData={setUserData}
                    setAddressAutoComplete={setAddressAutoComplete}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (!autoCompleteAddressReady)
                        //@ts-ignore
                        getFormattedAddress(e.target.value);
                    }}
                  />
                  <Input
                    label={"Address 2"}
                    placeholder="Address line 2"
                    disabled={isInputDisabled}
                    // error={userData.yelp.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, address2: e.target.value})
                    }
                    value={userData.address2}
                    classes={"mt-4"}
                  />
                  <Select
                    label={"Approximate Retail Space (SQFT)"}
                    disabled={isInputDisabled}
                    classes="mt-4"
                    classesSelect="py-2 px-2 mr-3"
                    required={true}
                    error={userData.sizeType.length === 0 && inputError}
                    onChange={(e: any) =>
                      setUserData({...userData, sizeType: e.target.value})
                    }
                    value={userData.sizeType}
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="SMALL">
                        Less than 1,000 square feet (Small)
                      </option>
                      <option value="MEDIUM">
                        1,000 - 2,500 square feet (Medium)
                      </option>
                      <option value="LARGE">
                        Greater than 2,500 square feet (Large)
                      </option>
                    </>
                  </Select>
                  <Input
                    disabled
                    value={userData.state}
                    id="state"
                    label="State"
                    required
                    error={userData.state.length === 0 && inputError}
                    classes="mt-4 lg:basis-1/5 md:basis-1/2 basis-full"
                    classesSelect="border border-gray-400 p-2.5 pl-4"
                    onChange={(e: any) => {
                      // setChangeFromSelect(true);
                      // handleChangeState(e);
                      setUserData({
                        ...userData,
                        state: e.target.selectedOptions[0].text,
                      });
                    }}
                  />
                  <Input
                    disabled
                    value={userData.city}
                    id="city"
                    label="City / Town"
                    classes="mt-4"
                    required
                    error={userData.city.length === 0 && inputError}
                    classesSelect="border border-gray-400 p-2.5 pl-4"
                    onChange={(e: any) => {
                      setCities({
                        selected: e.target.value,
                        values: cities.values,
                      });
                      setUserData({...userData, city: e.target.value});
                    }}
                  />
                  <Input
                    max={99999}
                    min={0}
                    maxLength={5}
                    required={true}
                    error={userData.zipCode.length === 0 && inputError}
                    label={"Zip Code"}
                    placeholder="-"
                    value={userData.zipCode}
                    onChange={(e: any) => {
                      if (isNaN(e.target.value)) return;
                      // handleCheckLocation(e);
                      setUserData({...userData, zipCode: e.target.value});
                    }}
                    classes={"mt-4"}
                    disabled={googleZipCode === false ? false : true}
                  />
                </div>
              </div>
              <Subtitle classes="mt-2 mb-2">Choose Categories</Subtitle>
              <hr className="mb-6 w-full" />
              <div className="w-full">
                <DropdownCategory
                  hasLabel={true}
                  label="Categories"
                  openDropdown={openDropdown}
                  dropdown={dropdown}
                  elementsChecked={elementsChecked}
                  elementsName={elementsName}
                  handleClickCheckbox={handleClickCheckbox}
                  dataCategories={getFilteredNames(
                    dataCategories,
                    dropdownSearchFilter,
                    isExactSearchActive ? "exact" : ""
                  )}
                  disabled={isInputDisabled}
                  isErrorCategories={isErrorCategories}
                  isLoadingCategories={isLoadingCategories}
                  classNameSelect="w-full"
                  error={error}
                  required={true}
                  showNames={false}
                  isError={!elementsChecked.length && inputError}
                  handleClickDropdown={handleClickDropdown}
                  expandItems={dropdownSearchFilter}
                  hasSearchValue={{
                    show: true,
                    content: (
                      <div className="flex mb-3 justify-between">
                        <div className="flex items-center w-full">
                          <label htmlFor="search">
                            <Search classes="h-6 w-6 mr-2" />
                          </label>
                          <input
                            id="search"
                            name="search"
                            type="text"
                            placeholder="Search category"
                            className="decoration-none outline-none w-full"
                            onChange={(e: any) =>
                              setDowpdownSearchFilter(e.target.value)
                            }
                            value={dropdownSearchFilter}
                          />
                        </div>
                      </div>
                    ),
                  }}
                />
                <div
                  className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                    elementsChecked.length > 0
                      ? "border border-gray-400"
                      : "border-none"
                  } rounded-md`}
                >
                  {elementsParentName.map((element, index) => (
                    <div
                      className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                      key={index}
                    >
                      <Tooltip id="my-tooltip"></Tooltip>
                      <div className="flex flex-wrap">
                        {element?.root && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.rootId}`}
                            className="flex items-center text-sm"
                          >
                            {element?.root} <ArrowNext classes="h-4 w-4" />
                          </div>
                        )}
                        {element?.parentId && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.parentId}`}
                            className="flex items-center text-sm"
                          >
                            {element?.parent} <ArrowNext classes="h-4 w-4" />
                          </div>
                        )}
                        {element?.childId && (
                          <div
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID:  ${element?.childId}`}
                            className="font-medium text-sm"
                          >
                            {element?.child}
                          </div>
                        )}
                      </div>
                      <Button
                        type="button"
                        onClick={() => {
                          setElementsChecked((prevElementsChecked: string[]) =>
                            prevElementsChecked.filter(
                              (item: string) => item !== element.id
                            )
                          );
                          setElementsName((prevElementsChecked: string[]) =>
                            prevElementsChecked.filter(
                              (item: string) => item !== element.child
                            )
                          );
                        }}
                        classes="ml-6 text-blue-400 text-sm"
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full">
                <Subtitle classes="mt-8 mb-2">Working Hours</Subtitle>
                <hr className="mb-6" />
                {workingHours.map((day: {day: number; isClosed: boolean}) => (
                  <div className="flex gap-3 items-center mb-3" key={day.day}>
                    <span className="text-xs font-semibold mr-3 min-w-[100px]">
                      {getDayString(day.day)}
                    </span>
                    <input
                      type="time"
                      className={`border-2 border-gray-200 outline-none px-1 rounded-sm ${
                        day.isClosed && "bg-gray-200"
                      }`}
                      value={workingHours[day.day]?.start || ""}
                      onChange={(e) => {
                        const newWorkingHours = [...workingHours];
                        newWorkingHours[day.day] = {
                          ...newWorkingHours[day.day],
                          start: e.target.value,
                        };
                        setWorkingHours(newWorkingHours);
                      }}
                      disabled={day.isClosed || isInputDisabled}
                    />
                    <p className="text-sm text-gray-400 mx-1">To</p>
                    <input
                      type="time"
                      className={`border-2 border-gray-200 outline-none px-1 rounded-sm ${
                        day.isClosed && "bg-gray-200"
                      }`}
                      value={workingHours[day.day]?.end || ""}
                      onChange={(e) => {
                        const newWorkingHours = [...workingHours];
                        newWorkingHours[day.day] = {
                          ...newWorkingHours[day.day],
                          end: e.target.value,
                        };
                        setWorkingHours(newWorkingHours);
                      }}
                      disabled={day.isClosed || isInputDisabled}
                    />

                    <select
                      key={day.day}
                      className={
                        day.isClosed
                          ? "text-red-400 text-xs font-semibold cursor-pointer"
                          : "text-green-400 text-xs font-semibold cursor-pointer"
                      }
                      disabled={isInputDisabled}
                      value={day.isClosed ? "closed" : "open"}
                      onChange={(e) => handleSelectChange(e, day.day)}
                    >
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                ))}
              </div>
              <div className="w-full ">
                <Subtitle classes="mt-5 mb-2">Merchant Photo</Subtitle>
                <hr />
                <div className="flex justify-between mt-4">
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant exterior photo"
                      required={true}
                      handleUploadPhoto={handleUploadPhoto}
                      image={secondImage}
                      imageSetter={setSecondImg}
                      disabledInput={isInputDisabled}
                      id="2"
                      type="PROFILE"
                      classesBorder={`${
                        !secondImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant interior photo"
                      handleUploadPhoto={handleUploadPhoto}
                      image={thirdImage}
                      required={true}
                      imageSetter={setThirdImg}
                      disabledInput={isInputDisabled}
                      type="INTERIOR"
                      id="3"
                      classesBorder={`${
                        !thirdImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <Dropzone
                      label="Merchant logo"
                      handleUploadPhoto={handleUploadPhoto}
                      type="LOGO_LARGE"
                      imageSetter={setFirstImg}
                      image={firstImage}
                      id="1"
                      required
                      classesBorder={`${
                        !firstImage && inputError && "!border-red-500"
                      }`}
                      typeItem="merchant"
                      disabledInput={isInputDisabled}
                      imageCropModalType={imageCropModalType}
                      setImageCropModalType={setImageCropModalType}
                      sizePlaceholder={"logo \n 16:9 @ 1920 x 1080"}
                      sizesContainer={{
                        minHeight: "172px",
                        maxHeight: "220px",
                        minWidth: "306px",
                        maxWidth: "306px",
                      }}
                      sizes={{height: 1920, width: 1080}}
                      aspect={16 / 9}
                      openModalOnClick="showImagesCategories"
                      setTypeInputModalDropzone={setTypeInputModalDropzone}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full ">
                <Subtitle classes="mt-8 mb-2">Merchant Special Access</Subtitle>
                <hr />
                <div className="mt-6">
                  <div className="flex items-center gap-4 mb-3">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isGp}
                      as={Fragment}
                    >
                      {({checked}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isGp
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                          onClick={() => {
                            setExtraOption((prev: any) => ({
                              ...prev,
                              isGp: checked === false ? true : false,
                            }));
                          }}
                        >
                          <span className="sr-only">
                            Enable create grand prize
                          </span>
                          <span
                            className={`${
                              extraOption.isGp
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to provide access to create a grand prize?
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isInTrendingPlace}
                      as={Fragment}
                    >
                      {({checked}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isInTrendingPlace
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                          onClick={() => handleChangePlacement(checked)}
                        >
                          <span className="sr-only">Enable trading place</span>
                          <span
                            className={`${
                              extraOption.isInTrendingPlace
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to show this merchant on a trending place?
                    </p>
                  </div>
                </div>
                <div className="flex items-center mt-6">
                  <Select
                    id="placement"
                    disabled={isInputDisabled || !extraOption.isInTrendingPlace}
                    classes="flex items-center"
                    classesSelect="py-2 px-2 mr-3"
                    onChange={(e: any) =>
                      setExtraOption((prev: any) => ({
                        ...prev,
                        placement: e.target.value,
                      }))
                    }
                    value={extraOption.placement}
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="10">10</option>
                      <option value="9">9</option>
                      <option value="8">8</option>
                      <option value="7">7</option>
                      <option value="6">6</option>
                      <option value="5">5</option>
                      <option value="4">4</option>
                      <option value="3">3</option>
                      <option value="2">2</option>
                      <option value="1">1</option>
                    </>
                  </Select>
                  <p className="font-medium mr-2">Placement (1-10)</p>
                </div>
                <div className="mt-6">
                  <div className="flex items-center gap-4 ">
                    <Switch
                      defaultChecked={false}
                      checked={extraOption.isInLoyalityProgram}
                      as={Fragment}
                    >
                      {({checked}: {checked: boolean}) => (
                        <button
                          disabled={isInputDisabled}
                          className={`${
                            extraOption.isInLoyalityProgram
                              ? "bg-primary-purple"
                              : "bg-gray-200"
                          } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                          onClick={() => handleChangeLoyalityProgram(checked)}
                        >
                          <span className="sr-only">
                            Enable Loyalty Program
                          </span>
                          <span
                            className={`${
                              extraOption.isInLoyalityProgram
                                ? "translate-x-6"
                                : "translate-x-1"
                            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                    <p className="font-medium mr-2">
                      Do you want to provide access to Swiggle Loyalty Program?
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 mt-6">
                  <Select
                    label="Months Availables"
                    id="loyalityProgram"
                    disabled={
                      isInputDisabled || !extraOption.isInLoyalityProgram
                    }
                    classes="w-40"
                    classesSelect="py-2 px-2 mr-3"
                    onChange={(e: any) =>
                      setExtraOption((prev: any) => ({
                        ...prev,
                        loyalityProgram: e.target.value,
                        loyaltyEndDate: addDays(
                          getTodayDate(),
                          e.target.value * 30
                        ),
                      }))
                    }
                    value={extraOption.loyalityProgram}
                  >
                    <>
                      <option defaultValue="" disabled></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </>
                  </Select>
                  <DatePicker
                    label="Loyalty program end date"
                    disabled={true}
                    value={getRoundedDate(extraOption) || ""}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="bg-white xl:ml-4 px-5 rounded xl:w-2/7 w-full h-max">
          <Subtitle classes="my-4">Employees</Subtitle>
          <hr className="mb-6" />
          <Tab.Group>
            <Tab.List className={"rounded flex justify-center"}>
              <Tab as={Fragment}>
                {({selected}) => (
                  <button
                    className={`text-sm p-2 rounded-l border-l-2 border-y-2 outline-none border-gray-300 w-40 truncate w-1/2 ${
                      selected
                        ? "bg-primary-purple text-white"
                        : "bg-white text-black"
                    }`}
                  >
                    Add new employee
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({selected}) => (
                  <button
                    className={`text-sm p-2 rounded-r border-r-2 border-y-2 outline-none border-gray-300 w-40 truncate w-1/2 ${
                      selected
                        ? "bg-primary-purple text-white"
                        : "bg-white text-black"
                    }`}
                  >
                    List of sent invites
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-8 mb-6">
                  <Subtitle>Provide Employee Details</Subtitle>

                  <Input
                    label="Employee Email"
                    required={true}
                    disabled={isInputDisabled}
                    value={employeeData.email}
                    placeholder="Enter Employee Email"
                    onChange={(e: any) =>
                      setEmployeeData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    classes="mt-5"
                  />
                  <Input
                    label="Employee First Name"
                    required={true}
                    disabled={isInputDisabled}
                    value={employeeData.name}
                    placeholder="Enter Employee First Name"
                    onChange={(e: any) =>
                      setEmployeeData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    classes="mt-5"
                  />
                  <Input
                    label="Employee Last Name"
                    required={true}
                    disabled={isInputDisabled}
                    value={employeeData.lastName}
                    placeholder="Enter Employee Last Name"
                    onChange={(e: any) =>
                      setEmployeeData((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                    classes="mt-5"
                  />
                  <div className="mt-5 mb-8 flex items-start">
                    <Checkbox
                      id="qrcodecheckbox"
                      onChange={(e: any) => handleClickCheckboxQREmployee(e)}
                      isChecked={employeeData.qr}
                      disabled={isInputDisabled}
                    />
                    <label
                      htmlFor="qrcodecheckbox"
                      className="text-sm font-medium ml-2"
                    >
                      Provide this merchant with unique QR codes for each
                      employee
                    </label>
                  </div>
                  <Subtitle>Choose the role</Subtitle>
                  <div className="mt-4 mb-10">
                    <div className="flex flex-col">
                      {/* First Radio Button */}
                      <div className="flex items-center mr-4 cursor-pointer">
                        <div className="w-6 h-6">
                          <div
                            onClick={() => (e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "captain",
                              }))}
                            className="w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                          >
                            {employeeData.role === "captain" && (
                              <div
                                id="captain"
                                className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                              ></div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            onClick={(e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "captain",
                              }))
                            }
                            htmlFor="captain"
                            className="font-medium text-gray-900 cursor-pointer"
                          >
                            Captain
                          </label>
                          <p className="text-xs font-normal text-gray-400">
                            Can manage all aspects of the business (profile, new
                            prizes, redeem QR codes, and insights)
                          </p>
                        </div>
                      </div>
                      {/* Second Radio Button */}
                      <div className="flex items-center mr-4 mt-4 cursor-pointer">
                        <div className="w-6 h-6">
                          <div
                            onClick={(e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "officer",
                              }))
                            }
                            className="w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                          >
                            {employeeData.role === "officer" && (
                              <div
                                id="officer"
                                className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                              ></div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            onClick={(e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "officer",
                              }))
                            }
                            htmlFor="officer"
                            className="font-medium text-gray-900  cursor-pointer"
                          >
                            Officers
                          </label>
                          <p className="text-xs font-normal text-gray-400">
                            Can publish new prizes and deals for consumers and
                            scan/redeem customer QR codes
                          </p>
                        </div>
                      </div>

                      {/* Thirth Radio Button */}
                      <div className="flex items-center mt-4 cursor-pointer">
                        <div className="w-6 h-6">
                          <div
                            onClick={(e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "crew_member",
                              }))
                            }
                            className="w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                          >
                            {employeeData.role === "crew_member" && (
                              <div
                                id="crew_member"
                                className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                              ></div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            onClick={(e: any) =>
                              setEmployeeData((prev) => ({
                                ...prev,
                                role: "crew_member",
                              }))
                            }
                            htmlFor="crew_member"
                            className="font-medium text-gray-900 cursor-pointer"
                          >
                            Crew Member
                          </label>
                          <p className="text-xs font-normal text-gray-400">
                            These are the employees that can only scan customer
                            QR codes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    variant={
                      !dataMerchant || isInputDisabled ? "disabled" : "normal"
                    }
                    onClick={sendInviteEmployee}
                  >
                    <AddPerson />
                    <p className="ml-2">Send invite</p>
                  </Button>
                </div>
              </Tab.Panel>
              <Tab.Panel className="pb-5">
                {!employeeInvitedList.length ? (
                  <div className="mt-10 mb-6 text-center w-full">
                    No invitation has been sent yet
                  </div>
                ) : (
                  employeeInvitedList?.map(
                    (
                      employee: {
                        email: string;
                        userRole: string;
                        idUser: number;
                        idRetailer: number;
                      },
                      key: number
                    ) => (
                      <div className="flex flex-col mt-5" key={key}>
                        <p className="text-md font-medium flex justify-between">
                          <span>Employee {key}</span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              handleCancelInvitation(
                                employee.idUser,
                                employee.idRetailer
                              )
                            }
                          >
                            <Delete classes="h-5 w-5 text-red-500" />
                          </span>
                        </p>
                        <p className="text-sm capitalize mt-1">
                          {employee.userRole?.includes("_")
                            ? `${employee.userRole.split("_")[0]} ${
                                employee.userRole.split("_")[1]
                              }`
                            : employee.userRole}
                        </p>
                        <p className="text-sm text-blue-400 underline mt-1">
                          {employee.email}
                        </p>
                      </div>
                    )
                  )
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded">
            <Dialog.Overlay />

            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
      {modal.type === "showImagesCategories" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded p-6 gap-2">
            <Dialog.Overlay />
            <Tabs tabsName={["Upload File", "Yelp Images"]} primaryTabs>
              {/* First Table */}
              <div>
                {typeInputModalDropzone === "exterior" && (
                  <Dropzone
                    required={true}
                    handleUploadPhoto={handleUploadPhoto}
                    image={secondImage}
                    imageSetter={setSecondImg}
                    id="4"
                    type="PROFILE"
                    classesBorder={`${
                      !secondImage && inputError && "!border-red-500"
                    }`}
                    typeItem="merchant"
                    imageCropModalType={imageCropModalType}
                    setImageCropModalType={setImageCropModalType}
                  />
                )}
                {typeInputModalDropzone === "interior" && (
                  <Dropzone
                    handleUploadPhoto={handleUploadPhoto}
                    image={thirdImage}
                    required={true}
                    imageSetter={setThirdImg}
                    type="INTERIOR"
                    id="5"
                    classesBorder={`${
                      !thirdImage && inputError && "!border-red-500"
                    }`}
                    typeItem="merchant"
                    imageCropModalType={imageCropModalType}
                    setImageCropModalType={setImageCropModalType}
                  />
                )}
                {typeInputModalDropzone === "logo" && (
                  <Dropzone
                    handleUploadPhoto={handleUploadPhoto}
                    type="LOGO_LARGE"
                    imageSetter={setFirstImg}
                    image={firstImage}
                    id="6"
                    required
                    classesBorder={`${
                      !firstImage && inputError && "!border-red-500"
                    }`}
                    typeItem="merchant"
                    imageCropModalType={imageCropModalType}
                    setImageCropModalType={setImageCropModalType}
                    sizePlaceholder={"logo \n 16:9 @ 1920 x 1080"}
                    sizesContainer={{
                      minHeight: "172px",
                      maxHeight: "220px",
                      minWidth: "306px",
                      maxWidth: "306px",
                    }}
                    sizes={{height: 1920, width: 1080}}
                    aspect={16 / 9}
                  />
                )}
              </div>
              {/* Second Table */}
              <div className="flex flex-wrap gap-4 max-h-96 overflow-y-auto p-8">
                {photosCategories && photosCategories.length > 0 ? (
                  photosCategories?.map((item: any, key: number) => (
                    <img
                      src={item}
                      className="aspect-square h-64 w-64 cursor-pointer"
                      onClick={() => {
                        if (typeInputModalDropzone === "exterior") {
                          handleUploadPhoto(item, "PROFILE", setSecondImg);
                          dispatch(setOpenModal(true));
                          dispatch(setType("cropImage"));
                          dispatch(setImageModal(secondImage));
                          setImageCropModalType &&
                            setImageCropModalType("PROFILE");
                        } else if (typeInputModalDropzone === "interior") {
                          handleUploadPhoto(item, "INTERIOR", setThirdImg);
                          dispatch(setOpenModal(true));
                          dispatch(setType("cropImage"));
                          dispatch(setImageModal(thirdImage));
                          setImageCropModalType &&
                            setImageCropModalType("INTERIOR");
                        } else if (typeInputModalDropzone === "logo") {
                          handleUploadPhoto(item, "LOGO_LARGE", setFirstImg);
                          dispatch(setOpenModal(true));
                          dispatch(setType("cropImage"));
                          dispatch(setImageModal(firstImage));
                          setImageCropModalType &&
                            setImageCropModalType("LOGO_LARGE");
                        } else {
                          return;
                        }
                      }}
                      alt={`category-${key}`}
                      key={key}
                    />
                  ))
                ) : (
                  <div className="text-center w-full">No images</div>
                )}
              </div>
            </Tabs>
          </div>
        </Modal>
      )}
    </section>
  );
}

export default AddNewMerchant;
