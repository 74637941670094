import { TypeClasses } from "./types";

function Dashboard({ classes }: TypeClasses) {
  return (
    <svg
      id="dashboard"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="dashboard_FILL0_wght400_GRAD0_opsz48"
        d="M15.75,12.75V6H24v6.75ZM6,15.75V6h8.25v9.75ZM15.75,24V14.25H24V24ZM6,24V17.25h8.25V24Zm1.5-9.75h5.25V7.5H7.5Zm9.75,8.25H22.5V15.75H17.25Zm0-11.25H22.5V7.5H17.25ZM7.5,22.5h5.25V18.75H7.5ZM12.75,14.25ZM17.25,11.25ZM17.25,15.75ZM12.75,18.75Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Dashboard;
