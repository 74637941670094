import { TypeClasses } from "./types";

function Upload({ classes }: TypeClasses) {
  return (
    <svg
      id="upload"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="upload_FILL0_wght400_GRAD0_opsz48"
        d="M9.688,26A1.73,1.73,0,0,1,8,24.313V20.291H9.688v4.022H24.313V20.291H26v4.022A1.73,1.73,0,0,1,24.313,26Zm6.469-4.3V11.263l-3.375,3.375-1.209-1.209L17,8l5.428,5.428-1.209,1.209-3.375-3.375V21.7Z"
        transform="translate(-5 -5)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Upload;
