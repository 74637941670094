import {useState, useEffect, useMemo, memo, useCallback} from "react";
import {withErrorBoundary} from "react-error-boundary";

// Components
import ErrorFallback from "components/ErrorFallback";
import Spinner from "components/Common/Spinner";
import Button from "components/Common/Button";
import Pagination from "../Pagination/Pagination";
import Numeration from "../Pagination/Numeration";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

// Hooks
import {useSortableTable} from "hooks/useSortableTable";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {
  setOrigin,
  setPage,
  setResults,
  updateStatus,
} from "store/filters/filtersSlice";
import {
  setData,
  setPaginationLoading,
  setResetElChecked,
} from "store/table/currentTableDataSlice";

// Assets
import Search from "assets/Search";
import removeUnderscore from "utils/removeUnderscore";
import {useNavigate} from "react-router-dom";
import routes from "utils/routesByRole";
import {toast} from "react-toastify";

interface TableTypes {
  data?: any;
  loadingData?: boolean;
  errorData?: boolean;
  refetch?: Function;
  results?: number;
  origin?: string;
  headers?: string[] | any;
  subHeaders?: string[];
  hasSubTable?: boolean;
  hasCheckbox?: boolean;
  show?: string[];
  sort?: any;
  showButtonOption?: boolean;
  itemOptions?: any[];
  typeOptions?: string;
  dropdown?: boolean;
  countPagination?: number;
  urlPagination?: {origin: string; url: string};
  handleItemChecked?: Function;
  setMerchantsStatus?: Function;
  additionalData?: any;
  orderingByAPI?: boolean;
  customError?: {show: boolean; content: JSX.Element | string};
  hasPagination?: boolean;
}

function TableComponent({
  data,
  loadingData = false,
  errorData = false,
  refetch,
  results = 1,
  origin = "",
  headers = [],
  subHeaders = [],
  hasSubTable = false,
  hasCheckbox = true,
  show = [],
  sort = [],
  showButtonOption,
  itemOptions,
  typeOptions,
  dropdown = false,
  countPagination,
  urlPagination,
  handleItemChecked,
  setMerchantsStatus,
  additionalData,
  orderingByAPI = false,
  customError = {show: false, content: ""},
  hasPagination = true,
}: TableTypes) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTable = useSelector(
    (state: RootState) => state.currentTableData
  );
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const user = useSelector((state: RootState) => state.user.user.user);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);

  const [tableData, handleSorting, setTableData] = useSortableTable(
    data,
    headers,
    origin
  );
  const [amountToSee, setAmountToSee] = useState(10);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTableData(data);
      dispatch(setResults(results));
      dispatch(setOrigin(origin));
      dispatch(setData(data));
    }
  }, [data]);

  useEffect(() => {
    if (handleItemChecked) handleItemChecked(isCheck);
    return;
  }, [isCheck]);

  useEffect(() => {
    if (currentTable?.resetElChecked) {
      setIsCheck([]);
      dispatch(setResetElChecked(false));
    }
  }, [currentTable]);

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      tableData.map(
        (li: any) =>
          li.id ||
          li.prizeId ||
          li.categoryId ||
          parseInt(li.dmaCode) ||
          li.loyaltyId
      )
    );
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const {id, checked} = e.target;

    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== parseInt(id)));
    }
  };

  const handleRedirect = useCallback(() => {
    if (user.data.role === "MODERATOR") {
      toast.error(
        "Your role limitations as a moderator prevent you from performing this action"
      );
      return;
    }

    if (
      origin.toLowerCase() === "merchants" &&
      // @ts-ignore
      routes[user.data.role].urlAllowed.merchant?.includes("add")
    ) {
      return navigate(`/merchants/create`);
    } else if (
      origin.toLowerCase() === "items" ||
      (origin.toLowerCase().includes("items") &&
        origin.toLowerCase() !== "items_grand_prize" &&
        // @ts-ignore
        routes[user.data.role].urlAllowed.item?.includes("add"))
    ) {
      return navigate(`/items/create`, {
        state: {
          type: "prize",
          id:
            additionalData && additionalData.hasOwnProperty("idMerchant")
              ? additionalData.idMerchant
              : "",
        },
      });
    } else if (
      (origin.toLowerCase() === "merchant_grand_prize" ||
        origin.toLowerCase() === "items_grand_prize") &&
      // @ts-ignore
      routes[user.data.role].urlAllowed.gp?.includes("add")
    ) {
      return navigate("/items/create", {
        state: {
          type: "grandprize",
          id:
            additionalData && additionalData.hasOwnProperty("idMerchant")
              ? additionalData.idMerchant
              : "",
        },
      });
    } else if (
      origin.toLowerCase() === "users" &&
      // @ts-ignore
      routes[user.data.role].urlAllowed.user?.includes("add")
    ) {
      return navigate(`/users/create`);
    } else if (origin.toLowerCase() === "admins") {
      return navigate(`/admins/create`);
    } else if (origin.toLowerCase() === "loyalty_program") {
      // console.log(additionalData);
      return navigate(
        `/merchants/${additionalData?.merchantInfo?.id}/loyalty`,
        {
          state: {merchantInfo: additionalData.merchantInfo},
        }
      );
    }
  }, []);

  const handleRenderBody = useMemo(() => {
    if (
      tabsAndPage.tabs.lastVisitedPage &&
      !window.location.pathname.includes("/", 1) &&
      !filters?.autocomplete.length
    ) {
      // if (filters?.changeStatus?.name !== "noexecnext") {
      dispatch(setPage(`&page=${tabsAndPage.tabs.lastVisitedPage}`));
      // }
      dispatch(setPaginationLoading(false));
    } else if (!data.length && parseInt(filters?.page?.split("=")[1]) > 1) {
      dispatch(setPage(`&page=1`));
      dispatch(setPaginationLoading(false));
    } else {
      dispatch(setPaginationLoading(false));
    }

    return (
      <TableBody
        {...{
          tableData,
          origin,
          hasCheckbox,
          hasSubTable,
          subHeaders,
          handleClick,
          isCheck,
          show,
          sort,
          showButtonOption,
          options: itemOptions,
          typeOptions,
          dropdown,
          setMerchantsStatus,
          additionalData,
        }}
      />
    );
  }, [data, tableData, isCheck]);

  const handlePagination = useMemo(
    () => (
      <div className="flex items-center justify-between mt-8 mb-10">
        <div className="flex items-center text-gray-400">
          <Numeration
            urlPagination={urlPagination}
            amountToSee={amountToSee}
            setAmountToSee={setAmountToSee}
            setTableData={setTableData}
          />
        </div>
        <Pagination
          countPagination={countPagination}
          setTableData={setTableData}
        />
      </div>
    ),
    [amountToSee, countPagination]
  );

  return (
    <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 shadow-md border-separate border-spacing-y-1.5">
        <TableHead
          {...{
            headers,
            handleSorting,
            hasCheckbox,
            handleSelectAll,
            isCheckAll,
            showButtonOption,
            orderingByAPI,
            origin,
          }}
        />
        {!loadingData && !errorData && data.length > 0 && handleRenderBody}
      </table>
      {!loadingData && !errorData && data.length === 0 && (
        <div className="flex flex-col w-full justify-center items-center h-96 bg-white">
          <div className="rounded-full h-40 w-40 bg-gray-200 flex justify-center items-center">
            <Search classes="h-20 w-20 text-gray-400" />
          </div>
          {customError.show === true && customError.content !== "" ? (
            customError.content
          ) : (
            <>
              <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
                {removeUnderscore(origin)} not found
              </h2>
              <p className="text-sm w-72 text-center my-4">
                There is no data available. Wait for the admin to add or you can
                do it yourself.
              </p>
              {origin === "merchant_activity" ||
              origin === "merchant_employee_list" ? (
                <></>
              ) : (
                <Button
                  variant="normal"
                  classes="flex justify-center items-center"
                  onClick={() => handleRedirect()}
                >
                  <p className="capitalize">Add {removeUnderscore(origin)}</p>
                </Button>
              )}
            </>
          )}
        </div>
      )}
      {loadingData && (
        <div className="flex w-full justify-center items-center h-96 bg-white">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-7 h-7" />
        </div>
      )}
      {errorData && (
        <div className="flex flex-col w-full justify-center items-center h-96 mt-5">
          <p>An error occurred while trying to retrieve the data</p>
          <Button onClick={refetch}>Try again</Button>
        </div>
      )}
      {!loadingData &&
        !errorData &&
        data.length !== 0 &&
        hasPagination &&
        handlePagination}
    </>
  );
}

const Table = withErrorBoundary(TableComponent, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
  },
});

export default memo(Table);
