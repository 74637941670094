import {useState} from "react";

// Components
import General from "./Content/General";
import Wheel from "./Content/Wheel";
import Shop from "./Content/Shop";
import Locations from "./Content/Location/";
import ConfigCategories from "./Content/Categories";

function Configuration() {
  const [actualTab, setActualTab] = useState("Locations");
  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className={`font-bold text-2xl tracking-normal`}>Configuration</h1>
        <div className="rounded border-2 border-gray-200 flex justify-center items-center bg-white">
          <span
            className={`cursor-pointer h-full w-full px-4 py-2.5  flex rounded-l items-center text-sm ${
              actualTab === "General"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => setActualTab("General")}
          >
            General
          </span>

          <span
            className={`cursor-pointer  h-full w-full px-4 py-2.5  flex items-center text-sm ${
              actualTab === "Wheel"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => setActualTab("Wheel")}
          >
            Wheel
          </span>

          <span
            className={`cursor-pointer  h-full w-full px-4 py-2.5   flex items-center text-sm ${
              actualTab === "Shop"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => setActualTab("Shop")}
          >
            Shop
          </span>
          <span
            className={`cursor-pointer  h-full w-full px-4 py-2.5  flex items-center text-sm ${
              actualTab === "Locations"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => setActualTab("Locations")}
          >
            Locations
          </span>
          <span
            className={`cursor-pointer  h-full w-full px-4 py-2.5  flex rounded-r items-center text-sm ${
              actualTab === "ConfigCategories"
                ? "bg-primary-purple text-white"
                : "hover:bg-tertiary-purple"
            }`}
            onClick={() => setActualTab("ConfigCategories")}
          >
            Categories
          </span>
        </div>
      </div>
      {actualTab === "Locations" && <Locations />}
      {actualTab === "General" && <General />}
      {actualTab === "Wheel" && <Wheel />}
      {actualTab === "Shop" && <Shop />}
      {actualTab === "ConfigCategories" && <ConfigCategories />}
    </div>
  );
}

export default Configuration;
