import {Dispatch, SetStateAction} from "react";
import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";

// Assets
import Archive from "assets/Archive";
import {itemApi} from "store/api/itemApi";
import {merchantsApi} from "store/api/merchantsApi";

type ArchiveModalType = {
  elementsChecked: number[];
  setElementsChecked: Dispatch<SetStateAction<number[]>>;
  merchantIDProp?: string | null;
  promise: Function;
  type: string;
};

function ArchiveModal({
  elementsChecked,
  setElementsChecked,
  merchantIDProp = null,
  promise,
  type,
}: ArchiveModalType) {
  // Need to change the name, in merchant name is stored all the names
  // regardless the type (item, grand prize, merchant)
  const names = useSelector(
    (state: RootState) => state.merchants.merchantsName
  );
  const dispatch = useDispatch();

  const handleSubmit = () => {
    elementsChecked.forEach(async (id: number) => {
      let toastPromise;
      let merchantID: string | undefined;

      if (type === "Merchant") {
        toastPromise = toast.promise(promise({id, status: "ARCHIVED"}), {
          pending: `Archiving ${type}`,
        });
      } else if (type === "Item") {
        let internalType;

        if (merchantIDProp) {
          merchantID = merchantIDProp;

          internalType = document
            ?.querySelector("table")
            ?.querySelector(`[id='${id}']`)?.children[2]?.innerHTML;
        } else {
          merchantID = document
            ?.querySelector("table")
            ?.querySelector(`[id='${id}']`)
            ?.querySelector(".merchant-id")?.children[0].id;

          internalType = document
            ?.querySelector("table")
            ?.querySelector(`[id='${id}']`)?.children[3]?.innerHTML;
        }

        if (!internalType || !merchantID) return;

        toastPromise = toast.promise(
          promise({
            idItem: id.toString(),
            idMerchant: merchantID?.toString(),
            status: "PAUSED",
          }),
          {
            pending: `Archiving ${internalType}`,
          }
        );
      } else if (type === "Grand Prize") {
        if (merchantIDProp) {
          merchantID = merchantIDProp;
        } else {
          merchantID = document
            ?.querySelector("table")
            ?.querySelector(`[id='${id}']`)?.children[5]?.innerHTML;
        }

        if (!merchantID || merchantID === "0")
          return toast.error("Error trying to get Merchant owner of item");

        toastPromise = toast.promise(
          promise({
            idItem: id.toString(),
            idMerchant: merchantID?.toString(),
            status: "ARCHIVED",
          }),
          {
            pending: `Archiving ${type}`,
          }
        );
      } else if (type === "Loyalty Program") {
        toastPromise = toast.promise(
          promise({
            idLoyalty: elementsChecked[0],
          }),
          {
            pending: `Archiving ${type}`,
          }
        );
      } else {
        return;
      }

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while archiving {type}</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            if (type === "Item") {
              const toastPromise = toast.promise(
                promise({
                  idItem: id.toString(),
                  idMerchant: merchantID?.toString(),
                  status: "ARCHIVED",
                }),
                {
                  pending: "Archiving item",
                }
              );

              toastPromise
                .then((res: any) => {
                  if (res.error) {
                    toast.error(
                      <div>
                        <h2>Error while archiving item</h2>
                        <p className="text-xs"> {res?.error?.data?.code} </p>
                      </div>
                    );
                  } else {
                    toast.success(`${type} archived`);
                    dispatch(itemApi.util.invalidateTags(["Item"]));
                    dispatch(
                      merchantsApi.util.invalidateTags(["MerchantGrandPrize"])
                    );
                    dispatch(
                      merchantsApi.util.invalidateTags(["MerchantItems"])
                    );
                  }
                })
                .catch((err) => {
                  toast.error("Error while archiving item");
                });
            } else {
              toast.success(`${type} archived`);
            }
          }
        })
        .catch((err) => {
          toast.error(`Error while archiving ${type}`);
        })
        .finally(() => {
          dispatch(setResetElChecked(true));
          setElementsChecked([]);
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        });
    });
  };

  const getNames = () => {
    if (!elementsChecked.length) return <></>;

    // console.log("elementsChecked", elementsChecked, names);

    const elementNames = elementsChecked.map((el) => {
      return names?.filter((employee: any) => {
        if (employee.id === el || employee.prizeId === el) {
          return employee;
        }
      });
    });

    const namesFlatted = elementNames?.flat();

    return namesFlatted?.map((element: any) => {
      if (element) {
        return namesFlatted.length > 1 ? (
          <b key={element.name}>{element.name}, </b>
        ) : (
          <b key={element.name}>{element.name} </b>
        );
      } else {
        return <b></b>;
      }
    });
  };

  return (
    <Modal>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
            <Archive classes="text-red-500 h-10 w-10" />
          </div>
          <h1 className="text-red-500 mt-4">Archive</h1>
          <p className="text-sm mt-4 text-center">
            Are you sure you want to archive {type} {getNames()}?
          </p>
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleSubmit();
            }}
          >
            Archive
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ArchiveModal;
