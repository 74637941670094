export const differenceDate = (startDate: string, endDate: string) => {
    // @ts-ignore
    const diffInMs = new Date(endDate?.split("T")[0]) - new Date(startDate?.split("T")[0]);

    let diffInDays;

    if (startDate.includes("00:00:00") && endDate.includes("23:59:59")) {
        diffInDays = Math.ceil((diffInMs + 13600000) / (1000 * 60 * 60 * 24));
    } else {
        diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    }

    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"}`;
};

export const getRoundedDate = (extraOption: any) => {
    if (!extraOption.loyaltyEndDate) return "";

    const originalDate = new Date(extraOption.loyaltyEndDate);

    // Add one second to the original date
    originalDate.setSeconds(originalDate.getSeconds() + 12000);

    return originalDate.toISOString().substring(0, 10);
};