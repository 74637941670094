import { TypeClasses } from "./types";

function Publish({ classes }: TypeClasses) {
  return (
    <svg
      id="publish"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="feed_FILL0_wght400_GRAD0_opsz48"
        d="M7.5,24A1.538,1.538,0,0,1,6,22.5V7.5A1.538,1.538,0,0,1,7.5,6H19.05L24,10.95V22.5A1.538,1.538,0,0,1,22.5,24Zm0-1.5h15V11.775H18.225V7.5H7.5Zm2.475-2.775h10.05v-1.5H9.975Zm0-7.95H15v-1.5H9.975Zm0,3.975h10.05v-1.5H9.975ZM7.5,7.5v0Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Publish;
