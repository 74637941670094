// Components
import ItemOption from "components/Options";
import MenuItem from "components/Options/MenuItem";
import {useSelector} from "react-redux";
import {RootState} from "store/store";

function ProfileItem({hide}: any) {
  const userInfo = useSelector((state: RootState) => state.user.user.user.data);

  const handleSignOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const getRole = () => {
    const role = userInfo?.role?.toLowerCase();
    if (role) {
      if (role === "superadmin") {
        return "Super Admin";
      } else if (role === "salesadmin") {
        return "Admin";
      } else if (role === "moderator") {
        return "Moderator";
      } else {
        return userInfo?.role;
      }
    }
  };

  return (
    <div className="flex flex-col justify-end items-center h-full w-full space-y-32">
      <div className="w-full bg-primary-purple-dark sticky bottom-0">
        <div
          className={`flex justify-center items-center w-full  pb-5 pt-5 ${
            !hide && "pl-6"
          }`}
        >
          {!hide && (
            <div className="flex justify-center items-center  space-x-2 ">
              <div className="flex justify-start flex-col items-start">
                <p className="leading-5 text-sm text-white font-semibold pb-2">
                  {userInfo?.firstName} {userInfo?.lastName}
                </p>
                <p className="text-xs leading-3 text-slate-500 font-semibold">
                  {getRole()}
                </p>
              </div>
            </div>
          )}
          <ItemOption hide={hide} toTop={true} classes="text-white-200">
            <MenuItem type="resetPassword" title="Reset Password" />
            <MenuItem onClick={handleSignOut} title="Sign out" />
          </ItemOption>
        </div>
      </div>
    </div>
  );
}

export default ProfileItem;
