import update from "immutability-helper";
import {FC, useEffect} from "react";
import {useCallback, useState} from "react";

import {Card} from "./Card";

export interface Item {
  id: number;
  text: string;
  retailerId?: number;
}

export interface ContainerState {
  cards: Item[];
}

export const Container = ({
  data,
  callback,
  typeBack = "",
}: {
  data: any;
  callback: any;
  typeBack?: string;
}) => {
  const [cards, setCards] = useState<any>(data);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);

  const moveCardKeepPosition = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: Item[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1], // remove the card we're dragging
            [dragIndex, 0, prevCards[hoverIndex]], // put the card we're hovering over in it's place
            [hoverIndex, 1], // remove the card we're hovering over
            [hoverIndex, 0, prevCards[dragIndex]], // insert dragged card into hover cards's position
          ],
        })
      );
    },
    []
  );

  useEffect(() => {
    if (cards) {
      if (typeBack === "merchant") {
        const newCategoriesOrdered: any = [];

        cards.forEach((element: any, i: number) => {
          if (element.retailerId) {
            newCategoriesOrdered.push({
              retailerId: element.retailerId,
              placement: i + 1,
            });
          }
        });

        callback(newCategoriesOrdered);
      } else {
        callback(
          cards.map((card: any, i: number) => {
            return {
              categoryId: card.categoryId,
              position: i + 1,
            };
          })
        );
      }
    }
  }, [cards]);

  const renderCard = useCallback(
    (card: {id: number; text: string}, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      );
    },
    []
  );

  const renderCardKeepPostition = useCallback(
    (card: {id: number; text: string; position: string}, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          position={card.position}
          text={card.text}
          moveCard={moveCardKeepPosition}
        />
      );
    },
    []
  );

  return (
    <>
      <div>
        {cards?.map((card: any, i: number) =>
          typeBack !== "merchant"
            ? renderCard(card, i)
            : renderCardKeepPostition(card, i)
        )}
      </div>
    </>
  );
};
