import Alert from "assets/Alert";

const Checkbox = ({
  id,
  alert,
  name,
  onChange,
  onClick,
  isChecked,
  disabled,
  className,
}: any) => {
  return (
    <div>
      <input
        className={`cursor-pointer w-4 h-4 accent-primary-purple ${className}`}
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
        onClick={onClick}
        checked={isChecked}
        disabled={disabled}
      />
      <label htmlFor={id} className="sr-only">
        checkbox
      </label>
    </div>
  );
};

export default Checkbox;
