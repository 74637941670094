import {useEffect, useState} from "react";

// Components
import Checkbox from "components/Common/Checkbox";
import {useDispatch} from "react-redux";
import {setSorting} from "store/filters/filtersSlice";

interface TableHeadTypes {
  headers: any;
  hasCheckbox: boolean;
  handleSorting?: any;
  handleSelectAll: any;
  isCheckAll: boolean;
  showButtonOption?: boolean;
  orderingByAPI?: boolean;
  origin?: string;
}

const TableHead = ({
  headers,
  hasCheckbox,
  handleSorting,
  handleSelectAll,
  isCheckAll,
  showButtonOption = true,
  orderingByAPI = false,
  origin,
}: TableHeadTypes) => {
  const dispatch = useDispatch();
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("");

  const handleSortingChange = (accessor: any) => {
    const seletedFilter = accessor === sortField;

    const sortOrder = () => {
      if (seletedFilter) {
        if (order === "asc") {
          return "desc";
        } else if (order === "") {
          return "asc";
        }
      } else {
        return "asc";
      }
      return "";
    };

    setSortField(accessor);
    setOrder(sortOrder());

    if (!orderingByAPI) {
      handleSorting(accessor, sortOrder() === "" ? "asc" : sortOrder());
    }
  };

  const getOrdering = () => {
    console.log(origin);
    if (origin === "locations") {
      return "dma_code";
    } else if (origin === "areas") {
      return "zip_code";
    } else if (origin === "categories_published") {
      return "position";
    } else if (origin === "categories_archived") {
      return "created_at";
    } else {
      return "id";
    }
  };

  useEffect(() => {
    if (sortField) {
      const sortFieldOrder = () => {
        if (order === "asc") {
          return `ordering=-${sortField}`;
        } else if (order === "desc") {
          return `ordering=${sortField}`;
        } else {
          return `ordering=${getOrdering()}`;
        }
      };

      if (orderingByAPI) {
        dispatch(setSorting(sortFieldOrder()));
      }
    }
  }, [order, sortField]);

  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
      <tr>
        {hasCheckbox ? (
          <th scope="col" className="p-4">
            <div className="flex items-center">
              <Checkbox
                name="selectAll"
                id="selectAll"
                onChange={handleSelectAll}
                isChecked={isCheckAll}
              />
            </div>
          </th>
        ) : null}
        {headers.map(({label, accessor, sortable}: any, key: any) => {
          // sortableChecker({ label, accessor, sortable });
          const cl = sortable
            ? sortField === accessor && order === "asc"
              ? "up"
              : sortField === accessor && order === "desc"
              ? "down"
              : "default"
            : "";

          return (
            <th
              key={key}
              onClick={
                sortable ? () => handleSortingChange(accessor) : () => {}
              }
              id={"up"}
              className={`py-3 px-4 font-medium normal-case text-gray-400 ${cl}`}
            >
              {label}
            </th>
          );
        })}
        {showButtonOption && <th scope="col" className="py-3 px-6"></th>}
      </tr>
    </thead>
  );
};

export default TableHead;
