export const transformDate = (value: string) => {
    const date = new Date(value);

    // Extract day, month, and year in UTC format
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();

    // Array of month names in short format
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Get month abbreviation from the months array
    const month = months[monthIndex];

    // Reformat the date as `${day} ${month}. ${year}`
    const finalDate = `${day} ${month}. ${year}`;

    return finalDate;
};

export const transformDateWithHour = (value: string) => {
    const date = new Date(value);
    const day = date.getUTCDate(); // DD
    const month = date.toLocaleString("en-us", { month: "short" }); // MMM
    const year = date.toLocaleString("en-us", { year: "numeric" }); // YYYY
    const hour = date.toLocaleString("en-us", { hour: "2-digit" }); // HH
    const minute = date.toLocaleString("en-us", { minute: "2-digit" }); // MM



    const finalDate = `${day} ${month}. ${year} at ${hour?.split(" ")[0]}:${minute} ${hour?.split(" ")[1]}`;

    return finalDate
}