import { TypeClasses } from "./types";

function Edit({ classes }: TypeClasses) {
  return (
    <svg
      id="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="edit_FILL0_wght400_GRAD0_opsz48"
        d="M7.473,21.877h1.08L19.432,11l-1.08-1.08L7.473,20.8ZM22.551,9.942,19.408,6.8l1.031-1.031a1.483,1.483,0,0,1,2.063,0l1.08,1.08a1.483,1.483,0,0,1,0,2.063ZM21.52,10.973,9.143,23.35H6V20.207L18.377,7.83Zm-2.628-.516-.54-.54L19.432,11Z"
        transform="translate(-3 -2.35)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Edit;
