import { TypeClasses } from "./types";

function Archive({ classes }: TypeClasses) {
  return (
    <svg
      id="arrow-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="move_to_inbox_FILL0_wght400_GRAD0_opsz48"
        d="M7.5,24A1.538,1.538,0,0,1,6,22.5V7.5A1.538,1.538,0,0,1,7.5,6h15A1.538,1.538,0,0,1,24,7.5v15A1.538,1.538,0,0,1,22.5,24Zm0-1.5h15V19.1H18.85a4.4,4.4,0,0,1-1.687,1.538,4.619,4.619,0,0,1-4.325,0A4.4,4.4,0,0,1,11.15,19.1H7.5ZM15,19.675a3.114,3.114,0,0,0,1.85-.587,5.314,5.314,0,0,0,1.4-1.487H22.5V7.5H7.5V17.6h4.25a5.314,5.314,0,0,0,1.4,1.487A3.114,3.114,0,0,0,15,19.675Zm0-3.225-3.825-3.825L12.25,11.55l2,2V8.825h1.5V13.55l2-2,1.075,1.075ZM7.5,22.5h0Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Archive;
