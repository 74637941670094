// Components
import Button from "components/Common/Button";
import Searchbar from "components/Common/Searchbar";

// Assets
import {ArrowDown} from "assets/Arrows";
import FilterIcon from "assets/Filter";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "components/Common/Checkbox";
import {useGetAllLocationQuery} from "store/api/locationApi";
import {useClickOutside} from "hooks/useClickOutside";
import {RootState} from "store/store";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {setURLFilters} from "store/filters/filtersSlice";

interface FilterTypes {
  showFilter: boolean;
  hasFilterButton?: boolean;
  handleShowFilter: Function;
  showDropdownCheckbox?: boolean;
  showDropdownCheckboxOptional?: boolean;
  optionsDropdownCheckbox?: string[];
  optionsAllDropdownCheckbox?: string;
  searchBarPlaceholder?: string;
  getInfoStatesFilters?: Function;
  getInfoDMAFilters?: Function;
}

function FilterFormLocation({
  showFilter,
  hasFilterButton = true,
  handleShowFilter,
  showDropdownCheckbox = true,
  optionsDropdownCheckbox = [],
  optionsAllDropdownCheckbox = "",
  searchBarPlaceholder,
  getInfoStatesFilters,
}: FilterTypes) {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const [search, setSearch] = useState("");
  const [dropdown, openDropdown] = useState(false);

  const [stateListDropdown, setStateListDropdown] = useState<any>([
    filters.lastCategoryLocation.states,
  ]);

  useClickOutside(wrapperRef, openDropdown);

  const {
    data: dataLocation,
    isLoading: isLoadingDataLocation,
    isError: isErrorDataLocation,
    refetch,
    // @ts-ignore
  } = useGetAllLocationQuery(`?search=${search}`);

  useEffect(() => {
    if (stateListDropdown && stateListDropdown.length > 0) {
      const params = "&state=" + stateListDropdown.join(",");

      dispatch(setURLFilters(params));
    }
  }, [stateListDropdown]);

  useEffect(() => {
    if (
      dataLocation &&
      dataLocation.locations &&
      dataLocation.locations.length > 0
    ) {
      const categoriesNames = dataLocation.locations.map((el: any) => {
        return {id: el.dmaName, name: el.dmaName};
      });
      dispatch(setMerchantsName(categoriesNames));
    }
  }, [dataLocation]);

  const handleClickCheckbox = (e: any) => {
    const {id, checked} = e.target;

    if (checked) {
      setStateListDropdown([id]);
      if (getInfoStatesFilters) {
        getInfoStatesFilters([id]);
      }
    }

    if (!checked) {
      setStateListDropdown([]);
      if (getInfoStatesFilters) {
        getInfoStatesFilters([]);
      }
    }
  };

  const handleGetCurrentSearch = (currentSearch: string) => {
    if (currentSearch.length === 0) {
      setSearch("");
    }
    if (currentSearch.length > 1) {
      setSearch(currentSearch);
    }
  };

  return (
    <div className="flex justify-between items-center flex-col lg:flex-row">
      <div className="flex">
        {hasFilterButton && (
          <Button
            classes={`text-black-300 font-medium rounded-lg h-full text-sm ${
              showFilter ? "bg-indigo-200" : "bg-white hover:bg-primary-white"
            } mr-4`}
            onClick={() => handleShowFilter((prev: boolean) => !prev)}
          >
            <FilterIcon classes={"mr-2"} />
            Filter
            <ArrowDown classes={"ml-2"} />
          </Button>
        )}

        <div className="relative flex h-full">
          {showDropdownCheckbox && (
            <div className="relative">
              <div
                onClick={() => openDropdown((prev) => !prev)}
                className={`${
                  dropdown ? "border-primary-purple" : "border-transparent"
                } h-9  items-center rounded-l-lg border-2  hover:bg-primary-white cursor-pointer p-2.5 pl-4 z-0 bg-white rounded-l-t font-medium text-sm flex justify-between`}
              >
                <span className="flex capitalize status-filter whitespace-nowrap">
                  {stateListDropdown.length > 0 && stateListDropdown[0] !== ""
                    ? stateListDropdown.join(", ", stateListDropdown)
                    : optionsAllDropdownCheckbox}
                </span>
                <span>
                  <ArrowDown />
                </span>
              </div>
              {dropdown && (
                <div
                  className="bg-white py-3 px-4 absolute l-0 rounded-b drop-shadow-xl z-30 w-full flex items-center gap-2"
                  ref={wrapperRef}
                  style={{
                    height: "auto",
                    maxHeight: "350px",
                    overflowY: "scroll",
                  }}
                >
                  {optionsDropdownCheckbox?.map((opt: string) => (
                    <div className="flex items-center">
                      <Checkbox
                        id={opt}
                        key={opt}
                        type="checkbox"
                        name={opt}
                        onChange={handleClickCheckbox}
                        isChecked={stateListDropdown?.includes(opt)}
                      />
                      <label
                        htmlFor={opt}
                        className="ml-3 font-medium text-sm capitalize cursor-pointer"
                      >
                        {opt}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <Searchbar
            placeholder={
              searchBarPlaceholder ??
              "Search by Merchants name, location, ID, zip-code"
            }
            classes="w-96 pr-3"
            classesContainer="h-min search-bar-autocomplete"
            useTags={true}
            callback={handleGetCurrentSearch}
          />
        </div>
      </div>
    </div>
  );
}

export default FilterFormLocation;
