import {TypeClasses} from "./types";

export function ButtonCirclePlus({classes}: TypeClasses) {
  return (
    <svg
      id="plus-circle"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classes}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Сгруппировать_39105"
        data-name="Сгруппировать 39105"
        transform="translate(-419 -197)"
      >
        <rect
          id="Прямоугольник_8951"
          data-name="Прямоугольник 8951"
          width="24"
          height="24"
          transform="translate(419 197)"
          fill="none"
        />
        <g
          id="_94ed4df1c470af571565164b9572c9c1"
          data-name="94ed4df1c470af571565164b9572c9c1"
          transform="translate(420 197.929)"
        >
          <path
            id="Контур_1327"
            data-name="Контур 1327"
            d="M11,20.071a9,9,0,1,1,9-9A9.01,9.01,0,0,1,11,20.071Zm0-16.56a7.56,7.56,0,1,0,7.56,7.56A7.568,7.568,0,0,0,11,3.511Z"
            fill="currentColor"
          />
          <path
            id="Контур_1328"
            data-name="Контур 1328"
            d="M14.22,17.651a.72.72,0,0,1-.72-.72V8.291a.72.72,0,0,1,1.44,0v8.64A.72.72,0,0,1,14.22,17.651Z"
            transform="translate(-3.22 -1.54)"
            fill="currentColor"
          />
          <path
            id="Контур_1329"
            data-name="Контур 1329"
            d="M16.86,15.011H8.22a.72.72,0,0,1,0-1.44h8.64a.72.72,0,0,1,0,1.44Z"
            transform="translate(-1.54 -3.22)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export function ButtonCircleSubstract({classes}: TypeClasses) {
  return (
    <svg
      id="substract-circle"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classes}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Сгруппировать_39105"
        data-name="Сгруппировать 39105"
        transform="translate(-419 -197)"
      >
        <rect
          id="Прямоугольник_8951"
          data-name="Прямоугольник 8951"
          width="24"
          height="24"
          transform="translate(419 197)"
          fill="none"
        />
        <g
          id="_94ed4df1c470af571565164b9572c9c1"
          data-name="94ed4df1c470af571565164b9572c9c1"
          transform="translate(420 197.929)"
        >
          <path
            id="Контур_1327"
            data-name="Контур 1327"
            d="M11,20.071a9,9,0,1,1,9-9A9.01,9.01,0,0,1,11,20.071Zm0-16.56a7.56,7.56,0,1,0,7.56,7.56A7.568,7.568,0,0,0,11,3.511Z"
            fill="currentColor"
          />
          <path
            id="Контур_1329"
            data-name="Контур 1329"
            d="M16.86,15.011H8.22a.72.72,0,0,1,0-1.44h8.64a.72.72,0,0,1,0,1.44Z"
            transform="translate(-1.54 -3.22)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
