import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";

// Components
import Button from "components/Common/Button";
import Checkbox from "components/Common/Checkbox";
import Input from "components/Common/Input";
import Select from "components/Common/Input/Select";
import Spinner from "components/Common/Spinner";

// Assets
import {EyeHide, EyeView} from "assets/Eye";
import {toast} from "react-toastify";
import {useRegisterUserMutation} from "store/api/authApi";
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";

interface User {
  firstName: string;
  lastName: string;
  email: {
    value: string;
    error: boolean;
  };
  password: {
    value: string;
    error: boolean;
  };
  role: string;
}

interface ErrorRegister {
  show: boolean;
  msg: string;
}

const regexEmail = /@(theswiggleapp|swiggleit|swiggle-it|swigglesavings)\b/;
const regexPassword =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const errorMessages = {
  required: "All fields are required",
  password:
    "Password must contain at least eight characters (including at least 1 capital letter, 1 digit and 1 special symbol)",
  email: "Invalid email, only Swiggle team members can sign up",
};

function Register({setBackTo}: any) {
  const dispatch = useDispatch();

  const [
    registerUser,
    {data: register, isLoading: isLoadingRegister, isError: isErrorRegister},
  ] = useRegisterUserMutation();

  const [user, setUser] = useState<User>({
    firstName: "",
    lastName: "",
    email: {value: "", error: false},
    password: {value: "", error: false},
    role: "",
  });

  const [errorRegister, setErrorRegister] = useState<ErrorRegister>({
    show: false,
    msg: "",
  });
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (errorRegister.show && errorRegister.msg.length > 0) {
      toast.error(errorRegister.msg);
    }
  }, [errorRegister]);

  useEffect(() => {
    if (user.password.error) {
      const isValidPassword = regexPassword.test(user.password.value);

      if (isValidPassword) {
        setUser({
          ...user,
          password: {...user.password, error: false},
        });
      }
    }

    if (user.email.error) {
      const isValidEmail = regexEmail.test(user.email.value);

      if (isValidEmail) {
        setUser({
          ...user,
          email: {...user.email, error: false},
        });
      }
    }
  }, [user]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorRegister({show: false, msg: ""});

    const data = {
      firstName: user.firstName?.trim(),
      lastName: user.lastName?.trim(),
      email: user.email?.value?.trim()?.toLowerCase(),
      password: user.password?.value?.trim(),
      confirmPassword: user.password?.value?.trim(),
      role: user.role,
    };

    const isValidEmail = regexEmail.test(data.email);
    const isValidPassword = regexPassword.test(data.password);

    setErrorRegister({show: false, msg: ""});

    if (
      data.email.length <= 0 ||
      data.password.length <= 0 ||
      data.firstName.length <= 0 ||
      data.lastName.length <= 0 ||
      data.role.length <= 0
    ) {
      setErrorRegister({show: true, msg: errorMessages.required});
      return;
    }
    if (!isValidPassword) {
      setErrorRegister({
        show: true,
        msg: errorMessages.password,
      });
      setUser({
        ...user,
        password: {...user.password, error: true},
      });
      return;
    }
    if (!isValidEmail) {
      setErrorRegister({
        show: true,
        msg: errorMessages.email,
      });
      setUser({
        ...user,
        email: {...user.email, error: true},
      });
      return;
    }

    const toastPromise = toast.promise(registerUser(data), {
      pending: "Registering user",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while registering user</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Please check email to verify your email address");

          setTimeout(() => {
            setBackTo("login");
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error("Error while registering user");
      });
  };

  return (
    <>
      <form
        action=""
        className="bg-white rounded p-6 pr-20 w-4/5 border-t-8 border-primary-purple drop-shadow-[0_10px_10px_#9965d04b]"
      >
        <h1 className="text-3xl font-semibold mb-3">Create Account</h1>
        <Input
          label="First name"
          value={user.firstName}
          required
          onChange={(e: any) => setUser({...user, firstName: e.target.value})}
          type="text"
          placeholder="Enter your first name"
          classes={`my-6`}
          classesInput={`pl-4 w-full ${
            errorRegister.show &&
            user.firstName.length <= 0 &&
            "!border-red-500"
          }`}
          error={errorRegister.show && user.firstName.length <= 0}
          classesLabel={`${
            user.firstName.length > 0 ? "text-primary-purple" : "text-gray-400"
          }`}
        />

        <Input
          label="Last name"
          value={user.lastName}
          required
          onChange={(e: any) => setUser({...user, lastName: e.target.value})}
          type="text"
          placeholder="Enter your last name"
          classes={`my-6`}
          classesInput={`pl-4 w-full ${
            errorRegister.show && user.lastName.length <= 0 && "!border-red-500"
          }`}
          error={errorRegister.show && user.lastName.length <= 0}
          classesLabel={`${
            user.lastName.length > 0 ? "text-primary-purple" : "text-gray-400"
          }`}
        />
        <Input
          label="Email address"
          value={user.email?.value}
          required
          onChange={(e: any) =>
            setUser({...user, email: {...user.email, value: e.target.value}})
          }
          type="email"
          placeholder="name@theswiggleapp.com"
          classes={`my-6`}
          classesInput={`pl-4 w-full ${
            ((errorRegister.show && user.email?.value.length <= 0) ||
              user.email?.error) &&
            "!border-red-500"
          }`}
          error={
            errorRegister.show &&
            user.email?.value.length <= 0 &&
            user.email?.error
          }
          classesLabel={`${
            user.email?.value.length > 0
              ? "text-primary-purple"
              : "text-gray-400"
          }`}
        />
        <Input
          label="Password"
          secondLabel="Password must contain at least eight characters (including at least 1 capital letter, 1 digit and 1 special symbol)"
          value={user.password?.value}
          required
          placeholder="Enter your password"
          error={
            errorRegister.show &&
            user.password?.value?.length <= 0 &&
            user.password?.error
          }
          classesInput={`pl-4 w-full ${
            ((errorRegister.show && user.password?.value.length <= 0) ||
              user.password?.error) &&
            "!border-red-500"
          }`}
          type={showPass ? "text" : "password"}
          onChange={(e: any) =>
            setUser({
              ...user,
              password: {...user.password, value: e.target.value},
            })
          }
          icon={
            showPass ? (
              <EyeHide
                classes="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowPass((prev: any) => !prev)}
              />
            ) : (
              <EyeView
                classes="absolute right-3 top-3 cursor-pointer"
                onClick={() => setShowPass((prev: any) => !prev)}
              />
            )
          }
          classesLabel={`${
            user.password?.value?.length > 0
              ? "text-primary-purple"
              : "text-gray-400"
          }`}
        />
        <Select
          label="Role"
          required
          classesSelect={`py-2 pl-2.5 pr-9 w-full ${
            errorRegister.show && user.role?.length <= 0
              ? "!border-red-500"
              : "border border-gray-400"
          }`}
          classes="my-6"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setUser({
              ...user,
              role: e.target.value,
            });
          }}
          secondLabel={
            <>
              <Tooltip id="my-tooltip">
                <div className="p-1">
                  <ul className="flex gap-5 flex-col max-w-[250px]">
                    <li className="text-xs">
                      <b>Moderator</b> - Review content and images for all
                      merchants and items
                    </li>
                    <li className="text-xs">
                      <b>Sales Admin</b> - Part of the Sales team responsible
                      for acquiring new and existing merchants
                    </li>
                    <li className="text-xs">
                      <b>Super Admin</b> - Full access to the settings and
                      configuration tab
                    </li>
                  </ul>
                </div>
              </Tooltip>
              <span
                data-tooltip-id="my-tooltip"
                className="flex justify-center items-center cursor-pointer h-4 w-4 p-2 ml-2 bg-gray-200 rounded-full border-2 border-gray-400 text-gray-400"
              >
                i
              </span>
            </>
          }
        >
          <>
            <option value="" disabled selected>
              Choose your role
            </option>
            <option value="MODERATOR">Moderator</option>
            <option value="SALESADMIN">Admin</option>
            <option value="SUPERADMIN">Super Admin</option>
          </>
        </Select>

        <Button
          classes="bg-primary-purple text-white mr-2 mt-2 text-sm font-base hover:bg-secondary-purple w-full"
          onClick={(e: any) => handleSubmit(e)}
        >
          {isLoadingRegister ? (
            <Spinner classes="border-l-white border-r-white border-t-white w-5 h-5" />
          ) : (
            "Create Account"
          )}
        </Button>
        <div className="flex justify-center mt-7 mb-2">
          <Button
            type="button"
            onClick={() => setBackTo("login")}
            classes="text-sm text-blue-400"
          >
            Already have an account?
          </Button>
        </div>
      </form>
    </>
  );
}

export default Register;
