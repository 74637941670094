import { TypeClasses } from "./types";

function Delete({ classes }: TypeClasses) {
  return (
    <svg
      id="dashboard"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="delete_FILL0_wght400_GRAD0_opsz48"
        d="M10.525,24a1.538,1.538,0,0,1-1.5-1.5V8.25H8V6.75h4.7V6h6.6v.75H24v1.5H22.975V22.5a1.538,1.538,0,0,1-1.5,1.5ZM21.475,8.25H10.525V22.5h10.95Zm-8.3,12.1h1.5V10.375h-1.5Zm4.15,0h1.5V10.375h-1.5Zm-6.8-12.1v0Z"
        transform="translate(-4 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Delete;
