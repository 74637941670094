// @ts-nocheck
import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'store/store'
import customFetchBase from './customFetchBase';


export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUserByID: builder.query({
      query: (id) => ({
        url: `/retailer-profile/admin/retailer/${id}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    getAllUsers: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-profile/admin/retailer${!params ? '/' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["Users"]
    }),

  }),
})

export const {
  useGetAllUsersQuery
} = userApi