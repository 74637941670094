// Components
import Checkbox from "components/Common/Checkbox";

function CheckboxTable({
  element,
  hasCheckbox,
  idTableShow,
  isCheck,
  handleClick,
}: any) {
  return (
    <>
      {hasCheckbox ? (
        <td
          className={`p-4 w-4 ${
            idTableShow.includes(element.id || element.prizeId) &&
            origin === "merchant_items" &&
            "border-l-4 border-primary-purple"
          }`}
        >
          <div className="flex items-center">
            <Checkbox
              key={
                element.id ||
                element.prizeId ||
                element.categoryId ||
                element.dmaCode ||
                element.loyaltyId
              }
              type="checkbox"
              name={element.name}
              id={
                element.id ||
                element.prizeId ||
                element.categoryId ||
                element.dmaCode ||
                element.loyaltyId
              }
              onChange={handleClick}
              isChecked={isCheck.includes(
                element.id ||
                  element.prizeId ||
                  element.categoryId ||
                  parseInt(element.dmaCode) ||
                  element.loyaltyId
              )}
            />
          </div>
        </td>
      ) : null}
    </>
  );
}

export default CheckboxTable;
