import {TypeClasses} from "./types";

export function EyeHide({classes, onClick}: any) {
  return (
    <svg
      id="hide"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`w-5 h-5 ${classes}`}
      onClick={onClick}
      fill="currentColor"
    >
      <rect
        id="Прямоугольник_9044"
        data-name="Прямоугольник 9044"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="visibility_off_FILL0_wght400_GRAD0_opsz48"
        d="M14.048,13.873l-.9-.9a2.029,2.029,0,0,0-.552-2.414,2.185,2.185,0,0,0-2.352-.491l-.9-.9a2.472,2.472,0,0,1,.777-.327,3.784,3.784,0,0,1,.88-.1,3.457,3.457,0,0,1,3.477,3.477,3.576,3.576,0,0,1-.113.89,2.7,2.7,0,0,1-.317.767Zm2.639,2.639-.818-.818a9.593,9.593,0,0,0,1.749-1.647,6.573,6.573,0,0,0,1.094-1.831,8.165,8.165,0,0,0-3.068-3.59A8.032,8.032,0,0,0,11.2,7.307a9.824,9.824,0,0,0-1.759.164,6.522,6.522,0,0,0-1.411.389L7.093,6.9a9.275,9.275,0,0,1,1.831-.573A10.115,10.115,0,0,1,11.1,6.08a9.234,9.234,0,0,1,5.349,1.667A9.666,9.666,0,0,1,20,12.216a10.105,10.105,0,0,1-1.37,2.393A9.879,9.879,0,0,1,16.686,16.511Zm1.186,4.623-3.436-3.375a7.947,7.947,0,0,1-1.616.44,10.815,10.815,0,0,1-1.82.153,9.378,9.378,0,0,1-5.42-1.667A9.771,9.771,0,0,1,2,12.216,9.281,9.281,0,0,1,3.135,10.14,11.627,11.627,0,0,1,4.9,8.207L2.327,5.63l.859-.88L18.67,20.234ZM5.743,9.066a7.528,7.528,0,0,0-1.462,1.452,6.733,6.733,0,0,0-1.012,1.7,8.191,8.191,0,0,0,3.14,3.59,8.741,8.741,0,0,0,4.756,1.319,10.709,10.709,0,0,0,1.33-.082,3.177,3.177,0,0,0,.982-.245l-1.309-1.309a2.141,2.141,0,0,1-.552.153,3.987,3.987,0,0,1-.614.051,3.382,3.382,0,0,1-2.455-1,3.328,3.328,0,0,1-1.023-2.475,3.727,3.727,0,0,1,.051-.614,2.5,2.5,0,0,1,.153-.552ZM11.982,11.97ZM9.609,13.157Z"
        transform="translate(1 -0.75)"
      />
    </svg>
  );
}

export function EyeView({classes, onClick}: any) {
  return (
    <svg
      id="view"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
      onClick={onClick}
    >
      <rect
        id="Прямоугольник_9044"
        data-name="Прямоугольник 9044"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="visibility_FILL0_wght400_GRAD0_opsz48"
        d="M11,17.614a3.457,3.457,0,0,0,3.477-3.477A3.457,3.457,0,0,0,11,10.659a3.457,3.457,0,0,0-3.477,3.477A3.457,3.457,0,0,0,11,17.614Zm0-1.186A2.3,2.3,0,0,1,9.374,12.51a2.321,2.321,0,0,1,3.252,0A2.291,2.291,0,0,1,11,16.427Zm0,3.845a9.167,9.167,0,0,1-5.4-1.7A10.343,10.343,0,0,1,2,14.136,10.343,10.343,0,0,1,5.6,9.7,9.167,9.167,0,0,1,11,8a9.167,9.167,0,0,1,5.4,1.7A10.343,10.343,0,0,1,20,14.136a10.343,10.343,0,0,1-3.6,4.439A9.167,9.167,0,0,1,11,20.273ZM11,14.136Zm0,4.909a8.225,8.225,0,0,0,4.551-1.34,8.694,8.694,0,0,0,3.16-3.569,8.694,8.694,0,0,0-3.16-3.569,8.4,8.4,0,0,0-9.1,0,8.84,8.84,0,0,0-3.181,3.569,8.84,8.84,0,0,0,3.181,3.569A8.225,8.225,0,0,0,11,19.045Z"
        transform="translate(1 -2)"
        fill="currentColor"
      />
    </svg>
  );
}
