import { TypeClasses } from "./types";

function Profile({ classes }: TypeClasses) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="account_circle_FILL0_wght400_GRAD0_opsz48"
        d="M7.195,18.063a12.959,12.959,0,0,1,2.813-1.519A8.373,8.373,0,0,1,13,16.015a8.464,8.464,0,0,1,3,.529,12.856,12.856,0,0,1,2.824,1.519,7.9,7.9,0,0,0,1.406-2.453A8.132,8.132,0,0,0,20.65,13,7.5,7.5,0,0,0,13,5.35,7.5,7.5,0,0,0,5.35,13a7.937,7.937,0,0,0,.428,2.61A8.1,8.1,0,0,0,7.195,18.063ZM13,13.675a3.036,3.036,0,0,1-3.082-3.082A3.036,3.036,0,0,1,13,7.51a3.036,3.036,0,0,1,3.083,3.083A3.036,3.036,0,0,1,13,13.675ZM13,22a8.708,8.708,0,0,1-3.487-.709,9.068,9.068,0,0,1-4.8-4.8,8.962,8.962,0,0,1,0-6.986,9.1,9.1,0,0,1,4.8-4.793,8.962,8.962,0,0,1,6.986,0A9.129,9.129,0,0,1,21.291,9.5a8.962,8.962,0,0,1,0,6.986,9.1,9.1,0,0,1-4.792,4.8A8.684,8.684,0,0,1,13,22Zm0-1.35a8.252,8.252,0,0,0,2.419-.36,7.188,7.188,0,0,0,2.329-1.26,9.793,9.793,0,0,0-2.34-1.237,6.993,6.993,0,0,0-4.815,0,9.793,9.793,0,0,0-2.34,1.237,7.188,7.188,0,0,0,2.329,1.26A8.252,8.252,0,0,0,13,20.65Zm0-8.325a1.658,1.658,0,0,0,1.733-1.733,1.733,1.733,0,1,0-3.465,0A1.658,1.658,0,0,0,13,12.325ZM13,10.592ZM13,19.007Z"
        transform="translate(-1 -1)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Profile;
