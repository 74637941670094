import {useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";

// Components
import Modal from "components/Modal";
import Title from "components/Common/Title";
import Input from "components/Common/Input";
import Button from "components/Common/Button";

// Redux
import {useDispatch, useSelector} from "react-redux";
import type {RootState} from "store/store";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {toast} from "react-toastify";
import {EyeHide, EyeView} from "assets/Eye";
import {useChangeAdminPasswordMutation} from "store/api/adminApi";

function ResetPasswordModal() {
  const dispatch = useDispatch();
  const modal: any = useSelector((state: RootState) => state.modal);

  const [inputError, setInputError] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [data, setData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [errorLogin, setErrorLogin] = useState({show: false, msg: ""});
  const [showMsg, setShowMsg] = useState(false);

  const [
    resetPasswordMutation,
    {data: dataChangePassword, isLoading, isError, error},
  ] = useChangeAdminPasswordMutation();

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      if (isError?.data?.status === 401 || isError?.data?.status === 404) {
        setErrorLogin({
          show: true,
          // @ts-ignore
          msg: isError?.data?.code,
        });
      } else {
        setErrorLogin({
          show: true,
          msg:
            // @ts-ignore
            error?.data?.code === "error.incorrect-admin-password"
              ? "Incorrect admin password"
              : "An error occurred, try again later",
        });
      }
    }
  }, [isError]);

  useEffect(() => {
    if (dataChangePassword?.message && !isError && !isLoading) {
      setTimeout(() => {
        handleCloseModal();
        setShowMsg(false);
      }, 2500);
    }
  }, [dataChangePassword]);

  const handleCloseModal = () => {
    setData({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
    setShowPass({
      password: false,
      newPassword: false,
      confirmPassword: false,
    });

    setInputError({
      password: false,
      newPassword: false,
      confirmPassword: false,
    });

    setErrorLogin({show: false, msg: ""});

    dispatch(setType(""));
    dispatch(setOpenModal(false));
  };

  const validations = () => {
    if (!data.confirmPassword || !data.password || !data.newPassword) {
      setErrorLogin({show: true, msg: "All fields are required"});
      setInputError({
        password: true,
        newPassword: true,
        confirmPassword: true,
      });
      return false;
    }

    if (data.newPassword !== data.confirmPassword) {
      setErrorLogin({
        show: true,
        msg: "Your new password and confirmation password do not match",
      });
      setInputError({
        password: false,
        newPassword: true,
        confirmPassword: true,
      });
      return false;
    }

    const regexp = /^(?=.*[A-Za-z])(?=.*[0-9])/;

    if (!regexp.test(data.newPassword) || !regexp.test(data.confirmPassword)) {
      setErrorLogin({
        show: true,
        msg: "New password does not satisfy the requirements, should contain at least 8 letters and numbers",
      });

      setInputError({
        password: false,
        newPassword: true,
        confirmPassword: true,
      });
      return false;
    }

    if (data.newPassword.length < 8 || data.confirmPassword.length < 8) {
      setErrorLogin({
        show: true,
        msg: "Please enter password that contains at least 8 letters and numbers",
      });
      setInputError({
        password: false,
        newPassword: true,
        confirmPassword: true,
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    setErrorLogin({show: false, msg: ""});

    if (!validations()) return;

    setInputError({
      password: false,
      newPassword: false,
      confirmPassword: false,
    });

    await resetPasswordMutation({
      old_password: data.password,
      new_password: data.newPassword,
      new_password_confirm: data.confirmPassword,
    })
      .then((res) => {
        setShowMsg(true);
      })
      .catch((err) => {
        setShowMsg(true);
      });
  };

  return (
    <>
      {modal.type === "resetPassword" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            <Dialog.Overlay />
            <Title>Reset Password</Title>
            <hr />
            {errorLogin.show && (
              <span className="bg-red-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
                {errorLogin.msg}
              </span>
            )}
            {dataChangePassword?.message && showMsg && (
              <span className="bg-green-500 text-white text-sm flex items-center justify-center w-full p-3 rounded">
                {dataChangePassword?.message}
              </span>
            )}
            <div className="px-5 mt-5 mb-10">
              <Input
                type={showPass.password ? "text" : "password"}
                label="Enter your password"
                value={data.password}
                error={inputError.password ? true : false}
                onChange={(e) => setData({...data, password: e.target.value})}
                icon={
                  showPass.password ? (
                    <EyeHide
                      classes="absolute right-3 top-3 cursor-pointer"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          password: !showPass.password,
                        })
                      }
                    />
                  ) : (
                    <EyeView
                      classes="absolute right-3 top-3 cursor-pointer"
                      // @ts-ignore
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          password: !showPass.password,
                        })
                      }
                    />
                  )
                }
              />
              <Input
                type={showPass.newPassword ? "text" : "password"}
                label="Enter your new password"
                value={data.newPassword}
                classes="mt-4"
                error={
                  inputError.newPassword && data.newPassword.length < 9
                    ? true
                    : false
                }
                onChange={(e) =>
                  setData({...data, newPassword: e.target.value})
                }
                icon={
                  showPass.newPassword ? (
                    <EyeHide
                      classes="absolute right-3 top-3 cursor-pointer"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          newPassword: !showPass.newPassword,
                        })
                      }
                    />
                  ) : (
                    <EyeView
                      classes="absolute right-3 top-3 cursor-pointer"
                      // @ts-ignore
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          newPassword: !showPass.newPassword,
                        })
                      }
                    />
                  )
                }
              />
              <Input
                type={showPass.confirmPassword ? "text" : "password"}
                label="Confirm your new password"
                value={data.confirmPassword}
                classes="mt-4"
                error={
                  inputError.confirmPassword && data.confirmPassword.length < 9
                    ? true
                    : false
                }
                onChange={(e) =>
                  setData({...data, confirmPassword: e.target.value})
                }
                icon={
                  showPass.confirmPassword ? (
                    <EyeHide
                      classes="absolute right-3 top-3 cursor-pointer"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          confirmPassword: !showPass.confirmPassword,
                        })
                      }
                    />
                  ) : (
                    <EyeView
                      classes="absolute right-3 top-3 cursor-pointer"
                      // @ts-ignore
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          confirmPassword: !showPass.confirmPassword,
                        })
                      }
                    />
                  )
                }
              />
            </div>
            <hr className="w-full" />
            <div className="flex justify-between items-center w-full px-5 mt-3">
              <Button
                onClick={() => handleCloseModal()}
                variant="normal"
                classes="text-center"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="add"
                classes="text-center"
              >
                Reset
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ResetPasswordModal;
