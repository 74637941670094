// Components
import Button from "components/Common/Button";
import Searchbar from "components/Common/Searchbar";

// Assets
import {ArrowDown} from "assets/Arrows";
import FilterIcon from "assets/Filter";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "components/Common/Checkbox";
import {useLazyGetDMAQuery} from "store/api/locationApi";
import {useClickOutside} from "hooks/useClickOutside";
import {RootState} from "store/store";
import {setMerchantsName} from "store/merchants/merchantsSlice";

interface FilterTypes {
  showFilter: boolean;
  hasFilterButton?: boolean;
  handleShowFilter: Function;
  showDropdownCheckbox?: boolean;
  showDropdownCheckboxOptional?: boolean;
  optionsDropdownCheckbox?: string[];
  optionsAllDropdownCheckbox?: string;
  searchBarPlaceholder?: string;
  getInfoStatesFilters?: Function;
  getInfoDMAFilters?: Function;
}

function FilterFormCategories({
  showFilter,
  hasFilterButton = true,
  handleShowFilter,
  showDropdownCheckbox = true,
  showDropdownCheckboxOptional = true,
  optionsDropdownCheckbox = [],
  optionsAllDropdownCheckbox = "",
  searchBarPlaceholder,
  getInfoStatesFilters,
  getInfoDMAFilters,
}: FilterTypes) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const wrapperRefDMA = useRef(null);
  const filters = useSelector((state: RootState) => state?.filters?.filters);

  const [
    getDMA,
    {data: dataDMA, isLoading: isLoadingDataDMA, isError: isErrorDataDMA},
  ] = useLazyGetDMAQuery();

  const optionsAllDropdownCheckboxOptional = "Choose DMA";

  const [stateListDropdown, setStateListDropdown] = useState<any>([
    filters.lastCategoryLocation.states,
  ]);
  const [DMAListDropdown, setDMAListDropdown] = useState<any>([
    filters.lastCategoryLocation.dma,
  ]);
  const [dropdown, openDropdown] = useState(false);
  const [dropdownDMA, openDropdownDMA] = useState(false);

  useClickOutside(wrapperRef, openDropdown);
  useClickOutside(wrapperRefDMA, openDropdownDMA);

  useEffect(() => {
    if (stateListDropdown && stateListDropdown.length > 0) {
      getDMA(`?state=${stateListDropdown[0]}&filtered=yes`);
    }
  }, [stateListDropdown]);

  useEffect(() => {
    if (filters.lastCategoryLocation.states === "") {
      setDMAListDropdown([""]);
    }
  }, [filters]);

  const handleClickCheckbox = (e: any) => {
    const {id, checked} = e.target;

    if (checked) {
      setStateListDropdown([id]);
      if (getInfoStatesFilters) {
        getInfoStatesFilters([id]);
      }
    }

    if (!checked) {
      setStateListDropdown([]);
      if (getInfoStatesFilters) {
        getInfoStatesFilters([]);
      }
    }
  };

  const handleClickCheckboxDMA = (e: any) => {
    const {id, checked} = e.target;

    if (checked) {
      setDMAListDropdown([id]);
      if (getInfoDMAFilters) {
        getInfoDMAFilters([id]);
      }
    }

    if (!checked) {
      setDMAListDropdown([]);
      dispatch(setMerchantsName([]));
      if (getInfoDMAFilters) {
        getInfoDMAFilters([]);
      }
    }
  };

  const getNameDMA = () => {
    const element = dataDMA?.filter((obj: any) =>
      DMAListDropdown?.includes(obj.code)
    );

    const names = element?.map((el: any) => el.description);

    return names?.join(", ", names);
  };

  return (
    <div className="flex justify-between items-center pb-4 flex-col lg:flex-row">
      <div className="flex">
        {hasFilterButton && (
          <Button
            classes={`text-black-300 font-medium rounded-lg h-full text-sm ${
              showFilter ? "bg-indigo-200" : "bg-white hover:bg-primary-white"
            } mr-4`}
            onClick={() => handleShowFilter((prev: boolean) => !prev)}
          >
            <FilterIcon classes={"mr-2"} />
            Filter
            <ArrowDown classes={"ml-2"} />
          </Button>
        )}

        <div className="relative flex h-full">
          {showDropdownCheckbox && (
            <div className="relative">
              <div
                onClick={() => openDropdown((prev) => !prev)}
                className={`${
                  dropdown ? "border-primary-purple" : "border-transparent"
                } h-9  items-center rounded-l-lg border-2  hover:bg-primary-white cursor-pointer p-2.5 pl-4 z-0 bg-white rounded-l-t font-medium text-sm flex justify-between`}
              >
                <span className="flex capitalize status-filter whitespace-nowrap">
                  {stateListDropdown.length > 0 && stateListDropdown[0] !== ""
                    ? stateListDropdown.join(", ", stateListDropdown)
                    : optionsAllDropdownCheckbox}
                </span>
                <span>
                  <ArrowDown />
                </span>
              </div>
              {dropdown && (
                <div
                  className="bg-white py-3 px-4 absolute l-0 rounded-b drop-shadow-xl z-30 w-full flex items-center gap-2"
                  ref={wrapperRef}
                  style={{
                    height: "auto",
                    maxHeight: "350px",
                    overflowY: "scroll",
                  }}
                >
                  {optionsDropdownCheckbox?.map((opt: string) => (
                    <div key={opt} className="flex items-center">
                      <Checkbox
                        id={opt}
                        key={opt}
                        type="checkbox"
                        name={opt}
                        onChange={handleClickCheckbox}
                        isChecked={stateListDropdown?.includes(opt)}
                      />
                      <label
                        htmlFor={opt}
                        className="ml-3 font-medium text-sm capitalize cursor-pointer"
                      >
                        {opt}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {showDropdownCheckboxOptional && (
            <div className="relative">
              <div
                style={{minWidth: "240px"}}
                onClick={() => openDropdownDMA((prev) => !prev)}
                className={`${
                  dropdownDMA ? "border-primary-purple" : "border-transparent"
                } h-9 items-center w-full  border-2  hover:bg-primary-white cursor-pointer p-2.5 pl-4 z-0 bg-white  font-medium text-sm flex justify-between`}
              >
                <span className="flex capitalize status-filter whitespace-nowrap">
                  {DMAListDropdown.length > 0 &&
                  DMAListDropdown[0] !== "" &&
                  filters.lastCategoryLocation.states !== ""
                    ? getNameDMA()
                    : optionsAllDropdownCheckboxOptional}
                </span>
                <span>
                  <ArrowDown />
                </span>
              </div>
              {dropdownDMA && (
                <div
                  className="bg-white py-3 px-4 absolute l-0 rounded-b drop-shadow-xl z-30 w-full flex flex-col gap-3"
                  ref={wrapperRefDMA}
                  style={{
                    height: "auto",
                    maxHeight: "350px",
                    overflowY: "scroll",
                  }}
                >
                  {stateListDropdown &&
                  stateListDropdown?.length > 0 &&
                  stateListDropdown[0] !== "" ? (
                    dataDMA?.map((opt: any) => (
                      <div key={opt.code} className="flex ">
                        <Checkbox
                          id={opt.code}
                          key={opt.code}
                          type="checkbox"
                          name={opt.code}
                          onChange={handleClickCheckboxDMA}
                          isChecked={DMAListDropdown?.includes(opt.code)}
                        />
                        <label
                          htmlFor={opt.code}
                          className="ml-3 font-medium text-sm capitalize cursor-pointer"
                        >
                          {opt.description}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>Please select a state to view the DMA available</p>
                  )}
                </div>
              )}
            </div>
          )}
          <Searchbar
            placeholder={
              searchBarPlaceholder ??
              "Search by Merchants name, location, ID, zip-code"
            }
            classes="w-96 pr-3"
            classesContainer="h-min search-bar-autocomplete"
            useTags={true}
            origin="categories"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterFormCategories;
