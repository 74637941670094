import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Components
import Searchbar from "components/Common/Searchbar";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";
import Button from "components/Common/Button";
import ItemOption from "components/Options";
import MenuItem from "components/Options/MenuItem";

// Assets
import {ArrowPrev} from "assets/Arrows";
import {ButtonCirclePlus} from "assets/ButtonCircle";
import Ordering from "assets/Ordering";
import {EyeView} from "assets/Eye";

// Utils
import {TABLE_CATEGORY_ITEMS_HEADER} from "utils/categoriesHeader";
import {differenceDate} from "utils/diffenceDate";

// Store
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useGetCategoriesTreeQuery,
  useGetCategoryPrizeListQuery,
} from "store/api/categoriesApi";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import routes from "utils/routesByRole";
import {ItemsCategoryI} from "../types";
import Merchants from "assets/Merchant";
import Edit from "assets/Edit";
import Delete from "assets/Delete";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {
  segmentationByPrizeOffer,
  mergeArrays,
  getNamesFromCategories,
  removeObjectsById,
} from "utils/categoriesUtils";
import {toast} from "react-toastify";
import DropdownCategory from "components/Common/DropdownCategory";
import {setSorting} from "store/filters/filtersSlice";
import debounce from "lodash.debounce";
import DeleteModal from "components/Modal/DeleteModal";
import {setOpenModal, setType} from "store/modal/modalSlice";

function AddCategory({
  dataCategory: infoCategory,
  itemsAdded,
  setItemsAdded,
  updateItemsAdded,
  itemsAddedOverview,
  handleOpenSidebarCategory,
  currentDMAID,
}: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const user = useSelector((state: RootState) => state?.user?.user?.user);
  const modal = useSelector((state: RootState) => state.modal);

  const [isOpen, setIsOpen] = useState({prize: true, offer: true});
  const [params, setParams] = useState(``);
  const [searchValueItems, setSearchValueItems] = useState("");
  const [searchItems, setSearchItems] = useState("");
  const [localStoreCategory, setLocalStoreCategory] = useState<any[]>([]);
  const [storeLocalCat, setStoreLocalCat] = useState<any[]>([]);
  const [orderingPrizeOffer, setOrderingPrizeOffer] = useState<any>({
    offer: 1,
    prize: 2,
  });
  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<any[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [categoriesIdParam, setCategoriesIdParam] = useState<string>();
  const [categorySelected, setCategorySelected] = useState<ItemsCategoryI>();

  useEffect(() => {
    dispatch(setSorting("ordering=id"));
  }, []);

  // Get the categories list dropdown
  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  // Get the items in the category
  const {
    data: dataCategory,
    isLoading: isLoadingDataCategory,
    isError: isErrorDataCategory,
    refetch,
    // @ts-ignore
  } = useGetCategoryPrizeListQuery({dma_code: currentDMAID, params});

  // const {
  //   data: dataItemsName,
  //   isLoading: isLoadingDataItemsName,
  //   isError: isErrorDataItemsName,
  //   // @ts-ignore
  // } = useGetCategoryPrizeListQuery(`?category_id=${id}&search=${search}`);

  useEffect(() => {
    if (dataCategory && dataCategory.prizes && dataCategory.prizes.length > 0) {
      setStoreLocalCat((prev) => [...prev, ...dataCategory.prizes]);
    }
  }, [dataCategory]);

  useEffect(() => {
    if (itemsAdded.prize.length > 0 && itemsAdded.offer.length > 0) {
      const itemsName = getNamesFromCategories([
        ...itemsAdded.prize,
        ...itemsAdded.offer,
      ]);

      dispatch(setMerchantsName(itemsName));
    }
  }, [itemsAdded]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${
        filters.urlFilters
      }${searchItems ? `&search=${searchItems}` : ""}${
        categoriesIdParam ? `&${categoriesIdParam}` : ""
      }`
    );
  }, [filters, searchItems, categoriesIdParam]);

  function updateLocalStoreCategory(items: any) {
    const categoriesFiltered = items.map((el: any) => {
      return storeLocalCat.filter((item: any) => item.prizeId === el);
    });

    return categoriesFiltered;
  }

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      const result = elementsChecked
        .map((id: string) => `category_id=${id}`)
        .join("&");

      setCategoriesIdParam(result);

      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const handleItemChecked = (items: number[]) => {
    const categoriesFiltered = updateLocalStoreCategory(items);
    setLocalStoreCategory(removeDuplicates(categoriesFiltered.flat()));
  };

  function removeDuplicates(arr: any) {
    return arr.filter(
      (obj: any, index: any, self: any) =>
        index === self.findIndex((o: any) => o.prizeId === obj.prizeId)
    );
  }

  const handleAddToCategoryList = () => {
    const itemsFlatted = [...itemsAdded.prize, ...itemsAdded.offer];

    const mergedArrays: ItemsCategoryI[] = mergeArrays(
      localStoreCategory,
      itemsFlatted
    );

    const ids = itemsAddedOverview?.map((el: any) => el.prizeId);

    const removingIdsOriginal = removeObjectsById(mergedArrays, ids);

    if (mergedArrays.length > removingIdsOriginal.length) {
      toast.info(
        "One or more of the selected items already exist in the category"
      );
    }

    const result = segmentationByPrizeOffer(removingIdsOriginal);

    setItemsAdded({
      prize: [...result?.prize],
      offer: [...result?.offer],
    });

    dispatch(setResetElChecked(true));
  };

  const handleSwap = () => {
    setOrderingPrizeOffer({
      offer: orderingPrizeOffer.prize,
      prize: orderingPrizeOffer.offer,
    });
  };

  const handleDeleteItem = () => {
    if (!categorySelected) return;

    const type = categorySelected?.prizeType;
    let filteredItems: any[];

    if (type === "REGULAR") {
      filteredItems = itemsAdded?.prize?.filter(
        (item: ItemsCategoryI) => item.prizeId !== categorySelected?.prizeId
      );

      setItemsAdded((prev: any) => ({
        ...prev,
        prize: filteredItems,
      }));
    } else if (type === "OFFER") {
      filteredItems = itemsAdded?.offer?.filter(
        (item: ItemsCategoryI) => item.prizeId !== categorySelected?.prizeId
      );

      setItemsAdded((prev: any) => ({
        ...prev,
        offer: filteredItems,
      }));
    }

    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleCheckNames = (origin: string) => {
    if (origin === "local-search") {
      if (itemsAdded.prize.length > 0 || itemsAdded.offer.length > 0) {
        const itemsName = getNamesFromCategories([
          ...itemsAdded.prize,
          ...itemsAdded.offer,
        ]);

        dispatch(setMerchantsName(itemsName));
      }
    } else if (origin === "fetch-search") {
      const itemsNames = dataCategory?.prizes?.map((el: any) => {
        return {id: el.name, name: el.name};
      });
      dispatch(setMerchantsName(itemsNames));
    }
  };

  const ITEMS = [
    // @ts-ignore
    routes[user.data.role].urlAllowed?.item?.includes("view") && {
      title: "view",
      svg: <EyeView />,
      type: "openSidebarCategory",
      onClick: (e: any) => handleOpenSidebarCategory(e),
    },
    // @ts-ignore
    routes[user.data.role].urlAllowed?.categories?.includes("add") && {
      title: "add",
      svg: <ButtonCirclePlus classes="text-gray-400" />,
      type: "addPrizeListToSidebar",
      onClick: (e: any) => updateItemsAdded(e),
    },
  ];

  const getFilteredNamesPrize = () => {
    if (searchValueItems?.length === 0) {
      return itemsAdded.prize;
    }
    return itemsAdded.prize?.filter((item: any) =>
      item.name.toLowerCase().includes(searchValueItems.toLowerCase())
    );
  };
  const getFilteredNamesOffer = () => {
    if (searchValueItems?.length === 0) {
      return itemsAdded.offer;
    }
    return itemsAdded.offer?.filter((item: any) =>
      item.name.toLowerCase().includes(searchValueItems.toLowerCase())
    );
  };

  // const getFilteredNames = () => {
  //   if (searchValueItems?.length === 0) {
  //     return dataAllLocation;
  //   }
  //   return dataAllLocation?.filter((item: any) =>
  //     item.name.includes(searchValueItems)
  //   );
  // };

  const onChangeSearch = (value: any) => {
    setSearchItems(value);
  };

  const debouncedOnChangeSearch = debounce(onChangeSearch, 500);

  return (
    <div className="flex mt-6">
      <div className="w-full bg-white p-4  mr-4">
        <div className="mb-4 flex justify-between items-center">
          <Subtitle>Choose Item</Subtitle>
          <div className="flex gap-3  h-9">
            <div className="flex ml-4">
              {
                // @ts-ignore
                routes[user.data.role].urlAllowed?.categories?.includes(
                  "save"
                ) && (
                  <Button
                    variant={`${
                      localStoreCategory.length !== 0 &&
                      dataCategory &&
                      dataCategory?.prizes
                        ? "add"
                        : "disabled"
                    }`}
                    onClick={
                      localStoreCategory.length !== 0 &&
                      dataCategory &&
                      dataCategory?.prizes
                        ? () => handleAddToCategoryList()
                        : () => {}
                    }
                  >
                    <p className="ml-2">+ Add to category</p>
                  </Button>
                )
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="my-4 flex gap-2">
          <DropdownCategory
            openDropdown={openDropdown}
            dropdown={dropdown}
            elementsChecked={elementsChecked}
            elementsName={elementsName}
            handleClickCheckbox={handleClickCheckbox}
            dataCategories={dataCategories}
            isErrorCategories={isErrorCategories}
            isLoadingCategories={isLoadingCategories}
            handleClickDropdown={handleClickDropdown}
          />
          <Searchbar
            placeholder={"Search items"}
            classes="w-96 pr-3"
            classesContainer="w-7/12 ml-2 py-2 outline-1 border border-gray-300 rounded-r-sm rounded h-min search-bar-autocomplete"
            useTags={false}
            hasAutocomplete={false}
            callback={debouncedOnChangeSearch}
          />
        </div>
        <hr />
        <div>
          <Table
            data={
              dataCategory &&
              dataCategory?.prizes &&
              dataCategory?.prizes?.length
                ? dataCategory.prizes
                : []
            }
            loadingData={isLoadingDataCategory}
            errorData={isErrorDataCategory}
            refetch={refetch}
            origin="prize-list"
            customError={{
              show: true,
              content: (
                <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
                  There are no items in this category
                </h2>
              ),
            }}
            headers={TABLE_CATEGORY_ITEMS_HEADER}
            results={dataCategory !== undefined ? dataCategory?.count : 1}
            itemOptions={ITEMS}
            typeOptions="individual"
            handleItemChecked={handleItemChecked}
            show={[
              "name",
              "prizeType",
              "merchantName",
              "expiredAt",
              "remaining",
              "claimed",
              "redeemed",
            ]}
            sort={{
              name: null,
              prizeType: null,
              merchantName: null,
              expiredAt: null,
              remaining: null,
              claimed: null,
              redeemed: null,
            }}
            orderingByAPI={true}
          />
        </div>
      </div>
      <div className="w-4/12 bg-white rounded p-4">
        <div className="flex mb-4 justify-between mt-2">
          <Subtitle>{infoCategory?.categoryName} Items:</Subtitle>
        </div>
        <hr />
        <div className="my-2 w-min cursor-pointer" onClick={() => handleSwap()}>
          <Ordering />
        </div>
        <hr />
        <div className="my-4">
          <Searchbar
            placeholder="Search"
            useTags={false}
            classes="w-auto pr-3"
            classesContainer="w-full h-9  outline-1 border  border-gray-300  rounded-r-sm rounded"
            callback={(value: any) => setSearchValueItems(value)}
            origin="local-search"
            onClick={handleCheckNames}
            hasAutocomplete={false}
          />
        </div>
        <hr />
        <div
          className="flex flex-col gap-2 overflow-y-auto"
          style={{height: "800px"}}
        >
          <div style={{order: `${orderingPrizeOffer.offer}`}}>
            <div
              className="flex justify-between my-4 cursor-pointer"
              onClick={() => setIsOpen({...isOpen, offer: !isOpen.offer})}
            >
              <div className="flex">
                <ArrowPrev
                  classes={`w-6 h-6 transition ease-in-100 ${
                    isOpen.offer ? "rotate-90" : "-rotate-90"
                  }`}
                />
                <div className="font-medium text-gray-700 ml-3">
                  Offers ({getFilteredNamesOffer().length})
                </div>
              </div>
            </div>
            {isOpen.offer && (
              <div className="flex flex-col gap-2">
                {getFilteredNamesOffer().map((el: any) => (
                  <div
                    key={el.prizeId}
                    className="flex justify-between border-2 border-gray-300 rounded p-2.5"
                  >
                    <div className="flex gap-2">
                      <div className="w-20 flex items-center">
                        <img
                          src={el?.images[1]?.url}
                          alt={el?.images[1]?.type}
                          style={{
                            objectFit: "fill",
                            minWidth: "5rem",
                            minHeight: "5rem",
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <div
                          className="font-medium clear-both inline-block overflow-hidden whitespace-nowrap text-ellipsis"
                          style={{maxWidth: "9vw"}}
                        >
                          {el?.name}
                        </div>
                        <div
                          className="text-gray-400 text-sm clear-both inline-block overflow-hidden whitespace-nowrap text-ellipsis"
                          style={{maxWidth: "9vw"}}
                        >
                          {el.merchantName}
                        </div>
                        <div className="text-primary-purple text-sm">
                          Expire in{" "}
                          {differenceDate(
                            new Date().toISOString(),
                            el?.expiredAt
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex ">
                      <ItemOption classes="text-white-200">
                        <MenuItem
                          onClick={() => {
                            navigate("/items/" + el?.prizeId, {
                              state: {
                                idMerchant: el?.merchantId,
                                idItem: el?.prizeId,
                              },
                            });
                          }}
                          title="Edit"
                          svg={<Edit classes="mr-2" />}
                        />
                        <MenuItem
                          onClick={() => {
                            navigate(`/merchants/${el.merchantId}`, {
                              state: {
                                dmaCode: currentDMAID,
                              },
                            });
                          }}
                          title="Show Merchant"
                          svg={<Merchants classes="mr-2" />}
                        />
                        <MenuItem
                          onClick={() => {
                            setCategorySelected(el);
                            dispatch(setType("deleteCategoryAdd"));
                            dispatch(setOpenModal(true));
                          }}
                          title="Delete from list"
                          svg={<Delete classes="mr-2" />}
                        />
                      </ItemOption>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={{order: `${orderingPrizeOffer.prize}`}}>
            <div
              className="flex justify-between my-4 cursor-pointer"
              onClick={() => setIsOpen({...isOpen, prize: !isOpen.prize})}
            >
              <div className="flex">
                <ArrowPrev
                  classes={`w-6 h-6 transition ease-in-100 ${
                    isOpen.prize ? "rotate-90" : "-rotate-90"
                  }`}
                />
                <div className="font-medium text-gray-700 ml-3">
                  Prizes ({getFilteredNamesPrize().length})
                </div>
              </div>
            </div>
            {isOpen.prize && (
              <div className="flex flex-col gap-2">
                {getFilteredNamesPrize().map((el: any) => (
                  <div
                    key={el.prizeId}
                    className="flex justify-between border-2 border-gray-300 rounded p-2.5"
                  >
                    <div className="flex gap-2">
                      <div className="w-20 flex items-center">
                        <img
                          src={el?.images[1]?.url}
                          alt={el?.images[1]?.type}
                          style={{
                            objectFit: "fill",
                            minWidth: "5rem",
                            minHeight: "5rem",
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <div
                          className="font-medium clear-both inline-block overflow-hidden whitespace-nowrap text-ellipsis"
                          style={{maxWidth: "9vw"}}
                        >
                          {el?.name}
                        </div>
                        <div
                          className="text-gray-400 text-sm clear-both inline-block overflow-hidden whitespace-nowrap text-ellipsis"
                          style={{maxWidth: "9vw"}}
                        >
                          {el.merchantName}
                        </div>
                        <div className="text-primary-purple text-sm">
                          Expire in{" "}
                          {differenceDate(
                            new Date().toISOString(),
                            el?.expiredAt
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex ">
                      <ItemOption classes="text-white-200">
                        <MenuItem
                          onClick={() => {
                            navigate("/items/" + el?.prizeId, {
                              state: {
                                idMerchant: el?.merchantId,
                                idItem: el?.prizeId,
                              },
                            });
                          }}
                          title="Edit"
                          svg={<Edit classes="mr-2" />}
                        />
                        <MenuItem
                          onClick={() => {
                            navigate(`/merchants/${el.merchantId}`);
                          }}
                          title="Show Merchant"
                          svg={<Merchants classes="mr-2" />}
                        />
                        <MenuItem
                          onClick={() => {
                            setCategorySelected(el);
                            dispatch(setType("deleteCategoryAdd"));
                            dispatch(setOpenModal(true));
                          }}
                          title="Delete from list"
                          svg={<Delete classes="mr-2" />}
                        />
                      </ItemOption>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {modal.type === "deleteCategoryAdd" && (
        <DeleteModal
          elementsChecked={[]}
          setElementsChecked={() => {}}
          dataSource={[]}
          matchWidhId="prizeId"
          pendingMsg="Deleting category"
          returnValue="name"
          toastMessageId="name"
          type="category"
          typeOfDelete="reward"
          deleteCallback={handleDeleteItem}
          nameItem={categorySelected?.name}
        />
      )}
    </div>
  );
}

export default AddCategory;
