import { TypeClasses } from "./types";

function Items({ classes }: TypeClasses) {
  return (
    <svg
      id="prize"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="workspace_premium_FILL0_wght400_GRAD0_opsz48"
        d="M12.821,14.029l.771-2.464L11.557,9.979h2.486l.814-2.55.793,2.55h2.507l-2.036,1.586.75,2.464-2.014-1.521ZM9.8,22V15.486a6.064,6.064,0,0,1-1.382-2.207A7.319,7.319,0,0,1,8,10.857,6.735,6.735,0,0,1,14.857,4a6.735,6.735,0,0,1,6.857,6.857,7.319,7.319,0,0,1-.418,2.421,6.064,6.064,0,0,1-1.382,2.207V22l-5.057-1.693Zm5.057-5.571a5.524,5.524,0,0,0,5.571-5.571,5.524,5.524,0,0,0-5.571-5.571,5.524,5.524,0,0,0-5.571,5.571,5.524,5.524,0,0,0,5.571,5.571ZM11.086,20.2l3.771-1.179L18.629,20.2V16.536a5.815,5.815,0,0,1-1.843.9,6.815,6.815,0,0,1-3.857,0,5.815,5.815,0,0,1-1.843-.9ZM14.857,18.357Z"
        transform="translate(-2.857 -1)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Items;
