import {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";

// Assets
import Copy from "assets/Copy";

// Hooks
import {useClickOutside} from "hooks/useClickOutside";
import {useGetItemsByMerchantIDQuery} from "store/api/merchantsApi";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useChangeStatusItemMutation,
  useDeleteLoyaltyProgramMutation,
  useGetLoyaltyProgramQuery,
  useLazyGetIndividualLoyaltyProgramQuery,
} from "store/api/itemApi";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setMerchantsName} from "store/merchants/merchantsSlice";

// Utils
import routes from "utils/routesByRole";
import LoyaltyDetails from "./Details";
import Edit from "assets/Edit";
import Delete from "assets/Delete";
import {setSubMerchantTabIndex} from "store/tabs/tabsSlice";
import {setLoyaltyProgram} from "store/filters/filtersSlice";
import DeleteModal from "components/Modal/DeleteModal";
import {setResetElChecked} from "store/table/currentTableDataSlice";

function Loyality({id, merchantInfo, headers, subHeaders}: any) {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [dropdown, openDropdown] = useState(false);
  const [params, setParams] = useState("?");

  useClickOutside(wrapperRef, openDropdown);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const {
    data: dataItemsMerchants,
    isLoading: isLoadingItemsMerchants,
    isError: isErrorItemsMerchants,
    refetch,
  } = useGetItemsByMerchantIDQuery({id, params});

  const {data: dataLoyaltyProgram} = useGetLoyaltyProgramQuery({id, params});

  const [
    loyaltyProgramData,
    {data: loyaltyIndividualData, isLoading: isLoadingLoyaltyIndividualData},
  ] = useLazyGetIndividualLoyaltyProgramQuery();

  const [deleteLoyaltyProgram] = useDeleteLoyaltyProgramMutation();

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    if (
      dataItemsMerchants &&
      !isLoadingItemsMerchants &&
      !isErrorItemsMerchants
    ) {
      dispatch(setMerchantsName(dataItemsMerchants?.prizes));
    }
  }, [dataItemsMerchants, isLoadingItemsMerchants, isErrorItemsMerchants]);

  useEffect(() => {
    if (filters?.loyaltyProgram) {
      loyaltyProgramData({idLoyalty: filters?.loyaltyProgram?.loyaltyId});
    }
  }, [filters?.loyaltyProgram]);

  useEffect(() => {
    setParams(`?${filters.sorting}${filters.page}${filters.limit}`);
  }, [filters]);

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.loyalty?.includes("edit") && {
        title: "Edit",
        svg: <Edit classes="mr-3" />,
        type: "publishCategory",
        onClick: (loyalty: any) => {
          dispatch(setSubMerchantTabIndex("3"));

          dispatch(
            setLoyaltyProgram({
              loyaltyId: Number(loyalty.loyaltyId),
            })
          );
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.loyalty?.includes("delete") && {
        title: "Delete",
        svg: <Delete classes="mr-3" />,
        onClick: (e: any) => e,
      },
    ],
    []
  );

  const handleGetName = (id: number, dataSource: any, matchWidhId: any) => {
    return dataSource?.find((category: any) => category[matchWidhId] === id);
  };

  const handleDeleteLoyalties = () => {
    elementsChecked.forEach(async (id) => {
      const loyalityData = handleGetName(
        id,
        dataLoyaltyProgram?.list,
        "loyaltyId"
      );

      let toastPromise = toast.promise(deleteLoyaltyProgram({idLoyalty: id}), {
        pending: `Deleting loyalty ${loyalityData?.title}`,
      });

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while deleting loyalty: {loyalityData?.title}</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(`Loyalty: ${loyalityData?.title} deleted`);
            dispatch(setResetElChecked(true));
            setElementsChecked([]);
          }
        })
        .catch((err) => {
          toast.error("Error while deleting loyalty");
        })
        .finally(() => {
          dispatch(setType(""));
          dispatch(setOpenModal(false));
        });
    });
  };

  return (
    <div>
      {loyaltyIndividualData && filters?.loyaltyProgram ? (
        <LoyaltyDetails
          loyaltyProgram={loyaltyIndividualData}
          merchantInfo={merchantInfo}
        />
      ) : (
        <>
          <div className="px-5 bg-white flex items-center rounded justify-between">
            <Subtitle classes={"py-5"}>Loyalty Programs</Subtitle>
            <div className="flex">
              {
                // @ts-ignore
                routes[user.data.role].urlAllowed?.loyalty?.includes(
                  "delete"
                ) && (
                  <Button
                    variant={`${
                      elementsChecked.length > 0 ? "danger" : "disabled"
                    }`}
                    onClick={
                      elementsChecked.length > 0
                        ? () => {
                            dispatch(setType("deleteLoyalty"));
                            dispatch(setOpenModal(true));
                          }
                        : () => {}
                    }
                  >
                    <Delete />
                    <p className="ml-2">Delete</p>
                  </Button>
                )
              }
              {
                // @ts-ignore
                routes[user.data.role].urlAllowed?.loyalty?.includes("add") && (
                  <Button
                    variant={`add`}
                    onClick={() =>
                      navigate(`loyalty`, {
                        state: {merchantInfo},
                      })
                    }
                  >
                    <Copy />
                    <p className="ml-2">Add</p>
                  </Button>
                )
              }
            </div>
          </div>

          <Table
            data={
              dataLoyaltyProgram !== undefined && dataLoyaltyProgram.list.length
                ? dataLoyaltyProgram.list
                : []
            }
            loadingData={isLoadingItemsMerchants}
            errorData={isErrorItemsMerchants}
            refetch={refetch}
            results={
              dataLoyaltyProgram !== undefined ? dataLoyaltyProgram?.count : 1
            }
            origin="loyalty_program"
            headers={headers}
            itemOptions={ITEMS}
            typeOptions="group"
            handleItemChecked={handleItemChecked}
            show={[
              "title",
              "loyaltyId",
              "startDate",
              "endDate",
              "customerCount",
              "claimed",
              "redeemed",
              "state",
            ]}
            sort={{
              title: null,
              loyaltyId: null,
              startDate: null,
              endDate: null,
              customerCount: null,
              claimed: null,
              redeemed: null,
              state: null,
            }}
            additionalData={{merchantInfo}}
            orderingByAPI={true}
          />
        </>
      )}

      {modal.type === "deleteLoyalty" && (
        <DeleteModal
          elementsChecked={elementsChecked}
          dataSource={dataLoyaltyProgram?.list}
          setElementsChecked={setElementsChecked}
          matchWidhId="loyaltyId"
          pendingMsg="Deleting loyalty programs"
          returnValue="title"
          toastMessageId="title"
          type="loyalty program"
          deleteCallback={handleDeleteLoyalties}
          typeOfDelete="loyaltyprogram"
        />
      )}
    </div>
  );
}

export default Loyality;
