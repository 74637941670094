import { TypeClasses } from "./types";

function Configuration({ classes }: TypeClasses) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="tune_FILL0_wght400_GRAD0_opsz48"
        d="M13.675,24V18.375h1.5V20.45H24v1.5H15.175V24ZM6,21.95v-1.5h6.175v1.5Zm4.675-4.15V15.75H6v-1.5h4.675v-2.1h1.5V17.8Zm3-2.05v-1.5H24v1.5Zm4.15-4.125V6h1.5V8.05H24v1.5H19.325v2.075ZM6,9.55V8.05H16.325v1.5Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Configuration;
