import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TabsSlice {
  itemsType: string;
  tabIndex: string;
  subMerchantTabIndex: string;
  lastVisitedPage: string;
}

const initialState: TabsSlice = {
  itemsType: "Items",
  tabIndex: "",
  subMerchantTabIndex: "",
  lastVisitedPage: "",
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setTabIndex: (state, action: PayloadAction<string>) => {
      state.tabIndex = action.payload;
    },
    setSubMerchantTabIndex: (state, action: PayloadAction<string>) => {
      state.subMerchantTabIndex = action.payload;
    },
    setItemsType: (state, action: PayloadAction<string>) => {
      state.itemsType = action.payload;
    },
    setLastVisitedPage: (state, action: PayloadAction<string>) => {
      state.lastVisitedPage = action.payload;
    },
  },
});

export const { setTabIndex, setSubMerchantTabIndex, setItemsType, setLastVisitedPage } =
  tabsSlice.actions;

export default tabsSlice.reducer;
