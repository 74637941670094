function FormSqueleton() {
  return (
    <form action="" className="border border-indigo-200 rounded">
      <div className="flex p-4 bg-indigo-200">
        <span></span>
        <span className="ml-2">Filter</span>
      </div>
      <div className="border border-b-indigo-200 flex  items-center justify-around flex-wrap p-4 pb-8 pt-8">
        Loading filters...
      </div>
    </form>
  );
}

export default FormSqueleton;
