import {
  Info as InfoIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
} from "assets/NotificationIcon";
import Button from "components/Common/Button";

export interface NotificationProps {
  id: number;
  type: string;
  description: string;
  action: string;
}

function Notification() {
  const notific = [
    {
      id: 1,
      type: "info",
      description:
        "A new merchant [Merchant Name] has just created a profile. Kindly review the profile for accuracy and completeness. If all looks good, please approve the profile.",
      action: "",
    },
    {
      id: 2,
      type: "warning",
      description:
        "[Merchant name] has made changes to their profile details. Please review the updatesand provide feedback if needed. Once confirmed, kindly approve the updated merchant profile.",
      action: "",
    },
    {
      id: 3,
      type: "error",
      description:
        "Admin [Name] has declined the item «[Item Name]» with Item ID: [Item ID].",
      action: "",
    },
  ];

  const buildIcon = (type: string) => {
    if (type === "info") {
      return (
        <div className="h-12 w-12 rounded-full border-none bg-[#c1b0f1] flex justify-center items-center">
          <InfoIcon />
        </div>
      );
    } else if (type === "warning") {
      return (
        <div className="h-12 w-12 rounded-full border-none bg-[#fbdfa4] flex justify-center items-center">
          <WarningIcon />
        </div>
      );
    } else if (type === "error") {
      return (
        <div className="h-12 w-12 rounded-full border-none bg-[#facdd2] flex justify-center items-center">
          <ErrorIcon />
        </div>
      );
    }
  };

  const buildBG = (type: string) => {
    console.log("type", type);

    if (type === "warning") {
      return "#FEFACD";
    }

    if (type === "info") {
      return "#DFD7F8";
    }

    if (type === "error") {
      return "#FDE6E9";
    }
  };

  const buildDescription = (type: string) => {
    if (type === "info") {
      return "#7B57E0";
    }

    if (type === "warning") {
      return "#F79D24";
    }
    if (type === "error") {
      return "#EE5A6C";
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {notific.map((notif: NotificationProps) => (
        <div
          className={`flex gap-5 items-center w-full min-h-[80px] max-h-[100px] px-8 py-2`}
          style={{backgroundColor: buildBG(notif.type)}}
        >
          <div className="w-[5%] text-center">{buildIcon(notif.type)}</div>
          <p
            className="text-sm w-[85%]"
            style={{color: buildDescription(notif.type)}}
          >
            {notif.description}
          </p>
          <p className="text-sm w-[10%] text-center">14 min ago</p>
          <div className="w-[10%] flex justify-end gap-2">
            <Button variant="add" onClick={() => {}}>
              View
            </Button>
            <button className="text-lg text-gray-500">
              <CloseIcon />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Notification;
