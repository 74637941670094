import Success from "assets/Success";
import Button from "components/Common/Button";
import Spinner from "components/Common/Spinner";
import Modal from "components/Modal";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  useLazyApproveReqRegisterQuery,
  useLazyDeclineReqRegisterQuery,
} from "store/api/authApi";
import {setOpenModal, setType} from "store/modal/modalSlice";

function AdminConfirmationReq() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [typeReq, setTypeReq] = useState("");

  const [
    approveReq,
    {
      data: dataApprove,
      isLoading: isLoadingReqApprove,
      isError: isErrorReqApprove,
      error: errorApprove,
    },
  ] = useLazyApproveReqRegisterQuery();

  const [
    declineReq,
    {
      data: dataDecline,
      isLoading: isLoadingReqDecline,
      isError: isErrorReqDecline,
      error: errorDecline,
    },
  ] = useLazyDeclineReqRegisterQuery();

  useEffect(() => {
    dispatch(setOpenModal(true));
    if (params && location) {
      const urlSplitted = location.pathname.split("/");
      if (urlSplitted.includes("approve")) {
        setTypeReq("granted");
        approveReq(params.token);
      } else if (urlSplitted.includes("decline")) {
        declineReq(params.token);
        setTypeReq("refused");
      } else {
        return;
      }
    }
  }, []);

  return (
    <Modal
      fnClose={() => {
        navigate("/");
        dispatch(setOpenModal(false));
      }}
    >
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <h1 className="mt-4">Access Request</h1>
          {(isLoadingReqApprove || isLoadingReqDecline) && (
            <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
          )}
          {(dataApprove || dataDecline) &&
            !isLoadingReqApprove &&
            !isLoadingReqDecline &&
            !isErrorReqApprove &&
            !isErrorReqDecline && (
              <p className="text-sm mt-4 text-center">
                Access {typeReq} successfully
              </p>
            )}
          {(isErrorReqApprove || isErrorReqDecline) &&
            !isLoadingReqApprove &&
            !isLoadingReqDecline && (
              <p className="text-sm mt-4 text-center">
                {errorApprove?.data?.message || errorDecline?.data?.message
                  ? `${
                      errorApprove?.data?.message !== undefined
                        ? errorApprove?.data?.message
                        : ""
                    } ${
                      errorDecline?.data?.message !== undefined
                        ? errorDecline?.data?.message
                        : ""
                    }`
                  : "An error occurred trying to change the access, try again later"}
              </p>
            )}
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-center items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              navigate("/");
            }}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AdminConfirmationReq;
