import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoyaltyType } from "utils/typesLoyalty";

interface FiltersState {
  filters: any;
}

// Define the initial state using that type
const initialState: FiltersState = {
  filters: {
    autocomplete: [],
    urlFilters: "",
    urlSearch: "",
    sorting: "ordering=id",
    results: 0,
    origin: "",
    status: [],
    lastSearch: [],
    lastCategoryLocation: { states: '', dma: '' },
    lastLocation: '',
    page: "&page=1",
    limit: "&limit=10",
    changeStatus: { name: "", data: [], action: "" },
    filtersLoading: false,
    loyaltyProgram: null,
  },
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any[]>) => {
      state.filters.autocomplete = action.payload;
    },
    setURLFilters: (state, action: PayloadAction<string>) => {
      state.filters.urlFilters = action.payload;
    },
    setURLSearch: (state, action: PayloadAction<string>) => {
      state.filters.urlSearch = action.payload;
    },
    setResults: (state, action: PayloadAction<number>) => {
      state.filters.results = action.payload;
    },
    setSorting: (state, action: PayloadAction<string>) => {
      state.filters.sorting = action.payload;
    },
    setOrigin: (state, action: PayloadAction<string>) => {
      state.filters.origin = action.payload;
    },
    setStatus: (state, action: PayloadAction<string[]>) => {
      state.filters.status = action.payload;
    },
    setPage: (state, action: PayloadAction<string>) => {
      state.filters.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<string>) => {
      state.filters.limit = action.payload;
    },
    updateStatus: (state, action: PayloadAction<any>) => {
      state.filters.changeStatus = { ...action.payload };
    },
    setFiltersLoading: (state, action: PayloadAction<boolean>) => {
      state.filters.filtersLoading = action.payload;
    },
    setFiltersLastSearch: (state, action: PayloadAction<string[]>) => {
      state.filters.lastSearch = action.payload;
    },
    setFiltersLastCategoryLocation: (state, action: PayloadAction<{ states: string, dma: string }>) => {
      state.filters.lastCategoryLocation = action.payload;
    },
    setFiltersLastLocation: (state, action: PayloadAction<string>) => {
      state.filters.lastLocation = action.payload;
    },
    setLoyaltyProgram: (state, action: PayloadAction<LoyaltyType | null>) => {
      state.filters.loyaltyProgram = action.payload;
    },
  },
});

export const {
  setFilters,
  setURLFilters,
  setURLSearch,
  setResults,
  setSorting,
  setOrigin,
  setStatus,
  setPage,
  setLimit,
  updateStatus,
  setFiltersLoading,
  setFiltersLastSearch,
  setFiltersLastCategoryLocation,
  setFiltersLastLocation,
  setLoyaltyProgram,
} = filtersSlice.actions;

export default filtersSlice.reducer;
