import {useEffect, useState} from "react";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";
import FilterFormNeedApproveItems from "components/Filter/FilterFormNeedApproveItems";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useGetAllGrandPrizeQuery,
  useGetAllGrandPrizeNameQuery,
  useDuplicateGrandPrizeItemMutation,
} from "store/api/itemApi";
import {setRefetchEndpoint} from "store/user/userSlice";

// Utils
import {TABLE_MERCHANT_NEW_ITEMS_AND_NEED_APROVE_HEADER} from "utils/itemsHeaders";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import AddRedeemedCodeModal from "components/Modal/AddRedeemedCodeModal";

function NeedApproveItems() {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state.filters.filters);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);

  // Store item data to duplicate use on duplicate
  const [itemInfo, setItemInfo] = useState<any>([]);
  const [itemInfoSelected, setItemInfoSelected] = useState([]);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id&status=need_to_approve");

  const [duplicateGPMutation] = useDuplicateGrandPrizeItemMutation();

  const {
    data: dataItems,
    isLoading: isLoadingItems,
    isError: isErrorItems,
    refetch,
  } = useGetAllGrandPrizeQuery(params);

  const {
    data: dataNeedToApproveItemsName,
    isLoading: isLoadingNeedToApproveItemsName,
    isError: isErrorNeedToApproveItemsName,
  } = useGetAllGrandPrizeNameQuery("&status=NEED_TO_APPROVE");

  useEffect(() => {
    if (dataNeedToApproveItemsName && dataNeedToApproveItemsName.length) {
      dispatch(setMerchantsName(dataNeedToApproveItemsName));
    }
  }, [dataNeedToApproveItemsName]);

  useEffect(() => {
    if (dataItems && dataItems.data) {
      setItemInfo([...itemInfo, ...dataItems.data]);
    }
  }, [dataItems]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}&status=need_to_approve${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  const getItemInfo = (ids: string[]) => {
    return ids.map((id) => {
      return itemInfo.find((item: any) => item.prizeId === id);
    });
  };

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          optionsDropdownCheckbox={["pending review", "declined"]}
          optionsAllDropdownCheckbox="All Statuses"
          origin="grandprize_need_to_approve"
          searchBarPlaceholder="Search in Item name,Merchants name, Item ID, Merchant ID"
          getItemInfo={getItemInfo}
          setItemInfoSelected={setItemInfoSelected}
        />
        {showFilter && (
          <FilterFormNeedApproveItems handleShowFilter={setShowFilter} />
        )}
      </div>
      <Table
        data={dataItems !== undefined && dataItems.data ? dataItems?.data : []}
        loadingData={isLoadingItems}
        errorData={isErrorItems}
        refetch={refetch}
        results={dataItems !== undefined ? dataItems?.count : 1}
        origin="items_grand_prize"
        headers={TABLE_MERCHANT_NEW_ITEMS_AND_NEED_APROVE_HEADER}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeName",
          "prizeType",
          "merchantName",
          "merchantId",
          "startQty",
          "createdAt",
          "expiredAt",
          "prizeState",
        ]}
        sort={{
          prizeId: null,
          prizeName: null,
          prizeType: null,
          merchantName: null,
          merchantId: null,
          startQty: null,
          createdAt: null,
          expiredAt: null,
          prizeState: null,
        }}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        orderingByAPI={true}
      />
      {modal.type === "addRedeemedCode" && (
        <AddRedeemedCodeModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          itemInfoSelected={itemInfoSelected}
          setItemInfoSelected={setItemInfoSelected}
          promise={duplicateGPMutation}
          type={"Grand Prize"}
        />
      )}
    </>
  );
}

export default NeedApproveItems;
