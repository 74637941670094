export function addDays(date: any, days: any) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    const localISOTime = result.toISOString();
    return localISOTime.split("T")[0];
}

export function substractDays(date: any, days: any) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    const localISOTime = result.toISOString();
    return localISOTime.split("T")[0];
}

export const getNextDayEndDate = (endDate: string, issoString: boolean = false) => {
    if (!endDate) return "";

    const originalDate = new Date(
        endDate
    );

    // Add one second to the original date
    originalDate.setSeconds(originalDate.getSeconds() + 12000);

    if (issoString) {
        const date = originalDate.toISOString();
        if (date.includes(".000")) {
            return date.split(".000")[0] + "Z";
        }

        return originalDate.toISOString();
    }
    // Format the modified date as a string in ISO 8601 format
    return originalDate.toISOString().substring(0, 10);
};