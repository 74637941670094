import { TypeClasses } from "./types";

function Prize({ classes }: TypeClasses) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="redeem_FILL0_wght400_GRAD0_opsz48"
        d="M5.35,17.568V19.75h15.3V17.568Zm0-9.968H8.162a1.89,1.89,0,0,1-.18-.506,2.562,2.562,0,0,1-.068-.551,2.451,2.451,0,0,1,.743-1.8A2.425,2.425,0,0,1,10.435,4a2.458,2.458,0,0,1,1.283.349,2.689,2.689,0,0,1,.923.889l.382.585.36-.585a2.784,2.784,0,0,1,.922-.9A2.32,2.32,0,0,1,15.52,4a2.5,2.5,0,0,1,1.823.731,2.441,2.441,0,0,1,.742,1.811,2.227,2.227,0,0,1-.056.5,4.981,4.981,0,0,1-.169.562h2.79A1.384,1.384,0,0,1,22,8.95v10.8a1.384,1.384,0,0,1-1.35,1.35H5.35A1.384,1.384,0,0,1,4,19.75V8.95A1.384,1.384,0,0,1,5.35,7.6Zm0,7.56h15.3V8.95h-5.6l2.25,3.128-1.08.81L13,8.432,9.783,12.887l-1.08-.81,2.25-3.127H5.35Zm5.107-7.425A1.193,1.193,0,0,0,11.3,5.7,1.193,1.193,0,0,0,9.614,7.386,1.149,1.149,0,0,0,10.457,7.735Zm5.063,0a1.176,1.176,0,0,0,.866-.349,1.2,1.2,0,0,0,0-1.687,1.176,1.176,0,0,0-.866-.349A1.124,1.124,0,0,0,14.7,5.7a1.2,1.2,0,0,0,0,1.687A1.124,1.124,0,0,0,15.52,7.735Z"
        transform="translate(-1 -0.55)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Prize;
