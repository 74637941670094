import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type DataType = { email: string, firstName: string, id: number, lastName: string, role: string, status: string }
interface UserState {
  user: {
    data: DataType,
    token: string,
    refreshToken: string,
    isAuth: boolean,
    actualTab: string,
    actualTabIndex: number,
    refetchEndpoint: boolean,
    longLife: boolean
  }
}

// Define the initial state using that type
const initialState: UserState = {
  user: {
    data: { id: 0, email: "", firstName: "", lastName: "", role: "", status: "" },
    token: '',
    refreshToken: '',
    isAuth: false,
    actualTab: '',
    actualTabIndex: 0,
    refetchEndpoint: false,
    longLife: false,
  }
}

// let count = 0;

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<{ authToken: string, refreshToken: string }>) => {
      state.user.token = action.payload.authToken;
      state.user.refreshToken = action.payload.refreshToken;
      state.user.isAuth = true;
    },
    setData: (state, action: PayloadAction<DataType>) => {
      state.user.data = action.payload;
    },
    setActualTab: (state, action: PayloadAction<string>) => {
      state.user.actualTab = action.payload;
    },
    setActualTabIndex: (state, action: PayloadAction<number>) => {
      state.user.actualTabIndex = action.payload;
    },
    setRefetchEndpoint: (state, action: PayloadAction<boolean>) => {
      state.user.refetchEndpoint = action.payload;
    },
    setLongLife: (state, action: PayloadAction<boolean>) => {
      state.user.longLife = action.payload;
    },
  },
  // extraReducers: {
  //   // [fetchPlayerList.pending.type]: (state, action) => {
  //   //   state.user = {
  //   //     ...state.user,
  //   //     isAuth: true
  //   //   };
  //   // },
  //   [login.fulfilled.type]: (state, action) => {
  //     if (action.payload?.authToken) {
  //       state.user = {
  //         ...state.user,
  //         token: action.payload.authToken,
  //         refreshToken: action.payload?.refreshToken,
  //         isAuth: true
  //       };
  //     } else {
  //       state.user = {
  //         ...state.user,
  //         isAuth: false
  //       };
  //     }
  //   },
  //   [login.rejected.type]: (state, action) => {
  //     state.user = {
  //       ...state.user,
  //       isAuth: false
  //     };
  //   },
  // },
})

export const { setAuth, setData, setActualTab, setActualTabIndex, setRefetchEndpoint, setLongLife } = userSlice.actions

export default userSlice.reducer