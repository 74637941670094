
export const TABLE_LOCATION_CONFIG_HEADER = [
    { label: "DMA Name", accessor: "dma_name", sortable: true },
    { label: "Categories", accessor: "category_count", sortable: true },
    { label: "Merchant Count", accessor: "merchant_count", sortable: true },
    { label: "Created", accessor: "created_at", sortable: true },
    { label: "Offers Count", accessor: "offer_count", sortable: true },
    { label: "Prizes Count", accessor: "prize_count", sortable: true },
    { label: "Zip Codes", accessor: "zip_code_count", sortable: true },
    { label: "Redemptions Count", accessor: "redemption_count", sortable: true },
    { label: "Publish", accessor: "is_published", sortable: true },
];