import {useState, useMemo, useEffect} from "react";
import {useNavigate} from "react-router-dom";

// Components
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Form from "components/Table";
import Table from "components/Table/Table";
import FilterFormCategories from "components/Filter/FilterFormCategories";

// Utils
import {TABLE_CATEGORIES_HEADER} from "utils/categoriesHeader";
import routes from "utils/routesByRole";

// Store
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {
  categoriesApi,
  useLazyGetCategoryLocationsQuery,
} from "store/api/categoriesApi";
import {
  setFiltersLastCategoryLocation,
  setSorting,
} from "store/filters/filtersSlice";
import {
  useLazyGetCitiesFromLocationByIDQuery,
  useLazyGetLocationByIDQuery,
  usePublishCategoryLocationMutation,
  useUnpublishCategoryLocationMutation,
} from "store/api/locationApi";

// Assets
import List from "assets/List";
import Description from "assets/Description";
import {EyeHide} from "assets/Eye";
import {toast} from "react-toastify";
import {transformDate} from "utils/transformDate";
import Modal from "components/Modal";
import {Dialog} from "@headlessui/react";
import Button from "components/Common/Button";
import {setOpenModal, setType} from "store/modal/modalSlice";

function Categories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const user = useSelector((state: RootState) => state.user.user.user);
  const modal: any = useSelector((state: RootState) => state.modal);

  const [showFilter, setShowFilter] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [modalContent, setModalContent] = useState<any[]>([]);
  const [typeActionCategory, setTypeActionCategory] = useState("");

  const [params, setParams] = useState("?");

  const [localParams, setLocalParams] = useState(filters.lastCategoryLocation);

  const [publishCategoryLocationMutation] =
    usePublishCategoryLocationMutation();
  const [unpublishCategoryLocationMutation] =
    useUnpublishCategoryLocationMutation();

  // Get Cities by DMA
  const [getCities] = useLazyGetCitiesFromLocationByIDQuery();

  // Data Table (List Categories)
  const [
    getCategoryByDMA,
    {
      data: dataCategoryLocation,
      isLoading: isLoadingDataCategoryLocation,
      isError: isErrorDataCategoryLocation,
    },
  ] = useLazyGetCategoryLocationsQuery();

  // Data below title
  const [getDataCategoryByDMA, {data: dataCategory}] =
    useLazyGetLocationByIDQuery();

  useEffect(() => {
    // Get the DMA to use in the get cities endpoint
    if (dataCategory) {
      const cities = getCities({
        dmaCode: dataCategory?.dmaCode,
        params: "",
      }).unwrap();

      cities
        .then((res) => {
          const citiesByDMA = res?.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          });

          dispatch(setMerchantsName(citiesByDMA));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setMerchantsName([]));
        });
    }
  }, [dataCategory]);

  useEffect(() => {
    if (localParams.states === "") return;
    setLocalParams(filters.lastCategoryLocation);
  }, [filters.lastCategoryLocation]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    if (localParams.states === undefined || localParams.states === "") {
      dispatch(setFiltersLastCategoryLocation({states: "", dma: ""}));
    }
    if (
      localParams.states !== undefined &&
      localParams.states !== "" &&
      localParams.dma !== ""
    ) {
      dispatch(setFiltersLastCategoryLocation(localParams));
      getCategoryByDMA({dma_code: localParams.dma, params});
      getDataCategoryByDMA({id: localParams.dma});
    }
  }, [localParams, params]);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const getInfoStatesFilters = (data: any[]) => {
    setLocalParams((prev: any) => {
      return {
        ...prev,
        states: data[0],
      };
    });
  };
  const getInfoDMAFilters = (data: any) => {
    setLocalParams((prev: any) => {
      return {
        ...prev,
        dma: data[0],
      };
    });
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleCloseModalWarning = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleUnpublishCategoryFn = (e: any, type: string, option: string) => {
    if (option === "individual") {
      if (type === "unpublish" && e.isPublished === true) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else if (type === "publish" && e.isPublished === false) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else {
        toast.info(`This category is already ${type}`);
      }
    }
  };

  const handleUnpublish = async () => {
    const toastPromise = toast.promise(
      unpublishCategoryLocationMutation({
        dmaCode: localParams.dma,
        categoryId: modalContent[0].id,
      }),
      {
        pending: `Unpublishing category`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while unpublishing category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category unpublished`);
          getCategoryByDMA({dma_code: localParams.dma, params});
          dispatch(categoriesApi.util.invalidateTags(["CategoriesID"]));
          setModalContent([]);
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error while unpublishing category");
      });
  };

  const handlePublish = async () => {
    const prizeCount = modalContent[0]?.prizeCount;
    const offerCount = modalContent[0]?.offerCount;

    const result = prizeCount + offerCount;

    if (result < 5) {
      dispatch(setType("showWarningModalLessThan5"));

      return;
    }

    handleConfirmPublish();
  };

  const handleConfirmPublish = async () => {
    const toastPromise = toast.promise(
      publishCategoryLocationMutation({
        dmaCode: localParams.dma,
        categoryId: modalContent[0].id,
      }),
      {
        pending: `Publishing category`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while publishing category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category published`);
          getCategoryByDMA({dma_code: localParams.dma, params});
          dispatch(categoriesApi.util.invalidateTags(["CategoriesID"]));
          setModalContent([]);
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error while publishing category");
      });
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("view") && {
        title: "Details",
        svg: <List classes="mr-3" />,
        type: "redirectToCategory",
        onClick: (elementID: string) => {
          navigate(`/categories/${elementID}`, {state: {id: localParams.dma}});
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Publish",
        svg: <Description classes="mr-3" />,
        type: "unpublishCategory",
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "publish", "individual");
          setTypeActionCategory("publish");
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Unpublish",
        type: "unpublishCategory",
        svg: <EyeHide classes="mr-3" />,
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "unpublish", "individual");
          setTypeActionCategory("unpublish");
        },
      },
    ],
    [localParams]
  );

  const tabs = useMemo(
    () => (
      <Tabs tabsName={"default"}>
        <>
          <div className="flex">
            <FilterFormCategories
              showFilter={showFilter}
              handleShowFilter={setShowFilter}
              hasFilterButton={false}
              optionsAllDropdownCheckbox="Choose state"
              optionsDropdownCheckbox={["FL"]}
              searchBarPlaceholder="Search by city and zip-code"
              getInfoStatesFilters={getInfoStatesFilters}
              getInfoDMAFilters={getInfoDMAFilters}
            />
          </div>

          <Table
            data={
              dataCategoryLocation &&
              dataCategoryLocation?.categories &&
              dataCategoryLocation?.categories?.length &&
              filters?.lastCategoryLocation?.states &&
              filters?.lastCategoryLocation?.states !== "" &&
              filters?.lastCategoryLocation?.dma &&
              filters?.lastCategoryLocation?.dma !== ""
                ? dataCategoryLocation.categories
                : []
            }
            loadingData={isLoadingDataCategoryLocation}
            errorData={isErrorDataCategoryLocation}
            origin="categories"
            hasPagination={false}
            headers={TABLE_CATEGORIES_HEADER}
            itemOptions={ITEMS}
            typeOptions="group"
            handleItemChecked={handleItemChecked}
            hasCheckbox={false}
            show={[
              "name",
              "type",
              "position",
              "merchantCount",
              "updatedAt",
              "offerCount",
              "prizeCount",
              "uniqueCount",
              "status",
              "isPublished",
            ]}
            sort={{
              name: null,
              type: null,
              position: null,
              merchantCount: null,
              updatedAt: null,
              offerCount: null,
              prizeCount: null,
              uniqueCount: null,
              status: null,
              isPublished: null,
            }}
            customError={{
              show: true,
              content: (
                <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
                  Please select a DMA to view available categories.
                </h2>
              ),
            }}
            additionalData={{id: localParams.dma}}
          />
        </>
      </Tabs>
    ),
    [
      filters,
      localParams,
      dataCategoryLocation,
      isLoadingDataCategoryLocation,
      isErrorDataCategoryLocation,
    ]
  );

  return (
    <div>
      <Title>Categories</Title>
      {dataCategory &&
        dataCategory.dmaCode &&
        dataCategory.dmaCode !== "" &&
        localParams.states !== "" &&
        localParams.dma !== "" && (
          <>
            <p className="text-xs text-gray-800 font-semibold mt-3">
              {dataCategory?.dmaDescription}
            </p>
            <p className="text-xs text-gray-500 mt-3">
              Created: {transformDate(dataCategory?.createdAt)} / Modify date:{" "}
              {transformDate(dataCategory?.updatedAt)}/ Publish:{" "}
              {dataCategory?.isPublished ? "Yes" : "No"}
            </p>
          </>
        )}
      <Form>
        <div className="mt-3">{tabs}</div>
      </Form>

      {modal.type === "unpublishCategoryModal" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Are you sure you want to {typeActionCategory} this category?
              </Dialog.Title>
              <div className="px-6 mb-5">
                {modalContent.map((element: any) => {
                  return <p>- {element?.name}</p>;
                })}
              </div>

              <p className="text-sm px-6">
                This action will affect the customer application
              </p>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>
                  {typeActionCategory === "unpublish" ? (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== false
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== false
                          ? handleUnpublish
                          : () =>
                              console.log(
                                "This category is already unpublished"
                              )
                      }
                    >
                      <p>Unpublish</p>
                    </Button>
                  ) : (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== true
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== true
                          ? handlePublish
                          : () =>
                              console.log("This category is already published")
                      }
                    >
                      <p>Publish</p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {modal.type === "showWarningModalLessThan5" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                The category has fewer than five items
              </Dialog.Title>
              <div className="px-6 mb-5">
                <p>
                  Would you like to proceed the publication of the category?
                </p>
                {modalContent.map((element: any) => {
                  return <p className="mt-3">- {element?.name}</p>;
                })}
              </div>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModalWarning()}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant="add"
                    type="button"
                    onClick={handleConfirmPublish}
                  >
                    <p>Publish</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Categories;
