import React, { Fragment } from "react";

// Componenets
import Subtitle from "components/Common/Subtitle";

// Assets
import AddOffer from "assets/AddOffer";
import { Tab } from "@headlessui/react";
import Badge from "components/Badge";

const type = "Offer";

function Stats() {
  return (
    <div className="flex mt-5 flex-wrap">
      <div className="bg-white p-5 xl:w-6/7 w-full">
        <form>
          <div className="flex items-center justify-between mb-5 pr-5">
            <Subtitle classes="mt-1">Stats</Subtitle>
            <div className="flex">
              <div className="flex items-center">
                <div className="rounded py-1 bg-primary-white flex items-center">
                  <AddOffer classes="h-5 w-5 mr-1" />
                  <p className="text-sm font-medium">{type}</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex w-100">
            <div className="mt-5 w-full">
              <Tab.Group>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <Badge title="4 days"></Badge>
                  </div>
                  <Tab.List className={"rounded flex justify-center w-36"}>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`text-sm p-2 rounded-l border-l-2 border-y-2 outline-none border-gray-300 w-40 truncate w-1/2 ${
                            selected
                              ? "bg-primary-purple text-white"
                              : "bg-white text-black"
                          }`}
                        >
                          Wheel
                        </button>
                      )}
                    </Tab>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <button
                          className={`text-sm p-2 rounded-r border-r-2 border-y-2 outline-none border-gray-300 w-40 truncate w-1/2 ${
                            selected
                              ? "bg-primary-purple text-white"
                              : "bg-white text-black"
                          }`}
                        >
                          Shop
                        </button>
                      )}
                    </Tab>
                  </Tab.List>
                </div>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className="mt-8 mb-6">
                      <div className="grid grid-cols-5 gap-2 mb-2">
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Start Qty.
                          </p>
                          <p className="text-2xl font-medium">70</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Qty Remaining
                          </p>
                          <p className="text-2xl font-medium">51</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Claimed</p>
                          <p className="text-2xl font-medium">19</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Redeemed</p>
                          <p className="text-2xl font-medium">12</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Balance In
                          </p>
                          <p className="text-2xl font-medium">7</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-5 gap-2">
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            WinRate %
                          </p>
                          <p className="text-2xl font-medium">1.6</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Elegibility %
                          </p>
                          <p className="text-2xl font-medium">36.9</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Accept %</p>
                          <p className="text-2xl font-medium">100</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Reject %</p>
                          <p className="text-2xl font-medium">0</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Redemption %
                          </p>
                          <p className="text-2xl font-medium">63.1%</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="mt-8 mb-6">
                      <div className="grid grid-cols-5 gap-2 mb-2">
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Start Qty.
                          </p>
                          <p className="text-2xl font-medium">30</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Qty Remaining
                          </p>
                          <p className="text-2xl font-medium">0</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Claimed</p>
                          <p className="text-2xl font-medium">30</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Redeemed</p>
                          <p className="text-2xl font-medium">26</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Balance In
                          </p>
                          <p className="text-2xl font-medium">4</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-5 gap-2">
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Elegibility %
                          </p>
                          <p className="text-2xl font-medium">13.3%</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">Reject %</p>
                          <p className="text-2xl font-medium">0</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Redemption %
                          </p>
                          <p className="text-2xl font-medium">100</p>
                        </div>
                        <div className="p-3 bg-tertiary-purple rounded">
                          <p className="text-gray-500 text-sm mb-1">
                            Redem Rate %
                          </p>
                          <p className="text-2xl font-medium">86.7%</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <hr />
          <Subtitle classes="mt-4">Feedback</Subtitle>
          <div className="mt-5">
            <div className="grid grid-cols-3 grid-row-4 gap-4 items-center text-gray-500 text-sm pb-3 border-b-2 border-gray-300">
              <p>Question</p>
              <p></p>
              <p className="text-center">Answer</p>
            </div>
            <div className="grid grid-cols-3 grid-row-4 gap-4 items-center pb-5 border-b-2 border-gray-300">
              <p className="mt-8">How satisfied are you with the prize from</p>
              <div className="w-full bg-fourth-purple h-3 rounded mt-8 ml-6">
                <div
                  style={{ width: "92%" }}
                  className="bg-primary-purple z-10 h-full rounded"
                ></div>
              </div>
              <div className="text-center mt-8">95</div>
              <p className="mt-8">How much the prize matches the photo</p>
              <div className="w-full bg-fourth-purple h-3 rounded mt-8 ml-6">
                <div
                  style={{ width: "92%" }}
                  className="bg-primary-purple z-10 h-full rounded"
                ></div>
              </div>
              <div className="text-center mt-8">89</div>
              <p className="mt-8">Would you buy this prize?</p>
              <div className="w-full bg-fourth-purple h-3 rounded mt-8 ml-6">
                <div
                  style={{ width: "92%" }}
                  className="bg-primary-purple z-10 h-full rounded"
                ></div>
              </div>
              <div className="text-center mt-8">97</div>
              <p className="mt-8">Are you satisfied with the service?</p>
              <div className="w-full bg-fourth-purple h-3 rounded mt-8 ml-6">
                <div
                  style={{ width: "92%" }}
                  className="bg-primary-purple z-10 h-full rounded "
                ></div>
              </div>
              <div className="text-center mt-8">92</div>
            </div>
          </div>
          <div className="grid grid-cols-3 grid-row-4 gap-4 items-center mt-4">
            <div></div>
            <div></div>
            <div className="flex justify-center items-center">
              <p className="text-xl font-medium mr-2">Total: </p>
              <p className="text-2xl font-semibold text-primary-purple mr-20">
                93.2
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Stats;
