import {useState, useRef, useEffect, useCallback} from "react";
import {useLocation} from "react-router-dom";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import {useDispatch} from "react-redux";
import {setImageModal, setOpenModal, setType} from "store/modal/modalSlice";

import Modal from "components/Modal";
import {Crop as CropIcon} from "assets/Crop";

import {canvasPreview} from "./canvasPreview";
import {useDebounceEffect} from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";

interface ImageCropModalType {
  imageSetter: () => void;
  handleUploadPhoto: (data: any, type: any, showImage: any) => void;
  type: string;
  imageCropModalType: string;
  image: string;
  showCloseButton: boolean;
  setShowCloseButton: (state: boolean) => void;
  setImageCropModalType: (type: string) => void;
  sizes: any;
  aspect: any;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCropModal = ({
  imageSetter,
  handleUploadPhoto,
  type,
  imageCropModalType,
  image,
  showCloseButton,
  setShowCloseButton,
  setImageCropModalType,
  sizes = {height: 1080, width: 1080},
  aspect = 1,
}: ImageCropModalType) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    unit: "px",
    x: 0,
    y: 0,
    width: sizes.height,
    height: sizes.width,
  });
  const [crop, setCrop] = useState<Crop>();

  // const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      return;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      // @ts-ignore
      const {width, height} = e?.target;
      setCompletedCrop({
        unit: "px",
        x: 0,
        y: 0,
        height: height,
        width: width,
      });
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop, 1, 0]
  );

  const convertCanvasToBlob = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return;
    canvas.toBlob((blob: any) => {
      if (!blob) return;
      handleUploadPhoto(new File([blob], type), type, imageSetter);
      const url = URL.createObjectURL(blob);
      dispatch(setImageModal(url));
    });
    setTimeout(() => {
      dispatch(setOpenModal(false));
      dispatch(setType(""));
      dispatch(setImageModal(""));
      setShowCloseButton(true);
      setImageCropModalType("alreadyCropped");
    }, 500);
  };

  return (
    <>
      <Modal
        showCloseButton={showCloseButton}
        setShowCloseButton={setShowCloseButton}
      >
        <div className="flex flex-col bg-white text-black rounded relative justify-center items-center">
          {!!image && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={image}
                style={{
                  // aspectRatio: `${type !== "LOGO_LARGE" && aspect}`,
                  objectFit: "contain",
                  width: "fit-content",
                }}
                onLoad={onImageLoad}
                className="max-h-screen"
                crossOrigin="anonymous"
              />
            </ReactCrop>
          )}
          <button
            className="flex py-2 px-3 rounded items-center justify-center active:border-none 
                         transition ease-in-out bg-primary-purple text-white mr-2 mt-2 text-sm font-base 
                         hover:bg-secondary-purple absolute bottom-5 right-4"
            onClick={() => convertCanvasToBlob(previewCanvasRef.current)}
            type="button"
          >
            <CropIcon classes="mr-2" />
            <span>Crop</span>
          </button>
        </div>
        {completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ImageCropModal;
