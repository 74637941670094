
export const TABLE_CATEGORIES_HEADER = [
    { label: "Category Name", accessor: "name", sortable: true },
    { label: "Category Type", accessor: "type", sortable: true },
    { label: "Position", accessor: "position", sortable: true },
    { label: "Merchant Count", accessor: "merchantCount", sortable: true },
    { label: "Last Edit", accessor: "updatedAt", sortable: true },
    { label: "Offer Count", accessor: "offerCount", sortable: true },
    { label: "Prize Count", accessor: "prizeCount", sortable: true },
    { label: "Unique Count", accessor: "uniqueCount", sortable: true },
    { label: "Status", accessor: "status", sortable: true },
    { label: "Publish", accessor: "isPublished", sortable: true },
];

export const TABLE_CATEGORY_ITEMS_HEADER = [
    { label: 'Item name', accessor: 'name', sortable: true },
    { label: 'Type', accessor: 'prize_type', sortable: true },
    { label: 'Merchant Name', accessor: 'merchant_name', sortable: true },
    { label: 'Exp. Date', accessor: 'expired_at', sortable: true },
    { label: 'Qty. Remaining', accessor: 'remaining', sortable: true },
    { label: 'Claim Count', accessor: 'claimed', sortable: true },
    { label: 'Redemption Count', accessor: 'redeemed', sortable: true },
];
