import Alert from "assets/Alert";
import Calendar from "assets/Calendar";
import {useCallback} from "react";
import Checkbox from "../Checkbox";

function DatePicker({
  checkboxID,
  label,
  children,
  value,
  classes = "",
  required = false,
  disabled = false,
  onChange,
  error = false,
  onClick,
  placeholder,
  min = 0,
  max = 0,
  alert,
  classesCheckbox = "",
  handleClickCheckbox,
  checkedValues,
}: any) {
  const getTime = useCallback(() => {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return localISOTime.split("T")[0];
  }, []);

  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && error) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !error) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  return (
    <div className={classes}>
      <div className="mb-2 flex items-center direction-row justify-between">
        <label
          htmlFor="datepiker_filter"
          className="block text-sm font-medium text-gray-400 whitespace-nowrap"
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        {alert && (
          <div className="flex">
            <Alert classes="h-4 w-4 mr-2" />
            <Checkbox
              className={classesCheckbox}
              id={checkboxID || label.replace(/\s/g, "")}
              key={checkboxID || label.replace(/\s/g, "")}
              type="checkbox"
              name={checkboxID || label.replace(/\s/g, "")}
              onChange={(e: any) => handleClickCheckbox(e)}
              isChecked={checkedValues?.includes(
                checkboxID || label.replace(/\s/g, "")
              )}
            />
          </div>
        )}
      </div>
      <span className="relative">
        <input
          type="date"
          id="datepiker_filter"
          className={`cursor-pointer text-sm inline-block rounded-md w-full font-medium  p-2.5 pl-4 
          ${checkClassName()}`}
          name="datepiker"
          value={value}
          onChange={onChange}
          disabled={disabled}
          onClick={onClick}
          min={min === "today" ? getTime() : min}
          max={max === "today" ? getTime() : max}
        />
        <Calendar classes="absolute top-0 right-2 text-gray-500" />
      </span>
    </div>
  );
}

export default DatePicker;
