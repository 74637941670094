import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface CurrentDatateState {
    data: any[],
    resetElChecked: boolean,
    isRefetching: boolean,
    paginationLoading: boolean,
}

// Define the initial state using that type
const initialState: CurrentDatateState = {
    data: [],
    resetElChecked: false,
    isRefetching: false,
    paginationLoading: false,
}

export const currentTableDataSlice = createSlice({
    name: 'currentTableData',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<any>) => {
            // console.log(action.payload)
            state.data = action.payload;
        },
        setRefetch: (state, action: PayloadAction<boolean>) => {
            state.isRefetching = action.payload
        },
        setResetElChecked: (state, action: PayloadAction<boolean>) => {
            state.resetElChecked = action.payload;
        },
        setPaginationLoading: (state, action: PayloadAction<boolean>) => {
            state.paginationLoading = action.payload;
        },
    },
})

export const { setData, setResetElChecked, setRefetch, setPaginationLoading } = currentTableDataSlice.actions

export default currentTableDataSlice.reducer