import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";

interface ItemOptionTypes {
  children?: any;
  toTop?: boolean;
  classes?: string;
  hide?: boolean;
}

export default function ItemOption({
  children,
  toTop = false,
  classes,
  hide,
}: ItemOptionTypes) {
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`inline-flex ${classes} w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white`}
          >
            <svg
              className={`h-6 text-gray-400  ${!classes && "hover:text-white"}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none" />
              <circle cx="128" cy="64" r="16" fill="currentColor" />
              <circle cx="128" cy="128" r="16" fill="currentColor" />
              <circle cx="128" cy="192" r="16" fill="currentColor" />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute z-20 p-2 ${toTop && "bottom-12"} ${
              !hide ? "right-0" : "left-0"
            } mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none`}
          >
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
