import { TypeClasses } from "./types";

function Search({ classes }: TypeClasses) {
  return (
    <svg
      id="search"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="search_FILL0_wght400_GRAD0_opsz48"
        d="M22.9,23.95l-6.575-6.575a5.509,5.509,0,0,1-1.75,1.012,6.187,6.187,0,0,1-2.125.363,6.225,6.225,0,0,1-4.575-1.875A6.166,6.166,0,0,1,6,12.35,6.166,6.166,0,0,1,7.875,7.825a6.2,6.2,0,0,1,4.55-1.875,6.125,6.125,0,0,1,4.512,1.875,6.4,6.4,0,0,1,1.513,6.6A6.346,6.346,0,0,1,17.4,16.3L24,22.85Zm-10.475-6.7a4.679,4.679,0,0,0,3.45-1.437A4.74,4.74,0,0,0,17.3,12.35a4.74,4.74,0,0,0-1.425-3.463,4.679,4.679,0,0,0-3.45-1.437A4.749,4.749,0,0,0,8.938,8.887,4.72,4.72,0,0,0,7.5,12.35a4.72,4.72,0,0,0,1.438,3.462,4.749,4.749,0,0,0,3.488,1.438Z"
        transform="translate(-3 -2.95)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Search;
