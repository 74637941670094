interface TitleTypes {
  classes?: string;
  children: string | any[];
}

function Subtitle({children, classes}: TitleTypes) {
  return <h2 className={`font-semibold text-lg  ${classes}`}>{children}</h2>;
}

export default Subtitle;
