import { TypeClasses } from "./types";

export const Crop = ({ classes }: TypeClasses) => {
  return (
    <svg
      id="crop"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classes}`}
    >
      <g id="surface1">
        <path
          style={{ fill: "currentcolor" }}
          d="M 3.453125 4.078125 L 0.5 4.078125 C 0.222656 4.078125 0 4.304688 0 4.582031 L 0 6.410156 C 0 6.6875 0.222656 6.910156 0.5 6.910156 L 3.453125 6.910156 Z M 3.453125 4.078125 "
        />
        <path
          style={{ fill: "currentcolor" }}
          d="M 19.417969 24 C 19.695312 24 19.921875 23.777344 19.921875 23.5 L 19.921875 20.546875 L 17.089844 20.546875 L 17.089844 23.5 C 17.089844 23.773438 17.3125 24 17.589844 24 Z M 19.417969 24 "
        />
        <path
          style={{ fill: "currentcolor" }}
          d="M 19.921875 16.460938 L 19.921875 4.582031 C 19.921875 4.304688 19.695312 4.078125 19.417969 4.078125 L 7.539062 4.078125 L 7.539062 6.910156 L 17.089844 6.910156 L 17.089844 16.460938 Z M 19.921875 16.460938 "
        />
        <path
          style={{ fill: "currentcolor" }}
          d="M 23.5 17.089844 L 6.910156 17.089844 L 6.910156 0.5 C 6.910156 0.222656 6.6875 0 6.410156 0 L 4.582031 0 C 4.304688 0 4.078125 0.222656 4.078125 0.5 L 4.078125 19.417969 C 4.078125 19.695312 4.304688 19.921875 4.582031 19.921875 L 23.5 19.921875 C 23.773438 19.921875 24 19.695312 24 19.417969 L 24 17.589844 C 24 17.3125 23.773438 17.089844 23.5 17.089844 Z M 23.5 17.089844 "
        />
      </g>
    </svg>
  );
};

export default Crop;
