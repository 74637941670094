import {Suspense, lazy, useState, useEffect} from "react";
import {withErrorBoundary} from "react-error-boundary";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";
import ErrorFallback from "components/ErrorFallback";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useGetAllGrandPrizeQuery,
  useChangeStatusItemMutation,
  useGetAllGrandPrizeNameQuery,
  useChangeStatusGrandPrizeMutation,
  useDuplicateGrandPrizeItemMutation,
} from "store/api/itemApi";
import {setRefetchEndpoint} from "store/user/userSlice";

// Utils
import {TABLE_MERCHANT_ITEMS_APPROVED_HEADER} from "utils/itemsHeaders";
import FormSqueleton from "utils/FormSqueleton";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import ArchiveModal from "components/Modal/ArchiveModal";
import AddRedeemedCodeModal from "components/Modal/AddRedeemedCodeModal";
import {setSorting} from "store/filters/filtersSlice";

const FilterFormItems = lazy(() => import("components/Filter/FilterFormItems"));

function ApprovedGrandPrizeComponent() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);
  const modal = useSelector((state: RootState) => state.modal);

  useEffect(() => {
    dispatch(setSorting("ordering=id"));
  }, []);

  const [changeStatusGPMutation] = useChangeStatusGrandPrizeMutation();
  const [duplicateGPMutation] = useDuplicateGrandPrizeItemMutation();

  // Store item data to duplicate use on duplicate
  const [itemInfo, setItemInfo] = useState<any>([]);
  const [itemInfoSelected, setItemInfoSelected] = useState([]);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id&status=approved");

  const {
    data: dataItems,
    isLoading: isLoadingItems,
    isError: isErrorItems,
    refetch,
  } = useGetAllGrandPrizeQuery(params);

  const {
    data: dataApprovedItemsName,
    isLoading: isLoadingApprovedItemsName,
    isError: isErrorApprovedItemsName,
  } = useGetAllGrandPrizeNameQuery("&status=APPROVED");

  useEffect(() => {
    if (dataApprovedItemsName && dataApprovedItemsName.length) {
      dispatch(setMerchantsName(dataApprovedItemsName));
    }
  }, [dataApprovedItemsName]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}&status=approved${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    if (dataItems && dataItems.data) {
      setItemInfo([...itemInfo, ...dataItems.data]);
    }
  }, [dataItems]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  const getItemInfo = (ids: string[]) => {
    return ids.map((id) => {
      return itemInfo.find((item: any) => item.prizeId === id);
    });
  };

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          optionsDropdownCheckbox={["active", "paused", "completed"]}
          optionsAllDropdownCheckbox="All Statuses"
          origin="grandprize"
          searchBarPlaceholder="Search in Item name,Merchants name, Item ID, Merchant ID"
          getItemInfo={getItemInfo}
          setItemInfoSelected={setItemInfoSelected}
        />
        {showFilter && (
          <Suspense fallback={<FormSqueleton />}>
            <FilterFormItems handleShowFilter={setShowFilter} />
          </Suspense>
        )}
      </div>
      <Table
        data={dataItems !== undefined && dataItems.data ? dataItems?.data : []}
        loadingData={isLoadingItems}
        errorData={isErrorItems}
        refetch={refetch}
        results={dataItems !== undefined ? dataItems?.count : 1}
        origin="items_grand_prize"
        headers={TABLE_MERCHANT_ITEMS_APPROVED_HEADER}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeName",
          "prizeType",
          "merchantName",
          "merchantId",
          "startQty",
          "remaining",
          "claimed",
          "redeemed",
          "createdAt",
          "expiredAt",
          "daysToExpire",
          "prizeState",
        ]}
        sort={{
          prizeId: null,
          prizeName: null,
          prizeType: null,
          merchantName: null,
          merchantId: null,
          startQty: null,
          remaining: null,
          claimed: null,
          redeemed: null,
          createdAt: null,
          expiredAt: null,
          daysToExpire: null,
          status: null,
        }}
        showButtonOption={false}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        orderingByAPI={true}
      />
      {modal.type === "archiveGrandPrize" && (
        <ArchiveModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          promise={changeStatusGPMutation}
          type={"Grand Prize"}
        />
      )}
      {modal.type === "addRedeemedCode" && (
        <AddRedeemedCodeModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          itemInfoSelected={itemInfoSelected}
          setItemInfoSelected={setItemInfoSelected}
          promise={duplicateGPMutation}
          type={"Grand Prize"}
        />
      )}
    </>
  );
}

const ApprovedItems = withErrorBoundary(ApprovedGrandPrizeComponent, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
  },
});

export default ApprovedItems;
