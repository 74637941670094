import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

const useIdleTimeout = ({ idleTime = 60 }: { idleTime?: number }) => {
    const idleTimeout = 1000 * 60 * idleTime;
    const [isIdle, setIdle] = useState(false)

    const handleSignOut = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    const handleIdle = () => {
        setIdle(true)
        handleSignOut()
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle: handleIdle,
        debounce: 500
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;