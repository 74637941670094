import {useEffect, useState} from "react";

// Components
import Table from "components/Table/Table";
import Filter from "components/Filter";
import FilterFormNewItems from "components/Filter/FilterFormNewItems";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useChangeStatusItemMutation,
  useGetAllItemsNameQuery,
  useGetAllItemsQuery,
} from "store/api/itemApi";

// Utils
import {TABLE_MERCHANT_NEW_ITEMS_AND_NEED_APROVE_HEADER} from "utils/itemsHeaders";
import {setRefetchEndpoint} from "store/user/userSlice";
import {setMerchantsName} from "store/merchants/merchantsSlice";

function NewItems() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);
  const filters = useSelector((state: RootState) => state.filters.filters);
  const tabsAndPage = useSelector((state: RootState) => state.tabs);
  const modal = useSelector((state: RootState) => state.modal);

  // Store item data to duplicate use on duplicate
  const [itemInfo, setItemInfo] = useState<any>([]);
  const [itemInfoSelected, setItemInfoSelected] = useState([]);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState("?ordering=id&status=new");

  const [changeStatusMutation] = useChangeStatusItemMutation();

  const {
    data: dataItems,
    isLoading: isLoadingItems,
    isError: isErrorItems,
    refetch,
  } = useGetAllItemsQuery(params);

  const {
    data: dataNewItemsName,
    isLoading: isLoadingNewItemsName,
    isError: isErrorNewItemsName,
  } = useGetAllItemsNameQuery("&status=NEW");

  useEffect(() => {
    if (dataItems && dataItems.data) {
      setItemInfo([...itemInfo, ...dataItems.data]);
    }
  }, [dataItems]);

  useEffect(() => {
    if (dataNewItemsName && dataNewItemsName.length) {
      dispatch(setMerchantsName(dataNewItemsName));
    }
  }, [dataNewItemsName]);

  useEffect(() => {
    setParams(
      `?${filters.sorting}&status=new${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        refetch();
      }, 300);
      dispatch(setRefetchEndpoint(false));
      // dispatch(merchantsApi.util.invalidateTags(["Merchant"]));
    }
  }, [user]);

  const getItemInfo = (ids: string[]) => {
    return ids.map((id) => {
      return itemInfo.find((item: any) => item.prizeId === id);
    });
  };

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  return (
    <>
      <hr />
      <div className="mt-3">
        <Filter
          showFilter={showFilter}
          handleShowFilter={setShowFilter}
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          showDropdownCheckbox={false}
          origin="items_new"
          searchBarPlaceholder="Search in Item name,Merchants name, Item ID, Merchant ID"
          getItemInfo={getItemInfo}
          setItemInfoSelected={setItemInfoSelected}
        />
        {showFilter && <FilterFormNewItems handleShowFilter={setShowFilter} />}
      </div>
      <Table
        data={dataItems !== undefined && dataItems.data ? dataItems?.data : []}
        loadingData={isLoadingItems}
        errorData={isErrorItems}
        refetch={refetch}
        results={dataItems !== undefined ? dataItems?.count : 1}
        origin="items"
        headers={TABLE_MERCHANT_NEW_ITEMS_AND_NEED_APROVE_HEADER}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeName",
          "prizeType",
          "merchantName",
          "merchantId",
          "startQty",
          "createdAt",
          "expiredAt",
          "prizeState",
        ]}
        sort={{
          prizeId: null,
          prizeName: null,
          prizeType: null,
          merchantName: null,
          merchantId: null,
          startQty: null,
          createdAt: null,
          expiredAt: null,
          prizeState: null,
        }}
        countPagination={
          tabsAndPage.tabs.lastVisitedPage
            ? +tabsAndPage.tabs.lastVisitedPage
            : 0
        }
        orderingByAPI={true}
      />
    </>
  );
}

export default NewItems;
