import { Disclosure } from "@headlessui/react";
import { ArrowPrev } from "assets/Arrows";
import Save from "assets/Save";
import Button from "components/Common/Button";

interface DisclosureCompTypes {
  header: string;
  children: JSX.Element;
  onClick?: () => void;
  makeAsDefaultButton?: boolean;
}

function DisclosureComp({ header, children, onClick, makeAsDefaultButton }: DisclosureCompTypes) {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium ">
            <span className="text-lg font-semibold">{header}</span>
            <ArrowPrev
              classes={`w-6 h-6 transition ease-in-100 ${
                open ? "rotate-90" : "-rotate-90"
              }`}
            />
          </Disclosure.Button>
          <hr className="bg-gray-100" />
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 h-min">
            {children}
            <hr />            
            <div className="flex justify-between mt-3">
              {makeAsDefaultButton && 
                <Button type="button" variant="normal">
                  <Save classes="mr-2"/> Make as default
                </Button>
              }
              <div className="flex ml-auto">
                <Button type="button" variant="normal">Cancel</Button>
                <Button type="submit" variant="add">Save</Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default DisclosureComp;
