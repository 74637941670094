import {ArrowBack} from "assets/Arrows";
import Copy from "assets/Copy";
import Badge from "components/Badge";
import Button from "components/Common/Button";
import Input from "components/Common/Input";
import DatePicker from "components/Common/Input/DatePicker";
import Dropzone from "components/Common/Input/Dropzone";
import Subtitle from "components/Common/Subtitle";
import Textarea from "components/Common/Textarea";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {setLoyaltyProgram} from "store/filters/filtersSlice";
import {RootState} from "store/store";
import routes from "utils/routesByRole";
import RewardCard from "../Reward";
import {useNavigate} from "react-router-dom";
import Archive from "assets/Archive";
import Recovery from "assets/Recovery";
import {
  setEmployeeSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";
import Pause from "assets/Pause";
import Play from "assets/Play";
import {
  itemApi,
  useApproveLoyaltyProgramMutation,
  useArchiveLoyaltyProgramMutation,
  useChangeStatusItemMutation,
  useChangeStatusLoyaltyProgramMutation,
  useDeleteItemMutation,
  useDeleteLoyaltyProgramMutation,
  useDeleteRewardLoyaltyProgramMutation,
  useGetDeclineReasonsQuery,
  useUpdateLoyaltyProgramImageMutation,
  useUpdateLoyaltyProgramMutation,
} from "store/api/itemApi";
import ArchiveModal from "components/Modal/ArchiveModal";
import RecoveryModal from "components/Modal/RecoveryModal";
import Save from "assets/Save";
import DeclineModal from "components/Modal/DeclineModal";
import {merchantsApi} from "store/api/merchantsApi";
import Modal from "components/Modal";
import {Dialog} from "@headlessui/react";
import Delete from "assets/Delete";
import {getNextDayEndDate} from "utils/addDays";
import DeleteModal from "components/Modal/DeleteModal";

export type LoyalityData = {
  loyaltyId: number;
  title: string;
  terms: string;
  startDate: string;
  endDate: string;
  rewards: any[];
};

function LoyaltyDetails({
  loyaltyProgram,
  merchantInfo,
}: {
  loyaltyProgram: any;
  merchantInfo: any;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user.user);
  const modal = useSelector((state: RootState) => state.modal);

  const [changeStatusItem] = useChangeStatusItemMutation();

  const [loyalityData, setLoyalityData] = useState<LoyalityData>({
    loyaltyId: 0,
    title: "",
    terms: "",
    startDate: "",
    endDate: "",
    rewards: [],
  });

  const [inputError, setInputError] = useState(false);
  const [newDataCheckbox, setNewDataCheckbox] = useState<string[]>([]);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);

  const [loyaltyImage, setLoyaltyImage] = useState<string>("");
  const [loyalityImageFormData, setLoyaltyImageFormData] =
    useState<FormData | null>(null);

  const [reason, setReason] = useState();
  const [declinedItems, setDeclinedItems] = useState<string[]>([]);
  const [approvedItems, setApprovedItems] = useState<string[]>([]);
  const [btnApprove, setBtnApprove] = useState<boolean>(true);
  const [reasonDescription, setReasonDescription] = useState("");

  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.loyalty?.includes("add")
      ? false
      : true;
  });

  const {
    data: declineReasons,
    isLoading: isLoadingDeclineReasons,
    isError: isErrorDeclineReasons,
    // @ts-ignore
  } = useGetDeclineReasonsQuery();

  const [changeStatusLoyaltyMutation] = useChangeStatusLoyaltyProgramMutation();
  const [updateLoyaltyMutation] = useUpdateLoyaltyProgramMutation();
  const [applyChanges] = useApproveLoyaltyProgramMutation();
  const [deleteLoyalty] = useDeleteLoyaltyProgramMutation();
  const [archiveLoyalty] = useArchiveLoyaltyProgramMutation();

  const [updateImageLoyaltyProgram] = useUpdateLoyaltyProgramImageMutation();
  const [setDeleteItem] = useDeleteRewardLoyaltyProgramMutation();

  useEffect(() => {
    if (loyaltyProgram) {
      setLoyalityData({
        loyaltyId:
          loyaltyProgram?.newData?.loyaltyId || loyaltyProgram?.loyaltyId,
        title: loyaltyProgram?.newData?.title || loyaltyProgram?.title,
        terms: loyaltyProgram?.newData?.terms || loyaltyProgram?.terms,
        startDate:
          loyaltyProgram?.newData?.startDate || loyaltyProgram?.startDate,
        endDate: loyaltyProgram?.newData?.endDate || loyaltyProgram?.endDate,
        rewards: loyaltyProgram?.newData?.rewards || loyaltyProgram?.rewards,
      });

      if (
        loyaltyProgram &&
        loyaltyProgram?.images &&
        loyaltyProgram?.images?.length > 0
      ) {
        setLoyaltyImage(
          loyaltyProgram?.images[0]?.newUrl || loyaltyProgram?.images[0]?.url
        );
      }

      // setLoyaltyImage(loyaltyProgram?.newData?.loyaltyId || loyaltyProgram?.loyaltyId)
    }
  }, [loyaltyProgram]);

  useEffect(() => {
    if (
      loyaltyProgram?.newData ||
      loyaltyProgram?.images[0]?.hasOwnProperty("newName")
    ) {
      let resultDeclinedItems: any = [];
      let resultApprovedItems: any = [];

      if (loyaltyProgram?.newData) {
        resultDeclinedItems = Object?.keys(loyaltyProgram?.newData).filter(
          (val) => newDataCheckbox?.includes(val)
        );

        resultApprovedItems = Object?.keys(loyaltyProgram?.newData).filter(
          (val) => !newDataCheckbox?.includes(val)
        );
      }

      const resultNewImages: any[] = [];

      loyaltyProgram?.images?.forEach((image: any) => {
        if (image.hasOwnProperty("newName") || image.hasOwnProperty("newUrl")) {
          if (image.type === "SMALL") {
            resultNewImages.push("image#SMALL");
          }
        }
      });

      const resultDeclinedImages = resultNewImages.filter((image: any) =>
        newDataCheckbox.includes(image)
      );
      const resultApprovedImages = resultNewImages.filter(
        (image: any) => !newDataCheckbox.includes(image)
      );

      if (newDataCheckbox.length > 0) {
        handleChangeBtnAllChecked(false);
      } else {
        handleChangeBtnAllChecked(true);
      }

      setDeclinedItems(resultDeclinedItems.concat(resultDeclinedImages));
      setApprovedItems(resultApprovedItems.concat(resultApprovedImages));
    }
  }, [loyaltyProgram, newDataCheckbox]);

  const handleDeleteItem = async () => {
    const toastPromise = toast.promise(
      deleteLoyalty({
        idLoyalty: loyalityData?.loyaltyId,
      }),
      {
        pending: "Deleting loyalty",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting loyalty</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Deleted");
          dispatch(setType(""));
          dispatch(setOpenModal(false));
          dispatch(setLoyaltyProgram(null));
        }
      })
      .catch((err) => {
        toast.error("Error while deleting loyalty");
      });
  };

  const handleChangeBtnAllChecked = (value: boolean) => {
    if (value) {
      setBtnApprove(true);
    } else {
      setBtnApprove(false);
    }
  };

  const handleSendDeclineReason = async () => {
    if (!reason || !reasonDescription) {
      toast.error("Provide reason and description for decline");
      setInputError(true);
      return;
    }

    const checkIfIsApproved = (fieldName: string) => {
      const isNotApproved = declinedItems.find(
        (reason) => reason === fieldName
      );

      return isNotApproved ? true : false;
    };

    const data = {
      description: reasonDescription,
      approvedFields: approvedItems,
      declinedFields: declinedItems,
      reasonId: parseInt(reason),
      fullInfo: [
        {
          fieldName: "title",
          fieldValue: loyalityData.title,
          isApproved: checkIfIsApproved("title"),
        },
        {
          fieldName: "terms",
          fieldValue: loyalityData.terms,
          isApproved: checkIfIsApproved("terms"),
        },
        {
          fieldName: "startDate",
          fieldValue: loyalityData.startDate,
          isApproved: checkIfIsApproved("startDate"),
        },
        {
          fieldName: "endDate",
          fieldValue: loyalityData.endDate,
          isApproved: checkIfIsApproved("endDate"),
        },
        {
          fieldName: "endDate",
          fieldValue: loyalityData.endDate,
          isApproved: checkIfIsApproved("endDate"),
        },
        {
          fieldName: "image#SMALL",
          fieldValue: loyaltyImage,
          isApproved: checkIfIsApproved("image#SMALL"),
        },
      ],
    };

    const toastPromise = toast.promise(
      applyChanges({
        idLoyalty: loyalityData.loyaltyId,
        data,
      }),
      {
        pending: "Declining loyalty program",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while declining loyalty program</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Program Declined");
          dispatch(setType(""));
          setNewDataCheckbox([]);
          dispatch(setOpenModal(false));
        }
      })
      .catch((err) => {
        toast.error("Error while decliging loyalty program");
      });
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();

    if (typeof data !== "string") {
      formData.append("image", data);
    } else {
      formData.append("imageUrl", data);
    }
    formData.append("name", data.name);
    formData.append("type", type);

    let imgUrl;

    if (typeof data !== "string") {
      imgUrl = URL.createObjectURL(data);
    } else {
      imgUrl = data;
    }

    showImage(imgUrl);

    // set form data in image inside lotalty data
    setLoyaltyImageFormData(formData);
    // setObjectImages({...objectImages, logoLarge: formData});
  };

  const redirectTo = () => {
    dispatch(setLoyaltyProgram(null));
  };

  const handleNavigateItem = () => {
    return navigate("/items/create", {
      state: {
        type: "reward",
        id: merchantInfo?.id,
        loyaltyId: loyalityData?.loyaltyId,
      },
    });
  };

  const handlePublishItem = async (type: string) => {
    const toastPromise = toast.promise(
      changeStatusLoyaltyMutation({
        data: {newState: type},
        idLoyalty: loyalityData?.loyaltyId,
      }),
      {
        pending: "Changing status",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing status loyalty program</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Program Status Changed");
        }
      })
      .catch((err) => {
        toast.error("Error while changing loyalty program");
      });
  };

  const handleCheckApprove = async () => {
    const areAllRewardActive = loyalityData.rewards.every(
      (reward) => reward.reward.state === "ACTIVE"
    );

    if (loyalityData.rewards.length === 0)
      return toast.error(
        "You need to add at least one reward to approve this loyalty program"
      );

    if (!areAllRewardActive) {
      return toast.error(
        "All rewards must be reviewed and approved in the item section of the admin panel before you can publish the loyalty program"
      );
    }

    if (!loyaltyProgram?.hasOwnProperty("newData")) {
      handlePublishItem("ACTIVE");
      return;
    }

    // if ( === 0)
    //   return toast.error(
    //     "You need to add at least one reward to approve this loyalty program"
    //   );

    // useApproveLoyaltyProgramMutation

    const data = {
      description: "",
      approvedFields: approvedItems,
      declinedFields: declinedItems,
      reasonId: 0,
      fullInfo: [
        {
          fieldName: "title",
          fieldValue: loyalityData.title,
          isApproved: true,
        },
        {fieldName: "terms", fieldValue: loyalityData.terms, isApproved: true},
        {
          fieldName: "startDate",
          fieldValue: loyalityData.startDate,
          isApproved: true,
        },
        {
          fieldName: "endDate",
          fieldValue: loyalityData.endDate,
          isApproved: true,
        },
        {
          fieldName: "image#SMALL",
          fieldValue: loyaltyImage,
          isApproved: true,
        },
      ],
    };

    const toastPromise = toast.promise(
      applyChanges({
        idLoyalty: loyalityData.loyaltyId,
        data,
      }),
      {
        pending: "Approving Loyalty Program",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while approving item</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Program Approved");
          setNewDataCheckbox([]);
        }
      })
      .catch((err) => {
        toast.error("Error while approving item");
      });

    // handlePublishItem("ACTIVE");
  };

  const handleDecline = () => {
    dispatch(setType("declineModalLoyalty"));
    dispatch(setOpenModal(true));
  };

  const handleClickNewDataCheckbox = (e: any) => {
    const {id, checked} = e.target;

    setNewDataCheckbox([...newDataCheckbox, id]);

    if (!checked) {
      setNewDataCheckbox(newDataCheckbox.filter((item: any) => item !== id));
    }
  };

  const handleSubmit = async () => {
    if (
      !loyalityData.title ||
      !loyalityData.startDate ||
      !loyalityData.endDate ||
      !loyaltyImage
    ) {
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    // console.log(loyaltyProgram, loyalityData);
    const toastPromise = toast.promise(
      updateLoyaltyMutation({
        data: loyalityData,
        idLoyalty: loyalityData?.loyaltyId,
      }),
      {
        pending: "Updating Loyalty Program",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while updating loyalty program</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Program Updated");

          if (loyalityImageFormData) {
            const toastPromise = toast.promise(
              updateImageLoyaltyProgram({
                idLoyalty: loyalityData.loyaltyId,
                data: loyalityImageFormData,
              }),
              {
                pending: "Uploading Loyalty Program image",
              }
            );
            toastPromise
              .then((res: any) => {
                if (res.error) {
                  toast.error(
                    <div>
                      <h2>Error while updating loyalty program image</h2>
                      <p className="text-xs"> {res?.error?.data?.code} </p>
                    </div>
                  );
                } else {
                  toast.success("Loyalty Program image uploaded");
                  merchantsApi.util.invalidateTags(["IndividualMerchant"]);
                }
              })
              .catch((err) => {
                toast.error("Error while uploading loyalty program image");
              });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while updating loyalty program");
      });
  };

  const handleFormatElement = (element: string) => {
    if (element.toLowerCase() === "startDate") {
      return "start date";
    }
    if (element.toLowerCase() === "endDate") {
      return "end date";
    } else {
      return element;
    }
  };

  const handleRenderButton = () => {
    return (
      <div className="flex mr-3">
        {loyaltyProgram?.state !== "PENDING_REVIEW" &&
        loyaltyProgram?.state !== "ACTIVE"
          ? loyaltyProgram?.state === "ARCHIVED"
            ? // @ts-ignore
              routes[user.data.role].urlAllowed?.loyalty?.includes(
                "recover"
              ) && (
                <>
                  <Button
                    variant="recovery"
                    type="button"
                    onClick={() => {
                      dispatch(setType("recoverLoyalty"));
                      dispatch(setOpenModal(true));
                    }}
                  >
                    <Recovery />
                    <p className="ml-2">Recover Program</p>
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => {
                      dispatch(setType("deleteLoyaltyy"));
                      dispatch(setOpenModal(true));
                    }}
                  >
                    <Delete />
                    <p className="ml-2">Delete Program</p>
                  </Button>
                </>
              )
            : // @ts-ignore
              routes[user.data.role].urlAllowed?.loyalty?.includes(
                "archive"
              ) && (
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    dispatch(setType("archiveLoyalty"));
                    dispatch(setOpenModal(true));
                  }}
                >
                  <Archive />
                  <p className="ml-2">Archive Program</p>
                </Button>
              )
          : null}
        {loyaltyProgram?.state === "NEW" ||
        loyaltyProgram?.state === "PENDING_REVIEW"
          ? btnApprove // @ts-ignore
            ? routes[user.data.role].urlAllowed?.loyalty?.includes(
                "decline"
              ) && (
                <Button
                  variant="recovery"
                  type="button"
                  onClick={() => handleCheckApprove()}
                >
                  <p>Approve & Publish</p>
                </Button>
              )
            : // @ts-ignore
              routes[user.data.role].urlAllowed?.loyalty?.includes(
                "decline"
              ) && (
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => handleDecline()}
                >
                  <p>Decline</p>
                </Button>
              )
          : null}
        {loyaltyProgram?.state !== "PENDING_REVIEW" &&
          loyaltyProgram?.state !== "ARCHIVED" &&
          // @ts-ignore
          routes[user.data.role].urlAllowed?.loyalty?.includes("save") && (
            <Button
              type="button"
              variant="normal"
              onClick={() => handleSubmit()}
            >
              <Save />
              <p className="ml-2">Save</p>
            </Button>
          )}
        {loyaltyProgram?.state === "PAUSED"
          ? // @ts-ignore
            routes[user.data.role].urlAllowed?.loyalty?.includes("edit") && (
              <Button
                type="button"
                variant="active"
                onClick={() => handlePublishItem("ACTIVE")}
              >
                <Play />
                <p className="ml-2">Active</p>
              </Button>
            )
          : null}

        {loyaltyProgram?.state === "ACTIVE"
          ? // @ts-ignore
            routes[user.data.role].urlAllowed?.loyalty?.includes("edit") && (
              <Button
                type="button"
                variant="pause"
                onClick={() => handlePublishItem("PAUSED")}
              >
                <Pause />
                <p className="ml-2">Pause</p>
              </Button>
            )
          : null}
      </div>
    );
  };

  const checkStatus = () => {
    if (loyaltyProgram?.state) {
      if (loyaltyProgram?.state.toLowerCase() === "active") {
        return (
          <p className="text-sm font-medium text-green-500 capitalize">
            {loyaltyProgram?.state}
          </p>
        );
      } else if (loyaltyProgram?.state.toLowerCase() === "paused") {
        return (
          <p className="text-sm font-medium text-gray-500 capitalize">
            {loyaltyProgram?.state}
          </p>
        );
      } else if (loyaltyProgram?.state.toLowerCase() === "archived") {
        return (
          <p className="text-sm font-medium text-red-500 capitalize">
            {loyaltyProgram?.state}
          </p>
        );
      } else if (loyaltyProgram?.state.toLowerCase() === "pending_review") {
        return (
          <p className="text-sm font-medium text-yellow-500">Pending Review</p>
        );
      } else if (loyaltyProgram?.state.toLowerCase() === "declined") {
        return (
          <p className="text-sm font-medium text-red-500">
            {loyaltyProgram?.state}
          </p>
        );
      } else {
        return <p className="text-sm font-medium text-purple-500">Complete</p>;
      }
    }
  };

  const checkMinDate = () => {
    const today = new Date().toISOString();

    //today is bigger than End
    if (Date.parse(today) > Date.parse(loyaltyProgram?.startDate)) {
      return today.substring(0, 10);
    } else {
      // else return end date
      return loyaltyProgram?.startDate?.substring(0, 10);
    }
  };

  const handleDeleteReward = async () => {
    if (!modal?.employee?.data?.prizeId) return;

    const toastPromise = toast.promise(
      setDeleteItem({
        idReward: modal?.employee?.data?.prizeId,
      }),
      {
        pending: "Deleting reward",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting reward</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Reward deleted");
          dispatch(setOpenModal(false));
          dispatch(setType(""));
        }
      })
      .catch((err) => {
        toast.error("Error while deleting favorite filter");
      });
  };

  const checkHowManyActiveRewards = () => {
    return loyalityData?.rewards?.filter(
      (reward: any) => reward.reward.state === "ACTIVE"
    ).length;
  };

  const handleReturnText = () => {
    if (
      checkHowManyActiveRewards() > 1 &&
      modal?.employee?.data?.reward?.state === "ACTIVE"
    ) {
      return (
        <p className="text-sm mt-4 text-center">
          Are you sure you want to archive <b>{modal?.employee?.data?.name}</b>,
          any users that have gathered any stamps for this reward will be lost?
        </p>
      );
    } else if (
      checkHowManyActiveRewards() === 1 &&
      modal?.employee?.data?.reward?.state === "ACTIVE"
    ) {
      return (
        <p className="text-sm mt-4 text-center">
          You cannot archive this reward, there must be at least 1 active reward
          for an active loyalty program. Please pause the loyalty program first.
        </p>
      );
    } else {
      return (
        <p className="text-sm mt-4 text-center">
          Are you sure you want to archive <b>{modal?.employee?.data?.name}</b>?
        </p>
      );
    }
  };

  const handleArchive = async () => {
    console.log("Toast promise running");
    if (
      checkHowManyActiveRewards() === 1 &&
      modal?.employee?.data?.reward?.state === "ACTIVE"
    )
      return;

    console.log("Toast promise running");

    const toastPromise = toast.promise(
      changeStatusItem({
        idItem: modal?.employee?.data?.prizeId,
        idMerchant: modal?.employee?.data?.retailerId,
        status: "PAUSED",
      }),
      {}
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while archiving reward</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          const toastPromiseArchive = toast.promise(
            changeStatusItem({
              idItem: modal?.employee?.data?.prizeId,
              idMerchant: modal?.employee?.data?.retailerId,
              status: "ARCHIVED",
            }),
            {
              pending: "Archiving reward",
            }
          );

          toastPromiseArchive
            .then((res: any) => {
              if (res.error) {
                toast.error(
                  <div>
                    <h2>Error while archiving reward</h2>
                    <p className="text-xs"> {res?.error?.data?.message} </p>
                  </div>
                );
              } else {
                toast.success("Reward archived");
                dispatch(setOpenModal(false));
                dispatch(setType(""));
                dispatch(
                  setEmployeeSelectedToDelete({
                    id: "",
                    data: {},
                  })
                );
                dispatch(
                  itemApi.util.invalidateTags(["IndividualLoyaltyProgram"])
                );
              }
            })
            .catch((err) => {
              toast.error("Error while archiving reward");
            });
        }
      })
      .catch((err) => {
        toast.error("Error while pausing reward");
      });
  };

  return (
    <div className="flex mt-5 flex-wrap gap-5">
      <div className="bg-white p-5 xl:w-5/7 w-full">
        <div className="mb-5">
          <p
            onClick={() => redirectTo()}
            className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max"
          >
            <ArrowBack classes="h-5 w-5 mr-2" />
            Back to loyalty list
          </p>
        </div>
        <form action="" className="mb-8">
          <div className="gap-4 mb-3 flex flex-wrap max-w-full">
            <div className="w-full flex justify-between items-center">
              <Subtitle>Program Details</Subtitle>
              <div className="flex">
                {handleRenderButton()}
                <div className="flex items-center px-5 border-l-2 border-gray-200">
                  {checkStatus()}
                </div>
                {/* <Button
                  variant="danger"
                  type="button"
                  onClick={() => console.log("first")}
                >
                  <Archive classes="mr-2" /> Archive Program
                </Button> */}

                {/* <Badge title={"active"} onClick={() => console.log("Reward")} /> */}
              </div>
            </div>
            <div className="h-[1px] w-full bg-gray-200 rounded-lg"></div>
            <div className="w-full flex gap-4">
              <div className="md:w-full w-full">
                <div className="flex items-end">
                  <Input
                    label={"Loyalty Program ID"}
                    value={loyalityData?.loyaltyId}
                    disabled={true}
                    readOnly={true}
                    classes=""
                  />
                  <Copy
                    classes="mb-3 ml-3 cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        loyalityData?.loyaltyId.toString()
                      );
                      toast.success("Loyalty Program ID coppied to clipboard");
                    }}
                  />
                </div>
                <div>
                  <div className="flex justify-between  items-end mt-5">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-400"
                    >
                      Title
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <p className="font-medium text-sm text-gray-800">
                      {`${loyalityData.title.length}/92`}
                    </p>
                  </div>
                  <Input
                    label={""}
                    checkboxID="title"
                    placeholder="Provide loyalty program title"
                    value={loyalityData.title}
                    error={loyalityData.title.length === 0 && inputError}
                    maxLength={92}
                    max={92}
                    disabled={isInputDisabled}
                    onChange={(e: any) =>
                      setLoyalityData({
                        ...loyalityData,
                        title: e.target.value,
                      })
                    }
                    classes="min-w-[60%] mr-6 w-full"
                    alert={loyaltyProgram?.newData?.title ? true : false}
                    classesCheckbox="checkbox-cross"
                    handleClickCheckbox={handleClickNewDataCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                </div>
                <div className="mt-5">
                  <div className="flex justify-between  items-end">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-400"
                    >
                      Terms & Conditions
                    </label>
                    <p className="font-medium text-sm text-gray-800">{`${loyalityData.terms.length}/400`}</p>
                  </div>
                  <Textarea
                    label=""
                    checkboxID="terms"
                    disabled={isInputDisabled}
                    // error={!itemData.description && inputError ? true : false}
                    placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
                    classes="mb-5 w-full"
                    rows={8}
                    value={loyalityData.terms}
                    maxLength={400}
                    onChange={(e) =>
                      setLoyalityData({
                        ...loyalityData,
                        terms: e.target.value,
                      })
                    }
                    alert={loyaltyProgram?.newData?.terms ? true : false}
                    classesCheckbox="checkbox-cross"
                    handleClickCheckbox={handleClickNewDataCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex gap-3">
              <DatePicker
                label="Start Date"
                required
                disabled={isInputDisabled}
                checkboxID="startDate"
                value={loyalityData?.startDate?.substring(0, 10)}
                classes="ml-2 w-52"
                min={loyalityData?.startDate?.substring(0, 10)}
                max={loyalityData?.endDate?.substring(0, 10)}
                onChange={(e: any) =>
                  setLoyalityData({
                    ...loyalityData,
                    startDate: new Date(e.target.value)?.toISOString(),
                  })
                }
                alert={loyaltyProgram?.newData?.startDate ? true : false}
                classesCheckbox="checkbox-cross"
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              />
              <DatePicker
                label="End Date"
                required
                disabled={isInputDisabled}
                checkboxID="endDate"
                value={getNextDayEndDate(
                  loyalityData?.endDate,
                  true
                )?.substring(0, 10)}
                classes="ml-2 w-52"
                min={checkMinDate()}
                max={
                  getNextDayEndDate(loyaltyProgram?.endDate) ||
                  loyaltyProgram?.endDate?.substring(0, 10)
                }
                onChange={(e: any) =>
                  setLoyalityData({
                    ...loyalityData,
                    endDate: new Date(e.target.value)?.toISOString(),
                  })
                }
                alert={loyaltyProgram?.newData?.endDate ? true : false}
                classesCheckbox="checkbox-cross"
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              />
            </div>
            <div className="w-full mt-4">
              <div className="w-full flex justify-between items-center">
                <Subtitle>Reward Details</Subtitle>
                <Button
                  variant={
                    loyalityData.rewards.length >= 3 || isInputDisabled
                      ? "disabled"
                      : "add"
                  }
                  type="button"
                  onClick={
                    loyalityData.rewards.length >= 3 || isInputDisabled
                      ? () => {}
                      : handleNavigateItem
                  }
                >
                  + Add reward
                </Button>
              </div>
              <div className="h-[1px] w-full bg-gray-200 rounded-lg mt-5"></div>
              {
                // Map through rewards and show them
                loyalityData?.rewards?.length ? (
                  loyalityData?.rewards?.map((reward: any, index: number) => (
                    <RewardCard
                      reward={reward}
                      key={index}
                      index={index}
                      loyalityData={loyalityData}
                    />
                  ))
                ) : (
                  <div className="h-full flex flex-col justify-center items-center gap-2 py-5">
                    <h2 className="font-semibold text-lg">Not Rewards Found</h2>
                    <p>
                      Click in Add Rewards button to create and associate
                      Rewards to this Loyalty Program
                    </p>
                  </div>
                )
              }
            </div>
          </div>
        </form>
      </div>
      <Dropzone
        required
        label="Reward Loyalty Program cover"
        handleUploadPhoto={handleUploadPhoto}
        sizePlaceholder={"1:0.4 @ 331x 140"}
        classesBorder={`${!loyaltyImage && inputError && "!border-red-500"}`}
        type="SMALL"
        id="image#SMALL"
        disabledInput={isInputDisabled}
        image={loyaltyImage}
        imageSetter={setLoyaltyImage}
        imageCropModalType={imageCropModalType}
        setImageCropModalType={setImageCropModalType}
        sizesContainer={{
          minWidth: "260px",
        }}
        sizes={{width: 662, height: 280}}
        aspect={331 / 140}
        placeholder={
          <div className="flex flex-col items-end gap-1">
            <p>Add Loyalty program card</p> <p>1:0.4 @ 331 x 140 </p>{" "}
            <p>Maximum size: 8mb</p>
            <p> Support format: PNG,JPEG</p>
          </div>
        }
        alert={loyaltyProgram?.images.find((image: any) => {
          if (image.type.toLowerCase() === "small") {
            if (
              image.hasOwnProperty("newName") ||
              image.hasOwnProperty("newUrl")
            ) {
              return true;
            }
          }
        })}
        classesCheckbox="checkbox-cross"
        handleClickCheckbox={handleClickNewDataCheckbox}
        checkedValues={newDataCheckbox}
      />
      {modal.type === "deleteLoyaltyy" && (
        <DeleteModal
          elementsChecked={[]}
          setElementsChecked={() => {}}
          dataSource={[]}
          matchWidhId="categoryId"
          pendingMsg="Deleting loyalty"
          returnValue="name"
          toastMessageId="name"
          typeOfDelete="item"
          deleteCallback={handleDeleteItem}
          nameItem={loyalityData.title}
        />
      )}
      {modal.type === "archiveLoyalty" && (
        <ArchiveModal
          elementsChecked={[loyalityData.loyaltyId]}
          setElementsChecked={() => {}}
          promise={archiveLoyalty}
          type={"Loyalty Program"}
        />
      )}
      {modal.type === "recoverLoyalty" && (
        <RecoveryModal
          elementsChecked={[loyalityData.loyaltyId]}
          setElementsChecked={() => {}}
          promise={changeStatusLoyaltyMutation}
          type={"Loyalty Program"}
        />
      )}
      {modal.type === "declineModalLoyalty" && (
        <DeclineModal
          reason={reason}
          setReason={setReason}
          inputError={inputError}
          isLoadingDeclineReasons={isLoadingDeclineReasons}
          isErrorDeclineReasons={isErrorDeclineReasons}
          declineReasons={declineReasons}
          declinedItems={declinedItems}
          handleFormatElement={handleFormatElement}
          reasonDescription={reasonDescription}
          setReasonDescription={setReasonDescription}
          handleSendDeclineReason={handleSendDeclineReason}
        />
      )}
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded">
            <Dialog.Overlay />
            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
      {modal.type === "deleteReward" && (
        <DeleteModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          dataSource={[]}
          matchWidhId="categoryId"
          pendingMsg="Deleting reward"
          returnValue="name"
          toastMessageId="name"
          type="reward"
          typeOfDelete="reward"
          deleteCallback={handleDeleteReward}
          nameItem={modal?.employee?.data?.name}
        />
      )}
      {modal.type === "archiveReward" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                  <Archive classes="text-red-500 h-10 w-10" />
                </div>
                <h1 className="text-red-500 mt-4">Archive</h1>
                {handleReturnText()}
              </div>
              <hr />
              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  type="button"
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant={
                    checkHowManyActiveRewards() === 1 &&
                    modal?.employee?.data?.reward?.state === "ACTIVE"
                      ? "disabled"
                      : "danger"
                  }
                  onClick={handleArchive}
                >
                  Archive
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LoyaltyDetails;
