import React, { useState } from "react";

interface FormTypes {
  children: React.ReactNode;
}

function Form({ children }: FormTypes) {
  return (
    <div className="overflow-x-auto relative sm:rounded-lg">{children}</div>
  );
}

export default Form;
