import {useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";

// Components
import Logo from "components/Common/Logo";
import ListItem from "./ListItem";
import ProfileItem from "./ProfileItem";

// Assets
import {ArrowPrev} from "assets/Arrows";

function Sidebar() {
  const [hide, setHide] = useState<boolean>(false);

  useHotkeys("alt+h", () => setHide((prev) => !prev));

  return (
    <aside
      id="sidebar"
      className={`relative transform xl:translate-x-0 ease-in-out transition duration-500 ${
        !hide ? "min-w-max max-w-max w-full sm:w-80" : "w-auto"
      } flex justify-start items-start h-full  bg-gray-900 flex-col`}
    >
      <button
        className="absolute text-white -right-6 top-0 bg-gray-900 rounded-br h-7"
        onClick={() => setHide((prev) => !prev)}
        aria-label="open close sidebar"
      >
        <ArrowPrev
          classes={`w-6 h-6 transform ease-in-out transition duration-200 ${
            hide && "rotate-180"
          }`}
        />
      </button>
      <div className="sticky top-0 w-full">
        <Logo hide={hide} />
        <ListItem hide={hide} />
      </div>
      <ProfileItem hide={hide} />
    </aside>
  );
}

export default Sidebar;
