interface LabelTypes {
  children: string;
  classes?: string;
}

function Label({ children, classes }: LabelTypes) {
  return (
    <p className={`block mb-2 text-sm font-medium text-gray-400 ${classes}`}>
      {children}
    </p>
  );
}

export default Label;
