import Alert from "assets/Alert";
import {ChangeEventHandler} from "react";
import Checkbox from "../Checkbox";

interface TextareaTypes {
  inputID?: string;
  checkboxID?: string;
  rows?: number;
  label: string;
  hasLabel?: boolean;
  secondLabel?: string | JSX.Element;
  value: string | number;
  disabled?: boolean;
  required?: boolean;
  alert?: boolean;
  readOnly?: boolean;
  classes?: string;
  classesLabel?: string;
  classesInput?: string;
  classesCheckbox?: string;
  error?: boolean;
  placeholder?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  handleClickCheckbox?: any;
  checkedValues?: string[];
}

function Textarea({
  inputID,
  checkboxID,
  rows = 5,
  label = "default label",
  hasLabel = true,
  secondLabel,
  value,
  disabled = false,
  required = false,
  alert = false,
  classes = "",
  classesLabel = "",
  classesInput = "",
  classesCheckbox = "",
  error = false,
  placeholder = "-",
  maxLength,
  onChange,
  handleClickCheckbox,
  checkedValues,
}: TextareaTypes) {
  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && error) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !error) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  return (
    <div className={classes}>
      {hasLabel && (
        <div className="mb-2 flex items-center direction-row justify-between">
          <label
            htmlFor={label.replace(/\s/g, "")}
            className={`block text-sm font-medium  ${
              classesLabel.length === 0 ? "text-gray-400" : classesLabel
            }`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </label>
          {secondLabel && <label className="flex">{secondLabel}</label>}
          {alert && label !== "Items in wheel" && label !== "Items in shop" && (
            <div className="flex">
              <Alert classes="h-4 w-4 mr-2" />
              <Checkbox
                className={classesCheckbox}
                id={checkboxID || label.replace(/\s/g, "")}
                key={checkboxID || label.replace(/\s/g, "")}
                type="checkbox"
                name={checkboxID || label.replace(/\s/g, "")}
                onChange={(e: any) => handleClickCheckbox(e)}
                isChecked={checkedValues?.includes(
                  checkboxID || label.replace(/\s/g, "")
                )}
              />
            </div>
          )}
        </div>
      )}
      <div className="relative">
        <textarea
          className={`w-full rounded border p-2 ${classesInput}
          ${checkClassName()}`}
          name={inputID || label.replace(/\s/g, "")}
          id={inputID || label.replace(/\s/g, "")}
          cols={30}
          rows={rows}
          maxLength={maxLength}
          value={value}
          required={required}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
        ></textarea>
      </div>
    </div>
  );
}

export default Textarea;
