import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Components
import Badge from "components/Badge";
import Title from "components/Common/Title";
import Button from "components/Common/Button";

// Assets
import {ArrowBack} from "assets/Arrows";
import Archive from "assets/Archive";

// Redux
import {useDispatch} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import Label from "components/Common/Label";
import Recovery from "assets/Recovery";
import Save from "assets/Save";
import {useGetAdminByIDQuery} from "store/api/adminApi";

function AdminProfile() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [authorities, setAuthorities] = useState([
    {name: "Creation of Retailers", key: 1, permission: false},
    {name: "Change retailer details", key: 2, permission: false},
    {name: "Add prize", key: 3, permission: false},
    {name: "Change prize details", key: 4, permission: false},
    {name: "Change wheel probability", key: 5, permission: false},
    {name: "Change global settings", key: 6, permission: false},
  ]);

  const {
    data: dataAdminByID,
    isLoading: isLoadingAdminByID,
    isError: isErrorAdminByID,
    refetch,
  } = useGetAdminByIDQuery(id);

  // useEffect(() => {
  //   console.log(dataAdminByID, isErrorAdminByID);
  // }, [dataAdminByID, isErrorAdminByID]);

  const handleOpenModal = (state: string) => {
    if (state === "archive") {
      dispatch(setType("archiveAdmin"));
      dispatch(setOpenModal(true));
    } else {
      dispatch(setType("recoveryAdmin"));
      dispatch(setOpenModal(true));
    }
  };

  const handleRenderButton = () => {
    return (
      <div className="flex">
        {/* dataAdminByID?.status */}
        {true ? (
          <Button variant="recovery" onClick={() => handleOpenModal("recover")}>
            <Recovery />
            <p className="ml-2">Recover Profile</p>
          </Button>
        ) : (
          <Button variant="danger" onClick={() => handleOpenModal("archive")}>
            <Archive />
            <p className="ml-2">Archive Profile</p>
          </Button>
        )}
      </div>
    );
  };

  const handleChangePermissions = (key: string | number, value: boolean) => {
    console.log(value);

    const newAuthorities = authorities.map((obj: any) => {
      if (obj.key === key) {
        return {...obj, permission: value};
      }
      return obj;
    });

    setAuthorities(newAuthorities);
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <p
          onClick={() => navigate(`/admins`)}
          className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to admins list
        </p>
      </div>
      <div className="flex justify-between items-baseline">
        <Title classes="flex items-center">
          {/* <p className="mr-3">{dataMerchantByID?.name}</p>
        <Badge title={dataMerchantByID?.status.toLocaleLowerCase()}></Badge> */}
          <p className="mr-3">Admin Name</p>
          <Badge title="admin"></Badge>
        </Title>

        {handleRenderButton()}
      </div>
      <div className="mt-5 w-full sm:w-10/12  bg-white rounded-sm">
        <div className="flex justify-between pl-5 pr-20 py-5">
          <div>
            <Label>Email</Label>
            <p className="text-sm">superadmin@gmail.com</p>
          </div>
          <div>
            <Label>Phone</Label>
            <p className="text-sm">+1 900 9213 21 321</p>
          </div>
          <div>
            <Label>Location</Label>
            <p className="text-sm">New York, USA</p>
          </div>
        </div>
        <hr className="pb-5 mx-5 " />
        <div className="grid grid-cols-2">
          <div>
            <div className="h-10 text-gray-700  bg-gray-100 flex items-center">
              <p className="px-4 font-base normal-case text-gray-400">
                Authorities
              </p>
            </div>
            {authorities.map((auth) => (
              <div key={auth.key} className="border-b-2 border-slate-200 h-12">
                <p className="pl-4 py-3 text-sm">{auth.name}</p>
              </div>
            ))}
          </div>
          <div>
            <div>
              <div className="h-10 text-gray-700  bg-gray-100 flex items-center">
                <p className="px-4 font-medium normal-case text-gray-400">
                  Permission
                </p>
              </div>
              {authorities.map((auth) => (
                <div
                  key={auth.key}
                  className="border-b-2 border-slate-200 h-12"
                >
                  <div className="pl-4 py-3 flex">
                    <div className="flex items-center mr-4">
                      <input
                        type="radio"
                        name={`${auth.key}`}
                        id={`${auth.key}`}
                        checked={auth.permission ? true : false}
                        onChange={() => handleChangePermissions(auth.key, true)}
                        className="w-4 h-4 accent-purple-600"
                      />
                      <label
                        className="ml-2 text-sm font-medium text-gray-900"
                        htmlFor={`${auth.key}`}
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name={`-${auth.key}`}
                        id={`-${auth.key}`}
                        checked={!auth.permission ? true : false}
                        onChange={() =>
                          handleChangePermissions(auth.key, false)
                        }
                        className="w-4 h-4 accent-purple-600"
                      />
                      <label
                        htmlFor={`-${auth.key}`}
                        className="ml-2 text-sm font-medium text-gray-900"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminProfile;
