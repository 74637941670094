
export const TABLE_MERCHANT_ACTUAL_HEADER = [
    { label: "ID", accessor: "id", sortable: true },
    { label: "Name", accessor: "name", sortable: true },
    { label: "Zip code", accessor: "zip_code" },
    { label: "Data of creation", accessor: "created_at", sortable: true },
    { label: "Items", accessor: "itemsCount" },
    { label: "Subscribers", accessor: "subscriberCount" },
    { label: "Last Activity", accessor: "lastActivityDate" },
    { label: "Status", accessor: "status", sortable: true },
];

export const TABLE_MERCHANT_ARCHIVED_HEADER = [
    { label: "ID", accessor: "id", sortable: true },
    { label: "Name", accessor: "name", sortable: true },
    { label: "Zip code", accessor: "zip_code" },
    { label: "Data of creation", accessor: "created_at", sortable: true },
    { label: "Items", accessor: "items" },
    { label: "Last Activity", accessor: "lastActivityDate" },
    { label: "Archived Date", accessor: "archivedAt" },
    { label: "Status", accessor: "status" },
];

export const TABLE_MERCHANT_GRAND_PRIZE_ITEMS_HEADER = [
    { label: "Item ID", accessor: "prizeId", sortable: true },
    { label: "Type", accessor: "prizeType", sortable: true },
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Start Quantity", accessor: "prizeCount", sortable: true },
    { label: "Qty. Remaining", accessor: "remaining", sortable: true },
    { label: "Claimed", accessor: "claimed", sortable: true },
    { label: "Redeemed", accessor: "redeemed", sortable: true },
    { label: "Created", accessor: "startDate" },
    { label: "Exp. Date", accessor: "endDate" },
    { label: "Expiration", accessor: "daysToExpire" },
    { label: "Status", accessor: "state", sortable: true },
];

export const TABLE_MERCHANT_ITEMS_HEADER = [
    { label: "Item ID", accessor: "id", sortable: true },
    { label: "Type", accessor: "prize_type", sortable: true },
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Start Quantity", accessor: "prize_count", sortable: true },
    { label: "Qty. Remaining", accessor: "remaining", sortable: true },
    { label: "Claimed", accessor: "claimed", sortable: true },
    { label: "Redeemed", accessor: "redeemed", sortable: true },
    { label: "Created", accessor: "created_at", sortable: true },
    { label: "Exp. Date", accessor: "expired_at", sortable: true },
    { label: "Expiration", accessor: "days", sortable: true },
    { label: "Status", accessor: "state", sortable: true },
];

export const TABLE_MERCHANT_LOYALITY_HEADER = [
    { label: "Program Name", accessor: "title" },
    { label: "Program ID", accessor: "loyaltyId", sortable: true },
    { label: "Start", accessor: "startDate", sortable: true },
    { label: "End", accessor: "endDate", sortable: true },
    { label: "Subscribers", accessor: "customerCount", sortable: true },
    { label: "Claims(R1/R2/R3)", accessor: "claimed", sortable: true },
    { label: "Redemptions(R1/R2/R3)", accessor: "redeemed", sortable: true },
    { label: "Status", accessor: "state", sortable: true },
];


export const TABLE_MERCHANT_ITEMS_SUBHEADER = [
    "Item ID",
    "Dates",
    "Week Ending",
    "Total Active",
    "Quantity",
    "Shop",
    "Wheel",
    "Claimed",
    "Reedemed",
    "Qty. Remaining",
];


// export const TABLE_MERCHANT_ITEMS_SUBHEADER = [
//     { label: "Item ID", accessor: "item_id" },
//     { label: "Dates", accessor: "dates" },
//     { label: "User Exp. Date", accessor: "user_exp_date", sortable: true },
//     { label: "Total Active", accessor: "total_active" },
//     { label: "Quantity", accessor: "quantity", sortable: true },
//     { label: "Shop", accessor: "shop", sortable: true },
//     { label: "Wheel", accessor: "wheel", sortable: true },
//     { label: "Claimed", accessor: "claimed", sortable: true },
//     { label: "Redeemed", accessor: "redeemed", sortable: true },
//     { label: "Qty. Remaining", accessor: "qty_remaining", sortable: true }
// ];


export const TABLE_MERCHANT_EMPLOYEE_LIST = [
    { label: "Email", accessor: "email" },
    { label: "Created", accessor: "createdAt", sortable: true },
    { label: "Role", accessor: "role" },
    { label: "Status", accessor: "status" },
    { label: "Last Visit", accessor: "lastLogin", sortable: true },
]

export const TABLE_MERCHANT_ACTIVITY = [
    { label: "Member Name", accessor: "member_name" },
    { label: "Details", accessor: "details" },
    { label: "Time", accessor: "time" },
    { label: "Activity", accessor: "activity" },
]