import {useEffect} from "react";

// Components
import Select from "components/Common/Input/Select";

// Redux
import type {RootState} from "store/store";
import {useLazyGetAllMerchantsQuery} from "store/api/merchantsApi";
import {useDispatch, useSelector} from "react-redux";
import {
  setLimit,
  setPage,
  setResults,
  updateStatus,
} from "store/filters/filtersSlice";
import {setLastVisitedPage} from "store/tabs/tabsSlice";

interface NumerationType {
  amountToSee?: number;
  setAmountToSee: Function;
  setTableData: any;
  urlPagination?: {origin: string; url: string};
}

function Numeration({
  amountToSee,
  setAmountToSee,
  setTableData,
  urlPagination,
}: NumerationType) {
  const dispatch = useDispatch();

  const actualLimit = useSelector(
    (state: RootState) => state.filters.filters.limit
  );

  const handleSelectAmountToSee = (e: any) => {
    console.log("Check");
    dispatch(setLimit(`&limit=${e.target.value}`));
    dispatch(setPage(`&page=1`));
    dispatch(setLastVisitedPage(""));

    setAmountToSee(e.target.value);
  };

  return (
    <Select
      id="numeration"
      classes="flex items-center"
      label="Shows: "
      classesSelect="py-2 px-2  ml-4"
      onChange={(e: any) => handleSelectAmountToSee(e)}
      value={actualLimit.split("=")[1]}
    >
      <>
        <option defaultValue="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="999999999">All</option>
      </>
    </Select>
  );
}

export default Numeration;
