// Routes
import {Link, useLocation} from "react-router-dom";
import {
  setTabIndex,
  setLastVisitedPage,
  setSubMerchantTabIndex,
} from "store/tabs/tabsSlice";

import {useDispatch, useSelector} from "react-redux";
import {setActualTab} from "store/user/userSlice";
import {
  setFilters,
  setFiltersLastSearch,
  setSorting,
  setStatus,
  setURLFilters,
  setURLSearch,
} from "store/filters/filtersSlice";
import {RootState} from "store/store";
interface ItemTypes {
  itemName: string;
  svg: JSX.Element;
  url: string;
  hide: boolean;
}

function Item({itemName, svg, url, hide}: ItemTypes) {
  const dispatch = useDispatch();
  const location = useLocation();

  const filters = useSelector((state: RootState) => state.filters.filters);

  // Validatiing only the first param letter to indentify link that need to be colored
  return (
    <li
      className={`${
        hide && "flex flex-col justify-center items-center"
      } w-full`}
    >
      <Link
        to={`${url}`}
        className={`${
          hide ? "flex-col space-x-0 h-auto py-2 px-2" : "space-x-6 h-14"
        } flex jusitfy-start items-center w-full  text-white  transition ease-in-out delay-50 ${
          location.pathname[1] + location.pathname[2] === url[0] + url[1] ||
          (location.pathname === "/" && itemName === "Dashboard")
            ? "bg-primary-purple"
            : ""
        } ${
          location.pathname[1] !== url[0] &&
          "hover:text-primary-purple hover:bg-primary-transparent"
        }`}
        onClick={(e: any) => {
          // Check if current path if it is different that the one is coming in params.pathname
          if (url === filters.origin) return;

          dispatch(setActualTab(""));
          dispatch(setLastVisitedPage(""));
          dispatch(setTabIndex(""));
          dispatch(setSubMerchantTabIndex(""));
          dispatch(setStatus([]));
          dispatch(setSorting("ordering=id"));
          dispatch(setURLFilters(""));
          dispatch(setURLSearch(""));
          dispatch(setFilters([]));
          dispatch(setFiltersLastSearch([]));
        }}
      >
        {svg}
        <p className={`${hide ? "text-xs mt-1" : "text-base"} leading-4 `}>
          {itemName}
        </p>
      </Link>
    </li>
  );
}

export default Item;
