import { TypeClasses } from "./types";

function Save({ classes }: TypeClasses) {
  return (
    <svg
      id="save"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="save_FILL0_wght400_GRAD0_opsz48"
        fill="currentColor"
        d="M24,9.925V22.5A1.538,1.538,0,0,1,22.5,24H7.5A1.538,1.538,0,0,1,6,22.5V7.5A1.538,1.538,0,0,1,7.5,6H20.075Zm-1.5.675L19.4,7.5H7.5v15h15ZM15,20.875a2.6,2.6,0,0,0,1.838-4.437,2.6,2.6,0,1,0-3.675,3.675A2.5,2.5,0,0,0,15,20.875ZM8.825,12.4h8.95V8.825H8.825ZM7.5,10.6v0Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
}

export default Save;
