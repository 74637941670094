import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import DropdownCategory from "components/Common/DropdownCategory";
import Input from "components/Common/Input";
import Subtitle from "components/Common/Subtitle";
import Textarea from "components/Common/Textarea";
import Title from "components/Common/Title";

// Utils
import routes from "utils/routesByRole";

// Store
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {setPage, setURLFilters} from "store/filters/filtersSlice";
import {RootState} from "store/store";

// Assets
import AddPerson from "assets/AddPerson";
import {ArrowBack} from "assets/Arrows";
import DatePicker from "components/Common/Input/DatePicker";
import Dropzone from "components/Common/Input/Dropzone";
import {setSubMerchantTabIndex} from "store/tabs/tabsSlice";
import {
  useCreateLoyaltyProgramMutation,
  useUpdateLoyaltyProgramImageMutation,
} from "store/api/itemApi";
import {merchantsApi} from "store/api/merchantsApi";
import Modal from "components/Modal";
import {Dialog} from "@headlessui/react";
import {getNextDayEndDate} from "utils/addDays";

// Create types for the loyalityData with title, terms, categories, startDate, endDate, rewards (array of object), inside rewards the object will contain, rewardName, stampNeeded, rewardQuality, noLimit, shortDescription, rewardPhoto
type LoyalityData = {
  title: string;
  terms: string;
  startDate: string;
  endDate: string;
};

function AddLoyality() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {id} = useParams();

  const user = useSelector((state: RootState) => state.user.user.user);
  const modal = useSelector((state: RootState) => state.modal);

  const [loyalityData, setLoyalityData] = useState<LoyalityData>({
    title: "",
    terms: "",
    startDate: location?.state?.merchantInfo?.loyaltyStartDate,
    endDate: location?.state?.merchantInfo?.loyaltyEndDate,
  });

  const [inputError, setInputError] = useState(false);

  const [loyaltyImage, setLoyaltyImage] = useState<string>("");
  const [loyalityImageFormData, setLoyaltyImageFormData] =
    useState<FormData | null>(null);

  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.loyalty?.includes("add")
      ? false
      : true;
  });

  const [createLoyaltyProgram] = useCreateLoyaltyProgramMutation();
  const [updateImageLoyaltyProgram] = useUpdateLoyaltyProgramImageMutation();

  const redirectTo = () => {
    dispatch(setPage("&page=1"));
    dispatch(setURLFilters(""));
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (
      !loyalityData.title ||
      !loyalityData.terms ||
      !loyalityData.endDate ||
      !loyaltyImage
    ) {
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    const data = {
      title: loyalityData.title,
      terms: loyalityData.terms,
      endDate: new Date(loyalityData.endDate).toISOString(),
      // @ts-ignore
      retailerId: parseInt(id),
    };

    const toastPromise = toast.promise(
      createLoyaltyProgram({
        data,
      }),
      {
        pending: "Creating Loyalty Program",
      }
    );
    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating loyalty program</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Loyalty Program Created");

          const idLoyaltyProgram = res?.data?.loyaltyId;

          if (loyalityImageFormData) {
            const toastPromise = toast.promise(
              updateImageLoyaltyProgram({
                idLoyalty: idLoyaltyProgram,
                data: loyalityImageFormData,
              }),
              {
                pending: "Uploading Loyalty Program image",
              }
            );
            toastPromise
              .then((res: any) => {
                if (res.error) {
                  toast.error(
                    <div>
                      <h2>Error while updating loyalty program image</h2>
                      <p className="text-xs"> {res?.error?.data?.code} </p>
                    </div>
                  );
                } else {
                  toast.success("Loyalty Program image uploaded");
                  merchantsApi.util.invalidateTags(["IndividualMerchant"]);

                  setTimeout(() => {
                    dispatch(setSubMerchantTabIndex("3"));
                    setTimeout(() => {
                      navigate(`/merchants/${id}`);
                    }, 2000);
                  }, 100);
                }
              })
              .catch((err) => {
                toast.error("Error while uploading loyalty program image");
              });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while uploading loyalty program");
      });
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();

    if (typeof data !== "string") {
      formData.append("image", data);
    } else {
      formData.append("imageUrl", data);
    }
    formData.append("name", data.name);
    formData.append("type", type);

    let imgUrl;

    if (typeof data !== "string") {
      imgUrl = URL.createObjectURL(data);
    } else {
      imgUrl = data;
    }

    showImage(imgUrl);

    // set form data in image inside lotalty data
    setLoyaltyImageFormData(formData);
    // setObjectImages({...objectImages, logoLarge: formData});
  };

  const getRoundedDate = () => {
    if (!loyalityData?.endDate) return "";

    const originalDate = new Date(loyalityData?.endDate);

    // Add one second to the original date
    originalDate.setSeconds(originalDate.getSeconds() + 12000);

    // Format the modified date as a string in ISO 8601 format
    return originalDate.toISOString().substring(0, 10);
  };

  const compareDates = (d1: any) => {
    let startDate = new Date(d1);
    let today = new Date();

    if (startDate < today) {
      return today?.toISOString()?.substring(0, 10);
    } else if (startDate > today) {
      return startDate?.toISOString()?.substring(0, 10);
    } else {
      return startDate?.toISOString()?.substring(0, 10);
    }
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <p
          onClick={() => redirectTo()}
          className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to loyalty list
        </p>
      </div>
      <div className="flex justify-between">
        <Title classes="flex items-center">Create Loyalty Program</Title>
        <Button
          variant={isInputDisabled ? "disabled" : "add"}
          onClick={isInputDisabled ? () => {} : () => handleSubmit()}
        >
          <AddPerson classes="mr-2" />
          Create program
        </Button>
      </div>
      <div className="flex mt-5 flex-wrap gap-5">
        <div className="bg-white p-5 xl:w-5/7 w-full">
          <form action="" className="mb-8">
            <div className="gap-4 mb-3 flex flex-wrap max-w-full">
              <Subtitle>Program Details</Subtitle>
              <hr />
              <div className="w-full flex gap-4">
                <div className="md:w-full w-full">
                  <div>
                    <div className="flex justify-between  items-end">
                      <div className="flex">
                        <label
                          htmlFor=""
                          className="block text-sm font-medium text-gray-400"
                        >
                          Title
                        </label>
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                      <p className="font-medium text-sm text-gray-800">{`${loyalityData.title.length}/92`}</p>
                    </div>
                    <Input
                      label={""}
                      placeholder="Provide loyalty program title"
                      value={loyalityData.title}
                      error={loyalityData.title.length === 0 && inputError}
                      maxLength={92}
                      max={92}
                      disabled={isInputDisabled}
                      onChange={(e: any) =>
                        setLoyalityData({
                          ...loyalityData,
                          title: e.target.value,
                        })
                      }
                      classes="min-w-[60%] mr-6 w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <div className="flex justify-between  items-end">
                      <div className="flex">
                        <label
                          htmlFor=""
                          className="block text-sm font-medium text-gray-400"
                        >
                          Terms & Conditions
                        </label>
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                      <p className="font-medium text-sm text-gray-800">{`${loyalityData.terms.length}/400`}</p>
                    </div>
                    <Textarea
                      label=""
                      // error={!itemData.description && inputError ? true : false}
                      placeholder="A Terms and Conditions agreement (T&Cs) is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to use or access your website and mobile app."
                      classes="mb-5 w-full"
                      rows={8}
                      value={loyalityData.terms}
                      error={loyalityData?.terms?.length === 0 && inputError}
                      maxLength={400}
                      onChange={(e) =>
                        setLoyalityData({
                          ...loyalityData,
                          terms: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-3">
                <DatePicker
                  label="Loyalty program end date"
                  required
                  error={loyalityData?.endDate.length === 0 && inputError}
                  value={getRoundedDate() || ""}
                  classes="ml-2 w-52"
                  min={compareDates(
                    location?.state?.merchantInfo?.loyaltyStartDate?.substring(
                      0,
                      10
                    )
                  )}
                  max={
                    getNextDayEndDate(
                      location?.state?.merchantInfo?.loyaltyEndDate
                    ) ||
                    location?.state?.merchantInfo?.loyaltyEndDate?.substring(
                      0,
                      10
                    )
                  }
                  onChange={(e: any) =>
                    setLoyalityData({
                      ...loyalityData,
                      endDate: e.target.value,
                    })
                  }
                />
                <p
                  className="text-xs text-blue-400 w-52 mt-2 cursor-pointer"
                  onClick={() => {
                    dispatch(setSubMerchantTabIndex("0"));
                    setTimeout(() => {
                      navigate(-1);
                    }, 10);
                  }}
                >
                  * In order to change this expiration date, go to merchant page
                  to update expiration date for loyalty program
                </p>
              </div>
            </div>
          </form>
        </div>
        <Dropzone
          required
          label="Loyalty Program cover"
          handleUploadPhoto={handleUploadPhoto}
          sizePlaceholder={"1:0.4 @ 331x 140"}
          classesBorder={`${!loyaltyImage && inputError && "!border-red-500"}`}
          type="SMALL"
          image={loyaltyImage}
          imageSetter={setLoyaltyImage}
          id="loyalty_image"
          imageCropModalType={imageCropModalType}
          setImageCropModalType={setImageCropModalType}
          sizesContainer={{
            minWidth: "260px",
          }}
          sizes={{width: 662, height: 280}}
          aspect={331 / 140}
          placeholder={
            <div className="flex flex-col items-end gap-1">
              <p>Add Loyalty program card</p> <p>1:0.4 @ 331 x 140 </p>{" "}
              <p>Maximum size: 8mb</p>
              <p> Support format: PNG,JPEG</p>
            </div>
          }
        />
      </div>
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded">
            <Dialog.Overlay />
            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
    </section>
  );
}

export default AddLoyality;
