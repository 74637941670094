import React, {useState} from "react";

// Components
import Button from "components/Common/Button";
import Title from "components/Common/Title";
import Input from "components/Common/Input";
import Select from "components/Common/Input/Select";
import Table from "components/Table/Table";
import Subtitle from "components/Common/Subtitle";

// Assets
import {ArrowBack} from "assets/Arrows";
import Save from "assets/Save";
import Recovery from "assets/Recovery";
import Archive from "assets/Archive";

// Redux
import {useDispatch} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";

// Utils
import {TABLE_USERS_PROFILE_HEADER} from "utils/userHeaders";

function UserProfile() {
  const dispatch = useDispatch();
  const [actualTab, setActualTab] = useState("General");
  const handleSaveUser = () => {};

  const handleOpenModal = (state: string) => {
    if (state === "archive") {
      dispatch(setType("archiveUser"));
      dispatch(setOpenModal(true));
    } else {
      dispatch(setType("recoveryUser"));
      dispatch(setOpenModal(true));
    }
  };

  const handleRenderButton = () => {
    return (
      <div className="flex">
        {/* dataAdminByID?.status */}
        {true ? (
          <Button variant="recovery" onClick={() => handleOpenModal("recover")}>
            <Recovery />
            <p className="ml-2">Recover Profile</p>
          </Button>
        ) : (
          <Button variant="danger" onClick={() => handleOpenModal("archive")}>
            <Archive />
            <p className="ml-2">Archive Profile</p>
          </Button>
        )}
      </div>
    );
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <p className="text-sm text-gray-400 flex hover:text-primary-purple cursor-pointer max-w-max">
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to users list
        </p>
      </div>
      <div className="flex justify-between items-baseline">
        <Title classes="flex items-center">User Name</Title>
        {handleRenderButton()}
      </div>
      <div className="mt-5 w-full sm:w-10/12  bg-white rounded-sm">
        <div className="flex justify-between items-center px-5 pb-2">
          <div>
            <Subtitle classes={"py-5 flex"}>User Details</Subtitle>
            {/* Put loading */}
          </div>
          <Button variant="normal" onClick={() => handleSaveUser()}>
            <Save />
            <p className="ml-2">Save</p>
          </Button>
        </div>
        <hr className="pb-5 mx-5" />
        <div className="flex flex-col justify-between pl-5 pr-20 pb-6">
          <div className="mb-7">
            <p className="text-sm font-medium mb-3">Gender:</p>
            <div className="flex items-center gap-7">
              <input
                type="radio"
                name="male"
                id="male"
                // checked={!auth.permission ? true : false}
                // onChange={() =>
                //   handleChangePermissions(auth.key, false)
                // }
                className="w-4 h-4 accent-purple-600"
              />
              <label
                htmlFor="male"
                className="-ml-5 text-sm font-medium text-gray-900"
              >
                Male
              </label>
              <input
                type="radio"
                name="female"
                id="female"
                // checked={!auth.permission ? true : false}
                // onChange={() =>
                //   handleChangePermissions(auth.key, false)
                // }
                className="w-4 h-4 accent-purple-600"
              />
              <label
                htmlFor="female"
                className="-ml-5 text-sm font-medium text-gray-900"
              >
                Female
              </label>
              <input
                type="radio"
                name="other"
                id="other"
                // checked={!auth.permission ? true : false}
                // onChange={() =>
                //   handleChangePermissions(auth.key, false)
                // }
                className="w-4 h-4 accent-purple-600"
              />
              <label
                htmlFor="other"
                className="-ml-5 text-sm font-medium text-gray-900"
              >
                Other
              </label>
            </div>
          </div>
          <div className="flex flex-col w-100">
            {/* First 4 elements */}
            <div className="flex w-100 gap-6 flex-wrap mb-5">
              <div>
                <Input value={"1"} label="User ID" classesInput="w-auto" />
              </div>
              <div>
                <Input
                  value={"superadmin@gmail.com"}
                  label="Email"
                  classesInput="w-auto"
                />
              </div>
              <div>
                <Input
                  value={"25 Gvoove st."}
                  label="Address"
                  classesInput="w-auto"
                />
              </div>
              <div>
                <Input value={"32"} label="Age" classesInput="w-auto" />
              </div>
            </div>

            {/* Second 4 elements */}
            <div className="flex w-100 gap-6 flex-wrap">
              <div>
                <Input
                  value={"+1 900 9213 21 321"}
                  label="Phone"
                  classesInput="w-auto"
                />
              </div>
              <div>
                <Input
                  value={"Username"}
                  label="User Name 1"
                  classesInput="w-auto"
                />
              </div>
              <div className="">
                <Select
                  label="Interests/Categories"
                  classesSelect="border border-gray-400 p-2.5 pl-4 w-full"
                  style={{minWidth: "207px"}}
                  // onChange={(e: any) => handleSelectAmountToSee(e)}
                >
                  <>
                    <option defaultValue="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </>
                </Select>
              </div>
              <div>
                <Select
                  label="Interests/Categories"
                  classesSelect="border border-gray-400 p-2.5 pl-4 w-full"
                  style={{minWidth: "207px"}}
                  // onChange={(e: any) => handleSelectAmountToSee(e)}
                >
                  <>
                    <option defaultValue="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div className="mt-12 w-full sm:w-10/12 rounded-sm">
        {/* Navbar */}
        <div className="flex justify-end">
          <div className="rounded border-2 border-gray-200 flex justify-center items-center bg-white w-80">
            <span
              className={`cursor-pointer justify-center h-full w-full px-4 py-2.5  flex rounded-l items-center text-sm ${
                actualTab === "General"
                  ? "bg-primary-purple text-white"
                  : "hover:bg-tertiary-purple text-gray-400"
              }`}
              onClick={() => setActualTab("General")}
            >
              On wallet
            </span>

            <span
              className={`cursor-pointer justify-center h-full w-full px-4 py-2.5  flex items-center text-sm ${
                actualTab === "Wheel"
                  ? "bg-primary-purple text-white"
                  : "hover:bg-tertiary-purple text-gray-400"
              }`}
              onClick={() => setActualTab("Wheel")}
            >
              Won
            </span>

            <span
              className={`cursor-pointer justify-center h-full w-full px-4 py-2.5   flex items-center text-sm ${
                actualTab === "Shop"
                  ? "bg-primary-purple text-white"
                  : "hover:bg-tertiary-purple text-gray-400"
              }`}
              onClick={() => setActualTab("Shop")}
            >
              Redeemed
            </span>
          </div>
        </div>
      </div>

      <div className="mt-5 w-full sm:w-10/12 bg-white rounded-sm my-5">
        <Table
          headers={TABLE_USERS_PROFILE_HEADER}
          data={[]}
          hasCheckbox={false}
        />
      </div>
    </section>
  );
}

export default UserProfile;
