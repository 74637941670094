interface SubTableTypes {
  subHeaders: string[];
}

function SubtableHeader({ subHeaders }: SubTableTypes) {
  return (
    <tr className="bg-primary-light-blue">
      <td className="border-l-4 border-primary-purple"></td>
      {subHeaders.map((subheader: any) => (
        <td key={subheader} className="p-3 font-medium text-xs  text-black">
          {subheader}
        </td>
      ))}
      <td></td>
      <td></td>
    </tr>
  );
}

export default SubtableHeader;
