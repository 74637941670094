import { ItemsCategoryI } from "page/Categories/types";

export function segmentationByPrizeOffer(mergedArrays: ItemsCategoryI[]) {
    const result: { prize: ItemsCategoryI[]; offer: ItemsCategoryI[] } = {
        prize: [],
        offer: [],
    };

    for (const obj of mergedArrays) {
        if (obj.prizeType === "REGULAR") {
            result.prize.push(obj);
        } else if (obj.prizeType === "OFFER") {
            result.offer.push(obj);
        }
    }

    return result;
}

export function getNamesFromCategories(mergedArrays: ItemsCategoryI[]) {
    return mergedArrays.map(
        (item: any) => {
            return {
                id: item.prizeId,
                name: item.name,
            };
        }
    );
}

export function mergeArrays(arr1: ItemsCategoryI[], arr2: ItemsCategoryI[]) {
    const result = [];
    const prizeIds = {};

    for (const obj1 of arr1) {
        if (!(obj1.prizeId in prizeIds)) {
            result.push(obj1);
            // @ts-ignore
            prizeIds[obj1.prizeId] = true;
        }
    }

    for (const obj2 of arr2) {
        if (!(obj2.prizeId in prizeIds)) {
            result.push(obj2);
            // @ts-ignore
            prizeIds[obj2.prizeId] = true;
        }
    }

    return result;
}

export function removeObjectsById(array: ItemsCategoryI[], ids: number[]) {
    return array.filter((obj) => !ids.includes(obj.prizeId));
}

export function filterObjectsById(array: ItemsCategoryI[], ids: number[]) {
    // @ts-ignore
    return array.filter((obj) => ids.includes(obj.prizeId.toString()));
}

export const findCategoryById = (items: any[], itemId: string): any | null => {
    for (const item of items) {
        if (item.id.toString() === itemId) {
            return item;
        }
        if (item.subItems && item.subItems.length > 0) {
            const foundItem = findCategoryById(item.subItems, itemId); // Recursively search in subItems
            if (foundItem) {
                return foundItem;
            }
        }
    }
    return null;
};

export function sumObjectsByKey(arr: Record<string, number>[]): Record<string, number> {
    const result = arr.reduce((acc, obj) => {
        for (const [key, value] of Object.entries(obj)) {
            acc[key] = (acc[key] || 0) + value;
        }
        return acc;
    }, {} as Record<string, number>);
    return result;
}