import Alert from "assets/Alert";
import {ChangeEventHandler} from "react";
import Checkbox from "../Checkbox";

interface InputTypes {
  id?: string;
  inputID?: string;
  checkboxID?: string;
  label: string;
  hasLabel?: boolean;
  secondLabel?: string | JSX.Element;
  value: string | number;
  disabled?: boolean;
  required?: boolean;
  alert?: boolean;
  readOnly?: boolean;
  classes?: string;
  classesLabel?: string;
  classesInput?: string;
  classesCheckbox?: string;
  error?: boolean;
  type?: "text" | "number" | "password" | "email";
  placeholder?: string;
  pattern?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  icon?: JSX.Element;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  handleClickCheckbox?: any;
  checkedValues?: string[];
  subtitle?: string;
  classesSelect?: string;
}

function Input({
  id,
  inputID,
  checkboxID,
  label = "default label",
  hasLabel = true,
  secondLabel,
  value,
  disabled = false,
  required = false,
  alert = false,
  readOnly = false,
  classes = "",
  classesLabel = "",
  classesInput = "",
  classesCheckbox = "",
  error = false,
  type = "text",
  placeholder = "-",
  onChange,
  onBlur,
  pattern,
  max,
  maxLength,
  min,
  minLength,
  icon,
  handleClickCheckbox,
  checkedValues,
  subtitle,
}: InputTypes) {
  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && error) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !error) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  return (
    <div className={classes}>
      {hasLabel && (
        <div className="mb-2 flex items-center direction-row justify-between">
          <div>
            <label
              htmlFor={label.replace(/\s/g, "")}
              className={`block text-sm font-medium  ${
                classesLabel.length === 0 ? "text-gray-400" : classesLabel
              }`}
            >
              {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div>
              {secondLabel && (
                <label className="flex text-xs text-gray-400">
                  {secondLabel}
                </label>
              )}
            </div>
          </div>
          {alert && label !== "Items in wheel" && label !== "Items in shop" && (
            <div className="flex">
              <Alert classes="h-4 w-4 mr-2" />
              <Checkbox
                className={classesCheckbox}
                id={id || checkboxID || label.replace(/\s/g, "")}
                key={id || checkboxID || label.replace(/\s/g, "")}
                type="checkbox"
                name={id || checkboxID || label.replace(/\s/g, "")}
                onChange={(e: any) => handleClickCheckbox(e)}
                isChecked={checkedValues?.includes(
                  id || checkboxID || label.replace(/\s/g, "")
                )}
              />
            </div>
          )}
        </div>
      )}
      <div className="relative">
        <input
          type={type}
          id={inputID || label.replace(/\s/g, "")}
          readOnly={readOnly}
          disabled={disabled}
          pattern={pattern}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          onWheel={(e: any) => e.target.blur()}
          className={`text-sm rounded-md block p-2.5 pl-4 font-medium  ${
            classesInput ? classesInput : "w-full"
          }
           ${checkClassName()}`}
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
        />
        {icon}
        {subtitle && <p className="mt-2">{subtitle}</p>}
      </div>
    </div>
  );
}

export default Input;
