interface IURLAllowed {
    dashboard?: string[],
    merchant?: string[],
    item?: string[],
    gp?: string[],
    loyalty?: string[],
    categories?: string[],
    location?: string[],
    employee?: string[],
    user?: string[],
    admin?: string[],
    config?: string[],
    notifications?: string[]
}

interface IRoutes {
    "SUPERADMIN": {
        urlAllowed: IURLAllowed
    },
    "SALESADMIN": {
        urlAllowed: IURLAllowed
    },
    "MODERATOR": {
        urlAllowed: IURLAllowed
    }
}


const routes: IRoutes = {
    "SUPERADMIN": {
        urlAllowed: {
            dashboard: ["view"],
            merchant: ["view", "add", "edit", "archive", "recover", "publish", "delete", "approve", "decline", "regenerate qrcode"],
            item: ["view", "add", "edit", "archive", "recover", "save", "publish", "delete", "approve", "decline"],
            gp: ["view", "add", "edit", "archive", "recover", "save", "publish", "delete", "approve", "decline"],
            loyalty: ["view", "add", "edit", "archive", "recover", "save", "publish", "delete", "approve", "decline"],
            categories: ["view", "add", "edit", "archive", "recover", "save", "publish", "delete"],
            location: ["view", "add", "edit", "archive", "recover", "save", "publish", "delete"],
            employee: ["view", "add", "save", "edit", "delete", "publish"],
            user: ["view", "add", "edit", "archive", "recover", "publish", "delete"],
            admin: ["view", "add", "edit", "archive", "recover", "publish", "delete"],
            config: ["view", "add", "edit", "archive", "recover", "publish", "delete"],
            notifications: ["view"]
        }
    },
    "SALESADMIN": {
        urlAllowed: {
            dashboard: ["view"],
            merchant: ["view", "add", "edit", "archive", "recover"],
            item: ["view", "add", "edit", "save", "archive", "recover"],
            gp: ["view", "add", "edit", "save", "archive", "recover"],
            loyalty: ["view", "add", "edit", "save", "archive", "recover"],
            categories: ["view", "add", "edit", "save", "archive", "recover"],
            employee: ["view", "add", "edit", "publish"],
            user: ["view", "edit"],
            notifications: ["view"]
        }
    },
    "MODERATOR": {
        urlAllowed: {
            dashboard: ["view"],
            merchant: ["view", "publish", "approve", "decline", "delete"],
            item: ["view", "publish", "approve", "decline", "delete"],
            gp: ["view", "publish", "approve", "decline", "delete"],
            loyalty: ["view", "publish", "approve", "decline", "delete"],
            categories: ["view"],
            employee: ["view", "delete"],
            user: ["view"],
            notifications: ["view"]
        }
    },
}



export default routes;