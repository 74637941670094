import {Menu} from "@headlessui/react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  usePublishLocationMutation,
  useUnpublishLocationMutation,
} from "store/api/locationApi";
import {
  setIdSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";
interface MenuItemTypes {
  title: string;
  svg?: JSX.Element;
  type?: string;
  onClick?: Function;
  elementID?: string | number;
  element?: any;
  setMerchantsStatus?: Function;
}

function MenuItem({
  title,
  svg,
  type,
  elementID,
  element,
  onClick = () => {},
  setMerchantsStatus,
}: MenuItemTypes) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [publishLocation] = usePublishLocationMutation();
  const [unpublishLocation] = useUnpublishLocationMutation();

  const handleDispatchAction = async (type: string) => {
    if (type === "redirectToMerchant") {
      dispatch(setResetElChecked(true));
      return navigate(`/merchants/${elementID}`);
    } else if (type === "redirectToItems") {
      dispatch(setResetElChecked(true));
      return navigate("/items/create", {state: {id: elementID}});
    } else if (type === "redirectToLocation") {
      dispatch(setResetElChecked(true));
      return navigate(`/location/${elementID}`);
    } else if (type === "redirectToCategory") {
      dispatch(setResetElChecked(true));
      onClick(elementID);
    } else if (type === "changeCategoriesPublish") {
      dispatch(setResetElChecked(true));
      onClick(elementID);
    } else if (type === "addPrizeListToSidebar") {
      dispatch(setResetElChecked(true));
      onClick(elementID);
    } else if (type === "openSidebarCategory") {
      dispatch(setResetElChecked(true));
      onClick(elementID);
    } else if (type === "resetPassword") {
      dispatch(setResetElChecked(true));
      dispatch(setType("resetPassword"));
      dispatch(setOpenModal(true));
      return;
    } else if (type === "recover" && setMerchantsStatus) {
      setMerchantsStatus([elementID]);
      dispatch(setType("recoveryMerchant"));
      dispatch(setOpenModal(true));
    } else if (type === "archived" && setMerchantsStatus) {
      setMerchantsStatus([elementID]);
      dispatch(setType("archiveMerchant"));
      dispatch(setOpenModal(true));
      // setMerchantsStatus({id: elementID, status: "ARCHIVED"});
    } else if (type === "delete" && setMerchantsStatus) {
      dispatch(setResetElChecked(true));
      dispatch(setType("deleteMerchant"));
      dispatch(setOpenModal(true));
      if (elementID) {
        dispatch(setResetElChecked(true));
        dispatch(setIdSelectedToDelete(elementID.toString()));
      }
    } else if (type === "publishCategory") {
      onClick(element);
    } else if (type === "unpublishCategory") {
      onClick(element);
    } else if (type === "archiveCategory") {
      onClick(element);
    } else if (type === "recoverCategory") {
      onClick(element);
    } else if (type === "deleteCategory") {
      onClick(element);
    } else if (type === "publishLocation") {
      let toastPromise;

      toastPromise = toast.promise(
        publishLocation({data: {dmaCodes: [elementID?.toString()]}}),
        {
          pending: "Publishing locations",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while publishing locations</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Locations published", {
              autoClose: 5000,
            });
            dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while publishing locations");
        });
    } else if (type === "unpublishLocation") {
      let toastPromise;

      toastPromise = toast.promise(
        unpublishLocation({data: {dmaCodes: [elementID?.toString()]}}),
        {
          pending: "Unpublishing locations",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while unpublishing locations</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Locations unpublished", {
              autoClose: 5000,
            });
            dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while unpublishing locations");
        });
    } else {
      return onClick();
    }
  };

  return (
    <Menu.Item>
      {({active}) => (
        <button
          onClick={() => handleDispatchAction(type || "")}
          className={`bg-white hover:bg-primary-white  group flex w-full items-center rounded-md px-2 py-2 text-sm`}
        >
          {svg}
          {title}
        </button>
      )}
    </Menu.Item>
  );
}

export default MenuItem;
