import {useEffect, useState} from "react";
import {toast} from "react-toastify";

import Label from "../Label";

// Store
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import {setImageModal, setOpenModal, setType} from "store/modal/modalSlice";
import Upload from "assets/Upload";
import Zoom from "assets/Zoom";
import Alert from "assets/Alert";
import Checkbox from "../Checkbox";
import Crop from "assets/Crop";
import ImageCropModal from "components/Modal/ImageCropModal";

interface InputTypes {
  id?: string;
  checkboxID?: string;
  image?: any;
  label?: string;
  required?: boolean;
  alert?: boolean;
  handleUploadPhoto?: any;
  classes?: string;
  classTopOption?: string;
  classesLabel?: string;
  classesBorder?: string;
  classesCheckbox?: string;
  sizePlaceholder?: string;
  type?: string;
  imageSetter?: any;
  handleClickCheckbox?: any;
  checkedValues?: string[];
  typeItem?: string;
  isVideo?: boolean;
  disabledInput?: boolean;
  imageCropModalType?: string;
  setImageCropModalType?: (type: string) => void;
  newImageUpload?: any;
  setNewImageUpload?: any;
  sizesContainer?: {
    minWidth?: string;
    maxWidth?: string;
    minHeight?: string;
    maxHeight?: string;
  };
  sizesContainerGrandPrize?: {
    height: string;
    width?: string;
  };
  sizes?: {width: number; height: number};
  aspect?: any;
  openModalOnClick?: string;
  setTypeInputModalDropzone?: any;
  placeholder?: any;
}

function Dropzone({
  image,
  id = "1",
  checkboxID,
  handleUploadPhoto,
  label = "",
  required = false,
  alert = false,
  classes = "",
  classTopOption = "",
  classesLabel = "",
  classesBorder = "",

  classesCheckbox = "",
  sizePlaceholder = "picture \n 1:1 @ 1080 x 1080",
  type = "",
  imageSetter = () => {},
  handleClickCheckbox,
  checkedValues,
  typeItem,
  isVideo = false,
  disabledInput,
  imageCropModalType,
  setImageCropModalType,
  newImageUpload,
  setNewImageUpload,
  sizesContainer = {
    minHeight: "230px",
    maxHeight: "230px",
    minWidth: "230px",
    maxWidth: "230px",
  },
  sizesContainerGrandPrize = {
    width: "266px",
    height: "397px",
  },
  sizes,
  aspect = 1,
  openModalOnClick,
  setTypeInputModalDropzone,
  placeholder,
}: InputTypes) {
  const modal = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  const [showCloseButton, setShowCloseButton] = useState(true);

  const openCropModal = () => {
    dispatch(setOpenModal(true));
    dispatch(setType("cropImage"));
    dispatch(setImageModal(image));
    setImageCropModalType && setImageCropModalType(type);
  };

  useEffect(() => {
    if (
      !image ||
      imageCropModalType === "alreadyCropped" ||
      imageCropModalType === "default"
    )
      return;
    openCropModal();
    setShowCloseButton(false);
  }, [image]);

  const size =
    type === "COVER_PHOTO" ? sizesContainerGrandPrize : sizesContainer;

  const checkPlaceholderName = () => {
    if (placeholder) {
      return placeholder;
    }

    if (!isVideo) {
      return `Add ${typeItem ?? "prize"} ${sizePlaceholder}
      Maximum size: 8mb \n Support format: PNG,JPEG`;
    }

    return "Maximum size: 100mb \n Support format: avi, mp4";
  };

  return (
    <div>
      <div
        className={`mb-2 flex items-center direction-row justify-between ${classTopOption}`}
      >
        {label && (
          <div className={`flex ${classesLabel}`}>
            <Label>{label}</Label>
            {required && <span className="text-red-500 ml-1">*</span>}
          </div>
        )}
        {alert && label !== "Items in wheel" && label !== "Items in shop" && (
          <div className="flex">
            <Alert classes="h-4 w-4 mr-2" />
            <Checkbox
              className={classesCheckbox}
              id={id || checkboxID || label.replace(/\s/g, "")}
              key={checkboxID || label.replace(/\s/g, "")}
              type="checkbox"
              name={checkboxID || label.replace(/\s/g, "")}
              onChange={(e: any) => handleClickCheckbox(e)}
              isChecked={checkedValues?.includes(
                id || checkboxID || label.replace(/\s/g, "")
              )}
            />
          </div>
        )}
      </div>
      {/* <Label classes="xl:ml-20 lg:ml-8 my-8 ">{label}</Label> */}
      <div className={`flex items-center ${classes}`}>
        <label
          htmlFor={`dropzone-file-${id}`}
          className={`flex flex-col relative justify-center items-center px-6 overflow-hidden border rounded-lg ${
            !image && "bg-gray-50  rounded-lg border"
          } ${!disabledInput && "hover:bg-gray-100"}
           ${disabledInput ? "cursor-not-allowed" : "cursor-pointer"}
           ${classesBorder ? classesBorder : "border-gray-400"}`}
          style={{
            ...size,
            aspectRatio: aspect,
            whiteSpace: "pre-line",
          }}
        >
          {!image && (
            <div className="flex flex-row justify-center items-center pt-5 pb-5 gap-5">
              <svg
                aria-hidden="true"
                className="mb-3 w-10 h-10 text-[#b9b9b9]"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M450-313v-371L330-564l-43-43 193-193 193 193-43 43-120-120v371h-60ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z" />
              </svg>

              <p className="mb-2 text-xs text-gray-500 text-center max-w-xs font-medium">
                <span>{checkPlaceholderName()}</span>
              </p>
            </div>
          )}
          <input
            id={`dropzone-file-${id}`}
            type="file"
            className="hidden"
            disabled={disabledInput}
            onClick={(e: any) => {
              if (openModalOnClick && openModalOnClick !== "") {
                e.preventDefault();
                dispatch(setOpenModal(true));
                dispatch(setType("showImagesCategories"));
                switch (type) {
                  case "PROFILE":
                    setTypeInputModalDropzone("exterior");
                    break;
                  case "INTERIOR":
                    setTypeInputModalDropzone("interior");
                    break;
                  case "LOGO_LARGE":
                    setTypeInputModalDropzone("logo");
                    break;
                  default:
                    break;
                }
              }
              e.target.value = null;
            }}
            onChange={(e: any) => {
              if (isVideo) {
                if (e.target.files[0].size > 104857600)
                  return toast.error(
                    `This video ${e.target.files[0].name} is bigger than 100MB.`
                  );
                if (type.length) {
                  handleUploadPhoto(e.target.files[0], type, imageSetter);
                } else {
                  handleUploadPhoto(e.target.files[0]);
                }

                var video = document.getElementsByTagName("video")[0];
                var url = URL.createObjectURL(e.target.files[0]);
                video.src = url;
                video.load();
              } else {
                if (e.target.files[0].size > 8388608)
                  return toast.error(
                    `This image ${e.target.files[0].name} is bigger than 8MB.`
                  );
                if (type.length) {
                  handleUploadPhoto(e.target.files[0], type, imageSetter);
                } else {
                  handleUploadPhoto(e.target.files[0]);
                }
                setImageCropModalType && setImageCropModalType(type);
                setNewImageUpload &&
                  setNewImageUpload([...newImageUpload, type]);
              }
            }}
            accept={
              !isVideo
                ? "image/jpg, image/jpeg, image/png, image/webp"
                : "video/mp4, video/avi, video/*"
            }
          />
          <div
            className={` w-full absolute -top-40 object-cover justify-center items-center ${
              !disabledInput &&
              "hover:bg-primary-purple hover:bg-opacity-60 z-10 hover:cursor-pointer hover:flex"
            }
            ${
              type === "COVER_PHOTO"
                ? "h-full hover:-top-0"
                : type === "SMALL"
                ? "h-72 hover:-top-10"
                : isVideo
                ? "h-80"
                : "h-96 hover:-top-10"
            }
            `}
          >
            <div
              className={`flex  ${
                type === "SMALL" ? "!-translate-y-24" : "-translate-y-14"
              } ${
                type === "COVER_PHOTO" ? "!-translate-y-6" : "-translate-y-14"
              } ${isVideo && "!translate-y-20"}`}
            >
              {image && !isVideo && (
                <>
                  <p
                    className="p-1.5 bg-white flex items-center border rounded-lg mr-5"
                    onClick={(e) => {
                      e.preventDefault();

                      if (openModalOnClick && openModalOnClick !== "") {
                        e.preventDefault();
                        dispatch(setOpenModal(true));
                        dispatch(setType("showImagesCategories"));
                        switch (type) {
                          case "PROFILE":
                            setTypeInputModalDropzone("exterior");
                            break;
                          case "INTERIOR":
                            setTypeInputModalDropzone("interior");
                            break;
                          case "LOGO_LARGE":
                            setTypeInputModalDropzone("logo");
                            break;
                          default:
                            break;
                        }
                      } else {
                        fetch(image)
                          .then((res) => res.blob())
                          .then((data) => {
                            let objectURL = URL.createObjectURL(data);
                            dispatch(setImageModal(objectURL));
                          })
                          .catch((err) => console.log(err));
                        dispatch(setOpenModal(true));
                        dispatch(setType("showImage"));
                      }
                    }}
                  >
                    <Zoom />
                  </p>
                  {((imageCropModalType !== "default" &&
                    window.location.pathname.includes("create")) ||
                    newImageUpload?.includes(type)) && (
                    <p
                      className="p-1.5 bg-white flex items-center border rounded-lg mr-5"
                      onClick={(e) => {
                        e.preventDefault();
                        openCropModal();
                      }}
                    >
                      <Crop classes="text-gray-800" />
                    </p>
                  )}
                </>
              )}
              {type !== "editItemMerchant" && (
                <p className="p-1.5 bg-white flex items-center border rounded-lg">
                  <Upload />
                </p>
              )}
            </div>
          </div>
          {image && !isVideo && (
            <img
              src={
                image
                // "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
              }
              alt="preview"
              className={`h-full w-full absolute ${
                type === "SMALL" || type === "COVER_PHOTO"
                  ? ""
                  : "object-contain"
              }  border rounded-lg ${alert && "!border-yellow-400"}`}
              crossOrigin="anonymous"
            />
          )}
          {isVideo && image && (
            <video
              width="320"
              height="240"
              controls={true}
              className="h-64 w-full absolute object-cover border rounded-lg"
            >
              <source src={image} type="video/mp4" />
            </video>
          )}
        </label>
      </div>

      {modal.type === "cropImage" &&
        imageCropModalType === type &&
        !isVideo && (
          <ImageCropModal
            image={image}
            type={type}
            imageSetter={imageSetter}
            handleUploadPhoto={handleUploadPhoto}
            imageCropModalType={imageCropModalType || ""}
            showCloseButton={showCloseButton}
            //@ts-ignore
            setImageCropModalType={setImageCropModalType}
            setShowCloseButton={setShowCloseButton}
            sizes={sizes}
            aspect={aspect}
          />
        )}
    </div>
  );
}

export default Dropzone;
