export function transformTimeFormat(timeString: string): string {
    // Convert time string from "HHmm" to "HH:mm"
    const hours = timeString?.substring(0, 2);
    const minutes = timeString?.substring(2, 4);
    return `${hours}:${minutes}`;
}

export function transformTimeFormatAMPM(time: string): string {
    // Check if the time is in the AM or PM format
    const isAM = time.slice(-2) === "AM";

    // Extract hours and minutes from the time string
    const [hours, minutes] = time.slice(0, -2).split(":");

    // Convert the hours to a 24-hour format
    let formattedHours = parseInt(hours, 10);
    if (!isAM) {
        if (formattedHours === 12) {
            formattedHours = 0; // 12:00PM should be converted to 00:00
        } else {
            formattedHours += 12;
        }
    }

    // Pad single-digit hours and minutes with leading zeros
    const formattedHoursStr = String(formattedHours)?.padStart(2, "0");
    const formattedMinutesStr = minutes?.padStart(2, "0");

    // Return the formatted time in "HH:mm" format
    return `${formattedHoursStr}:${formattedMinutesStr}`;
}

export function formatTimeToAMPM(time: any) {

    if (time === ":") return "";

    const [hours, minutes] = time.split(":");
    let formattedHours = parseInt(hours, 10);

    // Handle midnight (00:00)
    if (formattedHours === 0) {
        formattedHours = 12;
        return `12:${minutes}PM`;
    }

    // Handle noon (12:00)
    if (formattedHours === 12) {
        return `12:${minutes}AM`;
    }

    // Convert to 12-hour format for PM
    if (formattedHours > 12) {
        formattedHours -= 12;
        return `${formattedHours}:${minutes}PM`;
    }

    return `${formattedHours}:${minutes}AM`;
}

export function formatArrayToAMPM(array: any[]) {
    const formattedArray = array.map(item => ({
        ...item,
        start: item.start ? formatTimeToAMPM(item.start) : "",
        end: item.end ? formatTimeToAMPM(item.end) : "",
    }));

    return formattedArray;
}

export function updateWorkingHours(mainArray: any[], updateArray: any[]) {
    // Create a deep copy of the mainArray to avoid modifying the original array
    const updatedArray: any[] = [...mainArray]

    for (const updateItem of updateArray) {
        const dayToUpdate = updateItem.day;
        const matchingDayIndex = updatedArray.findIndex((item: any) => item.day === dayToUpdate);

        if (matchingDayIndex !== -1) {
            updatedArray[matchingDayIndex].start = updateItem.start;
            updatedArray[matchingDayIndex].end = updateItem.end;
            updatedArray[matchingDayIndex].isOvernight = updateItem.isOvernight;
            updatedArray[matchingDayIndex].isClosed = updateItem.isClosed;
        }
    }

    return updatedArray;
}

export function findMatches(
    objArr: any,
    searchArray: any,
    resultIds: any,
    resultNames: any
) {
    objArr.forEach((obj: any) => {
        if (obj.name && searchArray?.includes(obj.name)) {
            resultIds.push(obj.id);
            resultNames.push(obj.name);
        }

        if (Array.isArray(obj.subItems)) {
            findMatches(obj.subItems, searchArray, resultIds, resultNames);
        }
    });
}

export function getFilteredNames(data: any[], searchText: string, type: string): any {
    const filteredData: any[] = [];

    if (!searchText || searchText.length === 0) {
        return data;
    }

    if (searchText.length > 2) {
        for (const item of data) {
            const name = item.name.toLowerCase();
            const id = item.id
            const search = searchText.toLowerCase();


            if (name.includes(search) || id.toString().includes(search)) {
                const isDuplicate = filteredData.some(
                    (existingItem) => existingItem.name.toLowerCase() === name || existingItem.id.toString() === id.toString()
                );
                if (!isDuplicate) {
                    filteredData.push(item);
                }
            }


            if (item.subItems) {
                const subFilteredData: any[] = getFilteredNames(
                    item.subItems,
                    searchText,
                    type
                );
                if (subFilteredData.length > 0) {
                    const newItem: any = { ...item, subItems: subFilteredData };
                    const isDuplicate = filteredData.some(
                        (existingItem) => existingItem.name.toLowerCase() === newItem.name.toLowerCase()
                    );
                    if (!isDuplicate) {
                        filteredData.push(newItem);
                    }
                }
            }
        }

        return filteredData;
    }
}

export const findItemById = (items: any[], itemId: string): any | null => {
    for (const item of items) {
        if (item.id.toString() === itemId) {
            return item;
        }
        if (item.subItems && item.subItems.length > 0) {
            const foundItem = findItemById(item.subItems, itemId); // Recursively search in subItems
            if (foundItem) {
                return foundItem;
            }
        }
    }
    return null;
};