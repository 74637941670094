import Delete from "assets/Delete";
import Edit from "assets/Edit";
import Search from "assets/Search";
import Button from "components/Common/Button";
import DropdownCategory from "components/Common/DropdownCategory";
import Select from "components/Common/Input/Select";
import Searchbar from "components/Common/Searchbar";
import Spinner from "components/Common/Spinner";
import Subtitle from "components/Common/Subtitle";
import {Container} from "components/DragNDrop/Container";
import Modal from "components/Modal";
import DeleteModal from "components/Modal/DeleteModal";
import Table from "components/Table/Table";
import debounce from "lodash.debounce";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {
  useChangeTrendingPlacesMutation,
  useGetAllMerchantsNameQuery,
  useGetTrendingPlacesQuery,
} from "store/api/merchantsApi";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {RootState} from "store/store";
import {TABLE_TRANDING_PLACES} from "utils/configurationHeaders";
import {getFilteredNames} from "utils/workingHoursUtils";

const initialState = Array.from({length: 10}, (_, index) => ({
  id: "",
  name: "-",
  placement: index + 1,
  itemCount: 0,
  prizeCount: 0,
  redeemedCount: 0,
  categories: [],
}));

function TrandingPlaces({id}: {id: string}) {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);

  const [categoriesOrdered, setCategoriesOrdered] = useState<
    [{merchantId?: string; position?: number}]
  >([{}]);

  const [dropdown, openDropdown] = useState(false);
  const [elementsCheckedDropdown, setElementsCheckedDropdown] = useState<
    string[]
  >([]);

  const [dataCategoriesState, setDataCategoriesState] = useState<any>([]);

  const [elementsName, setElementsName] = useState<string[]>([]);
  const [placement, setPlacement] = useState("");
  const [searchItems, setSearchItems] = useState("");

  const {
    data: dataTrendingPlaces,
    isLoading: isLoadingTrendingPlaces,
    isError: isErrorTrandingPlaces,
    refetch,
  } = useGetTrendingPlacesQuery({dmaCode: id});

  const {
    data: dataMerchantsName,
    isLoading: isLoadingMerchantsName,
    isError: isErrorMerchantsName,
  } = useGetAllMerchantsNameQuery({dmaCode: id});

  const [changePositionMutation] = useChangeTrendingPlacesMutation();

  const [selectedMerchant, setSelectedMerchant] = useState<{
    id?: string;
    name?: string;
    category?: any;
  }>({});
  const [positionsAlreadyTook, setPositionsAlreadyTook] = useState<number[]>(
    []
  );
  const [merchantsInTrendingPlaces, setMerchantsInTrendingPlaces] =
    useState<any>([]);

  useEffect(() => {
    // Get all the positions already took
    if (dataTrendingPlaces && dataTrendingPlaces.length > 0) {
      setMerchantsInTrendingPlaces(dataTrendingPlaces);
      setPositionsAlreadyTook(
        dataTrendingPlaces.map((item: any) => item.placement)
      );
      const combinedData = initialState.map((item) => {
        const match = dataTrendingPlaces.find(
          (info: any) => info.placement === item.placement
        );
        return match ? match : item;
      });

      setDataCategoriesState(combinedData);
    }
  }, [dataTrendingPlaces]);

  const handleClickDropdown = async (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      if (categoriesOrdered && categoriesOrdered.length > 0) {
        const toastPromise = toast.promise(
          changePositionMutation({dmaCode: id, data: categoriesOrdered}),
          {
            pending: `Changing position merchant`,
          }
        );
        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while changing position</h2>
                  <p className="text-xs"> {res?.error?.data?.message} </p>
                </div>
              );
            } else {
              toast.success(`Positions changed`);
            }
          })
          .catch((err) => {
            toast.error("Error while changing position");
          })
          .finally(() => {
            openDropdown(false);
          });
      }

      return;
    }
    if (type === "clear") {
      openDropdown(false);
      return;
    }
  };

  const ITEMS = [
    {
      title: "delete",
      svg: <Delete />,
      onClick: (e: any) => {
        setSelectedMerchant(e);
        handleOpenModal();
      },
    },
  ];

  const handleOpenModal = () => {
    setTimeout(() => {
      dispatch(setOpenModal(true));
      dispatch(setType("deleteMerchantTrendingPlace"));
    }, 100);
  };

  const onChangeSearch = (value: any) => {
    setSearchItems(value);
  };

  const debouncedOnChangeSearch = debounce(onChangeSearch, 500);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsCheckedDropdown.includes("all")) {
        setElementsCheckedDropdown([id]);
        setElementsName([name]);
      } else {
        setElementsCheckedDropdown([...elementsCheckedDropdown, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsCheckedDropdown(
          elementsCheckedDropdown.filter((item: any) => item !== id)
        );
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsCheckedDropdown(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsCheckedDropdown(
          elementsCheckedDropdown.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const getFilteredNamesMerchants = () => {
    if (isLoadingMerchantsName)
      return (
        <div className="w-full flex justify-center items-center">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
        </div>
      );

    if (isErrorMerchantsName)
      return (
        <div className="w-full flex flex-col justify-center items-center">
          <p>An error occurred trying to get merchants</p>
          <Button onClick={refetch} classes="font-semibold">
            Try again
          </Button>
        </div>
      );

    let filteredData = dataMerchantsName;

    if (searchItems.length > 2) {
      filteredData = getFilteredNames(dataMerchantsName, searchItems, "");
    }

    return (
      <div className="flex flex-col  px-6 max-h-[750px] overflow-auto">
        {filteredData?.map((item: any) => (
          <div key={item?.id} className="flex flex-col my-2">
            <div className="flex justify-between">
              <div>
                <p className="font-medium">{item?.name}</p>
                <p className="text-gray-400 mb-3 text-sm">
                  {item?.categories?.map((item: any) => item?.name)?.join(", ")}
                </p>
              </div>
              <div className="flex items-center justify-end">
                <div
                  className="flex cursor-pointer justify-center items-center font-semibold border-2 rounded-full border-primary-purple text-primary-purple w-5 h-5"
                  onClick={() => {
                    setSelectedMerchant(item);
                    dispatch(setOpenModal(true));
                    dispatch(setType("addNewTrendingPlace"));
                  }}
                >
                  {" "}
                  +{" "}
                </div>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    );
  };

  const handleChangeTrendingData = async (data: any, type: string) => {
    // Get name and placement of every object inside the dataTrendingPlaces in this way {name, placement}
    const merchantsTrendingPlaces = merchantsInTrendingPlaces?.map(
      ({id, placement}: any) => {
        return {retailerId: id, placement};
      }
    );

    let newData = [];

    if (type === "delete") {
      // remove from merchantsTrendingPlaces the id coming in data
      newData = merchantsTrendingPlaces.filter(
        (item: any) => item.retailerId !== parseInt(data.id)
      );
    } else {
      newData = [
        ...merchantsTrendingPlaces,
        {retailerId: selectedMerchant.id, placement: parseInt(placement)},
      ];
    }

    const toastPromise = toast.promise(
      changePositionMutation({dmaCode: id, data: newData}),
      {
        pending: `${type === "add" ? "Adding" : "Deleting"} merchant position`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>
                Error while {type === "add" ? "adding" : "deleting"} merchant
                position
              </h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(
            `Merchant positions ${type === "add" ? "added" : "deleted"}`
          );
          dispatch(setOpenModal(false));
          dispatch(setType(""));
          setPlacement("");
        }
      })
      .catch((err) => {
        toast.error(
          `Error while ${
            type === "add" ? "adding" : "deleting"
          } merchant position`
        );
      })
      .finally(() => {
        setSelectedMerchant({});
      });
  };

  const handleCloseModal = () => {
    // setCurrentId("");
    setPlacement("");
    dispatch(setOpenModal(false));
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="flex">
        <div className="w-4/12 bg-white rounded border border-gray-300">
          <div className="flex flex-col p-4">
            <div>
              <Subtitle>Merchant List:</Subtitle>
            </div>
            <hr />
            <Searchbar
              placeholder={"Search"}
              classes="w-80 pr-3"
              classesContainer="w-full py-2 mt-4 outline-1 border border-gray-300 rounded-r-sm rounded h-min search-bar-autocomplete"
              useTags={false}
              hasAutocomplete={false}
              callback={debouncedOnChangeSearch}
            />
          </div>
          <hr />

          {getFilteredNamesMerchants()}
        </div>
        <div className="w-full bg-white p-4 border-gray-300">
          <div className="flex justify-between items-center mb-2">
            <div>
              <Subtitle>Trending Places</Subtitle>
              <p className="text-xs text-gray-400">
                Are shown in the application on the store page
              </p>
            </div>
            <div>
              <DropdownCategory
                openDropdown={openDropdown}
                dropdown={dropdown}
                elementsChecked={[]}
                hasLabel={false}
                placeholder="Change Merchant Position"
                elementsName={[]}
                handleClickCheckbox={handleClickCheckbox}
                // dataCategories={dataCategories}
                customData={
                  <Container
                    callback={(data: any) => {
                      setCategoriesOrdered(data);
                    }}
                    typeBack="merchant"
                    data={dataCategoriesState?.map(
                      ({id, placement, name, ...props}: any) => {
                        return {
                          id: placement,
                          position: placement,
                          text: `${name}`,
                          retailerId: id,
                        };
                      }
                    )}
                  />
                }
                isErrorCategories={isErrorTrandingPlaces}
                isLoadingCategories={isLoadingTrendingPlaces}
                handleClickDropdown={handleClickDropdown}
                hasSelectAll={false}
                hasId={true}
                hasClearAll={true}
                buttonName="Close"
                hasCheckbox={false}
              />
            </div>
          </div>
          <div>
            <Table
              data={
                dataCategoriesState !== undefined && dataCategoriesState
                  ? dataCategoriesState?.map((item: any) => {
                      return {
                        ...item,
                        categories:
                          item?.categories && item?.categories?.length > 0
                            ? item?.categories
                                ?.map((item: any) => item?.name)
                                ?.join(", ")
                            : "-",
                      };
                    })
                  : []
              }
              loadingData={false}
              errorData={false}
              hasCheckbox={false}
              origin="prize-list"
              hasPagination={false}
              headers={TABLE_TRANDING_PLACES}
              itemOptions={ITEMS}
              typeOptions="individual"
              show={[
                "placement",
                "name",
                "categories",
                "itemCount",
                "prizeCount",
                "redeemedCount",
              ]}
              sort={{
                placement: null,
                name: null,
                categories: null,
                itemCount: null,
                prizeCount: null,
                redeemedCount: null,
              }}
              customError={{
                show: true,
                content: (
                  <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
                    There are no tranding places
                  </h2>
                ),
              }}
            />
          </div>
        </div>
      </div>
      {modal.type === "addNewTrendingPlace" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            <div>
              <h1
                className={
                  "font-medium text-xl px-5 text-start  mb-4 tracking-normal"
                }
              >
                Add {selectedMerchant?.name} to trending places
              </h1>
              <hr />
              <div className="px-5 my-5">
                <Select
                  label="Select a position"
                  id="placement"
                  classesSelect="py-2 px-2 mr-3"
                  onChange={(e: any) => setPlacement(e.target.value)}
                  value={placement}
                >
                  <>
                    <option defaultValue="" disabled></option>
                    <option
                      value="10"
                      className={
                        positionsAlreadyTook.includes(9)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={
                        positionsAlreadyTook.includes(10) ? true : false
                      }
                    >
                      10
                    </option>
                    <option
                      value="9"
                      className={
                        positionsAlreadyTook.includes(9)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(9) ? true : false}
                    >
                      9
                    </option>
                    <option
                      value="8"
                      className={
                        positionsAlreadyTook.includes(8)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(8) ? true : false}
                    >
                      8
                    </option>
                    <option
                      value="7"
                      className={
                        positionsAlreadyTook.includes(7)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(7) ? true : false}
                    >
                      7
                    </option>
                    <option
                      value="6"
                      className={
                        positionsAlreadyTook.includes(6)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(6) ? true : false}
                    >
                      6
                    </option>
                    <option
                      value="5"
                      className={
                        positionsAlreadyTook.includes(5)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(5) ? true : false}
                    >
                      5
                    </option>
                    <option
                      value="4"
                      className={
                        positionsAlreadyTook.includes(4)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(4) ? true : false}
                    >
                      4
                    </option>
                    <option
                      value="3"
                      className={
                        positionsAlreadyTook.includes(3)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(3) ? true : false}
                    >
                      3
                    </option>
                    <option
                      value="2"
                      className={
                        positionsAlreadyTook.includes(2)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(2) ? true : false}
                    >
                      2
                    </option>
                    <option
                      value="1"
                      className={
                        positionsAlreadyTook.includes(1)
                          ? "bg-gray-300 cursor-not-allowed"
                          : ""
                      }
                      disabled={positionsAlreadyTook.includes(1) ? true : false}
                    >
                      1
                    </option>
                  </>
                </Select>
              </div>

              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => handleCloseModal()}
                  variant="normal"
                  classes="text-center"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  variant="add"
                  classes="text-center"
                  type="button"
                  onClick={(e) => handleChangeTrendingData(e, "add")}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal.type === "deleteMerchantTrendingPlace" && (
        <DeleteModal
          elementsChecked={[parseInt(selectedMerchant.id as string)]}
          setElementsChecked={() => {}}
          dataSource={[selectedMerchant]}
          matchWidhId="id"
          pendingMsg="Deleting category"
          returnValue="name"
          toastMessageId="name"
          type=""
          typeOfDelete="trendingPlaces"
          extraArgs={{data: selectedMerchant, type: "delete"}}
          deleteCallback={handleChangeTrendingData}
        />
      )}
    </div>
  );
}

export default TrandingPlaces;
