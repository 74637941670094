import Title from "components/Common/Title";

function Dashboard() {
  return (
    <div>
      <Title>Dashboard</Title>
    </div>
  );
}

export default Dashboard;
