import React, { useCallback, useEffect, useState, useRef } from "react";
import Input from "../Input";
import "./multiRange.css";
import debounce from "lodash.debounce";

const MultiRange = ({ min, max, valueMin, valueMax, label, onChange, classes = "", resetDefaultValues, setResetDefaultValues }) => {


  const [minVal, setMinVal] = useState(valueMin);
  const [maxVal, setMaxVal] = useState(valueMax);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useEffect(() => {
    if (valueMin) {
      setMinVal(valueMin)
    }

    if (valueMax) {
      setMaxVal(valueMax)
    }

  }, [valueMin, valueMax])


  useEffect(() => {
    if (resetDefaultValues) {
      setMinVal(min)
      setMaxVal(max)
      setResetDefaultValues(false)
    }
  }, [resetDefaultValues])


  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);


  return (
    <div className={classes}>
      <div className="mb-2 flex items-center direction-row justify-between">
        <label
          htmlFor={label.replace(/\s/g, "")}
          className={`block text-sm font-medium text-gray-400`}
        >
          {label}
        </label>
      </div>
      <div className="flex">
        <Input
          hasLabel={false}
          placeholder="__"
          maxLength={4}
          classes="mr-5"
          classesInput={"w-16"}
          onChange={(e) => {
            if (isNaN(e.target.value) ||
              parseInt(e.target.value) > parseInt(max) + 1 ||
              parseInt(e.target.value) > parseInt(maxVal) ||
              !e.target.value || parseInt(e.target.value) < 0) {

              onChange({ min: 0, max: maxVal });
              setMinVal(0)
              return
            };

            onChange({ min: e.target.value, max: maxVal });
          }}
          value={Math.abs(minVal)} />
        <div className="container">
          <input
            type="range"
            min={min || 0}
            max={max}
            value={minVal}
            ref={minValRef}
            onChange={(event) => {
              // debounce(() => {
              const value = Math.min(+event.target.value, parseInt(maxVal) - 1) || 0;
              onChange({ min: value || 0, max: maxVal });
              event.target.value = value.toString() || "0";
              // }, 500)
            }}
            className={`thumb thumb--zindex-3 ${minVal > max - 100 && "thumb--zindex-5"}`}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onChange={(event) => {
              // debounce(() => {
              const value = Math.max(+event.target.value, parseInt(minVal) + 1);
              onChange({ min: minVal, max: value });
              event.target.value = value.toString();
              // }, 500);
            }}
            className="thumb thumb--zindex-4"
          />

          <div className="slider">
            <div className="slider__track" />
            <div ref={range} className="slider__range" />
          </div>
        </div>
        <Input hasLabel={false} placeholder="__" classes="ml-5"
          onBlur={(e) => {
            if (parseInt(e.target.value) < parseInt(minVal) || !e.target.value) {
              return onChange({ min: minVal, max: parseInt(minVal) + 1 });
            }
          }}
          maxLength={4}
          classesInput={"w-16"}
          onChange={(e) => {
            if (isNaN(e.target.value) || e.target.value > max) {
              return
            } else {
              onChange({ min: minVal, max: Math.abs(e.target.value) });
            }
          }}
          value={maxVal} />
      </div>

    </div>
  );
};

export default MultiRange;