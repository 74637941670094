import {Fragment, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {Dialog, Tab} from "@headlessui/react";

// Components
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Form from "components/Table";
import Modal from "components/Modal";
import Label from "components/Common/Label";
import Input from "components/Common/Input";
import Button from "components/Common/Button";

// Tabs Item
import ApprovedItems from "./Tabs/ApprovedItems";
import NewItems from "./Tabs/NewItems";
import NeedApproveItems from "./Tabs/NeedApproveItems";
import ArchivedItems from "./Tabs/ArchivedItems";

// Tabs Grand Prize
import ApprovedGrandPrize from "./Tabs/ApprovedGrandPrize";
import NewGrandPrize from "./Tabs/NewGrandPrize";
import NeedApproveGrandPrize from "./Tabs/NeedApproveGrandPrize";
import ArchivedGrandPrize from "./Tabs/ArchivedGrandPrize";

// Assets
import Delete from "assets/Delete";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import {
  setIdSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";
import {
  useDeleteGrandPrizeMutation,
  useDeleteItemMutation,
} from "store/api/itemApi";
import {
  setItemsType,
  setTabIndex,
  setLastVisitedPage,
} from "store/tabs/tabsSlice";
import {setActualTab} from "store/user/userSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import getNamesFromCurrentPage from "utils/getNamesFromCurrentPage";
import {
  setFilters,
  setFiltersLastSearch,
  setURLFilters,
  setURLSearch,
} from "store/filters/filtersSlice";

function Items() {
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);
  const tabsData = useSelector((state: RootState) => state.tabs);
  const dispatch = useDispatch();

  const [itemsSelected, setItemsSelected] = useState([]);
  const [consentDelete, setConsentDelete] = useState("");
  const [inputError, setInputError] = useState(false);

  const [setDeleteItem] = useDeleteItemMutation();
  const [setDeleteGrandPrize] = useDeleteGrandPrizeMutation();

  const handleDeleteItem = async () => {
    if (consentDelete.toLowerCase() !== "delete") {
      setInputError(true);
    } else {
      setInputError(false);
      //   const type = document.getElementById(`${itemID}`)?.children[3]
      //   ?.innerHTML;

      // const merchantID = document
      //   .getElementById(`${itemID}`)
      //   ?.querySelector(".merchant-id")?.children[0].id;

      // if (!type || !merchantID) return;

      itemsSelected?.map(async (itemID) => {
        const id = parseInt(itemID);

        const type = document.getElementById(`${itemID}`)?.children[3]
          ?.innerHTML;

        const merchantID = document
          .getElementById(`${itemID}`)
          ?.querySelector(".merchant-id")?.children[0].id;

        if (!type || !merchantID) return;

        const toastPromise = toast.promise(
          tabsData.tabs.itemsType.toLowerCase() === "items"
            ? setDeleteItem({idItem: id, idMerchant: merchantID})
            : setDeleteGrandPrize({idItem: id, idMerchant: merchantID}),
          {
            pending: "Deleting item",
          }
        );

        await toastPromise
          .then((res: any) => {
            if (res.error) {
              toast.error(
                <div>
                  <h2>Error while deleting item</h2>
                  <p className="text-xs"> {res?.error?.data?.code} </p>
                </div>
              );
            } else {
              toast.success("Item Deleted");
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              dispatch(setIdSelectedToDelete(""));
              dispatch(setResetElChecked(true));
              setConsentDelete("");
            }
          })
          .catch((err) => {
            toast.error("Error while deleting item");
          });
      });
    }
  };

  const tabs = useMemo(
    () => (
      <Tabs
        tabsName={["Approved", "New Items", "Need to Approve", "Archived"]}
        primaryTabs
      >
        {/* First Table */}
        <ApprovedItems />
        {/* Second Table */}
        <NewItems />
        {/* Thirth Table */}
        <NeedApproveItems />
        {/* Fourth Table */}
        <ArchivedItems setItemsSelected={setItemsSelected} />
      </Tabs>
    ),
    [user.actualTab]
  );

  const tabsGrandPrize = useMemo(
    () => (
      <Tabs
        tabsName={["Approved", "New Items", "Need to Approve", "Archived"]}
        primaryTabs
      >
        {/* First Table */}
        <ApprovedGrandPrize />
        {/* Second Table */}
        <NewGrandPrize />
        {/* Thirth Table */}
        <NeedApproveGrandPrize />
        {/* Fourth Table */}
        <ArchivedGrandPrize setItemsSelected={setItemsSelected} />
      </Tabs>
    ),
    [user.actualTab]
  );

  return (
    <div>
      <Title>Items</Title>
      <Tab.Group
        selectedIndex={tabsData.tabs.itemsType === "Grand Prize" ? 1 : 0}
      >
        <Tab.List className={"rounded flex justify-center w-80 mb-3"}>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                className={`text-sm p-1.5 rounded-l border-l-2 border-y-2 outline-none border-gray-300 truncate w-1/2 ${
                  selected
                    ? "bg-primary-purple text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => {
                  dispatch(setTabIndex(""));
                  dispatch(setItemsType("Items"));
                  dispatch(setActualTab(""));
                  dispatch(setLastVisitedPage(""));
                  dispatch(setURLFilters(""));
                  dispatch(setURLSearch(""));
                  dispatch(setFilters([]));
                  dispatch(setFiltersLastSearch([]));
                }}
              >
                Items
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                className={`text-sm p-1.5 rounded-r border-r-2 border-y-2 outline-none border-gray-300 truncate w-1/2 ${
                  selected
                    ? "bg-primary-purple text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => {
                  dispatch(setTabIndex(""));
                  dispatch(setItemsType("Grand Prize"));
                  dispatch(setActualTab(""));
                  dispatch(setLastVisitedPage(""));
                  dispatch(setURLFilters(""));
                  dispatch(setURLSearch(""));
                  dispatch(setFilters([]));
                  dispatch(setFiltersLastSearch([]));
                }}
              >
                Grand Prize
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Form>
              <div className="mt-3">{tabs}</div>
            </Form>
          </Tab.Panel>
          <Tab.Panel>
            <Form>
              <div className="mt-3">{tabsGrandPrize}</div>
            </Form>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {modal.type === "deleteItem" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                  <Delete classes="text-red-500 h-10 w-10" />
                </div>
                <h1 className="text-red-500 mt-4">Delete</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to delete item{" "}
                  {modal.id || itemsSelected?.join(" - ")} from archive?
                </p>
              </div>
              <Label classes="pl-6 mt-5 mb-0 ">Type DELETE to confirm</Label>
              <Input
                inputID="input-modal-delete-merchant"
                classes="px-5 font-normal mb-6"
                classesInput=""
                label=""
                value={consentDelete}
                onChange={(e) => setConsentDelete(e.target.value)}
                placeholder=""
                error={consentDelete !== "delete" && inputError}
              />

              <hr />

              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                    dispatch(setIdSelectedToDelete(""));
                    dispatch(setResetElChecked(true));
                  }}
                >
                  Close
                </Button>
                <Button variant="danger" onClick={() => handleDeleteItem()}>
                  Delete
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Items;
