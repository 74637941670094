import { TypeClasses } from "./types";

function AddOffer({ classes }: TypeClasses) {
  return (
    <svg
      id="add-offer"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="img"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="post_add_FILL0_wght400_GRAD0_opsz48"
        d="M7.5,24A1.538,1.538,0,0,1,6,22.5V7.5A1.538,1.538,0,0,1,7.5,6h9.85V7.5H7.5v15h15V12.65H24V22.5A1.538,1.538,0,0,1,22.5,24Zm3.525-3.925v-1.5H19v1.5Zm0-3.175V15.4H19v1.5Zm0-3.175v-1.5H19v1.5ZM20.3,11.9V9.7H18.1V8.2h2.2V6h1.5V8.2H24V9.7H21.8v2.2Z"
        transform="translate(-3 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export default AddOffer;
