import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";
import Textarea from "components/Common/Textarea";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";

// Assets
import {useDuplicateGrandPrizeItemMutation} from "store/api/itemApi";

type AddRedeemedCodeType = {
  elementsChecked: number[];
  setElementsChecked: Dispatch<SetStateAction<number[]>>;
  itemInfoSelected: any[];
  setItemInfoSelected: Function;
  merchantIDProp?: string | null;
  promise: Function;
  type: string;
};

function AddRedeemedCodeModal({
  elementsChecked,
  setElementsChecked,
  itemInfoSelected,
  setItemInfoSelected,
  merchantIDProp = null,
  promise,
  type,
}: AddRedeemedCodeType) {
  const dispatch = useDispatch();
  const [newRedeemptionCodes, setNewRedeemptionCodes] = useState<any>({});

  const [duplicateGrandPrizeMutation] = useDuplicateGrandPrizeItemMutation();

  const handleDuplicateGP = async ({value, newCodes}: any) => {
    // console.log(value, newCodes);
    const toastPromise = toast.promise(
      duplicateGrandPrizeMutation({
        idItem: value.id.toString(),
        idMerchant: value.merchantId?.toString(),
        redemptionCodes: newCodes,
      }),
      {
        pending: "Duplicating items",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while duplicating item</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Item Duplicated");
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while duplicating item");
      });
  };

  const handleSubmit = () => {
    // let errors: any[] = [];

    if (Object.keys(newRedeemptionCodes).length !== itemInfoSelected.length) {
      toast.error("The new codes field cannot be empty");
      return;
    }

    const itemsDuplicated = Object.entries(newRedeemptionCodes).map(
      ([key, value]: any) => {
        if (!value.codes) {
          return {error: true, message: "The new codes field cannot be empty"};
        }

        const newCodes = value?.codes
          ?.split(",")
          .map((value: string) => value.trim());

        if (parseInt(newCodes?.length) !== parseInt(value.startQty)) {
          return {
            error: true,
            message: `Amount of new codes does not match with the amount required ${value.prizeName}`,
          };
        } else {
          return {error: false, value, newCodes};
        }
      }
    );

    // console.log(itemsDuplicated);

    const noErrors = itemsDuplicated.map((el) => {
      if (el.error) {
        toast.error(el.message);
        return false;
      }
      return true;
    });

    console.log(noErrors.every((el) => el === true));

    if (noErrors.every((el) => el === true)) {
      const duplicateGP = itemsDuplicated.map(
        ({error, value, newCodes}: any) => {
          if (error) return;
          handleDuplicateGP({value, newCodes});
        }
      );

      Promise.all(duplicateGP).then(() => {
        setTimeout(() => {
          dispatch(setType(""));
          dispatch(setOpenModal(false));
          setItemInfoSelected([]);
        }, 1000);
      });
    }
  };

  const handleChange = (
    e: any,
    id: string,
    claimed: string,
    startQty: string,
    prizeName: string,
    merchantId: string
  ) => {
    setNewRedeemptionCodes({
      ...newRedeemptionCodes,
      [id]: {
        id,
        prizeName,
        merchantId,
        claimed,
        startQty,
        codes: e.target.value,
      },
    });
  };

  return (
    <Modal onClose={() => setItemInfoSelected([])}>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <p className="mt-4 text-center">Duplicate item</p>
        </div>
        <div className="px-6 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <div>
            {itemInfoSelected?.map((el) => (
              <div key={el.prizeId || el.id}>
                <p className="text-sm mt-4"></p>
                <Textarea
                  label={`Add ${el.claimed === 0 ? el.startQty : el.claimed} new
                  redeemption codes to the new item, (duplication of ${
                    el.prizeName
                  } item)`}
                  classesInput="text-sm"
                  placeholder="Ех. code1, code2, code3, code4. (Separeted by comma)"
                  classes="mb-5"
                  rows={3}
                  required={true}
                  value={newRedeemptionCodes[el.prizeId]?.codes}
                  onChange={(e) =>
                    handleChange(
                      e,
                      el.prizeId,
                      el.claimed,
                      el.startQty,
                      el.prizeName,
                      el.merchantId
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div className="mt-2 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              setItemInfoSelected([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="add"
            onClick={() => {
              handleSubmit();
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddRedeemedCodeModal;
