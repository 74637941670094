import React from "react";

function Info({ children, hasArrow = true, classes }: any) {
  return (
    <div>
      <div
        className={`relative bg-orange-100 border-4 border-l-orange-300 border-transparent p-3 ${
          hasArrow && "ml-8 arrow_box"
        } ${classes}`}
      >
        {children}
      </div>
    </div>
  );
}

export default Info;
