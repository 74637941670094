import {TypeClasses} from "./types";

function Description({classes}: TypeClasses) {
  return (
    <svg
      id="merchant"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      className={`${classes}`}
      fill="currentColor"
    >
      <path d="M320 816h320v-80H320v80Zm0-160h320v-80H320v80Zm-80 320q-33 0-56.5-23.5T160 896V256q0-33 23.5-56.5T240 176h320l240 240v480q0 33-23.5 56.5T720 976H240Zm280-520V256H240v640h480V456H520ZM240 256v200-200 640-640Z" />
    </svg>
  );
}

export default Description;
