import { TypeClasses } from "./types";

function Zoom({ classes }: TypeClasses) {
  return (
    <svg
      id="zoom"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <path d="M3.5 20.5V15H5v2.95l3.35-3.35 1.05 1.05L6.05 19H9v1.5Zm11.5 0V19h2.95l-3.35-3.35 1.05-1.05L19 17.95V15h1.5v5.5ZM8.35 9.4 5 6.05V9H3.5V3.5H9V5H6.05L9.4 8.35Zm7.3 0L14.6 8.35 17.95 5H15V3.5h5.5V9H19V6.05Z" />
    </svg>
  );
}

export default Zoom;
