import { TypeClasses } from "./types";

function Merchants({ classes }: TypeClasses) {
  return (
    <svg
      id="merchant"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="storefront_FILL0_wght400_GRAD0_opsz48"
        d="M20.959,13.222V20.85a1.381,1.381,0,0,1-1.343,1.35H6.16a1.381,1.381,0,0,1-1.343-1.35V13.222a2.55,2.55,0,0,1-.828-1.328,2.829,2.829,0,0,1,.045-1.575L5,7.282a1.768,1.768,0,0,1,.627-.945A1.665,1.665,0,0,1,6.653,6H19.034A1.778,1.778,0,0,1,20.78,7.282l.985,3.037a2.761,2.761,0,0,1,.034,1.575A2.618,2.618,0,0,1,20.959,13.222Zm-6.045-.7a1.529,1.529,0,0,0,1.1-.428,1.129,1.129,0,0,0,.358-1.035L15.81,7.35H13.571v3.712a1.514,1.514,0,0,0,.381,1.024A1.22,1.22,0,0,0,14.914,12.525Zm-4.187,0a1.463,1.463,0,0,0,1.063-.428,1.4,1.4,0,0,0,.437-1.035V7.35H9.989l-.56,3.712a1.18,1.18,0,0,0,.313,1.024,1.287,1.287,0,0,0,.985.439Zm-4.075,0a1.305,1.305,0,0,0,.929-.371,1.468,1.468,0,0,0,.459-.911L8.623,7.35H6.384l-1.03,3.285a1.423,1.423,0,0,0,.179,1.294A1.278,1.278,0,0,0,6.653,12.525Zm12.471,0a1.313,1.313,0,0,0,1.131-.585,1.417,1.417,0,0,0,.19-1.3L19.414,7.35H17.176l.582,3.892a1.468,1.468,0,0,0,.459.911A1.279,1.279,0,0,0,19.123,12.525ZM6.16,20.85H19.616v-7q.022.022-.146.022h-.347a2.475,2.475,0,0,1-1.063-.236,3.564,3.564,0,0,1-1-.731,2.53,2.53,0,0,1-.9.709,2.7,2.7,0,0,1-1.187.259,3.121,3.121,0,0,1-1.153-.191,2.768,2.768,0,0,1-.929-.641,2.16,2.16,0,0,1-.851.619,3.012,3.012,0,0,1-1.164.214,2.913,2.913,0,0,1-1.231-.247,2.52,2.52,0,0,1-.918-.72,4.7,4.7,0,0,1-1.052.72,2.36,2.36,0,0,1-1.03.247h-.3a.507.507,0,0,1-.19-.022Zm13.456,0h0Z"
        transform="translate(-0.897 -2)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Merchants;
