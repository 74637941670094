import {useEffect, useState} from "react";
import debounce from "lodash.debounce";
import {v4 as uuidv4} from "uuid";

// Components
import Searchbar from "components/Common/Searchbar";
import Subtitle from "components/Common/Subtitle";
import Button from "components/Common/Button";
import Modal from "components/Modal";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {
  useCreateSubCategoriesCategoriesMutation,
  useDeleteSubCategoriesCategoriesMutation,
  useGetAdminCategoriesTreeQuery,
  useGetCategoriesTreeQuery,
} from "store/api/categoriesApi";

// Utils
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";

// Assets
import {ArrowNext} from "assets/Arrows";
import Input from "components/Common/Input";
import {toast} from "react-toastify";
import Spinner from "components/Common/Spinner";
import DeleteModal from "components/Modal/DeleteModal";
import DropdownCategory from "components/Common/DropdownCategory";
import Search from "assets/Search";

function ConfigCategories() {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);

  const [searchItems, setSearchItems] = useState("");
  const [selectedParent, setSelectedParent] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
  const [subSubCategories, setSubSubCategories] = useState<any>([]);

  const [currentId, setCurrentId] = useState("");
  const [newSubSubCategory, setNewSubSubCategory] = useState<any>("");

  const [expanded, setExpanded] = useState<any>([]);

  const [dropdown, openDropdown] = useState(false);
  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);

  // Create subsubcategory
  const [createSubSubCategoryMutation] =
    useCreateSubCategoriesCategoriesMutation();
  const [deleteSubSubCategoryMutation] =
    useDeleteSubCategoriesCategoriesMutation();

  // Get the categories list dropdown
  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    refetch,
    // @ts-ignore
  } = useGetAdminCategoriesTreeQuery({dmaCode: ""});

  console.log("selectedSub", selectedSubCategory);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        setSelectedParent(currentElement);
        setSubSubCategories([]);

        console.log("first");

        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        setSelectedParent({
          id: rootParentElement.id,
          name: rootParentElement.name,
        });

        setSelectedSubCategory({
          id: parentElement.id,
          name: parentElement.name,
        });

        if (currentElement?.subItems?.length > 0) {
          setSubSubCategories([]);
        } else {
          setSubSubCategories([currentElement]);
        }

        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement.id,
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      } else {
        console.log("third");

        // findItemById(dataCategories, id.toString())

        setSelectedParent({
          id: parentElement.id,
          name: parentElement.name,
        });

        setSelectedSubCategory(currentElement);
        setSubSubCategories(currentElement.subItems);

        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      }
    });
  }, [elementsChecked, dataCategories]);

  const handleToggle = (category: any) => {
    setSelectedSubCategory(category);

    if (category.subItems && category.subItems.length > 0) {
      setSubSubCategories(category.subItems);
    } else {
      setSubSubCategories([]);
    }
  };

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    setElementsChecked([id]);
    setElementsName([name]);
    setSearchItems(name);

    if (!checked) {
      setElementsChecked(elementsChecked.filter((item: any) => item !== id));
      setElementsName(elementsName.filter((item: any) => item !== name));
      setSearchItems("");
    }
  };

  const onChangeSearch = (value: any) => {
    setSearchItems(value);
  };

  console.log(subSubCategories);

  const debouncedOnChangeSearch = debounce(onChangeSearch, 500);

  const rendererParentSide = () => {
    if (isLoadingCategories) {
      return (
        <div className="flex justify-center items-center w-full h-full">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-8 h-8 mr-5 mt-4" />
        </div>
      );
    }

    return dataCategories?.map((item: any) => (
      <div
        key={item.id}
        className={`flex justify-between p-4 border-b border-b-gray-200 cursor-pointer ${
          item.id === selectedParent?.id ? "bg-primary-purple text-white" : ""
        } `}
        onClick={() => {
          setSelectedParent(item);
          setSelectedSubCategory([]);
          setSubSubCategories([]);
        }}
      >
        <p className="w-[40%]">{item.name}</p>
        <p className="w-[20%] text-center">{item.id}</p>
        <p className="text-sm w-[20%] text-center">{item.retailerCount}</p>
        <p className="text-sm w-[20%] text-center">{item.prizeCount}</p>
        <p className="font-medium text-2xl w-[5%]">
          <ArrowNext classes="h-5 w-5" />
        </p>
      </div>
    ));
  };

  const rendererSubCategory = (category: any) => {
    const hasChildren = category.subItems && category.subItems.length > 0;

    return (
      <div
        className={`flex flex-col justify-between p-4 border-b border-b-gray-200 cursor-pointer ${
          category.id === selectedSubCategory?.id
            ? "bg-primary-purple text-white"
            : ""
        }`}
        key={category?.id || category?.code}
      >
        <div
          className="flex items-center justify-between "
          onClick={() => handleToggle(category)}
        >
          <p className={`font-medium text-sm cursor-pointer w-[35%]`}>
            {category?.name || category?.description}
          </p>
          <p className="w-[20%] text-center">{category.id}</p>
          <p className="text-sm w-[20%] text-center">
            {category.retailerCount}
          </p>
          <p className="text-sm w-[20%] text-center">{category.prizeCount}</p>

          <div className="flex w-[5%]">
            {hasChildren && (
              <div className={` flex justify-end`}>
                <div>
                  <ArrowNext classes="h-5 w-5" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getSubCategories = (item: any) => {
    if (selectedParent.id === item.id) {
      if (searchItems.length > 2) {
        if (selectedParent?.subItems?.length) {
          return selectedParent?.subItems?.map((el: any) =>
            rendererSubCategory(el)
          );
        } else {
          const filteredData = getFilteredNames(
            item?.subItems,
            searchItems,
            ""
          );
          return filteredData?.map((el: any) => rendererSubCategory(el));
        }
      } else {
        return item?.subItems?.map((el: any) => rendererSubCategory(el));
      }
    }
  };

  const handleAddNewSubSubCategory = async (e: any) => {
    const data = {
      name: newSubSubCategory,
      parentId: selectedSubCategory?.id,
    };

    const toastPromise = toast.promise(
      createSubSubCategoryMutation({
        data,
      }),
      {
        pending: "Creating Subsubcategory",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating subsubcategor</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Subsubcategory Created");
          const newSubSubCategory = res?.data;

          setSubSubCategories((prev: any) => [...prev, newSubSubCategory]);

          setNewSubSubCategory("");
          dispatch(setOpenModal(false));
          dispatch(setType(""));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while creating subsubcategory");
      });
  };

  const handleDeleteSubSubCategory = async (e: any) => {
    const toastPromise = toast.promise(
      deleteSubSubCategoryMutation({
        subcategoryID: currentId,
      }),
      {
        pending: "Deleting Subsubcategory",
      }
    );
    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting subsubcategory</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Subsubcategory Deleted");

          // Filter subsubcategories removing the currentId
          setSubSubCategories((prev: any) => {
            return prev.filter((el: any) => el.id !== parseInt(currentId));
          });

          dispatch(setOpenModal(false));
          dispatch(setType(""));
          setCurrentId("");
          setNewSubSubCategory("");
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while deleting subsubcategory");
      });
  };

  const handleCloseModal = () => {
    setCurrentId("");
    setNewSubSubCategory("");
    dispatch(setOpenModal(false));
  };

  const handleClearAll = () => {
    setElementsChecked([]);
    setElementsName([]);
    setSelectedParent({});
    setSearchItems("");
    setDowpdownSearchFilter("");
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="flex">
        <div className="w-full bg-white p-4 border-gray-300">
          <Subtitle classes="mb-4">Category Tree</Subtitle>
          <DropdownCategory
            hasLabel={true}
            label="Search by category, subcategory or subsubcategory"
            openDropdown={openDropdown}
            dropdown={dropdown}
            elementsChecked={elementsChecked}
            elementsName={elementsName}
            handleClickCheckbox={handleClickCheckbox}
            dataCategories={getFilteredNames(
              dataCategories,
              dropdownSearchFilter,
              ""
            )}
            isErrorCategories={isErrorCategories}
            isLoadingCategories={isLoadingCategories}
            classNameSelect="w-full"
            className="w-1/3"
            classAutoComplete="w-1/3"
            showNames={true}
            handleClickDropdown={() => handleClearAll()}
            expandItems={dropdownSearchFilter}
            hasCustomValue={false}
            hasSaveBtn={false}
            hasClearAll={true}
            hasSearchValue={{
              show: true,
              content: (
                <div className="flex mb-3 justify-between">
                  <div className="flex items-center w-full">
                    <label htmlFor="search">
                      <Search classes="h-6 w-6 mr-2" />
                    </label>
                    <input
                      id="search"
                      name="search"
                      type="text"
                      placeholder="Search category"
                      className="decoration-none outline-none w-full"
                      onChange={(e: any) => {
                        setDowpdownSearchFilter(e.target.value);
                      }}
                      value={dropdownSearchFilter}
                    />
                  </div>
                </div>
              ),
            }}
          />
          <div className="grid grid-cols-2 mt-12 h-[700px]">
            <div className="border-r-2 border-r-gray-200">
              <div className="flex justify-between px-5 py-3 border-b border-b-gray-200 bg-gray-100">
                <p className="font-semibold text-sm w-[40%]">Category</p>
                <p className="font-semibold text-sm w-[20%] text-center">ID</p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Merchants
                </p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Items
                </p>
                <p className="font-semibold text-sm w-[5%] text-center"></p>
              </div>
              {rendererParentSide()}
            </div>
            <div className="overflow-y-auto">
              <div className="flex  px-5 py-3 border-b border-b-gray-200 bg-gray-100 sticky top-0">
                <p className="font-semibold text-sm w-[35%]">Subcategory</p>
                <p className="font-semibold text-sm w-[20%] text-center">ID</p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Merchants
                </p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Items
                </p>
              </div>
              {dataCategories?.map((item: any) => getSubCategories(item))}
            </div>
          </div>
        </div>
        <div className="w-1 mx-5 bg-gray-200"></div>
        <div className="w-5/12 bg-white rounded border border-gray-300">
          <div className="p-4 flex justify-between">
            <Subtitle>Subsubcategories</Subtitle>
            <Button
              variant={!selectedSubCategory?.id ? "disabled" : "add"}
              onClick={
                !selectedSubCategory?.id
                  ? () => {}
                  : () => {
                      dispatch(setOpenModal(true));
                      dispatch(setType("addNewSubSubCategory"));
                    }
              }
            >
              + Add
            </Button>
          </div>
          <div>
            <div className="flex justify-between px-5 py-3 border-b border-b-gray-200 bg-gray-100 w-full">
              <p className="font-semibold text-sm w-[35%]">Filters</p>
              <p className="font-semibold text-sm w-[20%]">ID</p>
              <p className="font-semibold text-sm w-[20%]">Merchants</p>
              <p className="font-semibold text-sm w-[20%] text-center">Items</p>
              <p className="font-semibold text-sm w-[5%]"></p>
            </div>
          </div>
          <div>
            {subSubCategories?.map((item: any) => {
              return item.status === "DELETED" ? (
                <></>
              ) : (
                <div
                  key={item.id}
                  id={item.id}
                  className="flex justify-between p-4 border-b border-b-gray-200 cursor-pointer"
                >
                  <p className="text-sm w-[35%]">{item.name}</p>
                  <p className="text-sm w-[20%]">{item.id}</p>
                  <p className="text-sm w-[20%] text-center">
                    {item.retailerCount}
                  </p>
                  <p className="text-sm w-[20%] text-center">
                    {item.prizeCount}
                  </p>
                  <div className="w-[5%]">
                    <div
                      id={item.id}
                      className="flex cursor-pointer justify-center items-center font-bold border-2 rounded-full border-red-500 text-red-500 w-5 h-5"
                      onClick={(e: any) => {
                        setCurrentId(e.target.id);
                        dispatch(setType("deleteSubSubCategory"));
                        dispatch(setOpenModal(true));
                      }}
                    >
                      {" "}
                      -{" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {modal.type === "addNewSubSubCategory" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            <div>
              <h1
                className={
                  "font-medium text-2xl px-5 text-start  mb-4 tracking-normal"
                }
              >
                Add new Subsubcategory
              </h1>
              <hr />
              <div className="px-5 my-5">
                <Input
                  required
                  type={"text"}
                  label="Subsubcategory Name"
                  value={newSubSubCategory}
                  placeholder="Enter subsubcategory name"
                  onChange={(e) => setNewSubSubCategory(e.target.value)}
                />
              </div>

              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => handleCloseModal()}
                  variant="normal"
                  classes="text-center"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  variant="add"
                  classes="text-center"
                  type="button"
                  onClick={(e) => handleAddNewSubSubCategory(e)}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal.type === "deleteSubSubCategory" && (
        <DeleteModal
          elementsChecked={[parseInt(currentId)]}
          setElementsChecked={() => {}}
          dataSource={subSubCategories}
          matchWidhId="id"
          pendingMsg="Deleting category"
          returnValue="name"
          toastMessageId="name"
          type=""
          typeOfDelete="subsubcategory"
          deleteCallback={handleDeleteSubSubCategory}
          cleanFn={() => setCurrentId("")}
        />
      )}
    </div>
  );
}

export default ConfigCategories;
