import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Components
import Select from "components/Common/Input/Select";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";
import Spinner from "components/Common/Spinner";

// Redux
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { useGetMerchantActivityQuery } from "store/api/merchantsApi";
import { setURLFilters, setFiltersLoading } from "store/filters/filtersSlice";

const baseURL = "?";

function MerchantActivity({ headers }: any) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const [params, setParams] = useState(`${baseURL}`);

  const {
    data: dataActivityMerchant,
    isLoading: isLoadingActivityMerchant,
    isError: isErrorActivityMerchant,
    refetch,
  } = useGetMerchantActivityQuery({ id, params });

  useEffect(() => {
    setParams(
      `${baseURL}${filters.page}${filters.limit}${
        filters.urlFilters !== "&action=" ? filters.urlFilters : ""
      }`
    );
  }, [filters]);

  useEffect(() => {
    dispatch(setFiltersLoading(false));
  }, [dataActivityMerchant]);

  const handleSortBy = (value: string) => {
    let filterBy;
    if (value !== "all") {
      filterBy = "&action=" + value;
    } else {
      filterBy = "";
    }
    dispatch(setFiltersLoading(true));
    dispatch(setURLFilters(filterBy));
  };

  return (
    <div>
      <div className="px-5 bg-white flex items-center rounded justify-between">
        <Subtitle classes={"py-5"}>Activity</Subtitle>
        <div className="flex items-center">
          {filters.filtersLoading && (
            <div className="mr-5 mt-0.5">
              <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-6 h-6" />
            </div>
          )}
          <Select
            classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
            onChange={(e: any) => handleSortBy(e.target.value)}
          >
            <>
              <option value="" disabled selected>
                Filter By
              </option>
              <option value="">All</option>
              <option value="LOGIN">Login</option>
              <option value="INVITE_USER">User Invitation</option>
              <option value="CREATE_PRIZE">Items Creation</option>
              <option value="SCAN_PRIZE">Item Scanned</option>
              <option value="UPLOAD_PRIZE_IMAGE ">Upload Item Image</option>
            </>
          </Select>
        </div>
      </div>
      <Table
        headers={headers}
        data={
          dataActivityMerchant !== undefined && dataActivityMerchant.logRecords
            ? dataActivityMerchant?.logRecords
            : []
        }
        hasCheckbox={false}
        loadingData={isLoadingActivityMerchant}
        errorData={isErrorActivityMerchant}
        refetch={refetch}
        show={["userEmail", "userRole", "logDate", "action"]}
        origin="merchant_activity"
        results={dataActivityMerchant?.totalCount}
      />
    </div>
  );
}

export default MerchantActivity;
