import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  isSidebarCategoryOpen: boolean;
  type: string;
  imageModal: string;
  id: string;
  employee: {
    id: string;
    data: {};
  };
}

const initialState: ModalState = {
  isOpen: false,
  isSidebarCategoryOpen: false,
  type: "",
  imageModal: "",
  id: "",
  employee: {
    id: "",
    data: {},
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setSidebarCategoryOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isSidebarCategoryOpen = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setImageModal: (state, action: PayloadAction<string>) => {
      state.imageModal = action.payload;
    },
    setIdSelectedToDelete: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setEmployeeSelectedToDelete: (state, action: PayloadAction<any>) => {
      state.employee = action.payload;
    },
  },
});

export const {
  setOpenModal,
  setSidebarCategoryOpenModal,
  setType,
  setImageModal,
  setIdSelectedToDelete,
  setEmployeeSelectedToDelete,
} = modalSlice.actions;

export default modalSlice.reducer;
