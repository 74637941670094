import { useState } from "react";

function getDefaultSorting(defaultTableData: any, headers: any) {
    if (!Array.isArray(defaultTableData)) return [];
    const sorted = [...defaultTableData].sort((a, b) => {
        const filterColumn = headers.filter((column: any) => column.sortbyOrder);

        // Merge all array objects into single object and extract accessor and sortbyOrder keys
        let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
            {},
            ...filterColumn
        );

        if (a[accessor] === null) return 1;
        if (b[accessor] === null) return -1;
        if (a[accessor] === null && b[accessor] === null) return 0;

        const ascending = a[accessor]?.toString()
            .localeCompare(b[accessor]?.toString(), "en", {
                numeric: true,
            });

        return sortbyOrder === "asc" ? ascending : -ascending;
    });
    return sorted;
}

export const useSortableTable = (data: any, headers: any, origin: string) => {
    const [tableData, setTableData] = useState<any>(getDefaultSorting(data, headers));

    const handleSorting = (sortField: any, sortOrder: any) => {

        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (sortField === "data_creation") {
                    if (a.createdAt === null) return 1;
                    if (b.createdAt === null) return -1;
                    if (a.createdAt === null && b.createdAt === null) return 0;
                    return (
                        a.createdAt?.toString().localeCompare(b.createdAt?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                }
                if (sortField === "merchant_name" && origin === "prize-list-overview") {
                    if (a.merchantName === null) return 1;
                    if (b.merchantName === null) return -1;
                    if (a.merchantName === null && b.merchantName === null) return 0;
                    return (
                        a.merchantName?.toString().localeCompare(b.merchantName?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                }
                if (sortField === "prize_type" && origin === "prize-list-overview") {
                    if (a.prizeType === null) return 1;
                    if (b.prizeType === null) return -1;
                    if (a.prizeType === null && b.prizeType === null) return 0;
                    return (
                        a.prizeType?.toString().localeCompare(b.prizeType?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                }
                if (sortField === "expired_at" && origin === "prize-list-overview") {
                    if (a.expiredAt === null) return 1;
                    if (b.expiredAt === null) return -1;
                    if (a.expiredAt === null && b.expiredAt === null) return 0;
                    return (
                        a.expiredAt?.toString().localeCompare(b.expiredAt?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                }

                if (sortField === "items") {
                    if (a.itemsCount === null) return 1;
                    if (b.itemsCount === null) return -1;
                    if (a.itemsCount === null && b.itemsCount === null) return 0;
                    return (
                        a.itemsCount?.toString().localeCompare(b.itemsCount?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                } else {
                    if (a[sortField] === null) return 1;
                    if (b[sortField] === null) return -1;
                    if (a[sortField] === null && b[sortField] === null) return 0;
                    return (
                        a[sortField]?.toString().localeCompare(b[sortField]?.toString(), "en", {
                            numeric: true,
                        }) * (sortOrder === "asc" ? 1 : -1)
                    );
                }
            });
            setTableData(sorted);
        }
    };

    return [tableData, handleSorting, setTableData];
};