// Components
import {useEffect, useState} from "react";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import {useLocation, useParams} from "react-router-dom";
import Modal from "components/Modal";
import Button from "components/Common/Button";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {
  useLazyApproveReqRegisterQuery,
  useLazyConfirmEmailQuery,
  useLazyDeclineReqRegisterQuery,
} from "store/api/authApi";
import {RootState} from "store/store";
import {toast} from "react-toastify";
import AwaitApproveRegister from "components/Modal/AwaitApproveRegister";
import AwaitRejectRegister from "components/Modal/AwaitRejectRegister";
import Spinner from "components/Common/Spinner";

let limitRender = 0;
function Auth() {
  const modal = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();
  const location = useLocation();

  const [typeReq, setTypeReq] = useState("");
  const [backTo, setBackTo] = useState("login");

  const [
    confirmEmail,
    {
      data: dataConfirmEmail,
      isLoading: isLoadingConfirmEmail,
      isError: isErrorConfirmEmail,
    },
  ] = useLazyConfirmEmailQuery();

  const [
    approveReq,
    {
      data: dataApprove,
      isLoading: isLoadingReqApprove,
      isError: isErrorReqApprove,
      error: errorApprove,
    },
  ] = useLazyApproveReqRegisterQuery();

  const [
    declineReq,
    {
      data: dataDecline,
      isLoading: isLoadingReqDecline,
      isError: isErrorReqDecline,
      error: errorDecline,
    },
  ] = useLazyDeclineReqRegisterQuery();

  useEffect(() => {
    if (location.pathname?.includes("confirm-email") && limitRender <= 0) {
      handleConfirmEmail();
      limitRender++;
    } else if (location.pathname?.includes("approve") && limitRender <= 0) {
      handleReqByStatus();
    } else if (location.pathname?.includes("decline") && limitRender <= 0) {
      handleReqByStatus();
    }
  }, []);

  const handleReqByStatus = async () => {
    const urlSplitted = location.pathname.split("/");
    const token = urlSplitted[2];

    if (urlSplitted.includes("approve")) {
      setTypeReq("granted");

      const toastPromise = toast.promise(approveReq(token), {});
      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while approving user</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            dispatch(setType("userApproveDecline"));
            dispatch(setOpenModal(true));
          }
        })
        .catch((err) => {
          toast.error("Error while approving user");
        });
    } else if (urlSplitted.includes("decline")) {
      setTypeReq("refused");

      const toastPromise = toast.promise(declineReq(token), {});
      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while declining user</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            dispatch(setType("userApproveDecline"));
            dispatch(setOpenModal(true));
          }
        })
        .catch((err) => {
          toast.error("Error while declining user");
        });
    } else {
      return;
    }
  };

  const handleConfirmEmail = async () => {
    const token = location.pathname?.split("/")[2];
    const toastPromise = toast.promise(confirmEmail(token), {
      pending: "Confirming Email",
    });

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while confirming email</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success(
            "Your user account is created, please wait for the super admin to approve your account"
          );
        }
      })
      .catch((err) => {
        toast.error("Error while confirming email");
      });
  };

  const renderer = () => {
    if (backTo === "register") {
      return <Register setBackTo={setBackTo} />;
    } else if (backTo === "forgot") {
      return <ForgotPassword setBackTo={setBackTo} />;
    } else {
      return <Login setBackTo={setBackTo} />;
    }
  };

  return (
    <div className="flex h-full">
      <div className="sm:w-0 md:w-2/5 lg:w-3/5 h-screen bg-gradient-to-bl from-primary-purple to-tertiary-purple-dark"></div>
      <div className="w-full md:w-3/5 lg:w-2/5 h-screen bg-slate-100 flex justify-center items-center">
        {renderer()}
      </div>
      {modal.type === "awaitRejectRegister" && <AwaitRejectRegister />}
      {modal.type === "awaitApproveRegister" && <AwaitApproveRegister />}
      {modal.type === "userApproveDecline" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
              <h1 className="mt-4">Access Request</h1>
              {(isLoadingReqApprove || isLoadingReqDecline) && (
                <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
              )}
              {(dataApprove || dataDecline) &&
                !isLoadingReqApprove &&
                !isLoadingReqDecline &&
                !isErrorReqApprove &&
                !isErrorReqDecline && (
                  <p className="text-sm mt-4 text-center">
                    Access {typeReq} successfully
                  </p>
                )}
              {(isErrorReqApprove || isErrorReqDecline) &&
                !isLoadingReqApprove &&
                !isLoadingReqDecline && (
                  <p className="text-sm mt-4 text-center">
                    {errorApprove?.data?.message || errorDecline?.data?.message
                      ? `${
                          errorApprove?.data?.message !== undefined
                            ? errorApprove?.data?.message
                            : ""
                        } ${
                          errorDecline?.data?.message !== undefined
                            ? errorDecline?.data?.message
                            : ""
                        }`
                      : "An error occurred trying to change the access, try again later"}
                  </p>
                )}
            </div>
            <hr />
            <div className="mt-8 px-6 flex justify-center items-center">
              <Button
                variant="normal"
                onClick={() => {
                  dispatch(setType(""));
                  dispatch(setOpenModal(false));
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {modal.type === "reqByStatus" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
              <h1 className="my-4">Error</h1>
              <p className="text-base">
                You must be logged into your admin account to make this request
              </p>
            </div>
            <hr />
            <div className="mt-4 px-6 flex justify-center items-center">
              <Button
                variant="normal"
                onClick={() => {
                  dispatch(setOpenModal(false));
                  dispatch(setType(""));
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Auth;
