import {useState} from "react";
import ReactDOM from "react-dom/client";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import ItemOption from "components/Options";
import MenuItem from "components/Options/MenuItem";

// Assets
import {ArrowPrev} from "assets/Arrows";
import Select from "components/Common/Input/Select";

// Redux
import {useDispatch} from "react-redux";
import {
  setEmployeeSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";
import {useUpdateEmployeeListMutation} from "store/api/merchantsApi";

function ButtonOptionsTable({
  element,
  options,
  typeOptions,
  origin,
  dropdown,
  idTableShow,
  setIdTableShow,
  setMerchantsStatus,
}: any) {
  const {id} = useParams();
  const dispatch = useDispatch();

  const [employeeData, setEmployeeData] = useState({
    ...element,
    role: element?.role,
  });
  const [showOptionsIndividual, setShowOptionsIndividual] = useState(["edit"]);

  const [changeDataEmployeeMutation] = useUpdateEmployeeListMutation();

  const handleAddSubtablesActives = () => {
    setIdTableShow((prev: any) => {
      if (prev.includes(element.prizeId)) {
        return prev.filter((el: any) => el !== element.prizeId);
      }
      return [...prev, element.prizeId];
    });
  };

  const changeStatusEmployee = async ({
    idEmployee,
    idMerchant,
    data,
  }: {
    idEmployee: number | string;
    idMerchant: number | string;
    data: any;
  }) => {
    const toastPromise = toast.promise(
      changeDataEmployeeMutation({
        idUser: idEmployee?.toString(),
        idRetailer: idMerchant?.toString(),
        data: {
          status: data.status,
          role: data.role.toUpperCase(),
        },
      }),
      {
        pending: "Changing employee role",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing employee role</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Employee Role Changed");
        }
      })
      .catch((err) => {
        toast.error("Error while changing employee role");
      });
  };

  const handleCheckIndividualOption = (e: any, type: string, element: any) => {
    const parent = e.currentTarget.parentElement.parentElement;
    if (parent.tagName !== "TR") return;
    const roleChild = parent.children[3];
    const role = ReactDOM.createRoot(roleChild as HTMLElement);

    if (type === "edit") {
      setShowOptionsIndividual(["delete", "save"]);

      // console.log(employeeData);

      role.render(
        <Select
          onChange={(e: any) =>
            setEmployeeData({...employeeData, role: e.target.value})
          }
          classesSelect="py-2 px-2 bg-white border border-gray-400 text-gray-500 !outline-none !focus:outline-none"
        >
          <option disabled>Role</option>
          <option
            value="Captain"
            selected={employeeData?.role?.toLowerCase() === "captain"}
          >
            Captain
          </option>
          <option
            value="Officer"
            selected={employeeData?.role?.toLowerCase() === "officer"}
          >
            Officer
          </option>
          <option
            value="crew_member"
            selected={employeeData?.role?.toLowerCase() === "crew_member"}
          >
            Crew Member
          </option>
        </Select>
      );

      // status.render(<Input value={element.email} label="" />);
    } else if (type === "delete") {
      dispatch(setType("deleteEmployee"));
      dispatch(setOpenModal(true));
      dispatch(setEmployeeSelectedToDelete({element: element, id: id}));

      // setShowOptionsIndividual(["edit"]);
      // role.render(employeeData.role);
    } else {
      if (employeeData.role.toLowerCase() !== element.role.toLowerCase()) {
        changeStatusEmployee({
          idEmployee: employeeData.id,
          idMerchant: employeeData.retailerID,
          data: employeeData,
        });
      }

      const roleFormatted = employeeData.role.includes("_")
        ? employeeData.role.split("_")[0].charAt(0).toUpperCase() +
          employeeData.role.split("_")[0].slice(1).toLowerCase() +
          " " +
          employeeData.role.split("_")[1].charAt(0).toUpperCase() +
          employeeData.role.split("_")[1].slice(1).toLowerCase()
        : employeeData.role.charAt(0).toUpperCase() +
          employeeData.role.slice(1).toLowerCase();

      setShowOptionsIndividual(["edit"]);
      role.render(roleFormatted);
    }
  };

  return (
    <td className="py-4 pr-2 font-medium text-end">
      {options && typeOptions === "individual" && (
        <div className="flex">
          {Array.isArray(options)
            ? options?.map((option: any, key: number) => {
                if (!option) return;

                return (
                  <button
                    onClick={(e) => {
                      origin !== "prize-list"
                        ? handleCheckIndividualOption(e, option.title, element)
                        : option?.onClick(element);
                    }}
                    key={key}
                    className={`mr-4 ${
                      !showOptionsIndividual.includes(option.title) &&
                      origin !== "prize-list" &&
                      "hidden"
                    }`}
                  >
                    {option.svg}
                  </button>
                );
              })
            : "You must pass an array of objects"}
        </div>
      )}
      {options && typeOptions === "group" && (
        <ItemOption classes={"hover:bg-primary-white active:bg-primary-white"}>
          <div className="px-1 py-1 ">
            {Array.isArray(options)
              ? options?.map((option: any, key: number) => {
                  if (!option) return;

                  return (
                    <MenuItem
                      onClick={option.onClick}
                      key={key}
                      type={option.type}
                      title={option.title}
                      svg={option.svg}
                      element={element}
                      elementID={element.id || element.dmaCode}
                      setMerchantsStatus={setMerchantsStatus}
                    />
                  );
                })
              : "You must pass an array of objects"}
          </div>
        </ItemOption>
      )}
      {dropdown && (
        <span
          className="cursor-pointer"
          onClick={() => handleAddSubtablesActives()}
        >
          <ArrowPrev
            classes={`w-6 h-6 transition ease-in-100 ${
              idTableShow.includes(element.prizeId) ? "rotate-90" : "-rotate-90"
            }`}
          />
        </span>
      )}
    </td>
  );
}

export default ButtonOptionsTable;
