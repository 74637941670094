import {useState, memo, useEffect} from "react";

// Components
import Autocomplete from "components/Autocomplete";

// Redux
import {useDispatch, useSelector} from "react-redux";
import type {RootState} from "store/store";
import {
  merchantsApi,
  useGetAllMerchantsNameQuery,
} from "store/api/merchantsApi";
interface SearchbarTypes {
  placeholder?: string;
  classes?: string;
  classesContainer?: string;
  useTags?: boolean;
  getIDMerchant?: Function | undefined;
  dataMerchantsName?: any;
  callback?: any;
  getSelectedOption?: Function | undefined;
  origin?: string;
  onClick?: Function;
  hasAutocomplete?: boolean;
}

function Searchbar({
  placeholder,
  classes,
  classesContainer,
  useTags,
  getIDMerchant,
  callback,
  getSelectedOption,
  origin,
  onClick,
  hasAutocomplete,
}: SearchbarTypes) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);
  const data: any = useSelector(
    (state: RootState) => state.merchants.merchantsName
  );
  const filters = useSelector((state: RootState) => state.filters.filters);

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (user.refetchEndpoint) {
      setTimeout(() => {
        dispatch(merchantsApi.util.invalidateTags(["GetMerchantName"]));
        dispatch(merchantsApi.util.invalidateTags(["GetMerchantArchivedName"]));
      }, 200);
    }
  }, [user]);

  const arr = data?.map((el: any) => {
    return `${el.name}~id:${el.id}`;
  });

  return (
    <>
      <Autocomplete
        options={arr ?? []}
        value={selectedOption}
        onChange={setSelectedOption}
        classes={classes}
        classesContainer={classesContainer}
        placeholder={placeholder}
        useTags={useTags}
        callback={callback}
        origin={origin}
        filters={filters}
        // @ts-ignore
        getIDMerchant={getIDMerchant}
        getSelectedOption={getSelectedOption}
        onClick={onClick}
        hasAutocomplete={hasAutocomplete}
      />
    </>
  );
}

export default memo(Searchbar);
