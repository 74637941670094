import { useState } from "react";
// Components
import Disclosure from "../Disclosure";
import Info from "../Info";
import Input from "components/Common/Input";

function Shop() {
  const [firstBox, setFirstBox] = useState({
    buyingCoupons: 0,
    numberOfPositions: 0,
    numberOfAdvertising: 0,
  })

  const rangeOfNumbers = ['if >= 100%', 'if <= 75%', 'if <= 50%', 'if <= 40%', 'if <= 30%', 'if <= 20%', 'if <= 10%', 'if <= 5%']

  const [secondBox, setSecondBox] = useState(
    rangeOfNumbers.map(range => {
      return {
        range,
        number: 0,  
        expirationDate: 0 
      }
  }))

  const updateFormData = (index: number, value: any, key: string) => {
    const newState: any[] = secondBox.map((obj, idx) => {
      if (idx === index) {
        return {...obj, [key]: value};
      }
      return obj;
    });
    return setSecondBox(newState);
  }

  return (
    <div className="flex flex-col">
      {/* First */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form>
            <Disclosure header="Title">
              <div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={firstBox.buyingCoupons}
                    placeholder="0"
                    label="Buying coupons in one hand"
                    classes="mr-8 w-80"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({...firstBox, buyingCoupons: +e.target.value})
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={firstBox.numberOfPositions}
                    placeholder="0"
                    label="The number of positions marked TOP"
                    classes="mr-8 w-80"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({...firstBox, numberOfPositions: +e.target.value})
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={firstBox.numberOfAdvertising}
                    placeholder="0"
                    label="Number of advertising positions"
                    classes="mr-8 w-80"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({...firstBox, numberOfAdvertising: +e.target.value})
                    }}
                  />
                </div>
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">
              Buying Coupons In One Hand
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">
              The Number Of Positions Marked Top
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">
              Number Of Advertising Positions
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
        </Info>
      </div>

      {/* Second */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form onSubmit={(e) => {
            e.preventDefault()
            console.log('secondBox: ', secondBox)
          }}>
            <Disclosure header="Title">
              <div>
              <p className="mb-8">The probability of winning depends on the available number of Prizes</p>
              {secondBox.map((item, index) => {
                return(
                  <div key={item.range}>
                    <div className="flex mb-5 flex-wrap w-">
                      <Input
                        value={item.number}
                        placeholder="0"
                        label={item.range}
                        classes="md:mr-8 md:w-2/5 w-full"
                        classesInput="w-full"
                        onChange={e => updateFormData(index, e.target.value, 'number')}
                      />
                      <Input
                        value={item.expirationDate}
                        placeholder="0"
                        label="Expiration date"
                        classes="md:mr-8 md:w-80 w-full"
                        classesInput="w-full"
                        onChange={e => updateFormData(index, e.target.value, 'expirationDate')}
                      />
                    </div>
                  </div>  
                )
              })}
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">
              The Probability Of Winning Depends On The Available Number Of Prizes
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante. 
              Praesent lectus urna, mattis vitae venenatis sed, venenatis quis arcu. 
              Nulla elementum turpis nec velit sagittis, vitae ultrices quam aliquet. 
              Etiam condimentum velit a quam tempus elementum. Donec dignissim varius 
              commodo. Proin sit amet lacus non neque mollis venenatis vel sit amet augue.
            </p>
          </div>
        </Info>
      </div>

    </div>
  );
}

export default Shop;
