import {ArrowNext} from "assets/Arrows";
import Search from "assets/Search";
import Button from "components/Common/Button";
import DropdownCategory from "components/Common/DropdownCategory";
import Input from "components/Common/Input";
import DatePicker from "components/Common/Input/DatePicker";
import Dropzone from "components/Common/Input/Dropzone";
import Select from "components/Common/Input/Select";
import Textarea from "components/Common/Textarea";
import React, {useState} from "react";
import {Tooltip} from "react-tooltip";
import {addDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import {getFilteredNames} from "utils/workingHoursUtils";

function Item({
  itemData,
  itemInfo,
  setItemData,
  isInputDisabled,
  inputError,
  handleClickNewDataCheckbox,
  newDataCheckbox,
  itemsShop,
  setItemsWheel,
  itemsWheel,
  errorInItemsCount,
  setElementsChecked,
  setElementsName,
  setItemsShop,
  elementsChecked,
  elementsName,
  handleClickCheckbox,
  dataCategories,
  dropdownSearchFilter,
  isErrorCategories,
  isLoadingCategories,
  error,
  setDowpdownSearchFilter,
  elementsParentName,
  handleUploadPhoto,
  firstImage,
  setFirstImg,
  imageCropModalType,
  setImageCropModalType,
  newImageUpload,
  setNewImageUpload,
  secondImage,
  setSecondImg,
}: any) {
  const [dropdown, openDropdown] = useState(false);

  const prizeType = itemInfo?.prizeType?.toLowerCase();

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const parseToType = (x: string) => {
    return parseFloat(x);
  };

  const conditionalName = () => {
    if (prizeType === "reward") {
      return "Reward Name";
    } else if (prizeType === "offer") {
      return "Offer Headline";
    } else {
      return "Prize Headline";
    }
  };
  const conditionalDescription = () => {
    if (prizeType === "reward") {
      return "Reward Description";
    } else {
      return "Item Description";
    }
  };

  return (
    <div className="flex w-100">
      <div className="mt-5 w-full">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="flex mt-8 w-full ">
            <div className="w-1/2 mr-4">
              <Input
                label={prizeType !== "reward" ? "Item ID" : "Reward ID"}
                value={itemData.prizeId}
                classes="mb-5"
                disabled={true}
              />
              <Input
                label={conditionalName()}
                maxLength={125}
                alert={itemInfo?.newData?.hasOwnProperty("name") ? true : false}
                classesCheckbox="checkbox-cross"
                placeholder="Enter Item Name"
                value={itemData.name}
                required={true}
                checkboxID="name"
                classes="mb-5"
                onChange={(e: any) =>
                  setItemData({...itemData, name: e.target.value})
                }
                disabled={isInputDisabled}
                error={!itemData.name && inputError}
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              />
              <Textarea
                label={conditionalDescription()}
                placeholder="Include any details, terms, and restrictions"
                classes="mb-5"
                maxLength={600}
                value={itemData.description}
                alert={
                  itemInfo?.newData?.hasOwnProperty("description")
                    ? true
                    : false
                }
                classesCheckbox="checkbox-cross"
                checkboxID="description"
                onChange={(e: any) => {
                  setItemData({
                    ...itemData,
                    description: e.target.value,
                  });
                }}
                disabled={isInputDisabled}
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              />
              {prizeType !== "reward" && (
                <>
                  <div className="flex">
                    <Input
                      label={
                        itemInfo?.prizeType?.toLowerCase() === "regular"
                          ? "Retail value of prize ($)"
                          : "Retail value of offer ($)"
                      }
                      alert={itemInfo?.newData?.price ? true : false}
                      classesCheckbox="checkbox-cross"
                      required={true}
                      value={itemData.price}
                      type="number"
                      checkboxID="price"
                      onChange={(e: any) => {
                        if (itemInfo?.prizeType?.toLowerCase() === "regular") {
                          if (e.target.value > 500) {
                            setItemData({
                              ...itemData,
                              price: "500",
                            });
                          } else {
                            setItemData({
                              ...itemData,
                              price: Math.abs(e.target.value).toString(),
                            });
                          }
                        } else if (
                          itemInfo?.prizeType?.toLowerCase() === "offer"
                        ) {
                          if (e.target.value > 1000) {
                            setItemData({
                              ...itemData,
                              price: "1000",
                            });
                          } else {
                            setItemData({
                              ...itemData,
                              price: Math.abs(e.target.value).toString(),
                            });
                          }
                        }
                      }}
                      classes="mb-5 mr-5"
                      placeholder="Enter a Price"
                      disabled={isInputDisabled}
                      error={
                        (!itemData.price || parseInt(itemData.price) <= 0) &&
                        inputError
                      }
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                    />
                    {itemInfo?.prizeType?.toLowerCase() === "offer" && (
                      <Input
                        label="Offer %"
                        alert={itemInfo?.newData?.offerDiscount ? true : false}
                        classesCheckbox="checkbox-cross"
                        required={true}
                        checkboxID="offerDiscount"
                        value={itemData.offerDiscount}
                        type="number"
                        max={99}
                        maxLength={99}
                        onChange={(e: any) => {
                          if (e.target.value > 99) {
                            setItemData({
                              ...itemData,
                              offerDiscount: "99",
                            });
                          } else {
                            setItemData({
                              ...itemData,
                              offerDiscount: Math.abs(
                                e.target.value
                              ).toString(),
                            });
                          }
                        }}
                        classes="mb-5"
                        placeholder="Enter a Discount"
                        error={
                          (!itemData.offerDiscount ||
                            parseInt(itemData.offerDiscount) <= 0) &&
                          inputError
                        }
                        disabled={isInputDisabled}
                        handleClickCheckbox={handleClickNewDataCheckbox}
                        checkedValues={newDataCheckbox}
                      />
                    )}
                  </div>
                  <Input
                    label="Add additional quantity"
                    alert={itemInfo?.newData?.prizeCount ? true : false}
                    classesCheckbox="checkbox-cross"
                    placeholder="Enter Number of Offers"
                    classes="mb-5"
                    checkboxID="prizeCount"
                    value={itemData.prizeCount}
                    onChange={(e: any) => {
                      if (e.target.value > 500) {
                        setItemData({
                          ...itemData,
                          prizeCount: "500",
                        });
                      } else {
                        setItemData({
                          ...itemData,
                          prizeCount: !isNaN(e.target.value)
                            ? Math.abs(e.target.value).toString()
                            : "0",
                        });
                      }
                    }}
                    disabled={isInputDisabled}
                    error={
                      (!itemData.prizeCount ||
                        parseInt(itemData.prizeCount) <= 0) &&
                      inputError
                    }
                    required={true}
                    handleClickCheckbox={handleClickNewDataCheckbox}
                    checkedValues={newDataCheckbox}
                  />
                  <div className="flex">
                    <Input
                      id="percentShop"
                      label={
                        itemInfo?.prizeType?.toLowerCase() === "regular"
                          ? "Prize in wheel (%)"
                          : "Offers in wheel (%)"
                      }
                      alert={itemInfo?.newData?.percentShop ? true : false}
                      classesCheckbox="checkbox-cross"
                      required={true}
                      disabled={isInputDisabled}
                      value={itemsWheel}
                      min={0}
                      max={100}
                      maxLength={100}
                      onChange={(e: any) => {
                        if (isNaN(e.target.value)) return;
                        if (
                          parseToType(e.target.value) + parseToType(itemsShop) >
                            100 ||
                          parseToType(e.target.value) > 100
                        ) {
                          setItemsWheel(
                            Math.abs(
                              parseToType(itemsShop || "0") - 100
                            ).toString()
                          );
                          return;
                        }
                        setItemsWheel(Math.abs(e.target.value).toString());
                      }}
                      classes="mb-5 mr-2"
                      placeholder="-"
                      error={(!itemsWheel && inputError) || errorInItemsCount}
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                      // errorInItemsCount
                    />
                    <Input
                      id="percentShop"
                      label={
                        itemInfo?.prizeType?.toLowerCase() === "regular"
                          ? "Prize in shop (%)"
                          : "Offers in shop (%)"
                      }
                      alert={itemInfo?.newData?.percentShop ? true : false}
                      classesCheckbox="checkbox-cross"
                      required={true}
                      min={0}
                      max={100}
                      maxLength={100}
                      disabled={isInputDisabled}
                      value={itemsShop}
                      onChange={(e: any) => {
                        if (isNaN(e.target.value)) return;
                        if (
                          parseToType(e.target.value) +
                            parseToType(itemsWheel) >
                            100 ||
                          parseToType(e.target.value) > 100
                        ) {
                          setItemsShop(
                            Math.abs(
                              parseToType(itemsWheel || "0") - 100
                            ).toString()
                          );
                          return;
                        }
                        setItemsShop(Math.abs(e.target.value).toString());
                      }}
                      classes="mb-5"
                      placeholder="-"
                      error={(!itemsShop && inputError) || errorInItemsCount}
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                    />
                  </div>
                </>
              )}
              {prizeType === "reward" ? (
                <DatePicker
                  label="Expiration days"
                  disabled={true}
                  value={
                    itemData.expiredAt?.includes("T")
                      ? itemData.expiredAt?.split("T")[0]
                      : itemData.expiredAt
                  }
                  onChange={(e: any) => {}}
                />
              ) : (
                <DatePicker
                  label="Expiration days"
                  alert={itemInfo?.newData?.expiredAt ? true : false}
                  classesCheckbox="checkbox-cross"
                  disabled={isInputDisabled}
                  checkboxID="expiredAt"
                  required={true}
                  value={
                    itemData.expiredAt?.includes("T")
                      ? itemData.expiredAt?.split("T")[0]
                      : itemData.expiredAt
                  }
                  onChange={(e: any) =>
                    setItemData({...itemData, expiredAt: e.target.value})
                  }
                  error={!itemData.expiredAt && inputError}
                  handleClickCheckbox={handleClickNewDataCheckbox}
                  checkedValues={newDataCheckbox}
                  min={addDays(getTodayDate(), 1)}
                  max={addDays(getTodayDate(), 365)}
                />
              )}
            </div>
            <div className="w-1/2 mr-4">
              <Select
                label="Minimum age requirements"
                alert={itemInfo?.newData?.ageLimit ? true : false}
                classesCheckbox="checkbox-cross"
                value={itemData.ageLimit}
                checkboxID="ageLimit"
                onChange={(e: any) =>
                  setItemData({...itemData, ageLimit: e.target.value})
                }
                classes="mb-6"
                disabled={isInputDisabled}
                classesSelect="py-2 pl-2.5 pr-9 text-black"
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              >
                <>
                  <option value="" disabled>
                    Select Min. Age
                  </option>
                  <option value={18}>18+</option>
                  <option value={21}>21+</option>
                  <option value={25}>25+</option>
                  <option value={30}>30+</option>
                  <option value={40}>40+</option>
                </>
              </Select>
              {prizeType !== "reward" && (
                <Input
                  label="Maximum Radius Target miles"
                  min={0}
                  maxLength={2}
                  max={20}
                  required={true}
                  classes="mb-5"
                  checkboxID="radius"
                  value={itemData.radius === "0" ? "" : itemData.radius}
                  alert={itemInfo?.newData?.radius ? true : false}
                  classesCheckbox="checkbox-cross"
                  onChange={(e: any) => {
                    if (e.target.value > 20) {
                      setItemData({
                        ...itemData,
                        radius: "20",
                      });
                    } else {
                      setItemData({
                        ...itemData,
                        radius: !isNaN(e.target.value)
                          ? Math.abs(e.target.value).toString()
                          : "0",
                      });
                    }
                  }}
                  placeholder="Input a number between 1 to 20"
                  error={!itemData.radius && inputError}
                  disabled={isInputDisabled}
                  handleClickCheckbox={handleClickNewDataCheckbox}
                  checkedValues={newDataCheckbox}
                />
              )}
              <DropdownCategory
                hasLabel={true}
                label="Categories"
                openDropdown={openDropdown}
                dropdown={dropdown}
                checkboxID="categories"
                elementsChecked={elementsChecked}
                elementsName={elementsName}
                handleClickCheckbox={handleClickCheckbox}
                dataCategories={getFilteredNames(
                  dataCategories,
                  dropdownSearchFilter,
                  ""
                )}
                isErrorCategories={isErrorCategories}
                isLoadingCategories={isLoadingCategories}
                error={error}
                disabled={isInputDisabled}
                handleClickDropdown={handleClickDropdown}
                isError={!elementsChecked.length && inputError}
                alert={itemInfo?.newData?.categories ? true : false}
                classesCheckbox="checkbox-cross"
                handleClickNewDataCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
                classNameSelect="w-full"
                showNames={false}
                expandItems={dropdownSearchFilter}
                hasSearchValue={{
                  show: true,
                  content: (
                    <div className="flex mb-3 justify-between">
                      <div className="flex items-center w-full">
                        <label htmlFor="search">
                          <Search classes="h-6 w-6 mr-2" />
                        </label>
                        <input
                          id="search"
                          name="search"
                          type="text"
                          placeholder="Search category"
                          className="decoration-none outline-none w-full"
                          onChange={(e: any) =>
                            setDowpdownSearchFilter(e.target.value)
                          }
                          value={dropdownSearchFilter}
                        />
                      </div>
                    </div>
                  ),
                }}
              />
              <div
                className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                  elementsChecked.length > 0
                    ? "border border-gray-400"
                    : "border-none"
                } ${
                  isInputDisabled &&
                  "bg-gray-200 !border-gray-200 text-gray-500"
                } rounded-md`}
              >
                {elementsParentName.map((element: any, index: number) => (
                  <div
                    className={`${
                      isInputDisabled
                        ? "!border-b-gray-300"
                        : "border-b-slate-200"
                    } flex justify-between gap-2 items-center border-b-2  p-2`}
                    key={index}
                  >
                    <Tooltip id="my-tooltip"></Tooltip>
                    <div className="flex flex-wrap">
                      {element?.root && (
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={`ID:  ${element?.rootId}`}
                          className="flex items-center text-sm"
                        >
                          {element?.root} <ArrowNext classes="h-4 w-4" />
                        </div>
                      )}
                      {element?.parentId && (
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={`ID:  ${element?.parentId}`}
                          className="flex items-center text-sm"
                        >
                          {element?.parent} <ArrowNext classes="h-4 w-4" />
                        </div>
                      )}
                      {element?.childId && (
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={`ID:  ${element?.childId}`}
                          className="font-medium text-sm"
                        >
                          {element?.child}
                        </div>
                      )}
                    </div>
                    <Button
                      type="button"
                      onClick={() => {
                        if (isInputDisabled) return;
                        setElementsChecked((prevElementsChecked: string[]) =>
                          prevElementsChecked.filter(
                            (item: string) => item !== element.id
                          )
                        );
                        setElementsName((prevElementsChecked: string[]) =>
                          prevElementsChecked.filter(
                            (item: string) => item !== element.child
                          )
                        );
                      }}
                      classes={`${
                        isInputDisabled && "opacity-50 cursor-not-allowed"
                      } ml-6 text-blue-400 text-sm`}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
              {prizeType !== "reward" && (
                <>
                  <Select
                    label="Prize Attribute"
                    checkboxID="promotionType"
                    value={itemData.promotionType}
                    onChange={(e: any) =>
                      setItemData({
                        ...itemData,
                        promotionType: e.target.value,
                      })
                    }
                    classes="mt-6"
                    classesSelect="py-2 pl-2.5 pr-9 text-black"
                    alert={itemInfo?.newData?.promotionType ? true : false}
                    classesCheckbox="checkbox-cross"
                    disabled={isInputDisabled}
                    handleClickCheckbox={handleClickNewDataCheckbox}
                    checkedValues={newDataCheckbox}
                  >
                    <>
                      <option value="NONE" selected>
                        None
                      </option>
                      <option value="FREE">Free</option>
                      <option value="BOGO">Buy one Get one (BOGO)</option>
                      <option value="BUYBACK">Buy Back</option>
                    </>
                  </Select>
                  <Select
                    label="Redemptions per user"
                    checkboxID="perCustomerLimit"
                    alert={itemInfo?.newData?.perCustomerLimit ? true : false}
                    classesCheckbox="checkbox-cross"
                    value={itemData.perCustomerLimit}
                    onChange={(e: any) =>
                      setItemData({
                        ...itemData,
                        perCustomerLimit: e.target.value,
                      })
                    }
                    classes="my-6"
                    disabled={isInputDisabled}
                    classesSelect="py-2 pl-2.5 pr-9 text-black"
                    handleClickCheckbox={handleClickNewDataCheckbox}
                    checkedValues={newDataCheckbox}
                  >
                    <>
                      <option value={-1} selected={true}>
                        No limit
                      </option>
                      <option value={1}>x1</option>
                      <option value={2}>x2</option>
                      <option value={3}>x3</option>
                    </>
                  </Select>
                  <div className="flex flex-col mb-5">
                    <Textarea
                      label="Terms & Conditions"
                      alert={itemInfo?.newData?.terms ? true : false}
                      classesCheckbox="checkbox-cross"
                      checkboxID="terms"
                      placeholder="Terms & Conditions"
                      classes="mb-5"
                      required={true}
                      maxLength={1500}
                      value={itemData.terms}
                      onChange={(e: any) => {
                        setItemData({
                          ...itemData,
                          terms: e.target.value,
                        });
                      }}
                      disabled={isInputDisabled}
                      error={!itemData.terms && inputError}
                      handleClickCheckbox={handleClickNewDataCheckbox}
                      checkedValues={newDataCheckbox}
                    />
                  </div>
                </>
              )}

              {prizeType === "reward" && (
                <Input
                  label={"Stamps Needed"}
                  checkboxID="requiredStamps"
                  required
                  value={itemData.requiredStamps}
                  onChange={(e: any) =>
                    setItemData({
                      ...itemData,
                      requiredStamps: e.target.value,
                    })
                  }
                  classes="mt-6"
                  classesSelect="py-2 pl-2.5 pr-9 text-black"
                  error={!itemData.requiredStamps && inputError}
                  alert={itemInfo?.newData?.requiredStamps ? true : false}
                  classesCheckbox="checkbox-cross"
                  disabled={isInputDisabled}
                  handleClickCheckbox={handleClickNewDataCheckbox}
                  checkedValues={newDataCheckbox}
                />
              )}
            </div>
          </div>
          <div className="lg:border-l w-1/2 lg:w-3/8 lg:justify-self-end px-4 mt-2">
            <div>
              <Dropzone
                handleUploadPhoto={handleUploadPhoto}
                image={firstImage}
                imageSetter={setFirstImg}
                required={true}
                label={prizeType !== "reward" ? "Cover photo" : "Reward photo"}
                type="BIG"
                id="image#BIG"
                classesLabel="mt-5"
                classes="mb-8"
                alert={itemInfo?.images.find((image: any) => {
                  if (image.type.toLowerCase() === "big") {
                    if (
                      image.hasOwnProperty("newName") ||
                      image.hasOwnProperty("newUrl")
                    ) {
                      return true;
                    }
                  }
                })}
                classesCheckbox="checkbox-cross"
                disabledInput={isInputDisabled}
                imageCropModalType={imageCropModalType}
                setImageCropModalType={setImageCropModalType}
                newImageUpload={newImageUpload}
                setNewImageUpload={setNewImageUpload}
                handleClickCheckbox={handleClickNewDataCheckbox}
                checkedValues={newDataCheckbox}
              />
              <p className="mb-8 mt-2 text-xs text-gray-400">
                Use your photo or image that’s helps people identify your
                merchant in search result.
              </p>
            </div>
            {prizeType !== "reward" && (
              <div>
                <Dropzone
                  handleUploadPhoto={handleUploadPhoto}
                  image={secondImage}
                  imageSetter={setSecondImg}
                  required={true}
                  label={`Photo for ${
                    itemInfo?.prizeType?.toLowerCase() === "regular"
                      ? "prize"
                      : "offer"
                  }`}
                  disabledInput={isInputDisabled}
                  sizePlaceholder={"1:0.4 @ 331x 140"}
                  type="SMALL"
                  id="image#SMALL"
                  imageCropModalType={imageCropModalType}
                  setImageCropModalType={setImageCropModalType}
                  newImageUpload={newImageUpload}
                  setNewImageUpload={setNewImageUpload}
                  sizesContainer={{
                    minWidth: "260px",
                  }}
                  sizes={{width: 662, height: 280}}
                  aspect={331 / 140}
                  alert={itemInfo?.images.find((image: any) => {
                    if (image.type.toLowerCase() === "small") {
                      if (
                        image.hasOwnProperty("newName") ||
                        image.hasOwnProperty("newUrl")
                      ) {
                        return true;
                      }
                    }
                  })}
                  classesCheckbox="checkbox-cross"
                  handleClickCheckbox={handleClickNewDataCheckbox}
                  checkedValues={newDataCheckbox}
                />
                <p className="mb-8 mt-2 text-xs text-gray-400">
                  Use your photo or image that’s helps people identify your
                  merchant in search result.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Item;
