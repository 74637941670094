import { createApi } from "@reduxjs/toolkit/query/react";
import { store } from "store/store";
import customFetchBase from "./customFetchBase";

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: customFetchBase,
  tagTypes: ["Location", "LocationByID", "CategoriesFromLocationByID", "AreasFromLocationByID", "CitiesFromLocationByID"],
  endpoints: (builder) => ({
    getAllLocation: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-prize/admin/location${!params ? "" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["Location"],
    }),
    getLocationByID: builder.query<any, any>({
      // @ts-ignore
      query: ({ id }) => ({
        url: `/retailer-prize/admin/location/${id}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["LocationByID"],
    }),
    getCategoriesFromLocationByID: builder.query<any, any>({
      // @ts-ignore
      query: ({ id, params }) => ({
        url: `/retailer-prize/admin/location/${id}/category${!params ? "" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["CategoriesFromLocationByID"],
    }),
    getAreasFromLocationByID: builder.query<any, any>({
      // @ts-ignore
      query: ({ id, params }) => ({
        url: `/retailer-prize/admin/location/${id}/area${!params ? "" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["AreasFromLocationByID"],
    }),
    getStates: builder.query<any, void>({
      // @ts-ignore
      query: () => ({
        url: `/retailer-prize/admin/state`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    getDMA: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-prize/admin/dma${!params ? "" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    getPrizeCountLocation: builder.query<any, any>({
      // @ts-ignore
      query: ({ categoryId }) => ({
        url: `/retailer-prize/admin/location/category/${categoryId}/prize-count`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    getCitiesFromLocationByID: builder.query<any, any>({
      // @ts-ignore
      query: ({ dmaCode, params }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/city${!params ? "" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["CitiesFromLocationByID"],
    }),
    changePosition: builder.mutation({
      query: ({ dmaCode, data }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/change-position`,
        method: 'POST',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'CategoriesFromLocationByID']
    }),
    createLocation: builder.mutation({
      query: ({ dmaCode, state }) => ({
        url: `/retailer-prize/admin/location`,
        method: 'POST',
        body: { dmaCode, state },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Location']
    }),
    publishLocation: builder.mutation({
      query: ({ data }) => ({
        url: `/retailer-prize/admin/location/publish`,
        method: 'POST',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Location', 'LocationByID']
    }),
    unpublishLocation: builder.mutation({
      query: ({ data }) => ({
        url: `/retailer-prize/admin/location/unpublish`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['Location', 'LocationByID']
    }),
    publishCategoryLocation: builder.mutation({
      query: ({ dmaCode, categoryId }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/${categoryId}/publish`,
        method: 'POST',
        body: {},
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'AreasFromLocationByID']
    }),
    unpublishCategoryLocation: builder.mutation({
      query: ({ dmaCode, categoryId }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/${categoryId}/unpublish`,
        method: 'POST',
        body: {},
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'AreasFromLocationByID']
    }),
    archiveCategoryLocation: builder.mutation({
      query: ({ dmaCode, data }: { dmaCode: number, data: { categoryIds: number[] } }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/archive`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'AreasFromLocationByID']
    }),
    recoverCategoryLocation: builder.mutation({
      query: ({ dmaCode, data }: { dmaCode: number, data: { categoryIds: number[] } }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/recover`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'AreasFromLocationByID']
    }),
    createCategoryFromLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/retailer-prize/admin/location/${id}/category`,
        method: 'POST',
        body: { ...data },
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID']
    }),
    deleteCategoryFromLocation: builder.mutation({
      query: ({ dmaCode, categoryId }) => ({
        url: `/retailer-prize/admin/location/${dmaCode}/category/${categoryId}`,
        method: 'DELETE',
        body: {},
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID']
    }),
  }),
});

export const {
  useGetAllLocationQuery,
  useLazyGetAllLocationQuery,
  useGetLocationByIDQuery,
  useLazyGetLocationByIDQuery,
  useGetStatesQuery,
  useLazyGetStatesQuery,
  useGetDMAQuery,
  useLazyGetDMAQuery,
  useGetCategoriesFromLocationByIDQuery,
  useGetAreasFromLocationByIDQuery,
  useCreateLocationMutation,
  useGetPrizeCountLocationQuery,
  useGetCitiesFromLocationByIDQuery,
  useLazyGetCitiesFromLocationByIDQuery,
  usePublishLocationMutation,
  useUnpublishLocationMutation,
  usePublishCategoryLocationMutation,
  useUnpublishCategoryLocationMutation,
  useChangePositionMutation,
  useCreateCategoryFromLocationMutation,
  useArchiveCategoryLocationMutation,
  useRecoverCategoryLocationMutation,
  useDeleteCategoryFromLocationMutation
} = locationApi;
