import React from "react";

interface ButtonTypes {
  children?: React.ReactNode;
  onClick?:
    | void
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined
    | Function;
  classes?: string;
  variant?:
    | "danger"
    | "add"
    | "normal"
    | "disabled"
    | "alert"
    | "recovery"
    | "active"
    | "pause";
  type?: "button" | "submit" | "reset" | undefined;
}

function Button({children, onClick, classes, variant, type}: ButtonTypes) {
  const baseStyle =
    "flex py-2.5 px-3 h-9 rounded items-center justify-center transition ease-in-out";

  const handleSelectStyle = () => {
    if (variant === "danger") {
      return `${baseStyle} border-2 border-rose-400 text-rose-400 bg-white mr-2 text-sm font-medium hover:bg-rose-100 hover:border-transparent`;
    }

    if (variant === "normal") {
      return `${baseStyle} border-2 border-gray-400 text-gray-400 bg-white mr-2 text-sm font-medium hover:bg-blue-50`;
    }

    if (variant === "disabled") {
      return `${baseStyle} border-2 border-transparent text-gray-400 bg-gray-200 mr-2 text-sm font-medium hover:bg-blue-50 cursor-not-allowed`;
    }

    if (variant === "recovery") {
      return `${baseStyle} border-2 border-green-400 text-green-400 bg-white mr-2 text-sm font-medium hover:bg-green-50`;
    }

    if (variant === "alert") {
      return `${baseStyle} border-2 border-yellow-400 text-yellow-400 bg-white mr-2 text-sm font-medium hover:bg-yellow-50`;
    }

    if (variant === "active") {
      return `${baseStyle} border-2 border-transparent bg-green-200 text-green-900 mr-2 text-sm font-medium`;
    }

    if (variant === "pause") {
      return `${baseStyle} border-2 border-transparent bg-gray-200 text-gray-700 mr-2 text-sm font-medium`;
    }

    if (variant === "add") {
      return `${baseStyle} bg-primary-purple text-white mr-2 text-sm font-base hover:bg-secondary-purple`;
    } else return `${baseStyle}  ${classes}`;
  };

  return (
    <button
      // @ts-ignore-next-line
      onClick={onClick}
      type={type}
      className={handleSelectStyle()}
    >
      {children}
    </button>
  );
}

export default Button;
