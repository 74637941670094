import Delete from "assets/Delete";
import Recovery from "assets/Recovery";
import Table from "components/Table/Table";
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {
  useDeleteCategoryFromLocationMutation,
  useRecoverCategoryLocationMutation,
} from "store/api/locationApi";
import {RootState} from "store/store";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import {TABLE_CONFIGURATION_ARCHIVED} from "utils/configurationHeaders";
import routes from "utils/routesByRole";

function Archived({
  data,
  isLoading,
  isError,
  refetch,
  elementsChecked,
  handleItemChecked,
  DMACode,
}: any) {
  const user = useSelector((state: RootState) => state.user.user.user);
  const dispatch = useDispatch();

  const [recoverCategoryLocationMutation] =
    useRecoverCategoryLocationMutation();
  const [deleteCategoryMutation] = useDeleteCategoryFromLocationMutation();
  const handleRecoverCategory = async (e: any) => {
    const toastPromise = toast.promise(
      recoverCategoryLocationMutation({
        dmaCode: e.dmaCode,
        data: {categoryIds: [e.categoryId]},
      }),
      {
        pending: `Recovering category ${e.categoryName}`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while recovering category {e.categoryName}</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category ${e.categoryName} recovered`);
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while recovering category");
      });
  };
  const handleDeleteCategory = async (e: any) => {
    const toastPromise = toast.promise(
      deleteCategoryMutation({
        dmaCode: e.dmaCode,
        categoryId: e.categoryId,
      }),
      {
        pending: `Deleting category ${e.categoryName}`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting category {e.categoryName}</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category ${e.categoryName} deleted`);
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while deleting category");
      });
    // const toastPromise = toast.promise(
    //   deleteCategoryMutation({
    //     dmaCode: parseInt(e.dmaCode),
    //     data: {categoryIds: e.categoryId},
    //   }),
    //   {
    //     pending: `Archiving category`,
    //   }
    // );
    // await toastPromise
    //   .then((res: any) => {
    //     if (res.error) {
    //       toast.error(
    //         <div>
    //           <h2>Error while archiving category</h2>
    //           <p className="text-xs"> {res?.error?.data?.message} </p>
    //         </div>
    //       );
    //     } else {
    //       toast.success(`Category archived`);
    //       dispatch(setResetElChecked(true));
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("Error while archiving category");
    //   });
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Recover",
        svg: <Recovery classes="mr-3" />,
        type: "recoverCategory",
        onClick: (e: any) => handleRecoverCategory(e),
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Delete",
        svg: <Delete classes="mr-3" />,
        type: "deleteCategory",
        onClick: (e: any) => handleDeleteCategory(e),
      },
    ],
    []
  );

  return (
    <Table
      data={data && data.length > 0 ? data : []}
      loadingData={isLoading}
      errorData={isError}
      refetch={refetch}
      results={data !== undefined ? data?.count : 1}
      itemOptions={ITEMS}
      typeOptions="group"
      origin="categories_archived"
      hasPagination={false}
      customError={{
        show: true,
        content: (
          <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
            There Is No Categories Archived
          </h2>
        ),
      }}
      headers={TABLE_CONFIGURATION_ARCHIVED}
      handleItemChecked={handleItemChecked}
      show={["categoryName", "createdAt", "updatedAt"]}
      sort={{
        categoryName: null,
        createdAt: null,
        updatedAt: null,
      }}
      // countPagination={
      //   tabsAndPage.tabs.lastVisitedPage
      //     ? +tabsAndPage.tabs.lastVisitedPage
      //     : 0
      // }
      orderingByAPI={true}
    />
  );
}

export default Archived;
