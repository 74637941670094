import { TypeClasses } from "./types";

function Admin({ classes }: TypeClasses) {
  return (
    <svg
      id="admin"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="support_agent_FILL0_wght400_GRAD0_opsz48"
        d="M12.1,22.2V20.85h7.65V14.01a5.859,5.859,0,0,0-.562-2.509A7.215,7.215,0,0,0,17.68,9.375,7.508,7.508,0,0,0,15.52,7.9a6.036,6.036,0,0,0-5.04,0A7.508,7.508,0,0,0,8.32,9.375,7.215,7.215,0,0,0,6.813,11.5,5.859,5.859,0,0,0,6.25,14.01V19.5H5.8A1.805,1.805,0,0,1,4,17.7V15.9a1.676,1.676,0,0,1,.248-.911,1.976,1.976,0,0,1,.652-.641l.068-1.193a8.163,8.163,0,0,1,.934-2.97,7.906,7.906,0,0,1,1.823-2.25,8.19,8.19,0,0,1,2.453-1.429,8.191,8.191,0,0,1,5.67,0,7.922,7.922,0,0,1,2.441,1.44A8.218,8.218,0,0,1,20.1,10.2a8.036,8.036,0,0,1,.934,2.936L21.1,14.3a1.637,1.637,0,0,1,.9,1.463v2.07a1.579,1.579,0,0,1-.247.878,1.635,1.635,0,0,1-.653.585V20.85a1.344,1.344,0,0,1-1.35,1.35Zm-1.8-6.525a.648.648,0,0,1-.472-.2.673.673,0,0,1-.2-.5.612.612,0,0,1,.2-.461.694.694,0,0,1,.495-.191.629.629,0,0,1,.461.191.656.656,0,0,1,.191.484.667.667,0,0,1-.191.472A.637.637,0,0,1,10.3,15.675Zm5.4,0a.648.648,0,0,1-.472-.2.673.673,0,0,1-.2-.5.612.612,0,0,1,.2-.461.694.694,0,0,1,.495-.191.629.629,0,0,1,.461.191.656.656,0,0,1,.191.484.667.667,0,0,1-.191.472A.637.637,0,0,1,15.7,15.675Zm-8.078-1.17A5.161,5.161,0,0,1,7.994,12.1a5.569,5.569,0,0,1,1.238-1.834A5.592,5.592,0,0,1,13.045,8.7a4.872,4.872,0,0,1,3.442,1.294,5.477,5.477,0,0,1,1.71,3.229,6.507,6.507,0,0,1-3.724-1.136,7.488,7.488,0,0,1-2.486-2.891,7.186,7.186,0,0,1-1.519,3.229,7,7,0,0,1-2.846,2.081Z"
        transform="translate(-1 -2)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Admin;
