import { useState } from "react";

export const useGetFormattedAdress = () => {
  const [addressDetais, setAddressDetais] =
    useState<google.maps.places.PlaceResult>();

  let firstService: google.maps.places.PlacesService;
  let secondService: google.maps.places.PlacesService;

  const getPlaceDetails = (placeId: string) => {
    if (!placeId) return;

    let map = new google.maps.Map(document.createElement("div"));

    var request = {
      placeId: placeId,
      fields: ["address_components"],
    };

    secondService = new google.maps.places.PlacesService(map);
    secondService.getDetails(request, callback);

    function callback(place: any, status: any) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        setAddressDetais(place);
      }
    }
  };

  const getFormattedAddress = (street: string): void => {
    let map = new google.maps.Map(document.createElement("div"));

    const request = {
      query: street,
      fields: ["place_id"],
    };

    firstService = new google.maps.places.PlacesService(map);

    firstService.findPlaceFromQuery(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          for (let i = 0; i < results.length; i++) {
            getPlaceDetails(results[0].place_id || "");
          }
        }
      }
    );
  };

  return [getFormattedAddress, addressDetais];
};
