import React from "react";
import Modal from ".";
import {Dialog} from "@headlessui/react";
import {useDispatch} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import Label from "components/Common/Label";
import Select from "components/Common/Input/Select";
import Textarea from "components/Common/Textarea";
import Button from "components/Common/Button";

function DeclineModal({
  reason,
  setReason,
  inputError,
  isLoadingDeclineReasons,
  isErrorDeclineReasons,
  declineReasons,
  declinedItems,
  handleFormatElement,
  reasonDescription,
  setReasonDescription,
  handleSendDeclineReason,
}: any) {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setType(""));
    dispatch(setOpenModal(false));
  };
  return (
    <Modal fnClean={() => handleCloseModal()}>
      <div className="flex flex-col bg-white text-black rounded w-96 pb-6 pt-3">
        <div className="flex justify-center items-center flex-col">
          <div className="flex justify-center items-start flex-col mb-8 px-5">
            <Dialog.Title className=" mb-4 text-black text-xl font-medium">
              Provide decline reason
            </Dialog.Title>
            <Select
              error={!reason && inputError ? true : false}
              label="Provide reason"
              classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black w-full"
              // @ts-ignore
              onChange={(e: any) => setReason(e.target.value)}
            >
              {isLoadingDeclineReasons && !isErrorDeclineReasons && (
                <option value="" disabled>
                  Loading Decline Reasons
                </option>
              )}
              {!isLoadingDeclineReasons &&
                !isErrorDeclineReasons &&
                declineReasons && (
                  <>
                    <option disabled selected>
                      Choose reason of decline
                    </option>
                    {declineReasons.map(
                      (reason: {description: string; id: number}) => (
                        <option value={reason.id.toString()} key={reason.id}>
                          {reason.description}
                        </option>
                      )
                    )}
                  </>
                )}
            </Select>
            {declinedItems.length > 0 && (
              <div className="mt-5 w-full">
                <Label>Problem Inputs</Label>
                <div className="flex flex-wrap border border-gray-400 rounded p-2">
                  {declinedItems.map((option: any, key: any) => (
                    <div
                      key={key}
                      className="bg-fourth-purple mx-1 my-1 rounded pl-3 pr-4 py-1 text-black relative cursor-default w-max text-sm capitalize"
                    >
                      {handleFormatElement(option)}
                      <span
                        id={option}
                        // @ts-ignore
                        onClick={(e) => handleRemoveElement(e.target.id)}
                        className="text-xs text-gray-400 pr-1 ml-2 absolute top-0 right-0 cursor-pointer"
                      >
                        x
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Textarea
              label="Description"
              placeholder="Enter a description"
              error={!reasonDescription && inputError ? true : false}
              classes="mt-5 w-full"
              required={true}
              value={reasonDescription}
              onChange={(e: any) => {
                setReasonDescription(e.target.value);
              }}
            />
          </div>
          <hr className="w-full" />
          <div className="flex justify-between items-center w-full px-5 mt-3">
            <Button
              onClick={() => handleCloseModal()}
              variant="normal"
              classes="text-center"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSendDeclineReason()}
              variant="add"
              classes="text-center"
            >
              Sent
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeclineModal;
