function Copy({classes, onClick}: any) {
  return (
    <svg
      id="config"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
      onClick={onClick}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="content_copy_FILL0_wght400_GRAD0_opsz48"
        d="M7.35,21.95A1.384,1.384,0,0,1,6,20.6V7.033H7.35V20.6H18.015v1.35Zm2.7-2.7A1.384,1.384,0,0,1,8.7,17.9V5.3a1.384,1.384,0,0,1,1.35-1.35h9.9A1.384,1.384,0,0,1,21.3,5.3V17.9a1.384,1.384,0,0,1-1.35,1.35Zm0-1.35h9.9V5.3h-9.9Zm0,0v0Z"
        transform="translate(-2 -0.95)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Copy;
