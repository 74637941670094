import Button from "components/Common/Button";
import Spinner from "components/Common/Spinner";
import {differenceDate} from "utils/diffenceDate";
import {transformDate} from "utils/transformDate";

function SubtableBody({idOrigin, subData, loading, error}: any) {
  // console.log(subData);

  if (loading)
    return (
      <>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <div className="flex w-full justify-center items-center h-24">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-7 h-7" />
        </div>
      </>
    );

  if (error)
    return (
      <>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <div className="flex flex-col w-full justify-center items-center h-24 mt-5 whitespace-nowrap">
          <p>An error occurred while trying to retrieve the data</p>
          <Button>Try again</Button>
        </div>
      </>
    );

  if (!loading && !error && subData.length === 0) {
    return (
      <>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <td className=""></td>
        <div className="flex flex-col w-full justify-center items-center h-24 bg-white">
          <h2 className="capitalize text-lg text-gray-400 font-medium mt-4">
            Sub Items Not Found
          </h2>
          <p className="text-sm w-72 text-center my-4">
            No Sub Items has been added yet.
          </p>
        </div>
      </>
    );
  }

  return subData?.map((table: any, key: any) => (
    <tr key={key}>
      <td className="border-l-4 border-primary-purple"></td>
      <td className="py-3 text-center font-medium normal-case text-gray-400 ">
        {idOrigin}.{table?.subId}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400 ">
        {transformDate(table?.weekStartDate)}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {transformDate(table?.weekEndDate)}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {differenceDate(table?.weekStartDate, table?.weekEndDate)}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.quantity}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.shop}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.wheel}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.claimed}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.redeemed}
      </td>
      <td className="py-3 text-center font-medium normal-case text-gray-400">
        {table?.remaining}
      </td>
    </tr>
  ));
}

export default SubtableBody;
