import Table from "components/Table/Table";
import debounce from "lodash.debounce";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  useGetAreasFromLocationByIDQuery,
  useGetCitiesFromLocationByIDQuery,
} from "store/api/locationApi";
import {setSorting} from "store/filters/filtersSlice";
import {RootState} from "store/store";
import {TABLE_AREAS} from "utils/configurationHeaders";

import Input from "components/Common/Input";
import Search from "assets/Search";
import DropdownCategory from "components/Common/DropdownCategory";
import {useGetDataCardsQuery} from "store/api/categoriesApi";

function LocationAreas({
  id,
  setDataCardArea,
}: {
  id: string;
  setDataCardArea: any;
}) {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const [params, setParams] = useState("");
  const [localParams, setLocalParams] = useState({
    city_id: "",
    zip_code: "",
  });
  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [zipCode, setZipCode] = useState("");

  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  // This one dataAllLocation

  const {
    data: dataAllLocation,
    isLoading: loadingAllLocation,
    isError: errorAllLocation,
  } = useGetCitiesFromLocationByIDQuery({
    dmaCode: id,
  });

  const {
    data: dataCategoryByLocation,
    isLoading: isLoadingCategoryByLocation,
    isError: isErrorCategoryByLocation,
    refetch,
  } = useGetAreasFromLocationByIDQuery({id, params});

  const {
    data: dataCard,
    isLoading: isLoadingCard,
    isError: isErrorCard,
  } = useGetDataCardsQuery({dma_code: id, params});

  useEffect(() => {
    if (elementsChecked.length === 0) {
      setLocalParams({
        city_id: "",
        zip_code: "",
      });
    }
  }, [elementsChecked]);

  useEffect(() => {
    if (dataCard && dataCard.cityCount) {
      setDataCardArea(dataCard);
    }
  }, [dataCard]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }

      setLocalParams((prev) => {
        return {
          ...prev,
          city_id: ``,
        };
      });

      openDropdown(false);
    }
  };

  const handleClickDropdown = (category: any, type: string) => {
    if (type === "save") {
      if (elementsChecked[0] !== "all") {
        const result = [...elementsChecked];
        let path = "";

        if (result.includes(category.id.toString())) {
          path = result
            .filter((id: string) => id.toString() !== category.id.toString())
            .map((id: string) => `city_id=${id}`)
            .join("&");
        } else {
          path = [category.id, ...elementsChecked]
            .map((id: string) => `city_id=${id}`)
            .join("&");
        }

        setLocalParams((prev) => {
          return {
            ...prev,
            city_id: `&${path}`,
          };
        });
      }
      if (elementsChecked[0] === "all" && category.id.toString() !== "all") {
        // console.log(elementsChecked);
        const result = [category.id]
          .map((id: string) => `city_id=${id}`)
          .join("&");

        setLocalParams((prev) => {
          return {
            ...prev,
            city_id: `&${result}`,
          };
        });
      }
      // return openDropdown(false);
    }
  };

  useEffect(() => {
    dispatch(setSorting(""));
  }, []);

  useEffect(() => {
    if (zipCode.length >= 5) {
      debounceChange();
    } else if (zipCode.length === 0) {
      setLocalParams((prev) => {
        return {
          ...prev,
          zip_code: ``,
        };
      });
    }
  }, [zipCode]);

  const debounceChange = () => {
    const zipCodes = zipCode?.split(",");

    const result = zipCodes
      ?.map((id: string) => {
        if (id?.trim().length === 5) {
          return `zip_code=${id?.trim()}`;
        }
      })
      ?.join("&");

    setLocalParams((prev) => {
      return {
        ...prev,
        zip_code: `&${result}`,
      };
    });
  };

  useEffect(() => {
    // If filters.sorting includes id then don't filter by id, if it's different then filter by the sorting value
    setParams(
      `?${filters.sorting?.includes("id") ? "" : filters.sorting}${
        filters.page
      }${filters.limit}${filters.urlSearch}${filters.urlFilters}${
        localParams.city_id
      }${localParams.zip_code}`
    );
  }, [filters, localParams]);

  const getFilteredNames = () => {
    if (dropdownSearchFilter?.length === 0) {
      return dataAllLocation;
    }
    return dataAllLocation?.filter((item: any) =>
      item.name.toLowerCase().includes(dropdownSearchFilter.toLowerCase())
    );
  };

  // console.log(localParams);

  return (
    <>
      <div className="flex items-center mt-3 mb-2">
        <div className=" mt-4 flex items-center gap-3 text-sm font-medium text-gray-400">
          <label>Sort By: </label>
          <DropdownCategory
            openDropdown={openDropdown}
            dropdown={dropdown}
            elementsChecked={elementsChecked}
            placeholder="All cities"
            elementsName={elementsName}
            handleClickCheckbox={handleClickCheckbox}
            dataCategories={getFilteredNames()}
            isErrorCategories={errorAllLocation}
            isLoadingCategories={loadingAllLocation}
            handleClickDropdown={handleClickDropdown}
            origin="location_area"
            hasSelectAll={true}
            hasClearAll={false}
            hasLabel={false}
            hasCustomValue={true}
            hasSearchValue={{
              show: true,
              content: (
                <div className="flex mb-3">
                  <label htmlFor="search">
                    <Search classes="h-6 w-6 mr-2" />
                  </label>
                  <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="Search city"
                    className="decoration-none
                outline-none"
                    onChange={(e: any) =>
                      setDowpdownSearchFilter(e.target.value)
                    }
                    value={dropdownSearchFilter}
                  />
                </div>
              ),
            }}
          />
        </div>
        <div className="mt-4">
          <Input
            label="Zip Code"
            hasLabel={false}
            classes="w-full ml-8"
            placeholder="Zip Code"
            value={zipCode}
            onChange={(e: any) => setZipCode(e.target.value)}
            icon={<Search classes="absolute right-3 top-2 cursor-pointer" />}
          />
        </div>
      </div>
      <div className="mt-5">
        <Table
          data={
            dataCategoryByLocation && dataCategoryByLocation.areas
              ? dataCategoryByLocation?.areas
              : []
          }
          loadingData={isLoadingCategoryByLocation}
          errorData={isErrorCategoryByLocation}
          refetch={refetch}
          results={
            dataCategoryByLocation !== undefined
              ? dataCategoryByLocation?.count
              : 1
          }
          headers={TABLE_AREAS}
          origin="areas"
          //   customError={{show: false, content: ""}}
          hasCheckbox={false}
          show={[
            "zipCode",
            "cityName",
            "merchantCount",
            "offerCount",
            "prizeCount",
            "redemptionCount",
          ]}
          sort={{
            zipCode: null,
            cityName: null,
            merchantCount: null,
            offerCount: null,
            prizeCount: null,
            redemptionCount: null,
          }}
          orderingByAPI={true}
        />
      </div>
    </>
  );
}

export default LocationAreas;
