import { TypeClasses } from "./types";

function Users({ classes }: TypeClasses) {
  return (
    <svg
      id="users"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="group_FILL0_wght400_GRAD0_opsz48"
        d="M1.9,21.632v-1.92a2.384,2.384,0,0,1,.367-1.3,2.232,2.232,0,0,1,1.018-.868,16.112,16.112,0,0,1,2.678-.939,10.476,10.476,0,0,1,2.454-.286,10.348,10.348,0,0,1,2.443.286,16.25,16.25,0,0,1,2.667.939,2.294,2.294,0,0,1,1.028.868,2.336,2.336,0,0,1,.377,1.3v1.92Zm14.253,0v-1.92A3.312,3.312,0,0,0,15.5,17.6a4.771,4.771,0,0,0-1.71-1.338,20.14,20.14,0,0,1,2.647.48,9.313,9.313,0,0,1,2.016.725,3.155,3.155,0,0,1,1.059.96,2.244,2.244,0,0,1,.387,1.287v1.92ZM8.416,15.076a2.934,2.934,0,0,1-3.054-3.063A2.934,2.934,0,0,1,8.416,8.95a2.934,2.934,0,0,1,3.054,3.063,2.934,2.934,0,0,1-3.054,3.063Zm7.33-3.063a2.934,2.934,0,0,1-3.054,3.063,4.54,4.54,0,0,1-.5-.031,2.148,2.148,0,0,1-.5-.112,3.354,3.354,0,0,0,.743-1.256,5.128,5.128,0,0,0,.255-1.664,4.766,4.766,0,0,0-.255-1.624,4.345,4.345,0,0,0-.743-1.3,4.286,4.286,0,0,1,.5-.1,3.422,3.422,0,0,1,.5-.041,2.934,2.934,0,0,1,3.054,3.063ZM3.122,20.407H13.71v-.694a1.17,1.17,0,0,0-.193-.633,1.065,1.065,0,0,0-.479-.429,13.722,13.722,0,0,0-2.464-.878,9.819,9.819,0,0,0-2.158-.225,9.958,9.958,0,0,0-2.169.225,13.6,13.6,0,0,0-2.474.878,1.011,1.011,0,0,0-.468.429,1.217,1.217,0,0,0-.183.633Zm5.294-6.555A1.864,1.864,0,0,0,9.729,10.7a1.777,1.777,0,0,0-1.313-.521A1.864,1.864,0,0,0,7.1,13.33,1.777,1.777,0,0,0,8.416,13.851ZM8.416,20.407ZM8.416,12.013Z"
        transform="translate(1.1 -2.95)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Users;
