import {Link} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";

// Assets
import AddPerson from "assets/AddPerson";
import Archive from "assets/Archive";
import Recovery from "assets/Recovery";
import Copy from "assets/Copy";
import Prize from "assets/Prize";

// Redux
import {useDispatch, useSelector} from "react-redux";
import type {RootState} from "store/store";
import Delete from "assets/Delete";
import {
  useDuplicateGrandPrizeItemMutation,
  useDuplicateItemMutation,
} from "store/api/itemApi";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import routes from "utils/routesByRole";

function FilterButtons({
  elementsChecked,
  setElementsChecked,
  setMerchantsStatus,
  origin,
  getItemInfo,
  setItemInfoSelected,
}: any) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);

  const [duplicateItmMutation] = useDuplicateItemMutation();
  const [duplicateGrandPrizeMutation] = useDuplicateGrandPrizeItemMutation();

  if (
    (origin === "merchants" && !user.actualTab) ||
    user.actualTab === "Actual" ||
    user.actualTab === "default" ||
    origin === "Admins"
  ) {
    const handleArchiveMerchant = () => {
      dispatch(setType("archiveMerchant"));
      dispatch(setOpenModal(true));
    };
    return (
      <>
        {
          // @ts-ignore
          routes[user.data.role].urlAllowed.merchant?.includes("archive") &&
            user.actualTab === "Archived" && (
              <Button
                onClick={() => handleArchiveMerchant()}
                variant={elementsChecked.length <= 0 ? "disabled" : "danger"}
              >
                <Archive />
                <p className="ml-2 flex">Archive</p>
              </Button>
            )
        }
        {
          // @ts-ignore
          routes[user.data.role].urlAllowed.merchant?.includes("add") &&
            (user.actualTab === "Actual" || user.actualTab === "") && (
              <Link to={origin !== "Admins" ? "/merchants/create" : ""}>
                <Button
                  variant="add"
                  onClick={() => {
                    if (origin === "Admins") {
                      dispatch(setOpenModal(true));
                      dispatch(setType("addNewAdmin"));
                    }
                  }}
                >
                  <AddPerson />
                  <p className="ml-2 whitespace-nowrap">Add new</p>
                </Button>
              </Link>
            )
        }
      </>
    );
  }

  if (user.actualTab === "Archived") {
    const handleDeleteMerchant = () => {
      if (origin === "merchants") {
        dispatch(setType("deleteMerchant"));
      }
      if (origin === "items" || origin === "grandprize") {
        dispatch(setType("deleteItem"));
      }
      dispatch(setOpenModal(true));
    };

    const handleRecoverMerchant = () => {
      if (origin === "merchants") {
        dispatch(setType("recoveryMerchant"));
        dispatch(setOpenModal(true));
      }

      if (origin === "items") {
        dispatch(setType("recoveryItem"));
        dispatch(setOpenModal(true));
      }

      if (origin === "grandprize") {
        dispatch(setType("recoveryGrandPrize"));
        dispatch(setOpenModal(true));
      }
    };

    return (
      <>
        {
          // @ts-ignore
          routes[user.data.role].urlAllowed.merchant?.includes("delete") && (
            <Button
              onClick={
                elementsChecked.length <= 0
                  ? () => {}
                  : () => handleDeleteMerchant()
              }
              variant={elementsChecked.length <= 0 ? "disabled" : "danger"}
            >
              <Delete />
              <p className="ml-2">Delete</p>
            </Button>
          )
        }
        {
          // @ts-ignore
          routes[user.data.role].urlAllowed.merchant?.includes("recover") && (
            <Button
              onClick={
                elementsChecked.length <= 0
                  ? () => {}
                  : () => handleRecoverMerchant()
              }
              variant={elementsChecked.length <= 0 ? "disabled" : "recovery"}
            >
              <Recovery />
              <p className="ml-2">Recovery</p>
            </Button>
          )
        }
      </>
    );
  }

  if (
    ((origin === "items" ||
      origin === "grandprize" ||
      origin === "grandprize_need_to_approve" ||
      origin === "items_need_to_approve" ||
      origin === "items_new") &&
      !user.actualTab) ||
    user.actualTab === "Approved" ||
    user.actualTab === "New Items" ||
    user.actualTab === "Need to Approve"
  ) {
    const handleDuplicateItem = (e: any) => {
      const info = getItemInfo(elementsChecked);
      info.map(async (item: any) => {
        if (!item.prizeId || !item.merchantId) return;

        if (
          item?.prizeType?.toLowerCase() === "regular" ||
          item?.prizeType?.toLowerCase() === "offer" ||
          item?.prizeType?.toLowerCase() === "qr_code" ||
          item?.prizeType?.toLowerCase() === "ship_to_address"
        ) {
          if (
            item?.prizeType?.toLowerCase() === "regular" ||
            item?.prizeType?.toLowerCase() === "offer"
          ) {
            const toastPromise = toast.promise(
              duplicateItmMutation({
                idItem: item.prizeId.toString(),
                idMerchant: item.merchantId?.toString(),
              }),
              {
                pending: "Duplicating items",
              }
            );
            await toastPromise
              .then((res: any) => {
                if (res.error) {
                  toast.error(
                    <div>
                      <h2>Error while duplicating item</h2>
                      <p className="text-xs"> {res?.error?.data?.code} </p>
                    </div>
                  );
                } else {
                  toast.success("Item Duplicated");
                  dispatch(setResetElChecked(true));
                }
              })
              .catch((err) => {
                toast.error("Error while duplicating item");
              });
          } else {
            const toastPromise = toast.promise(
              duplicateGrandPrizeMutation({
                idItem: item.prizeId,
                idMerchant: item.merchantId,
              }),
              {
                pending: "Duplicating items",
              }
            );
            await toastPromise
              .then((res: any) => {
                if (res.error) {
                  toast.error(
                    <div>
                      <h2>Error while duplicating item</h2>
                      <p className="text-xs"> {res?.error?.data?.code} </p>
                    </div>
                  );
                } else {
                  toast.success("Item Duplicated");
                  dispatch(setResetElChecked(true));
                }
              })
              .catch((err) => {
                toast.error("Error while duplicating item");
              });
          }
        } else {
          setItemInfoSelected((prev: any) => [
            ...prev,
            {...item, count: item.claimed},
          ]);
          dispatch(setType("addRedeemedCode"));
          dispatch(setOpenModal(true));
        }
      });
    };

    const handleArchiveItem = async () => {
      console.log("origin", origin);
      if (origin === "merchants") {
        dispatch(setType("archiveMerchant"));
        dispatch(setOpenModal(true));
      }

      if (origin === "items") {
        dispatch(setType("archiveItem"));
        dispatch(setOpenModal(true));
      }

      if (origin === "grandprize") {
        dispatch(setType("archiveGrandPrize"));
        dispatch(setOpenModal(true));
      }
    };

    return (
      <>
        {user.actualTab !== "Need to Approve" &&
          user.actualTab !== "New Items" &&
          // @ts-ignore
          routes[user.data.role].urlAllowed?.item?.includes("archive") && (
            <Button
              variant={elementsChecked.length <= 0 ? "disabled" : "danger"}
              onClick={(e) => handleArchiveItem()}
            >
              <Archive />
              <p className="ml-2">Archive</p>
            </Button>
          )}

        {
          // @ts-ignore
          routes[user.data.role].urlAllowed?.item?.includes("add") && (
            <>
              <Button
                variant={elementsChecked.length <= 0 ? "disabled" : "normal"}
                onClick={(e) => handleDuplicateItem(e)}
              >
                <Copy />
                <p className="ml-2">Duplicate</p>
              </Button>
              <Link to="/items/create">
                <Button variant="add">
                  <Prize />
                  <p className="ml-2">Add Item</p>
                </Button>
              </Link>
            </>
          )
        }
      </>
    );
  }

  // Table Tabs ----------------------
  // ---------------------------------
  return <></>;
}

export default FilterButtons;
