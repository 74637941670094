import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

// Components
import Button from "components/Common/Button";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";

// Assets
import Archive from "assets/Archive";
import {ArrowDown} from "assets/Arrows";
import Copy from "assets/Copy";

// Redux
import {useGetMerchantGrandPrizeQuery} from "store/api/merchantsApi";
import {toast} from "react-toastify";
import {
  useChangeStatusGrandPrizeMutation,
  useDuplicateGrandPrizeItemMutation,
} from "store/api/itemApi";
import {useDispatch, useSelector} from "react-redux";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import ArchiveModal from "components/Modal/ArchiveModal";
import {RootState} from "store/store";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {setOpenModal, setType} from "store/modal/modalSlice";

// Utils
import routes from "utils/routesByRole";

// Interface
interface DeleteItemI {
  elementsChecked: number[] | string[];
  idMerchant: number | string | undefined;
}

function MerchantGrandPrize({merchantInfo, headers}: any) {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const {
    data: dataItemsGrandPrize,
    isLoading: isLoadingItemsGrandPrize,
    isError: isErrorItemsGrandPrize,
    refetch,
  } = useGetMerchantGrandPrizeQuery(id);

  const [changeStatusGPMutation] = useChangeStatusGrandPrizeMutation();
  const [duplicateGrandPrizeMutation] = useDuplicateGrandPrizeItemMutation();

  useEffect(() => {
    if (
      dataItemsGrandPrize &&
      !isLoadingItemsGrandPrize &&
      !isErrorItemsGrandPrize
    ) {
      dispatch(setMerchantsName(dataItemsGrandPrize?.prizes));
    }
  }, [dataItemsGrandPrize, isLoadingItemsGrandPrize, isErrorItemsGrandPrize]);

  const duplicateGrandPrize = ({elementsChecked, idMerchant}: DeleteItemI) => {
    let toastPromise;

    elementsChecked.map(async (id: number | string) => {
      toastPromise = toast.promise(
        duplicateGrandPrizeMutation({
          idItem: id.toString(),
          idMerchant: idMerchant?.toString(),
        }),
        {
          pending: "Duplicating item",
        }
      );
      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while duplicating item</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Item duplicated");
            dispatch(setResetElChecked(true));
            refetch();
          }
        })
        .catch((err) => {
          toast.error("Error while duplicating item");
        });
    });
  };

  return (
    <div>
      <div className="px-5 bg-white flex items-center rounded justify-between">
        <Subtitle classes={"py-5"}>Grand Prize</Subtitle>
        <div className="flex">
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("archive") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "danger" : "disabled"
                }`}
                onClick={(e) => {
                  dispatch(setType("archiveGrandPrize"));
                  dispatch(setOpenModal(true));
                }}
              >
                <Archive />
                <p className="ml-2">Archive Item</p>
              </Button>
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("add") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "normal" : "disabled"
                }`}
                onClick={(e) =>
                  duplicateGrandPrize({elementsChecked, idMerchant: id})
                }
              >
                <Copy />
                <p className="ml-2">Duplicate</p>
              </Button>
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.gp?.includes("add") && (
              <Button
                variant="add"
                onClick={() =>
                  navigate("/items/create", {
                    state: {type: "grandprize", id: merchantInfo?.id},
                  })
                }
              >
                <ArrowDown />
                <p className="ml-2">Add</p>
              </Button>
            )
          }
        </div>
      </div>
      <Table
        data={
          dataItemsGrandPrize !== undefined && dataItemsGrandPrize.prizes.length
            ? dataItemsGrandPrize.prizes
            : []
        }
        loadingData={isLoadingItemsGrandPrize}
        errorData={isErrorItemsGrandPrize}
        origin="merchant_grand_prize"
        hasPagination={false}
        headers={headers}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeType",
          "name",
          "prizeCount",
          "remaining",
          "claimed",
          "redeemed",
          "startDate",
          "endDate",
          "daysToExpire",
          "state",
        ]}
        additionalData={{idMerchant: id}}
      />
      {modal.type === "archiveGrandPrize" && (
        <ArchiveModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          merchantIDProp={id?.toString()}
          promise={changeStatusGPMutation}
          type={"Grand Prize"}
        />
      )}
    </div>
  );
}

export default MerchantGrandPrize;
