import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Alert from "assets/Alert";
import Checkbox from "../Checkbox";

interface PlacesAutocompleteTypes {
  id: string;
  label: string;
  hasLabel?: boolean;
  disabled?: boolean;
  required: boolean;
  alert?: boolean;
  classes: string;
  classesLabel?: string;
  classesInput?: string;
  classesCheckbox?: string;
  error: boolean;
  placeholder: string;
  handleClickCheckbox?: any;
  checkedValues?: string[];
  clearAddressDetails: (inputValue: string) => void;
  userData: any;
  setUserData: any;
  setAddressAutoComplete: any;
  onBlur?: any;
}

const PlacesAutocomplete = ({
  id,
  label = "default label",
  hasLabel = true,
  disabled = false,
  required = false,
  alert = false,
  classes = "",
  classesLabel = "",
  classesInput = "",
  classesCheckbox = "",
  error = false,
  placeholder = "-",
  handleClickCheckbox,
  checkedValues,
  clearAddressDetails,
  userData,
  setUserData,
  setAddressAutoComplete,
  onBlur,
}: PlacesAutocompleteTypes) => {
  const {
    suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "us",
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    setUserData({...userData, address1: e.target.value});
    if (e.target.value.length < 4) clearAddressDetails(e.target.value);
  };

  const handleSelect = ({description}: any) => {
    return async () => {
      setAddressAutoComplete(description);
      setValue(description, false);
      clearSuggestions();
    };
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: {main_text, secondary_text},
      } = suggestion;

      return (
        <li
          className="font-medium py-1 text-sm cursor-pointer"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && error) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !error) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  const formatAddress = () => {
    if (userData?.address1?.includes(",") || userData?.address?.includes(",")) {
      return userData?.address1?.split(",")[0];
    } else {
      return userData?.address1 || userData?.address;
    }
  };

  return (
    <div className={classes}>
      <div className="flex items-center direction-row justify-between">
        {hasLabel && (
          <div className="mb-2 flex items-center direction-row justify-between">
            <label
              htmlFor={label.replace(/\s/g, "")}
              className={`block text-sm font-medium  ${
                classesLabel.length === 0 ? "text-gray-400" : classesLabel
              }`}
            >
              {label} {required && <span className="text-red-500">*</span>}
            </label>
          </div>
        )}
        {alert && (
          <div className="flex">
            <Alert classes="h-4 w-4 mr-2" />
            <Checkbox
              className={classesCheckbox}
              id={id || label.replace(/\s/g, "")}
              key={id || label.replace(/\s/g, "")}
              type="checkbox"
              name={id || label.replace(/\s/g, "")}
              onChange={(e: any) => handleClickCheckbox(e)}
              isChecked={checkedValues?.includes(
                id || label.replace(/\s/g, "")
              )}
            />
          </div>
        )}
      </div>
      <div ref={ref} className="relative">
        <input
          onBlur={onBlur}
          value={formatAddress()}
          onChange={disabled ? () => {} : (e) => handleInput(e)}
          placeholder={placeholder}
          className={`text-sm rounded-md block p-2.5 pl-4 font-medium  ${
            classesInput ? classesInput : "w-full"
          }
         ${checkClassName()} `}
          required={required}
        />
        {status === "OK" && (
          <ul className="rounded-b drop-shadow-xl w-full absolute z-10 bg-white font-medium text-gray-500 p-2.5 outline-primary-purple border-r-primary-purple">
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
