import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import Subtitle from "components/Common/Subtitle";
import Table from "components/Table/Table";

// Assets
import {ArrowDown} from "assets/Arrows";
import AddOffer from "assets/AddOffer";
import Archive from "assets/Archive";
import Prize from "assets/Prize";
import Copy from "assets/Copy";

// Hooks
import {useClickOutside} from "hooks/useClickOutside";
import {useGetItemsByMerchantIDQuery} from "store/api/merchantsApi";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {
  useChangeStatusItemMutation,
  useDuplicateItemMutation,
} from "store/api/itemApi";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import ArchiveModal from "components/Modal/ArchiveModal";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setMerchantsName} from "store/merchants/merchantsSlice";

// Utils
import routes from "utils/routesByRole";

// Interface
interface DeleteItemI {
  elementsChecked: number[] | string[];
  idMerchant: number | string | undefined;
}

function MerchantItems({merchantInfo, headers, subHeaders}: any) {
  const {id} = useParams();
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const modal = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [dropdown, openDropdown] = useState(false);
  const [params, setParams] = useState("?");

  useClickOutside(wrapperRef, openDropdown);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items);
  };

  const {
    data: dataItemsMerchants,
    isLoading: isLoadingItemsMerchants,
    isError: isErrorItemsMerchants,
    refetch,
  } = useGetItemsByMerchantIDQuery({id, params});

  const [duplicateItemMutation] = useDuplicateItemMutation();
  const [changeStatusMutation] = useChangeStatusItemMutation();

  useEffect(() => {
    if (
      dataItemsMerchants &&
      !isLoadingItemsMerchants &&
      !isErrorItemsMerchants
    ) {
      dispatch(setMerchantsName(dataItemsMerchants?.prizes));
    }
  }, [dataItemsMerchants, isLoadingItemsMerchants, isErrorItemsMerchants]);

  useEffect(() => {
    setParams(`?${filters.sorting}${filters.page}${filters.limit}`);
  }, [filters]);

  const duplicateItem = ({elementsChecked, idMerchant}: DeleteItemI) => {
    let toastPromise;

    elementsChecked.map(async (id: number | string) => {
      toastPromise = toast.promise(
        duplicateItemMutation({
          idItem: id.toString(),
          idMerchant: idMerchant?.toString(),
        }),
        {
          pending: "Duplicating items",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while duplicating item</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Item duplicated", {
              autoClose: 5000,
            });
            dispatch(setResetElChecked(true));
            refetch();
          }
        })
        .catch((err) => {
          toast.error("Error while duplicating item");
        });
    });
  };

  return (
    <div>
      <div className="px-5 bg-white flex items-center rounded justify-between">
        <Subtitle classes={"py-5"}>Items</Subtitle>
        <div className="flex">
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.item?.includes("archive") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "danger" : "disabled"
                }`}
                onClick={() => {
                  dispatch(setType("archiveItem"));
                  dispatch(setOpenModal(true));
                }}
              >
                <Archive />
                <p className="ml-2">Archive Item</p>
              </Button>
            )
          }
          {
            // @ts-ignore
            routes[user.data.role].urlAllowed?.item?.includes("add") && (
              <Button
                variant={`${
                  elementsChecked.length > 0 ? "normal" : "disabled"
                }`}
                onClick={() => duplicateItem({elementsChecked, idMerchant: id})}
              >
                <Copy />
                <p className="ml-2">Duplicate</p>
              </Button>
            )
          }
          <div className="relative">
            {
              // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("add") && (
                <Button
                  variant="add"
                  onClick={() => openDropdown((prev) => !prev)}
                >
                  <ArrowDown />
                  <p className="ml-2">Add</p>
                </Button>
              )
            }
            {dropdown && (
              <div
                className="bg-white absolute rounded-b drop-shadow-xl z-30 right-0 w-36"
                ref={wrapperRef}
              >
                <div className=" cursor-pointer hover:bg-primary-white">
                  <div
                    className="flex items-center px-4 py-2"
                    onClick={() =>
                      navigate("/items/create", {
                        state: {type: "prize", id: merchantInfo?.id},
                      })
                    }
                  >
                    <Prize classes="mr-3" />
                    <p className="text-sm">Prize</p>
                  </div>
                </div>
                <div className="flex cursor-pointer items-center hover:bg-primary-white">
                  <div
                    className="flex items-center px-4 py-2"
                    onClick={() =>
                      navigate("/items/create", {
                        state: {type: "offer", id: merchantInfo?.id},
                      })
                    }
                  >
                    <AddOffer classes="mr-3" />
                    <p className="text-sm">Offer</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Table
        data={
          dataItemsMerchants !== undefined && dataItemsMerchants.prizes.length
            ? dataItemsMerchants.prizes
            : []
        }
        loadingData={isLoadingItemsMerchants}
        errorData={isErrorItemsMerchants}
        refetch={refetch}
        results={
          dataItemsMerchants !== undefined ? dataItemsMerchants?.count : 1
        }
        origin="merchant_items"
        headers={headers}
        subHeaders={subHeaders}
        hasSubTable={true}
        dropdown={true}
        handleItemChecked={handleItemChecked}
        show={[
          "prizeId",
          "prizeType",
          "name",
          "prizeCount",
          "remaining",
          "claimed",
          "redeemed",
          "createdAt",
          "expiredAt",
          "daysToExpire",
          "state",
          "details",
        ]}
        additionalData={{idMerchant: id}}
        orderingByAPI={true}
      />
      {modal.type === "archiveItem" && (
        <ArchiveModal
          elementsChecked={elementsChecked}
          setElementsChecked={setElementsChecked}
          merchantIDProp={id?.toString()}
          promise={changeStatusMutation}
          type={"Item"}
        />
      )}
    </div>
  );
}

export default MerchantItems;
