import {Fragment, useState} from "react";
import {Switch} from "@headlessui/react";
// Components
import Disclosure from "../Disclosure";
import Info from "../Info";
import Input from "components/Common/Input";
import Select from "components/Common/Input/Select";
import Textarea from "components/Common/Textarea";

interface NotificationsTypes {
  scenario: string;
  text: string;
  frequencyDropDown: string;
}

function General() {
  const [userSettings, setUserSettings] = useState({
    regularSpinQuantity: "",
    grandSpinQuantity: "",
    maxPointsInWallet: 0,
    expiringPoints: "",
  });
  const [itemSettings, setItemSettings] = useState({
    state: "",
    city: "",
    zipCode: "",
    minPrice: 0,
    maxPrice: 0,
    rangeMaxPrice: 0,
    defaultItemToWheel: 0,
    rangeDefaultItemToWheel: 0,
  });

  const notificationsScenarios = ["1 Scenario", "2 Scenario", "3 Scenario"];

  const [frequencyUserNotification, setFrequencyUserNotification] =
    useState("");
  const [notificationsUser, setNotificationsUser] = useState<
    NotificationsTypes[]
  >(
    notificationsScenarios.map((scenario) => {
      return {
        scenario,
        text: "",
        frequencyDropDown: "",
      };
    })
  );

  const [frequencyMerchantsNotification, setFrequencyMerchantsNotification] =
    useState("");
  const [notificationsMerchants, setNotificationsMerchants] = useState<
    NotificationsTypes[]
  >(
    notificationsScenarios.map((scenario) => {
      return {
        scenario,
        text: "",
        frequencyDropDown: "",
      };
    })
  );

  const updateNotifications = (
    index: number,
    value: string,
    key: string,
    type: string
  ) => {
    const notificationType =
      type === "user" ? notificationsUser : notificationsMerchants;
    const newState = notificationType.map((obj, idx) => {
      if (idx === index) {
        return {...obj, [key]: value};
      }
      return obj;
    });
    if (type === "user") return setNotificationsUser(newState);
    return setNotificationsMerchants(newState);
  };

  return (
    <div className="flex flex-col">
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min W-">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("userSetting: ", userSettings);
            }}
          >
            <Disclosure header="Default User Settings" makeAsDefaultButton>
              <div>
                <div className="flex mb-5 flex-wrap">
                  <Select
                    label="Default regular spin quantities"
                    classes="w-2/5 mr-8"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full mr-8"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUserSettings({
                        ...userSettings,
                        regularSpinQuantity: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a time interval
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                  <Input
                    value={userSettings.maxPointsInWallet}
                    placeholder="0"
                    label="Maximum points in the wallet"
                    classes="mr-8 w-2/5"
                    classesInput="w-full"
                    onChange={(e) => {
                      setUserSettings({
                        ...userSettings,
                        maxPointsInWallet: +e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Select
                    label="Default grand spin quantities"
                    classes="w-2/5 mr-8"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full mr-8"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUserSettings({
                        ...userSettings,
                        grandSpinQuantity: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a time interval
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                  <Select
                    label="Days expiring points"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full mr-8"
                    classes="w-2/5"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUserSettings({
                        ...userSettings,
                        expiringPoints: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a time interval
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                </div>
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">
              Default Regular Spin Quantities
            </p>
            <p className="text-xs">
              How many spins are we providing for players every 24 h.
            </p>
          </div>
          <div>
            <p className="text-sm font-medium">Default Grand Spin Quantities</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante. Praesent
              lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
              Nulla elementum turpis nec velit sagittis, vitae ultrices quam
              aliquet. Etiam condimentum velit a quam tempus elementum. Donec
              dignissim varius commodo. Proin sit amet lacus non neque mollis
              venenatis vel sit amet augue.
            </p>
          </div>
        </Info>
      </div>

      {/* Second */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("itemSettings: ", itemSettings);
            }}
          >
            <Disclosure header="Default Items Settings" makeAsDefaultButton>
              <div>
                <div className="flex mb-5 flex-wrap">
                  <Select
                    label="State"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-7 w-40 mr-8"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setItemSettings({
                        ...itemSettings,
                        state: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a state
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                  <Select
                    label="City"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-60 mr-8"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setItemSettings({
                        ...itemSettings,
                        city: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a city
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                  <Select
                    label="Zip Code"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-3 w-40 mr-8"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setItemSettings({
                        ...itemSettings,
                        zipCode: e.target.value,
                      });
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select zip code
                      </option>
                      <option value="24h">24h</option>
                      <option value="1week">1 week</option>
                      <option value="2week">2 week</option>
                      <option value="1month">1 month</option>
                      <option value="1quarter">1 quarter</option>
                    </>
                  </Select>
                </div>
                <hr className="bg-gray-100 mb-4" />
                <div className="flex">
                  <div className="flex mb-5 flex-wrap w-2/5">
                    <div className="flex justify-between">
                      <Input
                        value={itemSettings.minPrice}
                        placeholder="0"
                        label="Min price of item"
                        classes="w-2/5"
                        classesInput="w-full"
                        classesLabel="whitespace-nowrap text-gray-400"
                        onChange={(e) => {
                          setItemSettings({
                            ...itemSettings,
                            minPrice: +e.target.value,
                          });
                        }}
                      />
                      <Input
                        value={itemSettings.maxPrice}
                        placeholder="0"
                        label="Max price of item"
                        classes="w-2/5"
                        classesInput="w-full"
                        classesLabel="whitespace-nowrap text-gray-400"
                        onChange={(e) => {
                          setItemSettings({
                            ...itemSettings,
                            maxPrice: +e.target.value,
                          });
                        }}
                      />
                    </div>
                    <Input
                      value={itemSettings.rangeMaxPrice}
                      placeholder="0"
                      label="Max price of item"
                      classes="w-full mt-6"
                      classesInput="w-full"
                      subtitle="15=100"
                      onChange={(e) => {
                        setItemSettings({
                          ...itemSettings,
                          rangeMaxPrice: +e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex mb-5 flex-wrap flex-col">
                    <Input
                      value={itemSettings.defaultItemToWheel}
                      placeholder="0"
                      label="Default items go to wheel"
                      classes="ml-12 w-full"
                      classesInput="w-full"
                      onChange={(e) => {
                        setItemSettings({
                          ...itemSettings,
                          defaultItemToWheel: +e.target.value,
                        });
                      }}
                    />
                    <Input
                      value={itemSettings.rangeDefaultItemToWheel}
                      placeholder="0"
                      label="Default items go to wheel"
                      classes="ml-12 w-full mt-6"
                      classesInput="w-full"
                      subtitle="23=16,1 - 19 go to the wheel + 5 shop"
                      onChange={(e) => {
                        setItemSettings({
                          ...itemSettings,
                          rangeDefaultItemToWheel: +e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">Prize In One Hand</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Min Price Of Prize</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Max Price Of Prize</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Maximum Points In The Wallet</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Days Expiring Points</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
        </Info>
      </div>

      {/* Thirth */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Disclosure header="Notifications Manager" makeAsDefaultButton>
              <div className="flex justify-between">
                <div className="flex mb-5 flex-col w-5/12">
                  <Select
                    label="Notifications user"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFrequencyUserNotification(e.target.value);
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a frequency
                      </option>
                      <option value="24h">24h</option>
                      <option value="1timePer3">1 time per 3</option>
                    </>
                  </Select>
                  {notificationsScenarios.map((item, index) => {
                    return (
                      <div key={item} className="mt-8 border-gray-200 border-t">
                        <div className="flex justify-between items-center pr-5 mt-4">
                          <p className="text-base font-semibold text-black">
                            {item}
                          </p>
                          <Switch defaultChecked={false} as={Fragment}>
                            {({checked}) => (
                              <button
                                className={`${
                                  checked ? "bg-primary-purple" : "bg-gray-200"
                                } relative inline-flex h-7 w-12 items-center rounded-full`}
                                onClick={() => console.log("changed")}
                              >
                                <span className="sr-only">
                                  Enable notifications
                                </span>
                                <span
                                  className={`${
                                    checked ? "translate-x-6" : "translate-x-1"
                                  } inline-block h-5 w-5 transform rounded-full bg-white transition w`}
                                />
                              </button>
                            )}
                          </Switch>
                        </div>
                        <Textarea
                          label="Text message"
                          // error={!itemData.description && inputError ? true : false}
                          placeholder="Enter a description"
                          classes="mb-5 mt-4"
                          rows={8}
                          value={notificationsUser[index].text}
                          onChange={(e) => {
                            updateNotifications(
                              index,
                              e.target.value,
                              "text",
                              "user"
                            );
                          }}
                        />
                        <Select
                          label="Frequency drop down"
                          classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full"
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            updateNotifications(
                              index,
                              e.target.value,
                              "frequencyDropDown",
                              "user"
                            );
                          }}
                        >
                          <>
                            <option value="" disabled selected>
                              Select a frequency
                            </option>
                            <option value="1day">1 on day</option>
                            <option value="1week">1 on week</option>
                            <option value="1month">1 on month</option>
                          </>
                        </Select>
                      </div>
                    );
                  })}
                </div>
                <div className="bg-gray-100 w-px" />
                <div className="flex mb-5 flex-col w-5/12">
                  <Select
                    label="Notifications merchants"
                    classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full"
                    classes=""
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFrequencyMerchantsNotification(e.target.value);
                    }}
                  >
                    <>
                      <option value="" disabled selected>
                        Select a frequency
                      </option>
                      <option value="24h">24h</option>
                      <option value="1timePer3">1 time per 3</option>
                    </>
                  </Select>
                  {notificationsScenarios.map((item, index) => {
                    return (
                      <div key={item} className="mt-8 border-gray-200 border-t">
                        <div className="flex justify-between items-center pr-5 mt-4">
                          <p className="text-base font-semibold text-black">
                            {item}
                          </p>
                          <Switch defaultChecked={false} as={Fragment}>
                            {({checked}) => (
                              <button
                                className={`${
                                  checked ? "bg-primary-purple" : "bg-gray-200"
                                } relative inline-flex h-7 w-12 items-center rounded-full`}
                                onClick={() => console.log("changed")}
                              >
                                <span className="sr-only">
                                  Enable notifications
                                </span>
                                <span
                                  className={`${
                                    checked ? "translate-x-6" : "translate-x-1"
                                  } inline-block h-5 w-5 transform rounded-full bg-white transition w`}
                                />
                              </button>
                            )}
                          </Switch>
                        </div>
                        <Textarea
                          label="Text message"
                          // error={!itemData.description && inputError ? true : false}
                          placeholder="Enter a description"
                          classes="mb-5 mt-4"
                          rows={8}
                          value={notificationsMerchants[index].text}
                          onChange={(e) => {
                            updateNotifications(
                              index,
                              e.target.value,
                              "text",
                              "merchants"
                            );
                          }}
                        />
                        <Select
                          label="Frequency drop down"
                          classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full"
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            updateNotifications(
                              index,
                              e.target.value,
                              "frequencyDropDown",
                              "merchants"
                            );
                          }}
                        >
                          <>
                            <option value="" disabled selected>
                              Select a frequency
                            </option>
                            <option value="1day">1 on day</option>
                            <option value="1week">1 on week</option>
                            <option value="1month">1 on month</option>
                          </>
                        </Select>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Disclosure>
          </form>
        </div>
        <div className="flex flex-col">
          <Info classes="w-72">
            <div className="mb-5">
              <p className="text-sm font-medium">
                Main Interests (By Silencing Secretion)
              </p>
              <p className="text-xs">
                Cras nec lacus id tellus tempus feugiat in non ante.
              </p>
            </div>
            <div className="mb-5">
              <p className="text-sm font-medium">
                Secondary Interests (By Silencing Secretion)
              </p>
              <p className="text-xs">
                Cras nec lacus id tellus tempus feugiat in non ante.
              </p>
            </div>
            <div>
              <p className="text-sm font-medium">
                Sub Categories (By Silencing Secretion)
              </p>
              <p className="text-xs">
                Cras nec lacus id tellus tempus feugiat in non ante. Praesent
                lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
                Nulla elementum turpis nec velit sagittis, vitae ultrices quam
                aliquet. Etiam condimentum velit a quam tempus elementum. Donec
                dignissim varius commodo. Proin sit amet lacus non neque mollis
                venenatis vel sit amet augue.
              </p>
            </div>
          </Info>
          <Info classes="w-72 mt-4">
            <div className="mb-5">
              <p className="text-sm font-medium">Notifications</p>
              <p className="text-xs">
                Cras nec lacus id tellus tempus feugiat in non ante. Praesent
                lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
                Nulla elementum turpis nec velit sagittis, vitae ultrices quam
                aliquet. Etiam condimentum velit a quam tempus elementum. Donec
                dignissim varius commodo. Proin sit amet lacus non neque mollis
                venenatis vel sit amet augue.
              </p>
            </div>
          </Info>
          <Info classes="w-72 mt-4">
            <div className="mb-5">
              <p className="text-sm font-medium">Terms and conditions</p>
              <p className="text-xs">
                Cras nec lacus id tellus tempus feugiat in non ante. Praesent
                lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
                Nulla elementum turpis nec velit sagittis, vitae ultrices quam
                aliquet. Etiam condimentum velit a quam tempus elementum. Donec
                dignissim varius commodo. Proin sit amet lacus non neque mollis
                venenatis vel sit amet augue.
              </p>
            </div>
          </Info>
        </div>
      </div>
    </div>
  );
}

export default General;
