import Archive from "assets/Archive";
import Delete from "assets/Delete";
import Edit from "assets/Edit";
import Badge from "components/Badge";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import DeleteModal from "components/Modal/DeleteModal";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  itemApi,
  useChangeStatusItemMutation,
  useDeleteItemMutation,
  useDeleteRewardLoyaltyProgramMutation,
} from "store/api/itemApi";
import {
  setEmployeeSelectedToDelete,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";
import {RootState} from "store/store";
import routes from "utils/routesByRole";
import {LoyalityData} from "../Details";

export interface Reward {
  prizeId: number;
  retailerId: number;
  loyaltyId: number;
  rewardOrder: number;
  name: string;
  description: string;
  requiredStamps: number;
  reward: RewardClass;
}

export interface RewardClass {
  prizeId: number;
  retailerId: number;
  name: string;
  price: null;
  terms: string;
  prizeType: string;
  prizeCount: number;
  percentShop: number;
  offerDiscount: null;
  createdAt: Date;
  expiredAt: Date;
  state: string;
  images: any[];
  prizes: any[];
  categories: Category[];
  perCustomerLimit: number;
  ageLimit: number;
  isNew: boolean;
  radius: null;
  color: string;
  promotionType: string;
  newData: any;
}

export interface Category {
  id: number;
  name: string;
  imageUrl: string;
  color: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  categories: null;
}

function RewardCard({
  reward,
  index,
  loyalityData,
}: {
  reward: Reward;
  index: number;
  loyalityData: any;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);

  const [changeStatusMutation] = useChangeStatusItemMutation();

  const [isInputDisabledDelete] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.loyalty?.includes("delete")
      ? false
      : true;
  });
  const [isInputDisabledView] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.loyalty?.includes("view")
      ? false
      : true;
  });

  const currentState = reward?.reward?.state;

  const handleChangeItemStatus = async (reward: Reward) => {
    const toastPromise = toast.promise(
      changeStatusMutation({
        idItem: reward.prizeId?.toString(),
        idMerchant: reward.retailerId?.toString(),
        status: reward.reward.state === "ACTIVE" ? "PAUSED" : "ACTIVE",
      }),
      {
        pending: "Changing status reward",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing status reward</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Reward status changed");
          dispatch(itemApi.util.invalidateTags(["LoyaltyProgram"]));
          dispatch(itemApi.util.invalidateTags(["IndividualLoyaltyProgram"]));
        }
      })
      .catch((err) => {
        toast.error("Error while changing status reward");
      });
  };

  return (
    <div
      className="w-full rounded-md border-solid border border-gray-300 "
      key={index}
    >
      <div className="flex p-4 gap-3">
        <img
          className="h-[150px] aspect-square"
          src={
            reward?.reward?.images[0]?.newUrl || reward?.reward?.images[0]?.url
          }
          alt=""
        />
        <div className="flex flex-col justify-between w-full">
          <div>
            <p className="flex gap-1 text-sm text-gray-400">
              ID:{" "}
              <p
                className="text-blue-400 underline underline-offset-1 cursor-pointer"
                onClick={() =>
                  navigate("/items/" + reward?.reward?.prizeId, {
                    state: {
                      idMerchant: reward?.retailerId,
                      idItem: reward?.reward?.prizeId,
                    },
                  })
                }
              >
                {reward.prizeId}
              </p>
            </p>
            <p className="text-base font-medium">
              {reward?.reward?.newData?.name || reward?.reward?.name}
            </p>
            <p className="text-sm text-gray-400">
              {" "}
              {reward?.reward?.newData?.description || reward?.description}
            </p>
          </div>
          <div className="flex gap-7 flex-wrap sm:mt-5">
            <div>
              <p className="text-xs mb-1">Categories:</p>
              <p className="text-sm font-medium">
                {reward?.reward?.newData?.categories
                  ? reward?.reward?.newData?.categories
                      ?.map((reward: any) => reward.name)
                      ?.join(", ")
                  : reward?.reward?.categories
                      ?.map((reward) => reward.name)
                      ?.join(", ")}
              </p>
            </div>
            <div>
              <p className="text-xs mb-1">Stamps Needed:</p>
              <p className="text-sm font-medium">
                {reward?.reward?.newData?.requiredStamps ||
                  reward.requiredStamps}
              </p>
            </div>
            <div>
              <p className="text-xs mb-1">Minimum Age Requirements:</p>
              <p className="text-sm font-medium">
                {reward?.reward?.newData?.ageLimit || reward.reward.ageLimit}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between items-end">
          <div className="flex ">
            {(currentState === "ACTIVE" || currentState === "PAUSED") &&
              // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("archive") && (
                <Button
                  variant="danger"
                  type="button"
                  onClick={(e) => {
                    if (reward.prizeId) {
                      dispatch(
                        setEmployeeSelectedToDelete({
                          id: reward.prizeId,
                          data: reward,
                        })
                      );
                      dispatch(setType("archiveReward"));
                      dispatch(setOpenModal(true));
                    }
                  }}
                >
                  <Archive />
                  <p className="ml-2">Archive</p>
                </Button>
              )}
            {currentState === "ARCHIVED" &&
              // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("delete") && (
                <Button
                  variant={isInputDisabledDelete ? "disabled" : "danger"}
                  type="button"
                  onClick={
                    isInputDisabledDelete
                      ? () => {}
                      : () => {
                          dispatch(
                            setEmployeeSelectedToDelete({
                              id: reward.prizeId,
                              data: reward,
                            })
                          );
                          dispatch(setType("deleteReward"));
                          dispatch(setOpenModal(true));
                        }
                  }
                >
                  <Delete classes="mr-2" /> Delete
                </Button>
              )}
            {
              // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("view") && (
                <Button
                  variant={isInputDisabledView ? "disabled" : "normal"}
                  type="button"
                  onClick={
                    isInputDisabledView
                      ? () => {}
                      : () =>
                          navigate("/items/" + reward?.reward?.prizeId, {
                            state: {
                              idMerchant: reward?.retailerId,
                              idItem: reward?.reward?.prizeId,
                              currentRewardPosition: index,
                              loyalityData: loyalityData,
                            },
                          })
                  }
                >
                  <Edit classes="mr-2" /> Edit
                </Button>
              )
            }
          </div>
          <div>
            <Badge
              title={reward.reward.state}
              onClick={() => handleChangeItemStatus(reward)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardCard;
