import { TypeClasses } from "./types";

function AddPerson({ classes }: TypeClasses) {
  return (
    <svg
      id="arrow-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="person_add_FILL0_wght400_GRAD0_opsz48"
        d="M16.114,16.743V14.084H13.455V12.857h2.659V10.2h1.227v2.659H20v1.227H17.341v2.659ZM8.545,15.086a2.943,2.943,0,0,1-3.068-3.068A2.943,2.943,0,0,1,8.545,8.95a2.943,2.943,0,0,1,3.068,3.068,2.943,2.943,0,0,1-3.068,3.068ZM2,21.652V19.73a2.382,2.382,0,0,1,.368-1.3,2.241,2.241,0,0,1,1.023-.869,15.676,15.676,0,0,1,2.731-.951,10.776,10.776,0,0,1,4.848,0,15.149,15.149,0,0,1,2.71.951,2.46,2.46,0,0,1,1.033.88,2.286,2.286,0,0,1,.378,1.289v1.923Zm1.227-1.227H13.864v-.7a1.273,1.273,0,0,0-.164-.624.991.991,0,0,0-.511-.44,10.657,10.657,0,0,0-2.444-.9,11.109,11.109,0,0,0-2.2-.2,10.6,10.6,0,0,0-2.2.215,12,12,0,0,0-2.465.89,1.071,1.071,0,0,0-.481.44,1.215,1.215,0,0,0-.174.624Zm5.318-6.566a1.841,1.841,0,1,0,0-3.682,1.841,1.841,0,1,0,0,3.682ZM8.545,12.018ZM8.545,17.561Z"
        transform="translate(1 -2.95)"
        fill="currentColor"
      />
    </svg>
  );
}

export default AddPerson;
