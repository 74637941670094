import {Dispatch, SetStateAction} from "react";
import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";

// Assets
import Recovery from "assets/Recovery";

type RecoveryModalType = {
  elementsChecked: number[];
  setElementsChecked: Dispatch<SetStateAction<number[]>>;
  promise: Function;
  type: string;
};

function RecoveryModal({
  elementsChecked,
  setElementsChecked,
  promise,
  type,
}: RecoveryModalType) {
  // Need to change the name, in merchant name is stored all the names
  // regardless the type (item, grand prize, merchant)
  const names = useSelector(
    (state: RootState) => state.merchants.merchantsName
  );
  const dispatch = useDispatch();

  const handleSubmit = () => {
    elementsChecked.forEach(async (id: number) => {
      let toastPromise;
      if (type === "Merchant") {
        toastPromise = toast.promise(promise({id, status: "UNVERIFIED"}), {
          pending: `Recovering ${type}`,
        });
      } else if (type === "Item") {
        const merchantID = document
          ?.querySelector("table")
          ?.querySelector(`[id='${id}']`)?.children[5]?.innerHTML;

        if (!merchantID || merchantID === "0")
          return toast.error("Error trying to get Merchant owner of item");

        toastPromise = toast.promise(
          promise({
            idItem: id.toString(),
            idMerchant: merchantID?.toString(),
            status: "PENDING_REVIEW",
          }),
          {
            pending: `Recovering ${type}`,
          }
        );
      } else if (type === "Grand Prize") {
        const merchantID = document
          ?.querySelector("table")
          ?.querySelector(`[id='${id}']`)?.children[5]?.innerHTML;

        if (!merchantID || merchantID === "0")
          return toast.error("Error trying to get Merchant owner of item");

        toastPromise = toast.promise(
          promise({
            idItem: id.toString(),
            idMerchant: merchantID?.toString(),
            status: "PENDING_REVIEW",
          }),
          {
            pending: `Recovering ${type}`,
          }
        );
      } else if (type === "Loyalty Program") {
        toastPromise = toast.promise(
          promise({
            idLoyalty: elementsChecked[0],
            data: {newState: "PENDING_REVIEW"},
          }),
          {
            pending: `Recovering ${type}`,
          }
        );
      } else {
        return;
      }

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while recovering {type}</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success(`${type} Recovered`);
            dispatch(setResetElChecked(true));
            setElementsChecked([]);
            setTimeout(() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error(`Error while recovering ${type}`);
        });
    });
  };

  const getNames = () => {
    if (!elementsChecked.length) return <></>;

    const elementNames = elementsChecked.map((el) => {
      return names?.filter((employee: any) => {
        if (employee.id === el) {
          return employee;
        }
      });
    });

    const namesFlatted = elementNames?.flat();

    return namesFlatted?.map((element: any) => {
      if (element) {
        return namesFlatted.length > 1 ? (
          <b key={element.name}>{element.name}, </b>
        ) : (
          <b key={element.name}>{element.name} </b>
        );
      } else {
        return <b></b>;
      }
    });
  };

  return (
    <Modal>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <div className="rounded-full h-20 w-20 bg-green-100 flex items-center justify-center mt-5">
            <Recovery classes="text-green-500 h-10 w-10" />
          </div>
          <h1 className="text-green-500 mt-4">Recover</h1>
          <p className="text-sm mt-4 text-center">
            Are you sure you want to recover {type} {getNames()}?
          </p>
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="recovery"
            onClick={() => {
              handleSubmit();
            }}
          >
            Recover
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RecoveryModal;
