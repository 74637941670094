import {Suspense, lazy, useState, useMemo, useEffect} from "react";

// Components
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Filter from "components/Filter";
import Form from "components/Table";
import Table from "components/Table/Table";
import Modal from "components/Modal/index";
import Input from "components/Common/Input";
import Button from "components/Common/Button";
import Select from "components/Common/Input/Select";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setMerchantsName} from "store/merchants/merchantsSlice";

import {setOpenModal} from "store/modal/modalSlice";
import {TABLE_ADMINS_HEADER} from "utils/adminHeaders";
import FormSqueleton from "utils/FormSqueleton";
import {useGetAdminByIDQuery, useGetAllAdminsQuery} from "store/api/adminApi";
import {useParams} from "react-router-dom";

const FilterFormUser = lazy(() => import("components/Filter/FilterFormUser"));

function Admins() {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [newAdminData, setNewAdminData] = useState({
    email: "",
    authority: "",
  });

  const {
    data: dataAdmins,
    isLoading: isLoadingAdmins,
    isError: isErrorAdmins,
    error,
    refetch,
    // @ts-ignore
  } = useGetAllAdminsQuery();

  const handleAddNewAdmin = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("newAdminData: ", newAdminData);
  };

  useEffect(() => {
    dispatch(setMerchantsName([]));
  }, []);

  return (
    <div>
      <Title>Admins</Title>
      <Form>
        <div className="mt-3">
          <Tabs>
            <>
              <div className="mt-3">
                <Filter
                  showFilter={showFilter}
                  handleShowFilter={setShowFilter}
                  elementsChecked={elementsChecked}
                  optionsDropdownCheckbox={[
                    "super admin",
                    "admin",
                    "moderator",
                  ]}
                  optionsAllDropdownCheckbox="All Authority"
                  origin="Admins"
                  searchBarPlaceholder="Email"
                />
                {showFilter && (
                  <Suspense fallback={<FormSqueleton />}>
                    <FilterFormUser handleShowFilter={setShowFilter} />
                  </Suspense>
                )}
              </div>
              <Table
                headers={TABLE_ADMINS_HEADER}
                data={dataAdmins && dataAdmins.length ? dataAdmins : []}
                loadingData={isLoadingAdmins}
                errorData={isErrorAdmins || !!error}
                origin="admins"
                show={[
                  "firstName",
                  "lastName",
                  "email",
                  "createdAt",
                  "role",
                  "status",
                ]}
                sort={{
                  firstName: null,
                  lastName: null,
                  email: null,
                  createdAt: null,
                  role: null,
                  status: null,
                }}
                refetch={refetch}
              />
            </>
          </Tabs>
        </div>
      </Form>
      {modal.type === "addNewAdmin" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            <form onSubmit={(e) => handleAddNewAdmin(e)}>
              <h1
                className={
                  "font-medium text-2xl px-5 text-start  mb-4 tracking-normal"
                }
              >
                Add new Admin
              </h1>
              <hr />
              <div className="px-5 my-5">
                <Input
                  required
                  type={"email"}
                  label="Email"
                  value={newAdminData.email}
                  placeholder="Enter email"
                  onChange={(e) =>
                    setNewAdminData({...newAdminData, email: e.target.value})
                  }
                />
              </div>
              <div className="px-5 mb-10">
                <Select
                  label="Authority"
                  classes="w-full"
                  classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 w-full mr-8"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setNewAdminData({
                      ...newAdminData,
                      authority: e.target.value,
                    });
                  }}
                  value={newAdminData.authority}
                >
                  <>
                    <option value="" disabled selected>
                      Select Authority
                    </option>
                    <option value="superAdmin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="moderator">Moderator</option>
                  </>
                </Select>
              </div>
              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => {
                    setNewAdminData({
                      email: "",
                      authority: "",
                    });
                    dispatch(setOpenModal(false));
                  }}
                  variant="normal"
                  classes="text-center"
                  type="button"
                >
                  Cancel
                </Button>
                <Button variant="add" classes="text-center" type="submit">
                  Send
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Admins;
