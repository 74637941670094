import React, {useEffect, useRef, useState} from "react";

// Components
import Button from "../Button";

// Assets
import {ArrowDown, ArrowNext} from "assets/Arrows";
import Checkbox from "../Checkbox";
import Alert from "assets/Alert";
import {useClickOutside} from "hooks/useClickOutside";

function DropdownCategory({
  checkboxID,
  openDropdown,
  dropdown,
  elementsChecked,
  elementsName,
  handleClickCheckbox,
  hasLabel,
  label = "Interests/Categories",
  placeholder = "Select a category",
  dataCategories,
  isErrorCategories,
  isLoadingCategories,
  error,
  isError,
  className,
  classNameSelect,
  required,
  customData,
  disabled = false,
  handleClickDropdown,
  alert,
  classesCheckbox = "",
  handleClickNewDataCheckbox,
  checkedValues,
  hasSelectAll = false,
  hasId = false,
  hasCheckbox = true,
  hasClearAll = true,
  buttonName = "",
  hasCustomValue = false,
  hasSaveBtn = true,
  returnValue = false,
  origin = "",
  hasSearchValue = {show: false, content: ""},
  showNames = true,
  expandItems = "",
  classAutoComplete = "",
}: any) {
  const wrapperRef = useRef(null);

  // useClickOutside(wrapperRef, openDropdown);

  const [expandItemsLocal, setExpandedItemsLocal] = useState<any>([]);

  const displayCategory = Array.from(new Set(elementsName));

  const a =
    dataCategories && dataCategories.length > 0 ? [...dataCategories] : [];

  const dataCategoriesSorted =
    origin === "location_area"
      ? a
          ?.sort((a: any, b: any) => b.zipCodeCount - a.zipCodeCount)
          ?.map((el) => {
            return {
              ...el,
              name: el?.name?.toLowerCase(),
            };
          })
      : a
          ?.sort((a: any, b: any) => a.id - b.id)
          ?.map((el) => {
            return {
              ...el,
              name: el?.name?.toLowerCase(),
            };
          });

  useEffect(() => {
    if (expandItems.length > 0) {
      dataCategoriesSorted?.map((category) => {
        const hasChildren = category.subItems && category.subItems.length > 0;

        if (hasChildren && expandItems.length > 0) {
          setExpandedItemsLocal((prev: any) => [...prev, category.id]);

          category.subItems.map((cat: any) => {
            const hasChildren = cat.subItems && cat.subItems.length > 0;

            if (hasChildren && expandItems.length > 0) {
              setExpandedItemsLocal((prev: any) => [...prev, cat.id]);
            }
          });
        }
      });
    } else {
      setExpandedItemsLocal([]);
    }
  }, [expandItems]);

  const handleToggle = (categoryId: any) => {
    let expandItemsLocalV2;

    if (expandItemsLocal.includes(categoryId)) {
      expandItemsLocalV2 = expandItemsLocal.filter(
        (id: any) => id !== categoryId
      );
    } else {
      expandItemsLocalV2 = [...expandItemsLocal, categoryId];
    }

    setExpandedItemsLocal(expandItemsLocalV2);
  };

  const renderCategory = (category: any) => {
    const hasChildren = category.subItems && category.subItems.length > 0;

    const isExpanded = expandItemsLocal.includes(category.id);
    const isChecked =
      elementsChecked?.find(
        (element: any) => element.toString() === category.id.toString()
      ) !== undefined;

    return (
      <div
        className="mb-3"
        key={category?.id || category?.code}
        onClick={
          hasCustomValue
            ? (e: any) => handleClickDropdown(category, "save")
            : returnValue
            ? (e: any) => handleClickDropdown(category, "find")
            : () => {}
        }
      >
        {hasId && <span>{category.id}</span>}

        <div className="flex items-center  w-full">
          {hasCheckbox && (
            <Checkbox
              className={`mr-3 flex`}
              id={category.id?.toString()}
              key={category.id}
              type="checkbox"
              name={category.name}
              onChange={(e: any) => handleClickCheckbox(e)}
              isChecked={isChecked}
            />
          )}

          <label
            htmlFor={category.id.toString()}
            className={`font-medium text-sm cursor-pointer whitespace-nowrap ${
              hasCustomValue && "w-full flex justify-between"
            }`}
          >
            {category.name || category?.description}
            {hasCustomValue && (
              <span className="font-medium text-sm">
                {category.zipCodeCount} zip codes
              </span>
            )}
          </label>

          {hasChildren && (
            <div
              className={`w-full flex justify-end`}
              onClick={() => handleToggle(category.id)}
            >
              <p
                className={`${
                  isExpanded ? "rotate-90" : "rotate-0"
                } text-xs text-gray-400 cursor-pointer`}
              >
                <ArrowNext classes="h-5 w-5" />
              </p>
            </div>
          )}
        </div>

        {isExpanded && hasChildren && (
          <div className="pl-6 mt-2">
            {category?.subItems?.map(renderCategory)}
          </div>
        )}
      </div>
    );
  };

  const showCategoryNames = () => {
    if (!showNames) return placeholder;
    if (!elementsChecked.length && !elementsName.length && placeholder) {
      return placeholder;
    } else if (elementsChecked?.includes("all") && placeholder) {
      return placeholder;
    } else if (elementsChecked?.includes("all") && !placeholder) {
      return "All";
    } else {
      return displayCategory?.map((el: any, key: number) => (
        <p key={key} className="mr-2 capitalize">
          {el},
        </p>
      ));
    }
  };

  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !isError) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && isError) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !isError) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  return (
    <>
      <div className="flex items-center direction-row justify-between">
        {hasLabel && (
          <label
            htmlFor={label.replace(/\s/g, "")}
            className={`block mb-2 text-sm font-medium text-gray-400`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </label>
        )}
        {alert && (
          <div className="flex">
            <Alert classes="h-4 w-4 mr-2" />
            <Checkbox
              className={classesCheckbox}
              id={checkboxID || label.replace(/\s/g, "")}
              key={checkboxID || label.replace(/\s/g, "")}
              type="checkbox"
              name={checkboxID || label.replace(/\s/g, "")}
              onChange={(e: any) => handleClickNewDataCheckbox(e)}
              isChecked={checkedValues?.includes(
                checkboxID || label.replace(/\s/g, "")
              )}
            />
          </div>
        )}
      </div>
      <div className="relative">
        <div
          onClick={
            disabled ? () => {} : () => openDropdown((prev: any) => !prev)
          }
          className={`${
            dropdown && "border-primary-purple"
          } ${className} h-auto items-center rounded-md border cursor-pointer p-2.5 pl-4 z-0 font-medium text-sm flex justify-between 
          ${checkClassName()}
         `}
        >
          <span
            className={`flex capitalize status-filter whitespace-nowrap overflow-hidden  flex-wrap ${
              classNameSelect ? classNameSelect : "w-72"
            }`}
          >
            {showCategoryNames()}
          </span>
          <span>
            <ArrowDown />
          </span>
        </div>
        {dropdown && (
          <div
            className={`bg-white pt-3 px-4 capitalize absolute l-0 rounded-b 
            drop-shadow-xl z-30 ${
              classAutoComplete ? classAutoComplete : "w-full"
            } max-h-[420px] overflow-y-auto`}
            ref={wrapperRef}
          >
            {hasSearchValue &&
              hasSearchValue.show &&
              hasSearchValue.content &&
              hasSearchValue.content}
            {hasSelectAll && (
              <div className="flex items-center">
                <Checkbox
                  id="all"
                  key="all"
                  type="checkbox"
                  name="all"
                  onChange={(e: any) => handleClickCheckbox(e)}
                  isChecked={elementsChecked?.includes("all")}
                />
                <label
                  htmlFor="all"
                  className="ml-3 font-medium text-sm cursor-pointer"
                >
                  Select All
                </label>
              </div>
            )}
            <hr className="my-2" />
            {isLoadingCategories && (
              <div className="flex items-center mb-3">
                <label className="ml-3 font-medium text-sm cursor-pointer">
                  Loading categories...
                </label>
              </div>
            )}
            {isErrorCategories && (
              <div className="flex items-center mb-3">
                <label className="ml-3 font-medium text-sm cursor-pointer">
                  {error?.data?.code}
                </label>
              </div>
            )}
            {dataCategoriesSorted.length > 0 ? (
              dataCategoriesSorted?.map(renderCategory)
            ) : !isLoadingCategories &&
              dataCategoriesSorted.length === 0 &&
              !customData ? (
              <p>No information to display</p>
            ) : (
              ""
            )}
            {customData && customData}
            <hr />
            <div className="sticky bottom-0 bg-white py-2 flex justify-between mt-2">
              {hasClearAll && (
                <Button
                  onClick={(e: any) => handleClickDropdown(e, "clear")}
                  variant="normal"
                  classes="!text-xs"
                >
                  {buttonName ? buttonName : "Clear all"}
                </Button>
              )}
              {!hasCustomValue && hasSaveBtn && (
                <Button
                  onClick={(e: any) => handleClickDropdown(e, "save")}
                  variant="add"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DropdownCategory;
