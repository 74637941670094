import {TypeClasses} from "./types";

function Ordering({classes}: TypeClasses) {
  return (
    <svg
      id="sort"
      className={`${classes}`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <g
        id="_73ed22c8e28425ba920f5b4413a497b2"
        data-name="73ed22c8e28425ba920f5b4413a497b2"
        transform="translate(-8 -15)"
      >
        <path
          id="Контур_1326"
          data-name="Контур 1326"
          d="M11.179,30.834,10,32.013l2.744,2.743a.833.833,0,0,0,1.178,0l2.744-2.743-1.179-1.179-1.322,1.322V20H12.5V32.155Z"
          fill="#151c39"
        />
        <rect
          id="Прямоугольник_9081"
          data-name="Прямоугольник 9081"
          width="11.667"
          height="1.667"
          transform="translate(18.333 21.667)"
          fill="#151c39"
        />
        <rect
          id="Прямоугольник_9082"
          data-name="Прямоугольник 9082"
          width="9.167"
          height="1.667"
          transform="translate(18.333 25)"
          fill="#151c39"
        />
        <rect
          id="Прямоугольник_9083"
          data-name="Прямоугольник 9083"
          width="6.667"
          height="1.667"
          transform="translate(18.333 28.333)"
          fill="#151c39"
        />
        <rect
          id="Прямоугольник_9084"
          data-name="Прямоугольник 9084"
          width="4.167"
          height="1.666"
          transform="translate(18.333 31.667)"
          fill="#151c39"
        />
      </g>
    </svg>
  );
}

export default Ordering;
