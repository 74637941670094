export const TABLE_USERS_HEADER = [
    { label: "User ID", accessor: "user_id" },
    { label: "User", accessor: "user" },
    { label: "Date of Creation", accessor: "date_creation", sortable: true },
    { label: "On Wallet", accessor: "on_wallet" },
    { label: "Won", accessor: "won" },
    { label: "Redeemed", accessor: "redeemed" },
    { label: "Last Activity", accessor: "last_activity" },
    { label: "Amount Session", accessor: "amount_session" },
    { label: "Location", accessor: "location" },
    { label: "Interest", accessor: "interest" },
];

export const TABLE_USERS_PROFILE_HEADER = [
    { label: "User ID", accessor: "user_id" },
    { label: "Item Name", accessor: "item_name" },
    { label: "Type", accessor: "type" },
    { label: "Merchant", accessor: "merchant" },
    { label: "Exp. Days", accessor: "exp_days" },

];