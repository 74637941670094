import { TypeClasses } from "./types";

function Recovery({ classes }: TypeClasses) {
  return (
    <svg
      id="recovery"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="refresh_FILL0_wght400_GRAD0_opsz48"
        d="M17,26a8.676,8.676,0,0,1-6.37-2.63A8.676,8.676,0,0,1,8,17a8.676,8.676,0,0,1,2.63-6.37A8.676,8.676,0,0,1,17,8a8.7,8.7,0,0,1,4.191.97,9.924,9.924,0,0,1,3.122,2.658V8H26v7.144H18.856V13.456h4.725a8.615,8.615,0,0,0-2.728-2.728A7.1,7.1,0,0,0,17,9.688,7.251,7.251,0,0,0,9.688,17,7.251,7.251,0,0,0,17,24.313a7.377,7.377,0,0,0,4.275-1.336,6.948,6.948,0,0,0,2.7-3.53h1.744A8.618,8.618,0,0,1,22.484,24.2,8.952,8.952,0,0,1,17,26Z"
        transform="translate(-5 -5)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Recovery;
