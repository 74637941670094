import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";

// Assets
import {useNavigate} from "react-router-dom";

function AwaitRejectRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Modal fnClean={() => navigate("/")}>
      <div className="flex flex-col ">
        <div className="bg-red-300 w-full h-16 flex justify-left items-center px-4">
          <h1 className="text-red-600 text-2xl font-medium">Error</h1>
        </div>
        <div className="flex flex-col bg-white text-black w-96 py-4">
          <div className="px-5 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
            <p className="text-sm py-2">
              Please contact your Swiggle admin to approve your admin panel
              account
            </p>
          </div>
          <hr />
          <div className="mt-1 px-6 flex justify-end items-center">
            <Button
              variant="normal"
              onClick={() => {
                dispatch(setType(""));
                dispatch(setOpenModal(false));
                navigate("/");
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AwaitRejectRegister;
