// @ts-nocheck
import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'store/store'
import customFetchBase from './customFetchBase';


export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: customFetchBase,
  tagTypes: ['Categories', 'CategoriesID', 'CategoriesTree', 'ShopFilter'],
  endpoints: (builder) => ({
    getAllCategories: builder.query<any, any>({
      query: (params) => ({
        url: `/retailer-prize/admin/category/detail${!params ? '/' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["Categories"]
    }),
    getCategoryByID: builder.query<any, any>({
      query: ({ dma_code, category_id }) => ({
        url: `/retailer-prize/admin/location/${dma_code}/category/${category_id}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ["CategoriesID"]
    }),
    getCategories: builder.query<any, any>({
      query: ({ params }) => ({
        url: `/retailer-prize/admin/category${!params ? '' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    getCategoriesTree: builder.query<any, any>({
      query: () => ({
        url: `/dictionary/public/category`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    getAdminCategoriesTree: builder.query<any, any>({
      query: ({ dmaCode }: { dmaCode: string }) => ({
        url: `/dictionary/admin/category${!dmaCode ? '' : `?dmaCode=${dmaCode}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
    }),
    getCategoriesTreeCategories: builder.query<any, any>({
      query: (category) => ({
        url: `/dictionary/public/category/root?q=${category}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ['CategoriesTree']
    }),
    getCategoryPrizeList: builder.query<any, any>({
      query: ({ dma_code, params }) => ({
        url: `/retailer-prize/admin/location/${dma_code}/category/prize-list${!params ? '' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    getCategoryPrizeListFromCategory: builder.query<any, any>({
      query: ({ category_id, dma_code, params }) => ({
        url: `/retailer-prize/admin/location/${dma_code}/category/${category_id}/prize-list`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    createCategoryPrize: builder.mutation({
      query: ({ category_id, location_id, data }) => ({
        url: `/retailer-prize/admin/location/${location_id}/category/${category_id}/add-prize`,
        method: 'POST',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesFromLocationByID', 'CategoriesID']
    }),
    getCategoryLocations: builder.query<any, any>({
      query: ({ dma_code, params }) => ({
        url: `/retailer-prize/admin/category/location/${dma_code}${!params ? '/' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    getDataCards: builder.query<any, any>({
      query: ({ dma_code, params }) => ({
        url: `/retailer-prize/admin/location/${dma_code}/detail${!params ? '' : `${params}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    createSubCategoriesCategories: builder.mutation<any, any>({
      query: ({ data }) => ({
        url: `/dictionary/admin/subsub-category`,
        method: 'POST',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesTree', 'ShopFilter']
    }),
    updateSubCategoriesCategories: builder.mutation<any, any>({
      query: ({ data, subcategoryID }) => ({
        url: `/dictionary/admin/subsub-category/${subcategoryID}`,
        method: 'PUT',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesTree', 'ShopFilter']
    }),
    deleteSubCategoriesCategories: builder.mutation<any, any>({
      query: ({ subcategoryID }) => ({
        url: `/dictionary/admin/subsub-category/${subcategoryID}`,
        method: 'DELETE',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['CategoriesTree', 'ShopFilter']
    }),
    getShopFilters: builder.query<any, any>({
      query: ({ dmaCode }) => ({
        url: `/dictionary/admin/shop-filter${!dmaCode ? '' : `?dmaCode=${dmaCode}`}`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      providesTags: ['ShopFilter']
    }),
    createShopFilter: builder.mutation<any, any>({
      query: ({ data }) => ({
        url: `/dictionary/admin/shop-filter`,
        method: 'POST',
        body: data,
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['ShopFilter']
    }),
    deleteShopFilter: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/dictionary/admin/shop-filter/${id}`,
        method: 'DELETE',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      }),
      invalidatesTags: ['ShopFilter']
    }),

  }),
})

export const {
  useGetAllCategoriesQuery,
  useGetCategoryByIDQuery,
  useGetCategoryPrizeListFromCategoryQuery,
  useGetCategoriesQuery,
  useGetCategoriesTreeQuery,
  useGetAdminCategoriesTreeQuery,
  useLazyGetCategoriesTreeCategoriesQuery,
  useGetCategoryPrizeListQuery,
  useGetCategoryLocationsQuery,
  useLazyGetCategoryLocationsQuery,
  useCreateCategoryPrizeMutation,
  useGetDataCardsQuery,
  // SubSubCategories
  useCreateSubCategoriesCategoriesMutation,
  useUpdateSubCategoriesCategoriesMutation,
  useDeleteSubCategoriesCategoriesMutation,
  // ShopFilter
  useGetShopFiltersQuery,
  useCreateShopFilterMutation,
  useDeleteShopFilterMutation,
} = categoriesApi