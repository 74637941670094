import {useEffect, useState} from "react";
import debounce from "lodash.debounce";

// Components
import Searchbar from "components/Common/Searchbar";
import Subtitle from "components/Common/Subtitle";

// Redux
import {
  useCreateShopFilterMutation,
  useDeleteShopFilterMutation,
  useGetAdminCategoriesTreeQuery,
  useGetCategoriesTreeQuery,
  useGetShopFiltersQuery,
} from "store/api/categoriesApi";
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";
import {toast} from "react-toastify";
import Button from "components/Common/Button";
import {ArrowNext} from "assets/Arrows";
import {findCategoryById} from "utils/categoriesUtils";
import Spinner from "components/Common/Spinner";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/store";
import DeleteModal from "components/Modal/DeleteModal";
import {setOpenModal, setType} from "store/modal/modalSlice";
import DropdownCategory from "components/Common/DropdownCategory";
import Search from "assets/Search";

function ShopFilters({id}: {id: string}) {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);
  const [searchItems, setSearchItems] = useState("");
  const [selectedParent, setSelectedParent] = useState<any>([]);
  const [elementsClicked, setElementsClicked] = useState<any>([]);
  const [elementsNameClicked, setElementsNameClicked] = useState<any>([]);
  const [expanded, setExpanded] = useState<any>([]);
  const [currentId, setCurrentId] = useState("");

  const [dropdown, openDropdown] = useState(false);
  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);

  const [createShopFilterMutation] = useCreateShopFilterMutation();
  const [deleteShopFilterMutation] = useDeleteShopFilterMutation();

  const handleToggle = (categoryId: any, keep: boolean = false) => {
    if (keep) {
      setExpanded((prevExpanded: any) => [...prevExpanded, categoryId]);
    } else {
      setExpanded((prevExpanded: any) =>
        prevExpanded.includes(categoryId)
          ? prevExpanded.filter((id: any) => id !== categoryId)
          : [...prevExpanded, categoryId]
      );
    }
  };

  // Get the categories list dropdown
  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetAdminCategoriesTreeQuery({dmaCode: id});

  //  Get list favorites shop filters
  const {
    data: dataFavoriteShopFilters,
    isLoading: isLoadingFavoriteShopFilters,
    isError: isErrorFavoriteShopFilters,
    refetch: refetchShopFilter,
    // @ts-ignore
  } = useGetShopFiltersQuery({dmaCode: id});

  useEffect(() => {
    if (searchItems.length > 2) {
      const filteredData = getFilteredNames(dataCategories, searchItems, "");

      if (filteredData?.length > 0) {
        setSelectedParent(filteredData[0]);
      }
    }
  }, [searchItems]);

  useEffect(() => {
    if (
      dataFavoriteShopFilters &&
      dataCategories &&
      dataCategories.length > 0
    ) {
      const ids = dataFavoriteShopFilters.map((item: any) => item?.id);
      const elements = dataFavoriteShopFilters.map((item: any) => {
        return findCategoryById(dataCategories, item?.id?.toString());
      });

      setElementsClicked(ids);
      setElementsNameClicked(elements);
    }
  }, [dataFavoriteShopFilters, dataCategories]);

  useEffect(() => {
    if (!dataCategories) return;

    elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        setSelectedParent(currentElement);

        // console.log("first");

        currentElement.subItems?.forEach((item: any) => {
          if (item?.subItems?.length > 0) {
            handleToggle(item?.id);
          }
        });

        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement?.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        handleToggle(currentElement.parentId, true);

        setSelectedParent({
          id: rootParentElement?.id,
          name: rootParentElement.name,
        });

        // console.log("second");

        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement?.id,
          parent: parentElement.name,
          parentId: parentElement?.id,
          child: currentElement.name,
          childId: currentElement?.id,
        };
      } else {
        setSelectedParent({
          id: parentElement?.id,
          name: parentElement.name,
        });

        handleToggle(currentElement?.id, true);

        // console.log("third");

        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement?.id,
          child: currentElement.name,
          childId: currentElement?.id,
        };
      }
    });
  }, [elementsChecked, dataCategories]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    setElementsChecked([id]);
    setElementsName([name]);
    setSearchItems(name);

    if (!checked) {
      setElementsChecked(elementsChecked.filter((item: any) => item !== id));
      setElementsName(elementsName.filter((item: any) => item !== name));
      setSearchItems("");
    }
  };

  const rendererParentSide = () => {
    if (isLoadingCategories) {
      return (
        <div className="w-full flex justify-center items-center">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
        </div>
      );
    }

    return dataCategories?.map((item: any) => (
      <div
        key={item?.id}
        className={`flex justify-between p-4 border-b border-b-gray-200 cursor-pointer ${
          item.id === selectedParent?.id ? "bg-primary-purple text-white" : ""
        } `}
        onClick={() => setSelectedParent(item)}
      >
        <p className="w-[40%]">{item.name}</p>
        <p className="w-[20%] text-center">{item?.id}</p>
        <p className="w-[20%] text-center">{item.retailerCount}</p>
        <p className="w-[20%] text-center">{item.prizeCount}</p>
        <p className="font-medium text-2xl w-[5%]">
          <ArrowNext classes="h-5 w-5" />
        </p>
      </div>
    ));
  };

  const handleClickSubCategory = async (category: any) => {
    // Check if elementsClicked has more than 10 items, if more than 10 show a toast and return
    if (elementsClicked.length >= 10) {
      toast.info("Maximum 10 items can be selected");
      return;
    }

    if (category.prizeCount === 0)
      return toast.info(
        "Subcategory should have at least one item to be added to favorite filter"
      );

    const {id: idCategory, name} = category;

    const toastPromise = toast.promise(
      createShopFilterMutation({
        data: {id: idCategory, dmaCode: id},
      }),
      {
        pending: "Adding favorite filter",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while adding favorite filter</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("New favorite filter added");
        }
      })
      .catch((err) => {
        toast.error("Error while adding favorite filter");
      });
  };

  const rendererSubCategory = (category: any) => {
    const hasChildren = category.subItems && category.subItems.length > 0;
    // console.log(expanded, category);

    const isExpanded = expanded.includes(category?.id);

    return (
      <div
        className="flex flex-col justify-between p-4 border-b border-b-gray-200"
        key={category?.id || category?.code}
      >
        <div className="flex items-center justify-between w-full">
          <p className={`font-medium text-sm cursor-pointer w-[35%]`}>
            {category?.name || category?.description}
          </p>
          <p className="w-[15%] text-center">{category?.id}</p>
          <p className="w-[15%] text-center">{category.retailerCount}</p>
          <p className="w-[15%] text-center">{category.prizeCount}</p>
          <div className="flex w-[15%] gap-2">
            <div
              className="flex cursor-pointer justify-center items-center font-semibold border-2 rounded-full border-primary-purple text-primary-purple w-5 h-5"
              onClick={() => handleClickSubCategory(category)}
            >
              {" "}
              +{" "}
            </div>
            {hasChildren && (
              <div
                className={` flex justify-end`}
                onClick={() => handleToggle(category?.id)}
              >
                <div
                  className={`${
                    isExpanded ? "rotate-90" : "rotate-0"
                  } text-xs text-primary-purple cursor-pointer`}
                >
                  <ArrowNext classes="h-5 w-5" />
                </div>
              </div>
            )}
          </div>
        </div>

        {isExpanded && hasChildren && (
          <div className="pl-4 mt-2 border-l-2 border-solid border-gray-300">
            {category?.subItems?.map(rendererSubCategory)}
          </div>
        )}
      </div>
    );
  };

  const getSubCategories = (item: any) => {
    if (selectedParent?.id === item?.id) {
      if (searchItems.length > 2) {
        if (selectedParent?.subItems?.length) {
          return selectedParent?.subItems?.map((el: any) =>
            rendererSubCategory(el)
          );
        } else {
          const filteredData = getFilteredNames(
            item?.subItems,
            searchItems,
            ""
          );
          return filteredData?.map((el: any) => rendererSubCategory(el));
        }
      } else {
        return item?.subItems?.map((el: any) => rendererSubCategory(el));
      }
    }
  };

  const handleDeleteShopFilter = async () => {
    const toastPromise = toast.promise(
      deleteShopFilterMutation({
        id: currentId,
      }),
      {
        pending: "Deleting favorite filter",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting favorite filter</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Favorite filter deleted");
          dispatch(setOpenModal(false));
          dispatch(setType(""));
          setCurrentId("");
        }
      })
      .catch((err) => {
        toast.error("Error while deleting favorite filter");
      });
  };

  const handleShowShopFilters = () => {
    if (isLoadingFavoriteShopFilters)
      return (
        <div className="w-full flex justify-center items-center">
          <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
        </div>
      );

    if (isErrorFavoriteShopFilters)
      return (
        <div className="w-full flex flex-col justify-center items-center">
          <p>An error occurred trying to get merchants</p>
          <Button onClick={refetchShopFilter} classes="font-semibold">
            Try again
          </Button>
        </div>
      );

    return elementsNameClicked?.map((element: any) => {
      if (!element) return <></>;

      return (
        <div
          key={element?.id}
          id={element?.id}
          className="flex justify-between px-5 py-4 border-b border-b-gray-200"
        >
          <p className="font-semibold text-sm w-[35%]">{element.name}</p>
          <p className="font-semibold text-sm w-[10%]">{element?.id}</p>
          <p className="font-semibold text-sm w-[25%] text-center">
            {element.retailerCount}
          </p>
          <p className="font-semibold text-sm w-[25%] text-center">
            {element.prizeCount}
          </p>
          <div className="w-[5%]">
            <div
              id={element?.id}
              className="flex cursor-pointer justify-center items-center font-bold border-2 rounded-full border-red-500 text-red-500 w-5 h-5"
              onClick={(e: any) => {
                setCurrentId(e.target?.id);
                dispatch(setType("deleteFavoriteFilter"));
                dispatch(setOpenModal(true));
              }}
            >
              {" "}
              -{" "}
            </div>
          </div>
        </div>
      );
    });
  };

  const handleClearAll = () => {
    setElementsChecked([]);
    setElementsName([]);
    setSelectedParent({});
    setSearchItems("");
    setDowpdownSearchFilter("");
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="flex">
        <div className="w-full bg-white p-4 border-gray-300">
          <Subtitle classes="mb-4">Category Tree</Subtitle>
          <DropdownCategory
            hasLabel={true}
            label="Search by category, subcategory or subsubcategory"
            openDropdown={openDropdown}
            dropdown={dropdown}
            elementsChecked={elementsChecked}
            elementsName={elementsName}
            handleClickCheckbox={handleClickCheckbox}
            dataCategories={getFilteredNames(
              dataCategories,
              dropdownSearchFilter,
              ""
            )}
            isErrorCategories={isErrorCategories}
            isLoadingCategories={isLoadingCategories}
            classNameSelect="w-full"
            className="w-1/3"
            classAutoComplete="w-1/3"
            showNames={true}
            handleClickDropdown={() => handleClearAll()}
            expandItems={dropdownSearchFilter}
            hasCustomValue={false}
            hasSaveBtn={false}
            hasClearAll={true}
            hasSearchValue={{
              show: true,
              content: (
                <div className="flex mb-3 justify-between">
                  <div className="flex items-center w-full">
                    <label htmlFor="search">
                      <Search classes="h-6 w-6 mr-2" />
                    </label>
                    <input
                      id="search"
                      name="search"
                      type="text"
                      placeholder="Search category"
                      className="decoration-none outline-none w-full"
                      onChange={(e: any) => {
                        setDowpdownSearchFilter(e.target.value);
                      }}
                      value={dropdownSearchFilter}
                    />
                  </div>
                </div>
              ),
            }}
          />
          {/* <div
              className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                elementsChecked.length > 0
                  ? "border border-gray-400"
                  : "border-none"
              } rounded-md`}
            >
              {elementsParentName.map((element, index) => (
                <div
                  className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                  key={index}
                >
                  <Tooltip id="my-tooltip"></Tooltip>
                  <div className="flex flex-wrap">
                    {element?.root && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.rootId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.root} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.parentId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.parentId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.parent} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.childId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.childId}`}
                        className="font-medium text-sm"
                      >
                        {element?.child}
                      </div>
                    )}
                  </div>
                  <Button
                    type="button"
                    onClick={() => {
                      setElementsChecked((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.id
                        )
                      );
                      setElementsName((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.child
                        )
                      );
                    }}
                    classes="ml-6 text-blue-400 text-sm"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div> */}
          <div className="grid grid-cols-2 mt-12 h-[700px]">
            <div className="border-r-2 border-r-gray-200">
              <div className="flex justify-between px-5 py-3 border-b border-b-gray-200 bg-gray-100">
                <p className="font-semibold text-sm w-[40%]">Category</p>
                <p className="font-semibold text-sm w-[20%] text-center">ID</p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Merchants
                </p>
                <p className="font-semibold text-sm w-[20%] text-center">
                  Items
                </p>
                <p className="font-semibold text-sm w-[5%] text-center"></p>
              </div>
              {rendererParentSide()}
            </div>
            <div className="overflow-y-auto">
              <div className="flex  px-5 py-3 border-b border-b-gray-200 bg-gray-100 sticky top-0">
                <p className="font-semibold text-sm w-[35%]">Subcategory</p>
                <p className="font-semibold text-sm w-[15%] text-center">ID</p>
                <p className="font-semibold text-sm w-[15%] text-center">
                  Merchants
                </p>
                <p className="font-semibold text-sm w-[25%] text-center">
                  Items
                </p>
              </div>
              {dataCategories?.map((item: any) => getSubCategories(item))}
            </div>
          </div>
        </div>
        <div className="w-1 mx-5 bg-gray-200"></div>
        <div className="w-5/12 bg-white rounded border border-gray-300">
          <div className="p-4">
            <Subtitle>Favorite filters</Subtitle>
            <p className="text-xs text-gray-400">
              Are shown in the application on the store page
            </p>
          </div>
          <div>
            <div className="flex justify-between px-5 py-3 border-b border-b-gray-200 bg-gray-100">
              <p className="font-semibold text-sm w-[35%]">Filters</p>
              <p className="font-semibold text-sm w-[10%]">ID</p>
              <p className="font-semibold text-sm w-[25%]">Merchants</p>
              <p className="font-semibold text-sm w-[25%] ">Items</p>
            </div>
            {handleShowShopFilters()}
          </div>
        </div>
      </div>
      {modal.type === "deleteFavoriteFilter" && (
        <DeleteModal
          elementsChecked={[parseInt(currentId)]}
          setElementsChecked={() => {}}
          dataSource={elementsNameClicked}
          matchWidhId="id"
          pendingMsg="Deleting favorite filter"
          returnValue="name"
          toastMessageId="name"
          type=""
          typeOfDelete="subsubcategory"
          deleteCallback={handleDeleteShopFilter}
          cleanFn={() => setCurrentId("")}
        />
      )}
    </div>
  );
}

export default ShopFilters;
