import {useCallback, useEffect, useState} from "react";
import debounce from "lodash.debounce";

// Components
import Filter from "assets/Filter";
import Button from "components/Common/Button";
import MultiRange from "components/Common/MultiRange";
import DatePicker from "components/Common/Input/DatePicker";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {setStatus, setURLFilters} from "store/filters/filtersSlice";
import Select from "components/Common/Input/Select";
import {setLastVisitedPage} from "store/tabs/tabsSlice";

//Utils
import {addDays, substractDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import {RootState} from "store/store";

interface FilterFormItemsTypes {
  handleShowFilter: Function;
}

const filterDefault = {
  type: "",
  startQuantity: {min: 0, max: 1000},
  create_rage: {from: "", to: ""},
  edit_rage: {from: "", to: ""},
  exp_rage: {from: "", to: ""},
};

function FilterFormNeedApproveItems({handleShowFilter}: FilterFormItemsTypes) {
  const dispatch = useDispatch();
  const tabs = useSelector((state: RootState) => state.tabs);
  const filtersRedux = useSelector((state: RootState) => state.filters.filters);

  const [resetDefaultValues, setResetDefaultValues] = useState(false);
  const [filters, setFilters] = useState<typeof filterDefault>({
    type: "",
    startQuantity: {min: 0, max: 1000},
    create_rage: {from: "", to: ""},
    edit_rage: {from: "", to: ""},
    exp_rage: {from: "", to: ""},
  });

  useEffect(() => {
    const currentURLFilters = filtersRedux.urlFilters;

    if (currentURLFilters) {
      const splitURL = currentURLFilters.split("&");

      const typeURL = splitURL.find((el: any) => el.includes("type"));
      const startQuantityFromURL = splitURL.find((el: any) =>
        el.includes("quantity_from")
      );
      const startQuantityToURL = splitURL.find((el: any) =>
        el.includes("quantity_to")
      );
      const createFromURL = splitURL.find((el: any) =>
        el.includes("date_from")
      );
      const createToURL = splitURL.find((el: any) => el.includes("date_to"));
      const editFromURL = splitURL.find((el: any) =>
        el.includes("edited_from")
      );
      const editToURL = splitURL.find((el: any) => el.includes("edited_to"));
      const expFromURL = splitURL.find((el: any) =>
        el.includes("expired_from")
      );
      const expToURL = splitURL.find((el: any) => el.includes("expired_to"));

      const newFilters = filterDefault;

      if (typeURL) {
        const typeValue = typeURL.split("=")[1];
        newFilters["type"] = typeValue;
      }
      if (startQuantityFromURL) {
        const startQuantityFromValue = startQuantityFromURL.split("=")[1];
        newFilters["startQuantity"]["min"] = Number(startQuantityFromValue);
      }
      if (startQuantityToURL) {
        const startQuantityToValue = startQuantityToURL.split("=")[1];
        newFilters["startQuantity"]["max"] = Number(startQuantityToValue);
      }
      if (createFromURL) {
        const createFromValue = createFromURL.split("=")[1];
        newFilters["create_rage"]["from"] = createFromValue;
      }
      if (createToURL) {
        const createToValue = createToURL.split("=")[1];
        newFilters["create_rage"]["to"] = createToValue;
      }
      if (editFromURL) {
        const editFromValue = editFromURL.split("=")[1];
        newFilters["edit_rage"]["from"] = editFromValue;
      }
      if (editToURL) {
        const editToValue = editToURL.split("=")[1];
        newFilters["edit_rage"]["to"] = editToValue;
      }
      if (expFromURL) {
        const expFromValue = expFromURL.split("=")[1];
        newFilters["exp_rage"]["from"] = expFromValue;
      }
      if (expToURL) {
        const expToValue = expToURL.split("=")[1];
        newFilters["exp_rage"]["to"] = expToValue;
      }

      setFilters(newFilters);
    }
  }, []);

  const handleResetFilters = (e: any) => {
    e.preventDefault();

    const datePikers = document.querySelectorAll(
      "form [id='datepiker_filter']"
    );

    datePikers.forEach((el: any) => {
      el.value = "";
    });

    const selectType = document.getElementById("type") as HTMLSelectElement;

    if (selectType) {
      selectType.selectedIndex = 0;
    }

    setResetDefaultValues(true);

    setFilters(filterDefault);
    dispatch(setStatus([]));
    dispatch(setURLFilters(""));
  };

  const handleCancelFilter = () => {
    dispatch(setURLFilters(""));
    dispatch(setStatus([]));
    handleShowFilter(false);
  };

  const handleFindByFilter = (e: any) => {
    e.preventDefault();

    dispatch(setLastVisitedPage(""));

    let params = "";

    if (filters.create_rage.from !== "" || filters.create_rage.to !== "") {
      params = params.concat(
        "&",
        `date_from=${new Date(filters.create_rage.from)?.toISOString()}`
      );
      params = params.concat(
        "&",
        `date_to=${new Date(filters.create_rage.to)?.toISOString()}`
      );
    }

    if (filters.edit_rage.from !== "" || filters.edit_rage.to !== "") {
      params = params.concat(
        "&",
        `edited_from=${new Date(filters.edit_rage.from)?.toISOString()}`
      );
      params = params.concat(
        "&",
        `edited_to=${new Date(filters.edit_rage.to)?.toISOString()}`
      );
    }

    if (filters.exp_rage.from !== "" || filters.exp_rage.to !== "") {
      params = params.concat(
        "&",
        `expired_from=${new Date(filters.exp_rage.from)?.toISOString()}`
      );
      params = params.concat(
        "&",
        `expired_to=${new Date(filters.exp_rage.to)?.toISOString()}`
      );
    }

    if (filters.type !== "") {
      params = params.concat("&", `type=${filters.type}`);
    }

    params = params.concat("&", `quantity_from=${filters.startQuantity.min}`);
    params = params.concat("&", `quantity_to=${filters.startQuantity.max}`);

    dispatch(setURLFilters(params));
  };

  return (
    <form action="" className="border border-indigo-200 rounded">
      <div className="flex p-4 bg-indigo-200">
        <span>
          <Filter />
        </span>
        <span className="ml-2">Filter</span>
      </div>
      <div className="border border-b-indigo-200 flex items-start justify-around flex-wrap p-4 pb-8">
        {tabs?.tabs?.itemsType !== "Grand Prize" && (
          <div className="xl:basis-1/12 lg:basis-7/12 basis-full ">
            <Select
              id="type"
              label="Type"
              classes="mt-4"
              classesSelect="border border-gray-400 p-2.5 pl-4"
              onChange={(e: any) => {
                setFilters({...filters, type: e.target.value});
              }}
              value={filters.type}
            >
              <>
                <option selected={true} value="" disabled>
                  Type
                </option>
                <option value="regular">Prize</option>
                <option value="offer">Offer</option>
                <option value="reward">Reward</option>
              </>
            </Select>
          </div>
        )}
        <div className="xl:basis-3/12 lg:basis-7/12 basis-full ">
          {/* <Range /> */}
          <MultiRange
            label={"Start quantity"}
            min={0}
            max={tabs?.tabs?.itemsType === "Grand Prize" ? 900 : 1000}
            valueMin={filters.startQuantity.min}
            valueMax={filters.startQuantity.max}
            onChange={({min, max}: any) => {
              setFilters({...filters, startQuantity: {min, max}});
            }}
            resetDefaultValues={resetDefaultValues}
            setResetDefaultValues={setResetDefaultValues}
            classes="mt-4"
          />
        </div>
        <div className="xl:basis-3/12 lg:basis-7/12 basis-full ">
          <div className="flex mt-4 items-end">
            <DatePicker
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  edit_rage: {...filters.edit_rage, from: e.target.value},
                })
              }
              classes="w-3/6 mr-2"
              label="Edited Date Range"
              max={substractDays(getTodayDate(), 1)}
              value={
                filters.edit_rage.from.includes("Z")
                  ? filters.edit_rage.from.substring(0, 10)
                  : filters.edit_rage.from
              }
            />
            <p className="text-xl flex text-gray-500 pb-2"> {">"} </p>
            <DatePicker
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  edit_rage: {...filters.edit_rage, to: e.target.value},
                })
              }
              // label=""
              classes="w-3/6 ml-2"
              min={
                filters.edit_rage.from
                  ? addDays(filters.edit_rage.from, 1)
                  : addDays(getTodayDate(), 1)
              }
              max={"today"}
              value={
                filters.edit_rage.to.includes("Z")
                  ? filters.edit_rage.to.substring(0, 10)
                  : filters.edit_rage.to
              }
            />
          </div>
          <div className="flex mt-6 items-end">
            <DatePicker
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  create_rage: {...filters.create_rage, from: e.target.value},
                })
              }
              classes="w-3/6 mr-2"
              label="Create Date Range"
              max={substractDays(getTodayDate(), 1)}
              value={
                filters.create_rage.from.includes("Z")
                  ? filters.create_rage.from.substring(0, 10)
                  : filters.create_rage.from
              }
            />
            <p className="text-xl flex text-gray-500 pb-2"> {">"} </p>
            <DatePicker
              classes="w-3/6 ml-2"
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  create_rage: {...filters.create_rage, to: e.target.value},
                })
              }
              min={
                filters.create_rage.from
                  ? addDays(filters.create_rage.from, 1)
                  : addDays(getTodayDate(), 1)
              }
              max={"today"}
              value={
                filters.create_rage.to.includes("Z")
                  ? filters.create_rage.to.substring(0, 10)
                  : filters.create_rage.to
              }
            />
          </div>
        </div>
        <div className="xl:basis-3/12 lg:basis-7/12 basis-full ">
          <div className="flex mt-4 items-end">
            <DatePicker
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  exp_rage: {...filters.exp_rage, from: e.target.value},
                })
              }
              classes="w-3/6 mr-2"
              label="Expiration Date Range"
              value={
                filters.exp_rage.from.includes("Z")
                  ? filters.exp_rage.from.substring(0, 10)
                  : filters.exp_rage.from
              }
              // classes="mt-4"
            />
            <p className="text-xl flex text-gray-500 pb-2"> {">"} </p>
            <DatePicker
              onChange={(e: any) =>
                setFilters({
                  ...filters,
                  exp_rage: {...filters.exp_rage, to: e.target.value},
                })
              }
              min={
                filters.exp_rage.from
                  ? addDays(filters.exp_rage.from, 1)
                  : addDays(getTodayDate(), 1)
              }
              classes="w-3/6 ml-2"
              value={
                filters.exp_rage.to.includes("Z")
                  ? filters.exp_rage.to.substring(0, 10)
                  : filters.exp_rage.to
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center p-4">
        <div>
          <Button
            type="button"
            onClick={(e: any) => handleResetFilters(e)}
            classes="bg-white border border-gray-400 text-gray-400 text-sm block p-2.5 pl-4 font-base hover:bg-gray-200 hover:border-transparent"
          >
            Clear All
          </Button>
        </div>
        <div className="flex">
          <Button
            type="button"
            onClick={() => handleCancelFilter()}
            classes="bg-white border border-gray-400 text-gray-400 text-sm block p-2.5 pl-4 font-base hover:bg-gray-200 hover:border-transparent"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleFindByFilter(e)}
            classes="bg-primary-purple text-white text-sm block p-2.5 pl-4 font-base hover:bg-secondary-purple ml-4"
          >
            Select
          </Button>
        </div>
      </div>
    </form>
  );
}

export default FilterFormNeedApproveItems;
