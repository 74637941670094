import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

// Assets
import Pause from "assets/Pause";
import Play from "assets/Play";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {updateStatus} from "store/filters/filtersSlice";
import {
  useChangeStatusGrandPrizeMutation,
  useChangeStatusItemMutation,
  useChangeStatusLoyaltyProgramMutation,
} from "store/api/itemApi";
import {
  merchantsApi,
  useUpdateEmployeeListMutation,
} from "store/api/merchantsApi";
import routes from "utils/routesByRole";
import {RootState} from "store/store";
import {useChangeAdminStatusMutation} from "store/api/adminApi";

interface BadgeType {
  title: string | undefined;
  origin?: string;
  element?: any;
  showIcon?: boolean;
  onClick?: () => void;
}

function Badge({title, origin, element, showIcon = true, onClick}: BadgeType) {
  const dispatch = useDispatch();
  const {id} = useParams();
  const user = useSelector((state: RootState) => state.user.user.user);
  const [changeStatusMutation] = useChangeStatusItemMutation();
  const [changeStatusAdminMutation] = useChangeAdminStatusMutation();
  const [changeStatusGrandPrizeMutation] = useChangeStatusGrandPrizeMutation();
  const [changeDataEmployeeMutation] = useUpdateEmployeeListMutation();
  const [changeStatusLoyaltyMutation] = useChangeStatusLoyaltyProgramMutation();

  const changeStatusItem = async ({
    idItem,
    idMerchant,
    status,
  }: {
    idItem: number | string;
    idMerchant: number | string;
    status: string;
  }) => {
    // @ts-ignore
    const isRoleAllowed = routes[user.data.role].urlAllowed?.merchant?.includes(
      "edit"
    )
      ? true
      : false;

    if (!isRoleAllowed) return;

    let toastPromise = new Promise((res, rej) => {});

    if (origin === "merchant_grand_prize" || origin === "items_grand_prize") {
      toastPromise = toast.promise(
        changeStatusGrandPrizeMutation({
          idItem: idItem?.toString(),
          idMerchant: idMerchant?.toString(),
          status: status.toUpperCase(),
        }),
        {
          pending: "Changing status item",
        }
      );
    } else {
      toastPromise = toast.promise(
        changeStatusMutation({
          idItem: idItem?.toString(),
          idMerchant: idMerchant?.toString(),
          status: status.toUpperCase(),
        }),
        {
          pending: "Changing status item",
        }
      );
    }

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing status item</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Item status changed");
          // Re fetch items to update UI
          dispatch(merchantsApi.util.invalidateTags(["MerchantItems"]));
          dispatch(merchantsApi.util.invalidateTags(["MerchantGrandPrize"]));
        }
      })
      .catch((err) => {
        toast.error("Error while changing status item");
      });
  };

  const changeStatusAdmin = async (status: string) => {
    // @ts-ignore
    const isRoleAllowed = routes[user.data.role].urlAllowed?.user?.includes(
      "edit"
    )
      ? true
      : false;

    if (!isRoleAllowed) return;

    const toastPromise = toast.promise(
      changeStatusAdminMutation({
        id: element.id?.toString(),
        data: {
          status: status.toUpperCase(),
        },
      }),
      {
        pending: "Changing status admin",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing status admin</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Admin Status Changed");
        }
      })
      .catch((err) => {
        toast.error("Error while changing admin status");
      });
  };

  const changeStatusEmployee = async ({
    idEmployee,
    idMerchant,
    action,
    data,
  }: {
    idEmployee: number | string;
    idMerchant: number | string;
    action: string;
    data: any;
  }) => {
    // @ts-ignore
    const isRoleAllowed = routes[user.data.role].urlAllowed?.employee?.includes(
      "edit"
    )
      ? true
      : false;

    if (!isRoleAllowed) return;

    const toastPromise = toast.promise(
      changeDataEmployeeMutation({
        idUser: idEmployee?.toString(),
        idRetailer: idMerchant?.toString(),
        data: {
          status: action.toUpperCase(),
          role: data.role,
        },
      }),
      {
        pending: "Changing status employee",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing status employee</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Employee Status Changed");
        }
      })
      .catch((err) => {
        toast.error("Error while changing employee status");
      });
  };

  // Generic function to change status from active to pause
  const handleCheckRequest = async (action: string) => {
    // @ts-ignore
    const isRoleAllowed = routes[user.data.role].urlAllowed?.loyalty?.includes(
      "edit"
    )
      ? true
      : false;

    if (!isRoleAllowed) return;

    let idItem = "";

    if (onClick) return;

    if (origin === "loyalty_program") {
      const toastPromise = toast.promise(
        changeStatusLoyaltyMutation({
          data: {newState: action.toUpperCase()},
          idLoyalty: element?.loyaltyId,
        }),
        {
          pending: "Changing status loyalty program",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while changing loyalty status</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success("Loyalty Status Changed");
          }
        })
        .catch((err) => {
          toast.error("Error while changing loyalty status");
        });
    }

    // console.log(origin);
    if (
      origin === "items" ||
      origin === "merchant_items" ||
      origin === "merchant_grand_prize" ||
      origin === "items_grand_prize"
    ) {
      // @ts-ignore
      const isRoleAllowed = routes[
        user.data.role
      ].urlAllowed?.merchant?.includes("edit")
        ? true
        : false;

      if (!isRoleAllowed) return;

      idItem = element.prizeId;

      changeStatusItem({
        idItem: idItem,
        idMerchant: id || element.merchantId,
        status: action,
      });
    } else if (origin === "merchant_employee_list") {
      // @ts-ignore
      if (routes[user.data.role].urlAllowed.employee?.includes("publish")) {
        changeStatusEmployee({
          idEmployee: element.id,
          idMerchant: element.retailerID,
          action,
          data: element,
        });
      }
    } else {
      idItem = element.id || element.prizeId;
    }

    dispatch(
      updateStatus({
        name: origin,
        data: element,
        action,
      })
    );
  };

  const checkButtonsToRender = (title: any, origin: any) => {
    if (origin === "admins") {
      if (title === "active") {
        return (
          <span
            onClick={
              // @ts-ignore
              routes[user.data.role].urlAllowed.admin?.includes("publish")
                ? () => changeStatusAdmin("inactive")
                : () => {}
            }
            className="flex items-center capitalize"
          >
            {showIcon && <Play classes="mr-1" />}
            {title}
          </span>
        );
      } else if (title === "inactive") {
        return (
          <span
            onClick={
              // @ts-ignore
              routes[user.data.role].urlAllowed.admin?.includes("publish")
                ? () => changeStatusAdmin("active")
                : () => {}
            }
            className="flex items-center capitalize"
          >
            {showIcon && <Pause classes="mr-1" />}
            {title}
          </span>
        );
      }
    } else if (origin !== "admins") {
      if (title === "active") {
        return (
          <span
            onClick={
              // @ts-ignore
              routes[user.data.role].urlAllowed.item?.includes("publish") ||
              // @ts-ignore
              routes[user.data.role].urlAllowed.gp?.includes("publish") ||
              // @ts-ignore
              routes[user.data.role].urlAllowed.employee?.includes("publish")
                ? () => handleCheckRequest("paused")
                : () => {}
            }
            className={`flex items-center capitalize ${
              !showIcon ? "px-2 py-0.5 pr-0.5" : ""
            }`}
          >
            {showIcon && <Play classes="mr-1" />}
            {title}
          </span>
        );
      } else {
        return (
          <span
            onClick={
              // @ts-ignore
              routes[user.data.role].urlAllowed.item?.includes("publish") ||
              // @ts-ignore
              routes[user.data.role].urlAllowed.gp?.includes("publish") ||
              // @ts-ignore
              routes[user.data.role].urlAllowed.employee?.includes("publish")
                ? () => handleCheckRequest("active")
                : () => {}
            }
            className={`flex items-center capitalize ${
              !showIcon ? "px-2 py-0.5 pr-0.5" : ""
            }`}
          >
            {showIcon && <Pause classes="mr-1" />}
            {title}
          </span>
        );
      }
    }
  };

  const getTitle = (title: string) => {
    const titleLower = title?.toLowerCase();
    if (titleLower === "superadmin") {
      return "super admin";
    } else if (titleLower === "salesadmin") {
      return "admin";
    }

    return title?.toLowerCase();
  };

  if (title === undefined) return <></>;

  const badgeStyle = (title: string) => {
    let classBadge =
      "text-xs font-semibold mr-2 px-2.5 py-1.5 rounded whitespace-nowrap";

    if (title === "published" || title === "active" || title === "admin") {
      classBadge =
        classBadge +
        " bg-green-100 text-green-600 dark:bg-green-200 dark:text-green-900 whitespace-nowrap";
    }
    if (title === "unverified" || title === "paused" || title === "inactive") {
      classBadge = classBadge + " bg-gray-200 text-gray-500  whitespace-nowrap";
    }
    if (
      title === "new" ||
      title?.includes("days") ||
      title === "pending_review" ||
      title === "moderator"
    ) {
      classBadge =
        classBadge +
        " bg-yellow-100 text-yellow-600 dark:bg-yellow-200 dark:text-yellow-900 whitespace-nowrap";
    }
    if (title === "archived" || title === "declined" || title === "canceled") {
      classBadge =
        classBadge +
        " bg-red-100 text-red-600 dark:bg-red-200 dark:text-red-900 whitespace-nowrap";
    }
    if (
      title === "completed" ||
      title === "invited" ||
      title === "super admin" ||
      title === "created"
    ) {
      classBadge =
        classBadge +
        " bg-purple-100 text-purple-600 dark:bg-purple-200 dark:text-purple-900 whitespace-nowrap";
    }

    if (title !== "active" && title !== "paused" && title !== "inactive") {
      // Validation to check if the title has underscore
      if (title?.includes("_")) {
        const newTitle = title.split("_");
        return (
          <span
            className={`${classBadge} capitalize`}
            onClick={onClick ? onClick : () => {}}
          >{`${newTitle[0]} ${newTitle[1]}`}</span>
        );
      }
      return (
        <span
          className={`${classBadge} capitalize`}
          onClick={onClick ? onClick : () => {}}
        >
          {title}
        </span>
      );
    } else {
      return (
        <div
          className={`${classBadge} !pl-1.5 !pr-3 !py-1 cursor-pointer w-min h-min `}
          onClick={onClick ? onClick : () => {}}
        >
          {checkButtonsToRender(title, origin)}
          {/* {origin !== "admins" &&} */}
        </div>
      );
    }
  };

  return badgeStyle(getTitle(title));
}

export default Badge;
