
export const TABLE_CONFIGURATION_PUBLISHED = [
    { label: "Category Name", accessor: "name", sortable: true },
    { label: "Category Type", accessor: "type", sortable: true },
    { label: "Position", accessor: "position", sortable: true },
    { label: "Created", accessor: "created_at", sortable: true },
    { label: "Last Edit", accessor: "updated_at", sortable: true },
    { label: "Status", accessor: "status", sortable: true },
    { label: "Publish", accessor: "is_published", sortable: true },
];


export const TABLE_CONFIGURATION_ARCHIVED = [
    { label: "Category Name", accessor: "name", sortable: true },
    { label: "Created", accessor: "created", sortable: true },
    { label: "Archived", accessor: "archived", sortable: true },
];

export const TABLE_AREAS = [
    { label: "Zip Code", accessor: "zip_code", sortable: true },
    { label: "City Name", accessor: "city_name", sortable: true },
    { label: "Merchant Count", accessor: "merchant_count", sortable: true },
    { label: "Offer Count", accessor: "offer_count", sortable: true },
    { label: "Prize Count", accessor: "prize_count", sortable: true },
    { label: "Redemption Count", accessor: "redemption_count", sortable: true },
];

export const TABLE_TRANDING_PLACES = [
    { label: "Position", accessor: "placement", sortable: true },
    { label: "Merchant name", accessor: "name" },
    { label: "Categories", accessor: "categories", sortable: true },
    { label: "Items", accessor: "itemCount", sortable: true },
    { label: "Prize Count", accessor: "prizeCount", sortable: true },
    { label: "Redemption Count", accessor: "redeemedCount", sortable: true },
]