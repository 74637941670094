import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {Link, useLocation, useParams, useNavigate} from "react-router-dom";
import {Dialog} from "@headlessui/react";

// Components
import Stats from "./Tabs/Stats";
import Details from "./Tabs/Details";
import Merchant from "./Tabs/Merchant";
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import Select from "components/Common/Input/Select";
import Textarea from "components/Common/Textarea";
import Label from "components/Common/Label";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {merchantsApi} from "store/api/merchantsApi";
import {RootState} from "store/store";
import {
  useApproveChangesMutation,
  useChangeStatusItemMutation,
  useCreateDeclineReasonsMutation,
  useDeleteItemMutation,
  useGetDeclineReasonsQuery,
  useGetIndividualItemByMerchantIDQuery,
  useUpdateImageItemMutation,
  useUpdateItemMutation,
} from "store/api/itemApi";

// Icons
import {ArrowBack} from "assets/Arrows";
import Archive from "assets/Archive";
import Publish from "assets/Publish";
import Alert from "assets/Alert";
import Recovery from "assets/Recovery";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import routes from "utils/routesByRole";
import Save from "assets/Save";
import Delete from "assets/Delete";
import DeleteModal from "components/Modal/DeleteModal";

function ItemsDetails() {
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal: any = useSelector((state: RootState) => state.modal);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [itemData, setItemData] = useState<any>({
    prizeId: "",
    name: "",
    description: "",
    price: "",
    offerDiscount: "",
    prizeCount: "",
    wheel: "",
    percentShop: "",
    percentWheel: "",
    expiredAt: "",
    categories: [],
    promotionType: "",
    perCustomerLimit: "",
    ageLimit: "",
    requiredStamps: "",
    radius: "",
    terms: "",
    state: "",
    itemsWheel: "",
    itemsShop: "",
  });

  const [firstImage, setFirstImg] = useState();
  const [secondImage, setSecondImg] = useState();
  const [objectImages, setObjectImages] = useState<{
    firstImage: any;
    secondImage: any;
  }>({
    firstImage: "",
    secondImage: "",
  });

  const [selectedTab, setSelectedTab] = useState("");
  const [inputError, setInputError] = useState(false);
  const [errorInItemsCount, setErrorInItemsCount] = useState(false);

  const [reason, setReason] = useState();
  const [declinedItems, setDeclinedItems] = useState<string[]>([]);
  const [approvedItems, setApprovedItems] = useState<string[]>([]);
  const [btnApprove, setBtnApprove] = useState<boolean>(true);
  const [reasonDescription, setReasonDescription] = useState("");
  const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [newDataCheckbox, setNewDataCheckbox] = useState<string[]>([]);

  const [isDirty, setIsDirty] = useState(false);

  const [canChangeRewardText, setCanChangeRewardText] = useState("");
  const [canSave, setCanSave] = useState(false);

  // Mutations
  const [updateStatusMutation] = useChangeStatusItemMutation();
  const [updateItem] = useUpdateItemMutation();
  const [updateImageItem] = useUpdateImageItemMutation();
  const [setDeleteItem] = useDeleteItemMutation();

  const {
    data: dataItem,
    isLoading: isLoadingItem,
    isError: isErrorItem,
    refetch,
  } = useGetIndividualItemByMerchantIDQuery({
    idMerchant: location?.state?.idMerchant,
    idItem: location?.state?.idItem,
  });

  const {
    data: declineReasons,
    isLoading: isLoadingDeclineReasons,
    isError: isErrorDeclineReasons,
    // @ts-ignore
  } = useGetDeclineReasonsQuery();

  const [applyChanges] = useApproveChangesMutation();

  useEffect(() => {
    if (isLoadingItem || isErrorItem) dispatch(setOpenModal(true));
    else {
      dispatch(setOpenModal(false));
    }
  }, [isLoadingItem, isErrorItem]);

  useEffect(() => {
    const arrFormData = [
      {data: objectImages.firstImage},
      {data: objectImages.secondImage},
    ];

    setArrayImageToUpload(arrFormData);
  }, [objectImages]);

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCloseModal = () => {
    dispatch(setType(""));
    dispatch(setOpenModal(false));
    setReasonDescription("");
  };

  const updateItemImages = async (imageToUpload: any, key: number) => {
    const toastPromiseImage = toast.promise(
      updateImageItem({
        ...imageToUpload,
        idMerchant: dataItem?.retailerId?.toString(),
        idItem: id?.toString(),
      }),
      {
        pending: "Upload Image",
      }
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key}</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          const messages = ["Item Photo Big", "Item Photo Small"];
          toast.success(`${messages[key]} uploaded`);
          setTimeout(() => {
            refetch();
          }, 1000);
        }
      })
      .catch((err: any) => {
        toast.error(`Error while uplading image ${key}`);
      });
  };

  const formatCategory = (arrayCategories: any) => {
    const newCat = arrayCategories.map((cat: any) => cat.id);
    const removeDup = new Set(newCat);
    const newArrCat = Array.from(removeDup);
    const resObj = newArrCat.map((catID: any) => ({id: catID}));

    return resObj;
  };

  const handlePublishItem = async () => {
    if (dataItem?.state === "ARCHIVED") {
      changeStatusItem({
        idItem: id,
        idMerchant: dataItem?.retailerId,
        status: "PENDING_REVIEW",
      });
    } else if (
      dataItem?.state === "PENDING_REVIEW" ||
      dataItem?.state === "PAUSED"
    ) {
      changeStatusItem({
        idItem: id,
        idMerchant: dataItem?.retailerId,
        status: "ACTIVE",
      });
    }
  };

  const handleCheckIfCanChangeSave = () => {
    if (dataItem.prizeType === "REWARD") {
      const previousFirstReward = location?.state?.loyalityData?.rewards[0];
      const previousSecondReward = location?.state?.loyalityData?.rewards[1];
      const currentRewardStampNeeded = itemData.requiredStamps;

      if (!itemData.name || !itemData.requiredStamps) {
        setInputError(true);
        toast.error("You must fill all the required fields");
        return;
      }

      if (location?.state?.currentRewardPosition === 0) {
        handleSaveItem();
        return;
      }

      if (location?.state?.currentRewardPosition === 1) {
        const previousRewardStampNeeded = previousFirstReward?.requiredStamps;
        const nextRewardStampNeeded = location?.state?.loyalityData?.rewards[2];

        if (
          parseInt(previousRewardStampNeeded) >=
            parseInt(currentRewardStampNeeded) ||
          (nextRewardStampNeeded
            ? parseInt(currentRewardStampNeeded) >=
              parseInt(nextRewardStampNeeded?.requiredStamps)
            : false)
        ) {
          setCanChangeRewardText(
            `You can reduce or increase the number of stamps, but it must be ${
              nextRewardStampNeeded
                ? `lower than ${nextRewardStampNeeded?.requiredStamps} stamps (Reward 3) and`
                : ""
            } greater than ${previousRewardStampNeeded} stamps (Reward 1)`
          );
          dispatch(setType("changeStamps"));
          dispatch(setOpenModal(true));
          setCanSave(false);
          return;
        }
        setCanSave(true);
      }

      if (location?.state?.currentRewardPosition === 2) {
        const previousRewardStampNeeded = previousSecondReward?.requiredStamps;

        if (
          parseInt(previousRewardStampNeeded) >=
          parseInt(currentRewardStampNeeded)
        ) {
          setCanChangeRewardText(
            `You can reduce the number of stamps, but it must be greater than ${previousRewardStampNeeded} stamps for Reward 2`
          );
          dispatch(setType("changeStamps"));
          dispatch(setOpenModal(true));
          setCanSave(false);
          return;
        }

        setCanSave(true);
      }

      handleSaveItem();
    } else {
      if (
        !itemData.name ||
        !itemData.price ||
        !itemData.prizeCount ||
        !itemData.radius ||
        !itemData.terms ||
        itemData.itemsWheel === "" ||
        itemData.itemsShop === "" ||
        !itemData.expiredAt
      ) {
        setInputError(true);
        toast.error("You must fill all the required fields");
        return;
      }

      if (
        // @ts-ignore
        parseInt(itemData.itemsWheel) + parseInt(itemData.itemsShop) !==
        100
      ) {
        toast.error(
          "Percent of items in wheel and items in shop must equal 100"
        );
        setErrorInItemsCount(true);
        return;
      }

      handleSaveItem();
    }
  };

  const handleSaveItem = async () => {
    setErrorInItemsCount(false);
    setImageCropModalType("default");

    // If accept the changes the first value will be send, otherwise the will send second value
    const toastPromise = toast.promise(
      updateItem({
        // @ts-ignore
        idItem: id?.toString(),
        idMerchant: dataItem?.retailerId?.toString(),
        data: {
          ageLimit: dataItem?.newData?.hasOwnProperty("ageLimit")
            ? !declinedItems.includes("ageLimit")
              ? parseInt(dataItem.newData.ageLimit)
              : parseInt(dataItem.ageLimit)
            : parseInt(itemData.ageLimit),
          expiredAt: dataItem?.newData?.hasOwnProperty("expiredAt")
            ? !declinedItems.includes("expiredAt")
              ? new Date(dataItem.newData.expiredAt)?.toISOString()
              : new Date(dataItem.expiredAt)?.toISOString()
            : new Date(itemData.expiredAt)?.toISOString(),
          name: dataItem?.newData?.hasOwnProperty("name")
            ? !declinedItems.includes("name")
              ? dataItem.newData.name
              : dataItem.name
            : itemData.name,
          requiredStamps: dataItem?.newData?.hasOwnProperty("requiredStamps")
            ? !declinedItems.includes("requiredStamps")
              ? parseInt(dataItem.newData.requiredStamps)
              : parseInt(dataItem.requiredStamps)
            : parseInt(itemData.requiredStamps),
          description: dataItem?.newData?.hasOwnProperty("description")
            ? !declinedItems.includes("description")
              ? dataItem.newData.description
              : dataItem.description
            : itemData.description,
          perCustomerLimit: dataItem?.newData?.hasOwnProperty(
            "perCustomerLimit"
          )
            ? !declinedItems.includes("perCustomerLimit")
              ? parseInt(dataItem.newData.perCustomerLimit)
              : parseInt(dataItem.perCustomerLimit)
            : parseInt(itemData.perCustomerLimit),
          radius: dataItem?.newData?.hasOwnProperty("radius")
            ? !declinedItems.includes("radius")
              ? parseInt(dataItem.newData.radius)
              : parseInt(dataItem.radius)
            : parseInt(itemData.radius),
          percentShop: dataItem?.newData?.hasOwnProperty("percentShop")
            ? !declinedItems.includes("percentShop")
              ? dataItem.newData.percentShop
              : dataItem.percentShop
            : parseInt(itemData.itemsShop),
          offerDiscount: dataItem?.newData?.hasOwnProperty("offerDiscount")
            ? !declinedItems.includes("offerDiscount")
              ? parseInt(dataItem.newData.offerDiscount)
              : parseInt(dataItem.offerDiscount)
            : parseInt(itemData.offerDiscount),
          categories: dataItem?.newData?.hasOwnProperty("categories")
            ? !declinedItems.includes("categories")
              ? formatCategory(dataItem.newData.categories)
              : formatCategory(dataItem.categories)
            : itemData.categories,
          promotionType: dataItem?.newData?.hasOwnProperty("promotionType")
            ? !declinedItems.includes("promotionType")
              ? dataItem.newData.promotionType
              : dataItem.promotionType
            : itemData.promotionType,

          price: dataItem?.newData?.hasOwnProperty("price")
            ? !declinedItems.includes("price")
              ? parseInt(dataItem.newData.price)
              : parseInt(dataItem.price)
            : parseInt(itemData.price),
          prizeCount: dataItem?.newData?.hasOwnProperty("prizeCount")
            ? !declinedItems.includes("prizeCount")
              ? parseInt(dataItem.newData.prizeCount)
              : parseInt(dataItem.prizeCount)
            : parseInt(itemData.prizeCount),
          terms: dataItem?.newData?.hasOwnProperty("terms")
            ? !declinedItems.includes("terms")
              ? dataItem.newData.terms
              : dataItem.terms
            : itemData.terms,
        },
      }),
      {
        pending: "Updating item",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while updating item</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Item updated");

          if (
            arrayImageToUpload.length &&
            arrayImageToUpload[0].data === "" &&
            arrayImageToUpload[1].data === ""
          )
            return;

          const imagesUpload = arrayImageToUpload.map(
            (imageToUpload: any, key: number) => {
              if (!imageToUpload?.data) return;
              updateItemImages(imageToUpload, key);
            }
          );

          Promise.all(imagesUpload);

          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while updating item");
      })
      .finally(() => {
        setDeclinedItems([]);
        setApprovedItems([]);
        setReasonDescription("");
        dispatch(setType(""));
        dispatch(setOpenModal(false));
      });
  };

  const changeStatusItem = async ({
    idItem,
    idMerchant,
    status,
  }: {
    idItem: string | undefined;
    idMerchant: string;
    status: string;
  }) => {
    if (!idItem && idItem !== undefined && !idMerchant && !status) return;

    const toastPromise = toast.promise(
      updateStatusMutation({
        // @ts-ignore
        idItem: idItem?.toString(),
        idMerchant: idMerchant?.toString(),
        status: status.toUpperCase(),
      }),
      {}
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while changing item status</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          if (status === "ACTIVE") {
            toast.success("Item status changed");
            return;
          }

          const toastPromise = toast.promise(
            updateStatusMutation({
              // @ts-ignore
              idItem: idItem?.toString(),
              idMerchant: idMerchant?.toString(),
              status: status === "PAUSED" ? "ARCHIVED" : "PENDING_REVIEW",
            }),
            {
              pending: "Changing item status",
            }
          );

          toastPromise
            .then((res: any) => {
              if (res.error) {
                toast.error(
                  <div>
                    <h2>Error while changing item status</h2>
                    <p className="text-xs"> {res?.error?.data?.message} </p>
                  </div>
                );
              } else {
                toast.success("Item status changed");
                // Re fetch items to update UI
              }
            })
            .catch((err) => {
              toast.error("Error while changing item status");
            })
            .finally(() => {
              dispatch(setResetElChecked(true));
              setTimeout(() => {
                dispatch(setType(""));
                dispatch(setOpenModal(false));
              }, 1000);

              dispatch(merchantsApi.util.invalidateTags(["MerchantItems"]));
            });
        }
      })
      .catch((err) => {
        toast.error("Error while changing item status");
      });
  };

  const handleRecoverItem = async (
    idItem: string | undefined,
    idMerchant: string
  ) => {
    let toastPromise;

    if (!idItem) return;

    toastPromise = toast.promise(
      updateStatusMutation({
        idItem,
        idMerchant,
        status: "PENDING_REVIEW",
      }),
      {
        pending: "Recovering Item",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while recovering item</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Item Recovered");
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error("Error while recovering item");
      });
  };

  const handleDecline = () => {
    dispatch(setType("declineModal"));
    dispatch(setOpenModal(true));
  };

  const handleApprove = async () => {
    if (
      dataItem?.hasOwnProperty("newData") ||
      dataItem?.images[0]?.hasOwnProperty("newName") ||
      dataItem?.images[1]?.hasOwnProperty("newName")
    ) {
      const data = {
        description: "",
        approvedFields: approvedItems,
        declinedFields: declinedItems,
        reasonId: 0,
        fullInfo: [
          {
            fieldName: "prizeId",
            fieldValue: itemData.prizeId,
            isApproved: true,
          },
          {fieldName: "name", fieldValue: itemData.name, isApproved: true},
          {
            fieldName: "description",
            fieldValue: itemData.description,
            isApproved: true,
          },
          {
            fieldName: "price",
            fieldValue: itemData.price,
            isApproved: true,
          },
          {
            fieldName: "offerDiscount",
            fieldValue: itemData.offerDiscount,
            isApproved: true,
          },
          {
            fieldName: "prizeCount",
            fieldValue: itemData.prizeCount,
            isApproved: true,
          },
          {
            fieldName: "expiredAt",
            fieldValue: itemData.expiredAt,
            isApproved: true,
          },
          {
            fieldName: "ageLimit",
            fieldValue: itemData.ageLimit,
            isApproved: true,
          },
          {
            fieldName: "promotionType",
            fieldValue: itemData.promotionType,
            isApproved: true,
          },
          {
            fieldName: "perCustomerLimit",
            fieldValue: itemData.perCustomerLimit,
            isApproved: true,
          },
          {
            fieldName: "radius",
            fieldValue: itemData.radius,
            isApproved: true,
          },
          {
            fieldName: "terms",
            fieldValue: itemData.terms,
            isApproved: true,
          },
          {
            fieldName: "requiredStamps",
            fieldValue: itemData.requiredStamps,
            isApproved: true,
          },
          {
            fieldName: "interest",
            fieldValue: elementsName.length ? elementsName.join(", ") : "",
            isApproved: true,
          },
          {
            fieldName: "image#BIG",
            fieldValue: firstImage,
            isApproved: true,
          },
          {
            fieldName: "image#SMALL",
            fieldValue: secondImage,
            isApproved: true,
          },
        ],
      };

      const toastPromise = toast.promise(
        applyChanges({
          idRetailer: dataItem.retailerId,
          idPrize: dataItem.prizeId,
          data,
        }),
        {
          pending: "Approving item",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while approving item</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success("Item Approved");
            setNewDataCheckbox([]);
            refetch();
          }
        })
        .catch((err) => {
          toast.error("Error while approving item");
        });

      return;
    } else {
      handlePublishItem();
      return;
    }
  };

  const handleDeleteItem = async () => {
    const toastPromise = toast.promise(
      setDeleteItem({
        idItem: dataItem.prizeId,
        idMerchant: dataItem.retailerId,
      }),
      {
        pending: "Deleting item",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting item</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Item Deleted");
          dispatch(setType(""));
          dispatch(setOpenModal(false));
          navigate(-1);
        }
      })
      .catch((err) => {
        toast.error("Error while deleting item");
      });
  };

  const handleRenderButton = () => {
    return (
      <div className="flex">
        {dataItem?.state !== "PENDING_REVIEW"
          ? dataItem?.state === "ARCHIVED"
            ? // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("recover") && (
                <>
                  <Button
                    variant="recovery"
                    onClick={() => {
                      dispatch(setType("recoverItem"));
                      dispatch(setOpenModal(true));
                    }}
                  >
                    <Recovery />
                    <p className="ml-2">Recovery</p>
                  </Button>
                  <Button
                    variant={"danger"}
                    onClick={() => {
                      dispatch(setType("deleteItem"));
                      dispatch(setOpenModal(true));
                    }}
                  >
                    <Delete />
                    <p className="ml-2">Delete</p>
                  </Button>
                </>
              )
            : // @ts-ignore
              routes[user.data.role].urlAllowed?.item?.includes("archive") && (
                <Button
                  variant="danger"
                  onClick={() => {
                    dispatch(setType("archiveItem"));
                    dispatch(setOpenModal(true));
                  }}
                >
                  <Archive />
                  <p className="ml-2">Archive Item</p>
                </Button>
              )
          : null}
        {
          dataItem?.state === "NEW" ||
          dataItem?.state === "PENDING_REVIEW" ||
          dataItem?.hasOwnProperty("newData") ||
          dataItem?.images[0]?.hasOwnProperty("newName") ||
          dataItem?.images[1]?.hasOwnProperty("newName")
            ? btnApprove // @ts-ignore
              ? routes[user.data.role].urlAllowed?.item?.includes(
                  "decline"
                ) && (
                  <Button variant="recovery" onClick={() => handleApprove()}>
                    <p>Approve & Publish</p>
                  </Button>
                )
              : // @ts-ignore
                routes[user.data.role].urlAllowed?.item?.includes(
                  "decline"
                ) && (
                  <Button variant="danger" onClick={() => handleDecline()}>
                    <p>Decline</p>
                  </Button>
                )
            : null
          // selectedTab === "Details" &&
          // @ts-ignore
          // routes[user.data.role].urlAllowed?.item?.includes("decline") && (
          //   <Button variant="danger" onClick={() => handleDecline()}>
          //     <p>Decline</p>
          //   </Button>
          // )
        }
        {dataItem?.state === "ARCHIVED" ||
        dataItem?.hasOwnProperty("newData") ||
        dataItem?.images[0]?.hasOwnProperty("newName") ||
        dataItem?.images[1]?.hasOwnProperty("newName")
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.item?.includes("save") && (
              <Button
                variant={isDirty ? "normal" : "disabled"}
                onClick={
                  isDirty ? () => handleCheckIfCanChangeSave() : () => {}
                }
              >
                <Save />
                <p className="ml-2">Save</p>
              </Button>
            )}
        {dataItem?.state === "ARCHIVED" ||
        dataItem?.hasOwnProperty("newData") ||
        dataItem?.images[0]?.hasOwnProperty("newName") ||
        dataItem?.images[1]?.hasOwnProperty("newName")
          ? null
          : // @ts-ignore
            routes[user.data.role].urlAllowed?.item?.includes("publish") && (
              <Button variant="add" onClick={() => handlePublishItem()}>
                <Publish />
                <p className="ml-2">Publish</p>
              </Button>
            )}
      </div>
    );
  };

  const handleRemoveElement = (element: any) => {
    // console.log(element);
    const itemsRemaining = declinedItems.filter((item) => item !== element);

    setDeclinedItems(itemsRemaining);
  };

  const handleFormatElement = (element: string) => {
    console.log("element", element);

    if (element.toLowerCase() === "prizecount") {
      return "amount prizes";
    }
    if (element.toLowerCase() === "expiredat") {
      return "expiration";
    }
    if (element.toLowerCase() === "agelimit") {
      return "limit age";
    }
    if (element.toLowerCase() === "percustomerlimit") {
      return "customer limit";
    }
    if (element.toLowerCase() === "offerdiscount") {
      return "offer discount";
    }
    if (element.toLowerCase() === "promotiontype") {
      return "prize attribute";
    }
    if (element.toLowerCase() === "requiredstamps") {
      return "stamps needed";
    }
    if (element.toLowerCase() === "percentshop") {
      return "percent shop";
    }
    if (element.toLowerCase() === "image#big") {
      return "cover photo";
    }
    if (element.toLowerCase() === "image#small") {
      return "prize photo";
    } else {
      return element;
    }
  };

  const handleSendDeclineReason = async () => {
    if (!reason || !reasonDescription) {
      toast.error("Provide reason and description for decline");
      setInputError(true);
      return;
    }

    const checkIfIsApproved = (fieldName: string) => {
      const isNotApproved = declinedItems.find(
        (reason) => reason === fieldName
      );

      return isNotApproved ? true : false;
    };

    const data = {
      description: reasonDescription,
      approvedFields: approvedItems,
      declinedFields: declinedItems,
      reasonId: parseInt(reason),
      fullInfo: [
        {
          fieldName: "prizeId",
          fieldValue: itemData.prizeId,
          isApproved: checkIfIsApproved("prizeId"),
        },
        {fieldName: "name", fieldValue: itemData.name, isApproved: true},
        {
          fieldName: "description",
          fieldValue: itemData.description,
          isApproved: true,
        },
        {
          fieldName: "price",
          fieldValue: itemData.price,
          isApproved: checkIfIsApproved("price"),
        },
        {
          fieldName: "offerDiscount",
          fieldValue: itemData.offerDiscount,
          isApproved: checkIfIsApproved("offerDiscount"),
        },
        {
          fieldName: "prizeCount",
          fieldValue: itemData.prizeCount,
          isApproved: checkIfIsApproved("prizeCount"),
        },
        {
          fieldName: "expiredAt",
          fieldValue: itemData.expiredAt,
          isApproved:
            dataItem.prizeType !== "reward"
              ? checkIfIsApproved("expiredAt")
              : true,
        },
        {
          fieldName: "ageLimit",
          fieldValue: itemData.ageLimit,
          isApproved: checkIfIsApproved("ageLimit"),
        },
        {
          fieldName: "requiredStamps",
          fieldValue: itemData.requiredStamps,
          isApproved: checkIfIsApproved("requiredStamps"),
        },
        {
          fieldName: "perCustomerLimit",
          fieldValue: itemData.perCustomerLimit,
          isApproved: checkIfIsApproved("perCustomerLimit"),
        },
        {
          fieldName: "radius",
          fieldValue: itemData.radius,
          isApproved: checkIfIsApproved("radius"),
        },
        {
          fieldName: "terms",
          fieldValue: itemData.terms,
          isApproved: checkIfIsApproved("terms"),
        },
        {
          fieldName: "interest",
          fieldValue: elementsName.length ? elementsName.join(", ") : "",
          isApproved: checkIfIsApproved("categories"),
        },
        {
          fieldName: "image#BIG",
          fieldValue: firstImage,
          isApproved: checkIfIsApproved("image#BIG"),
        },
        {
          fieldName: "image#SMALL",
          fieldValue: secondImage,
          isApproved: checkIfIsApproved("image#SMALL"),
        },
      ],
    };

    const toastPromise = toast.promise(
      applyChanges({
        idRetailer: dataItem.retailerId,
        idPrize: dataItem.prizeId,
        data,
      }),
      {
        pending: "Declining item",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while declining item</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Item Declined");
          dispatch(setType(""));
          setNewDataCheckbox([]);
          dispatch(setOpenModal(false));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while decliging item");
      });
  };

  const handleChangeBtnAllChecked = (value: boolean) => {
    if (value) {
      setBtnApprove(true);
    } else {
      setBtnApprove(false);
    }
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="text-sm text-gray-400 flex hover:text-primary-purple max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to item list
        </Link>
      </div>
      <div className="flex justify-between">
        <Title classes="flex items-center">
          {dataItem?.newData && <Alert classes="mr-3" />}{" "}
          {dataItem?.name || dataItem?.newData?.name}
        </Title>

        {handleRenderButton()}
      </div>
      <div className="mt-5">
        <Tabs
          tabsName={["Details", "Stats", "Merchants"]}
          setSelectedTab={handleSelectedTab}
        >
          <Details
            itemInfo={dataItem}
            isLoadingItem={isLoadingItem}
            itemData={itemData}
            setItemData={setItemData}
            setIsDirty={setIsDirty}
            firstImage={firstImage}
            setFirstImg={setFirstImg}
            secondImage={secondImage}
            setSecondImg={setSecondImg}
            inputError={inputError}
            errorInItemsCount={errorInItemsCount}
            setErrorInItemsCount={setErrorInItemsCount}
            setDeclinedItems={setDeclinedItems}
            setApprovedItems={setApprovedItems}
            handleChangeBtnAllChecked={handleChangeBtnAllChecked}
            objectImages={objectImages}
            setObjectImages={setObjectImages}
            elementsName={elementsName}
            setElementsName={setElementsName}
            imageCropModalType={imageCropModalType}
            setImageCropModalType={setImageCropModalType}
            newDataCheckbox={newDataCheckbox}
            setNewDataCheckbox={setNewDataCheckbox}
          />
          <Stats />
          <Merchant id={dataItem?.retailerId} />
        </Tabs>
      </div>
      {(isLoadingItem || isErrorItem) && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4 text-center">
            {isErrorItem ? (
              <div className="flex flex-col w-full justify-center items-center mt-5 px-4">
                <p className="">
                  An error occurred while trying to retrieve the item
                </p>
                <Button onClick={refetch} classes="font-semibold">
                  Try again
                </Button>
              </div>
            ) : (
              <p>Loading Item...</p>
            )}
          </div>
        </Modal>
      )}
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded">
            <Dialog.Overlay />
            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
      {modal.type === "declineModal" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 pb-6 pt-3">
            <div className="flex justify-center items-center flex-col">
              <div className="flex justify-center items-start flex-col mb-8 px-5">
                <Dialog.Title className=" mb-4 text-black text-xl font-medium">
                  Provide decline reason
                </Dialog.Title>
                <Select
                  error={!reason && inputError ? true : false}
                  label="Provide reason"
                  classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black w-full"
                  // @ts-ignore
                  onChange={(e: any) => setReason(e.target.value)}
                >
                  {isLoadingDeclineReasons && !isErrorDeclineReasons && (
                    <option value="" disabled>
                      Loading Decline Reasons
                    </option>
                  )}
                  {!isLoadingDeclineReasons &&
                    !isErrorDeclineReasons &&
                    declineReasons && (
                      <>
                        <option disabled selected>
                          Choose reason of decline
                        </option>
                        {declineReasons.map(
                          (reason: {description: string; id: number}) => (
                            <option
                              value={reason.id.toString()}
                              key={reason.id}
                            >
                              {reason.description}
                            </option>
                          )
                        )}
                      </>
                    )}
                </Select>
                {declinedItems.length > 0 && (
                  <div className="mt-5 w-full">
                    <Label>Problem Inputs</Label>
                    <div className="flex flex-wrap border border-gray-400 rounded p-2">
                      {declinedItems.map((option, key) => (
                        <div
                          key={key}
                          className="bg-fourth-purple mx-1 my-1 rounded pl-3 pr-4 py-1 text-black relative cursor-default w-max text-sm capitalize"
                        >
                          {handleFormatElement(option)}
                          <span
                            id={option}
                            // @ts-ignore
                            onClick={(e) => handleRemoveElement(e.target.id)}
                            className="text-xs text-gray-400 pr-1 ml-2 absolute top-0 right-0 cursor-pointer"
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <Textarea
                  label="Description"
                  placeholder="Enter a description"
                  error={!reasonDescription && inputError ? true : false}
                  classes="mt-5 w-full"
                  required={true}
                  value={reasonDescription}
                  onChange={(e: any) => {
                    setReasonDescription(e.target.value);
                  }}
                />
              </div>
              <hr className="w-full" />
              <div className="flex justify-between items-center w-full px-5 mt-3">
                <Button
                  onClick={() => handleCloseModal()}
                  variant="normal"
                  classes="text-center"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSendDeclineReason()}
                  variant="add"
                  classes="text-center"
                >
                  Sent
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal.type === "recoverItem" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-green-100 flex items-center justify-center mt-5">
                  <Recovery classes="text-green-500 h-10 w-10" />
                </div>
                <h1 className="text-green-500 mt-4">Recover</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to recover <b>{dataItem.name}</b> ?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="recovery"
                  onClick={() => {
                    handleRecoverItem(id, dataItem?.retailerId);
                  }}
                >
                  Recover
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
      {modal.type === "deleteItem" && (
        <DeleteModal
          elementsChecked={[]}
          setElementsChecked={() => {}}
          dataSource={[]}
          matchWidhId="categoryId"
          pendingMsg="Deleting item"
          returnValue="name"
          toastMessageId="name"
          typeOfDelete="item"
          deleteCallback={handleDeleteItem}
          nameItem={itemData.name}
        />
      )}
      {modal.type === "archiveItem" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
                  <Archive classes="text-red-500 h-10 w-10" />
                </div>
                <h1 className="text-red-500 mt-4">Archive</h1>
                <p className="text-sm mt-4 text-center">
                  Are you sure you want to archive <b>{dataItem.name}</b> ?
                </p>
              </div>
              <hr />
              <div className="mt-8 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    changeStatusItem({
                      idItem: id,
                      idMerchant: dataItem?.retailerId,
                      status: "PAUSED",
                    });
                  }}
                >
                  Archive
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
      {modal.type === "changeStamps" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <>
              <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
                <p className="text-sm mt-4">{canChangeRewardText}</p>
              </div>
              <hr />
              <div className="mt-4 px-6 flex justify-between items-center">
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(setType(""));
                    dispatch(setOpenModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={canSave ? "normal" : "disabled"}
                  onClick={canSave ? handleSaveItem : () => {}}
                >
                  Continue
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </section>
  );
}

export default ItemsDetails;
