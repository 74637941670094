import {TypeClasses} from "./types";

function Download({classes}: TypeClasses) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${classes}`}
      style={{fill: "rgb(151 151 151)"}}
      height="24"
      viewBox="0 96 960 960"
      width="24"
    >
      <path d="M240 896q-33 0-56.5-23.5T160 816V696h80v120h480V696h80v120q0 33-23.5 56.5T720 896H240Zm240-160L280 536l56-58 104 104V256h80v326l104-104 56 58-200 200Z" />
    </svg>
  );
}

export default Download;
