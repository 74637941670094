import {TypeClasses} from "./types";
function List({classes}: TypeClasses) {
  return (
    <svg
      id="merchant"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 96 960 960"
      width="24"
      className={`${classes}`}
      fill="currentColor"
    >
      <path d="M160 776q-17 0-28.5-11.5T120 736q0-17 11.5-28.5T160 696q17 0 28.5 11.5T200 736q0 17-11.5 28.5T160 776Zm0-160q-17 0-28.5-11.5T120 576q0-17 11.5-28.5T160 536q17 0 28.5 11.5T200 576q0 17-11.5 28.5T160 616Zm0-160q-17 0-28.5-11.5T120 416q0-17 11.5-28.5T160 376q17 0 28.5 11.5T200 416q0 17-11.5 28.5T160 456Zm120 320v-80h560v80H280Zm0-160v-80h560v80H280Zm0-160v-80h560v80H280Z" />
    </svg>
  );
}

export default List;
