import {Dispatch, SetStateAction} from "react";
import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import type {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";

// Assets
import Delete from "assets/Delete";

type DeleteModalType = {
  elementsChecked: number[];
  setElementsChecked: Dispatch<SetStateAction<number[]>>;
  dataSource: any;
  matchWidhId: string;
  toastMessageId: string;
  returnValue: string;
  promise?: Function;
  pendingMsg: string;
  type?: string;
  nameItem?: string;
  extraArgs?: any;
  typeOfDelete?: string;
  deleteCallback?: any;
  cleanFn?: any;
};

function DeleteModal({
  elementsChecked,
  setElementsChecked,
  dataSource,
  matchWidhId,
  toastMessageId,
  returnValue,
  promise,
  pendingMsg,
  type,
  extraArgs,
  nameItem,
  typeOfDelete,
  deleteCallback,
  cleanFn = () => {},
}: DeleteModalType) {
  const dispatch = useDispatch();

  const handleGetName = (id: number) => {
    return dataSource?.find((category: any) => category[matchWidhId] === id);
  };

  const handleSubmit = () => {
    if (typeOfDelete === "item") {
      deleteCallback();
      return;
    }
    if (typeOfDelete === "reward") {
      deleteCallback();
      return;
    }

    if (typeOfDelete === "subsubcategory") {
      deleteCallback();
      return;
    }

    if (typeOfDelete === "trendingPlaces") {
      deleteCallback(extraArgs?.data, extraArgs?.type);
      return;
    }

    if (typeOfDelete === "loyaltyprogram") {
      deleteCallback(extraArgs?.data, extraArgs?.type);
      return;
    }

    elementsChecked.forEach(async (id) => {
      let toastPromise;

      if (!promise) return;

      toastPromise = toast.promise(promise({...extraArgs, categoryId: id}), {
        pending: `${pendingMsg}`,
      });

      const elementData = handleGetName(id);

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while deleting category {handleGetName(id)}</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(`Category ${elementData[toastMessageId]} deleted`);
            dispatch(setResetElChecked(true));
            setElementsChecked([]);
            setTimeout(() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
            }, 1000);
          }
        })
        .catch((err) => {
          toast.error("Error while deleting category");
        });
    });
  };

  const getNames = () => {
    if (!elementsChecked.length) return <></>;

    const elementNames = elementsChecked?.map((id) => {
      return dataSource?.filter((el: any) => {
        if (!id || !el) return null;

        // console.log(id, el);

        if (el[matchWidhId] === id) {
          return el;
        }
      });
    });

    const namesFlatted = elementNames?.flat();

    return namesFlatted?.map((element: any) => {
      if (element) {
        return namesFlatted.length > 1 ? (
          <b key={element[matchWidhId]}>{element[returnValue]}, </b>
        ) : (
          <b key={element[matchWidhId]}>{element[returnValue]} </b>
        );
      } else {
        return <b></b>;
      }
    });
  };

  return (
    <Modal>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <div className="rounded-full h-20 w-20 bg-red-100 flex items-center justify-center mt-5">
            <Delete classes="text-red-500 h-10 w-10" />
          </div>
          <h1 className="text-red-500 mt-4">Delete</h1>
          <p className="text-sm mt-4 text-center">
            Are you sure you want to delete {type} <b>{nameItem} </b>{" "}
            {getNames()}?
          </p>
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              cleanFn();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleSubmit();
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
