import { TypeClasses } from "./types";

function Filter({ classes }: TypeClasses) {
  return (
    <svg
      id="arrow-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`w-5 h-5 ${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="filter_alt_FILL0_wght400_GRAD0_opsz48"
        d="M15.541,25.606a1.081,1.081,0,0,1-1.1-1.1V17.9L7.866,9.513a.832.832,0,0,1-.11-.99A.9.9,0,0,1,8.609,8H24.674a.9.9,0,0,1,.853.523.832.832,0,0,1-.11.99L18.842,17.9v6.6a1.081,1.081,0,0,1-1.1,1.1Zm1.1-7.592,6.6-8.363H10.04ZM16.642,18.013Z"
        transform="translate(-4.642 -5)"
        fill="#151c39"
      />
    </svg>
  );
}

export default Filter;
