import {Suspense, lazy, useState, useMemo, useEffect} from "react";

// Components
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Form from "components/Table";
import Table from "components/Table/Table";
import Filter from "components/Filter";

// Utils
import {TABLE_USERS_HEADER} from "utils/userHeaders";
import FormSqueleton from "utils/FormSqueleton";
import {setMerchantsName} from "store/merchants/merchantsSlice";
import {useDispatch} from "react-redux";

const FilterFormUser = lazy(() => import("components/Filter/FilterFormUser"));

function Users() {
  const dispatch = useDispatch();
  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    dispatch(setMerchantsName([]));
  }, []);

  const tabs = useMemo(
    () => (
      <Tabs tabsName={"default"}>
        <>
          <div className="mt-3">
            <Filter
              showFilter={showFilter}
              handleShowFilter={setShowFilter}
              elementsChecked={elementsChecked}
              showDropdownCheckbox={false}
              searchBarPlaceholder="Search by User name, location, ID"
            />
            {showFilter && (
              <Suspense fallback={<FormSqueleton />}>
                <FilterFormUser handleShowFilter={setShowFilter} />
              </Suspense>
            )}
          </div>
          <Table data={[]} origin="users" headers={TABLE_USERS_HEADER} />
        </>
      </Tabs>
    ),
    []
  );

  return (
    <div>
      <Title>Users</Title>
      <Form>
        <div className="mt-3">{tabs}</div>
      </Form>
    </div>
  );
}

export default Users;
