import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Dialog} from "@headlessui/react";
import {toast} from "react-toastify";

// Components
import Button from "components/Common/Button";
import Select from "components/Common/Input/Select";
import Table from "components/Table/Table";
import Modal from "components/Modal";
import FilterFormLocation from "components/Filter/FilterFormLocation";

// Redux
import {RootState} from "store/store";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {useDispatch, useSelector} from "react-redux";
import {setFiltersLastLocation, setSorting} from "store/filters/filtersSlice";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import {
  useCreateLocationMutation,
  useGetAllLocationQuery,
  useGetStatesQuery,
  useLazyGetDMAQuery,
  useLazyGetStatesQuery,
  usePublishLocationMutation,
  useUnpublishLocationMutation,
} from "store/api/locationApi";
import {setMerchantsName} from "store/merchants/merchantsSlice";

// Utils
import {TABLE_LOCATION_CONFIG_HEADER} from "utils/locationConfigHeader";
import routes from "utils/routesByRole";

// Assets
import {EyeHide} from "assets/Eye";
import Publish from "assets/Publish";
import List from "assets/List";
import Description from "assets/Description";

const defaultLocation = {state: "", city: "", shortNameState: ""};

const Configuration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal: any = useSelector((state: RootState) => state.modal);
  const filters = useSelector((state: RootState) => state?.filters?.filters);
  const user = useSelector((state: RootState) => state.user.user.user);

  const [locationData, setLocationData] = useState(defaultLocation);
  const [elementsChecked, setElementsChecked] = useState<number[]>([]);
  const [params, setParams] = useState("?ordering=dma_code");
  const [showFilter, setShowFilter] = useState(false);
  const [stateShortNames, setStateShortNames] = useState([]);
  const [localParams, setLocalParams] = useState<string>(filters.lastLocation);

  const [typeActionCategory, setTypeActionCategory] = useState("");
  const [modalContent, setModalContent] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setSorting("ordering=dma_code"));
  }, []);

  const {
    data: dataLocation,
    isLoading: isLoadingDataLocation,
    isError: isErrorDataLocation,
    refetch,
    // @ts-ignore
  } = useGetAllLocationQuery(params);

  const {
    data: dataStates,
    isLoading: isLoadingDataStates,
    isError: isErrorDataStates,
  } = useGetStatesQuery();

  const [getDMA, {data: dataDMA}] = useLazyGetDMAQuery();

  const [publishLocation] = usePublishLocationMutation();
  const [unpublishLocation] = useUnpublishLocationMutation();
  const [createLocation] = useCreateLocationMutation();

  useEffect(() => {
    if (locationData && locationData.state && locationData.shortNameState) {
      getDMA(`?state=${locationData.shortNameState}`);
    }
  }, [locationData]);

  useEffect(() => {
    if (localParams !== "") {
      dispatch(setFiltersLastLocation(localParams));
    }
  }, [localParams]);

  useEffect(() => {
    dispatch(setMerchantsName([]));
  }, []);

  useEffect(() => {
    setParams(
      `?${filters.sorting}${filters.page}${filters.limit}${filters.urlSearch}${filters.urlFilters}`
    );
  }, [filters]);

  useEffect(() => {
    setStateShortNames(dataStates?.states?.map((el: any) => el.shortName));
  }, []);

  const handleItemChecked = (items: number[]) => {
    setElementsChecked(items.map((el) => el));
  };

  const resetLocationData = () => {
    setLocationData(defaultLocation);
  };

  const handlePublish = async () => {
    let toastPromise;

    toastPromise = toast.promise(
      publishLocation({
        data: {dmaCodes: modalContent.map((el) => el.dmaCode.toString())},
      }),
      {
        pending: "Publishing locations",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while publishing locations</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Locations published", {
            autoClose: 5000,
          });
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
          dispatch(setResetElChecked(true));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while publishing locations");
      });
  };

  const handleUnpublish = async () => {
    let toastPromise;

    toastPromise = toast.promise(
      unpublishLocation({
        data: {dmaCodes: modalContent.map((el) => el.dmaCode.toString())},
      }),
      {
        pending: "Unpublishing locations",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while unpublishing locations</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Locations unpublished", {
            autoClose: 5000,
          });
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
          dispatch(setResetElChecked(true));
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while unpublishing locations");
      });
  };

  const handleCreateLocation = () => {
    dispatch(setType("createLocationModal"));
    dispatch(setOpenModal(true));
  };

  const handleCloseModal = () => {
    resetLocationData();
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleAddNewLocation = async (e: any) => {
    e.preventDefault();

    if (locationData.city !== "" && locationData.state !== "") {
      let toastPromise;
      toastPromise = toast.promise(
        createLocation({dmaCode: locationData.city, state: locationData.state}),
        {
          pending: "Creating locations",
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while creating locations</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success("Locations Created", {
              autoClose: 5000,
            });
            dispatch(setResetElChecked(true));
            dispatch(setOpenModal(false));
            dispatch(setType(""));

            setTimeout(() => {
              navigate("/location/" + locationData.city);
            }, 2000);
            resetLocationData();
          }
        })
        .catch((err) => {
          toast.error("Error while creating locations");
        });
    } else {
      toast.error("You have to select State and DMA to add a new location");
    }
  };
  const getInfoStatesFilters = (data: any[]) => {
    setLocalParams((prev: any) => {
      return {
        ...prev,
        states: data[0],
      };
    });
  };

  const handleUnpublishCategoryFn = (e: any, type: string, option: string) => {
    if (option === "array") {
      if (type === "unpublish") {
        const names = e.map((id: any) => {
          return handleGetName(id);
        });

        const arrPublishFiltered = names.filter(
          (el: any) => el.isPublished === true
        );

        if (arrPublishFiltered.length > 0) {
          dispatch(setType("unpublishCategoryModal"));
          dispatch(setOpenModal(true));
          setTypeActionCategory("unpublish");
          setModalContent(arrPublishFiltered);
        } else {
          toast.info(`Location is already ${type}ed`);
        }
      } else if (type === "publish") {
        const names = e.map((id: any) => {
          return handleGetName(id);
        });

        const arrUnpublishFiltered = names.filter(
          (el: any) => el.isPublished === false
        );

        if (arrUnpublishFiltered.length > 0) {
          dispatch(setType("unpublishCategoryModal"));
          dispatch(setOpenModal(true));
          setTypeActionCategory("publish");
          setModalContent(arrUnpublishFiltered);
        } else {
          toast.info(`Location is already ${type}ed`);
        }
      }
    }
    if (option === "individual") {
      if (type === "unpublish" && e.isPublished === true) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else if (type === "publish" && e.isPublished === false) {
        setModalContent([e]);
        dispatch(setType("unpublishCategoryModal"));
        dispatch(setOpenModal(true));
      } else {
        toast.info(`This location is already ${type}`);
      }
    }
  };

  const handleGetName = (locationId: number) => {
    return dataLocation?.locations?.find(
      // @ts-ignore
      (location: any) => parseInt(location.dmaCode) === parseInt(locationId)
    );
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.location?.includes("view") && {
        title: "Details",
        svg: <List classes="mr-3" />,
        type: "redirectToLocation",
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.location?.includes("publish") && {
        title: "Publish",
        svg: <Description classes="mr-3" />,
        type: "unpublishCategory",
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "publish", "individual");
          setTypeActionCategory("publish");
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.location?.includes("publish") && {
        title: "Unpublish",
        svg: <EyeHide classes="mr-3" />,
        type: "unpublishCategory",
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "unpublish", "individual");
          setTypeActionCategory("unpublish");
        },
      },
    ],
    []
  );

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center mb-5">
        <div className="flex">
          <FilterFormLocation
            showFilter={showFilter}
            handleShowFilter={setShowFilter}
            hasFilterButton={false}
            optionsAllDropdownCheckbox="Choose state"
            optionsDropdownCheckbox={["FL"]}
            searchBarPlaceholder="Search by DMA Name"
            getInfoStatesFilters={getInfoStatesFilters}
          />
        </div>
        <div className="flex">
          <div className="flex gap-1">
            <Button
              variant={`${elementsChecked.length > 0 ? "add" : "disabled"}`}
              onClick={
                elementsChecked.length > 0
                  ? () => {
                      handleUnpublishCategoryFn(
                        elementsChecked,
                        "publish",
                        "array"
                      );
                    }
                  : undefined
              }
            >
              <Publish />
              <p className="ml-2">Publish</p>
            </Button>
            <Button
              variant={`${elementsChecked.length > 0 ? "normal" : "disabled"}`}
              onClick={
                elementsChecked.length > 0
                  ? () => {
                      handleUnpublishCategoryFn(
                        elementsChecked,
                        "unpublish",
                        "array"
                      );
                    }
                  : undefined
              }
            >
              <EyeHide />
              <p className="ml-2">Unpublish</p>
            </Button>
            <Button variant={`recovery`} onClick={handleCreateLocation}>
              +<p className="ml-2">Create Location</p>
            </Button>
          </div>
        </div>
      </div>

      <Table
        data={
          dataLocation &&
          dataLocation?.locations &&
          dataLocation?.locations?.length
            ? dataLocation.locations
            : []
        }
        loadingData={isLoadingDataLocation}
        errorData={isErrorDataLocation}
        refetch={refetch}
        origin="locations"
        headers={TABLE_LOCATION_CONFIG_HEADER}
        results={dataLocation !== undefined ? dataLocation?.count : 1}
        itemOptions={ITEMS}
        typeOptions="group"
        handleItemChecked={handleItemChecked}
        show={[
          "dmaName",
          "categoryCount",
          "merchantCount",
          "createdAt",
          "offerCount",
          "prizeCount",
          "zipCodeCount",
          "redemptionCount",
          "isPublished",
        ]}
        sort={{
          dmaName: null,
          categoryCount: null,
          merchantCount: null,
          createdAt: null,
          offerCount: null,
          prizeCount: null,
          zipCodeCount: null,
          redemptionCount: null,
          isPublished: null,
        }}
        orderingByAPI={true}
      />

      {modal.type === "unpublishCategoryModal" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Are you sure you want to {typeActionCategory} this location?
              </Dialog.Title>
              <div className="px-6 mb-5">
                {modalContent.map((element: any) => {
                  return <p>- {element?.dmaName}</p>;
                })}
              </div>

              <p className="text-sm px-6">
                This action will affect the customer application
              </p>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>
                  {typeActionCategory === "unpublish" ? (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== false
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== false
                          ? handleUnpublish
                          : () =>
                              console.log(
                                "This location is already unpublished"
                              )
                      }
                    >
                      <p>Unpublish</p>
                    </Button>
                  ) : (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== true
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== true
                          ? handlePublish
                          : () =>
                              console.log("This location is already published")
                      }
                    >
                      <p>Publish</p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {modal.type === "createLocationModal" && (
        <Modal fnClean={() => handleCloseModal()}>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-4 text-black text-xl font-medium">
                Add new Location
              </Dialog.Title>
              <hr />

              <div className="mt-8 px-6">
                <Select
                  onChange={(e: any) => {
                    setLocationData({
                      state: e.target.value.split("-")[0],
                      shortNameState: e.target.value.split("-")[1],
                      city: "",
                    });

                    const citySelected = document.getElementById(
                      "citySelect"
                    ) as HTMLSelectElement;

                    if (citySelected) {
                      citySelected.selectedIndex = 0;
                    }
                  }}
                  disabled={isLoadingDataStates || isErrorDataStates}
                  classesSelect="py-2 px-2 mb-4 bg-white border border-gray-400 text-gray-500 !outline-none !focus:outline-none"
                >
                  <option disabled selected>
                    Choose State
                  </option>
                  {dataStates &&
                    dataStates.hasOwnProperty("states") &&
                    dataStates?.states?.map((state: any) => (
                      <option
                        key={state.id}
                        value={`${state.id}-${state.shortName}`}
                      >
                        {state.name}
                      </option>
                    ))}
                </Select>
                <Select
                  onChange={(e: any) => {
                    setLocationData({
                      ...locationData,
                      city: e.target.value,
                    });
                  }}
                  id="citySelect"
                  classesSelect="py-2 px-2 mb-4 bg-white border border-gray-400 text-gray-500 !outline-none !focus:outline-none"
                >
                  <option disabled selected>
                    Choose DMA
                  </option>
                  {dataDMA && dataDMA.length > 0 ? (
                    dataDMA.map((dma: any) => (
                      <option key={dma.code} value={dma.code}>
                        {dma.description}
                      </option>
                    ))
                  ) : (
                    <option>No DMA available</option>
                  )}
                </Select>

                <hr />
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button variant="normal" onClick={() => handleCloseModal()}>
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant={
                      locationData.city !== "" &&
                      locationData.state !== "" &&
                      locationData.city !== "No DMA available"
                        ? "add"
                        : "disabled"
                    }
                    type={
                      locationData.city !== "" &&
                      locationData.state !== "" &&
                      locationData.city !== "No DMA available"
                        ? "submit"
                        : "button"
                    }
                    onClick={
                      locationData.city !== "" &&
                      locationData.state !== "" &&
                      locationData.city !== "No DMA available"
                        ? (e: any) => handleAddNewLocation(e)
                        : () => {}
                    }
                  >
                    <p>Add</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Configuration;
