import { TypeClasses } from "./types";

function Calendar({ classes }: TypeClasses) {
  return (
    <svg
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${classes}`}
    >
      <rect
        id="Прямоугольник_8951"
        data-name="Прямоугольник 8951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="calendar_month_FILL0_wght400_GRAD0_opsz48"
        d="M7.35,22A1.384,1.384,0,0,1,6,20.65V6.7A1.384,1.384,0,0,1,7.35,5.35H8.813V4h1.462V5.35h7.65V4h1.462V5.35H20.85A1.384,1.384,0,0,1,22.2,6.7V20.65A1.384,1.384,0,0,1,20.85,22Zm0-1.35h13.5V10.975H7.35Zm0-11.025h13.5V6.7H7.35Zm0,0v0ZM14.1,14.8a.884.884,0,1,1,.641-.259A.871.871,0,0,1,14.1,14.8Zm-3.6,0a.884.884,0,1,1,.641-.259A.871.871,0,0,1,10.5,14.8Zm7.2,0a.884.884,0,1,1,.641-.259A.871.871,0,0,1,17.7,14.8Zm-3.6,3.6a.884.884,0,1,1,.641-.259A.871.871,0,0,1,14.1,18.4Zm-3.6,0a.884.884,0,1,1,.641-.259A.871.871,0,0,1,10.5,18.4Zm7.2,0a.884.884,0,1,1,.641-.259A.871.871,0,0,1,17.7,18.4Z"
        transform="translate(-2 -1)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Calendar;
