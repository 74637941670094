// Components
import Button from "components/Common/Button";
import Searchbar from "components/Common/Searchbar";
import FilterButtons from "./FilterButtons";

// Assets
import {ArrowDown} from "assets/Arrows";
import FilterIcon from "assets/Filter";
import DropdownCheckbox from "components/Common/DropdownCheckbox";

interface FilterTypes {
  showFilter: boolean;
  hasFilterButton?: boolean;
  hasActionButton?: boolean;
  handleShowFilter: Function;
  elementsChecked: number[];
  setElementsChecked?: any;
  setMerchantsStatus?: any;
  showDropdownCheckbox?: boolean;
  optionsDropdownCheckbox?: string[];
  optionsAllDropdownCheckbox?: string;
  origin?: string;
  refetch?: () => void;
  searchBarPlaceholder?: string;
  getItemInfo?: Function;
  setItemInfoSelected?: Function;
}

function Filter({
  showFilter,
  hasFilterButton = true,
  hasActionButton = true,
  handleShowFilter,
  elementsChecked,
  setElementsChecked,
  setMerchantsStatus,
  showDropdownCheckbox = true,
  optionsDropdownCheckbox = [],
  optionsAllDropdownCheckbox = "",
  origin = "",
  searchBarPlaceholder,
  refetch,
  getItemInfo,
  setItemInfoSelected,
}: FilterTypes) {
  return (
    <div className="flex justify-between items-center pb-4 flex-col lg:flex-row">
      <div className="flex">
        {hasFilterButton && (
          <Button
            classes={`text-black-300 font-medium rounded-lg h-full text-sm ${
              showFilter ? "bg-indigo-200" : "bg-white hover:bg-primary-white"
            } mr-4`}
            onClick={() => handleShowFilter((prev: boolean) => !prev)}
          >
            <FilterIcon classes={"mr-2"} />
            Filter
            <ArrowDown classes={"ml-2"} />
          </Button>
        )}

        <div className="relative flex h-full">
          {showDropdownCheckbox && (
            <DropdownCheckbox
              options={optionsDropdownCheckbox}
              titleAll={optionsAllDropdownCheckbox}
              origin={origin}
            />
          )}
          <Searchbar
            placeholder={
              searchBarPlaceholder ??
              "Search by Merchants name, location, ID, zip-code"
            }
            classes="w-96 pr-3"
            classesContainer="h-min search-bar-autocomplete"
            useTags={true}
          />
        </div>
      </div>
      <div className="flex">
        {hasActionButton && (
          <FilterButtons
            elementsChecked={elementsChecked}
            setElementsChecked={setElementsChecked}
            setMerchantsStatus={setMerchantsStatus}
            origin={origin}
            getItemInfo={getItemInfo}
            setItemInfoSelected={setItemInfoSelected}
          />
        )}
      </div>
    </div>
  );
}

export default Filter;
