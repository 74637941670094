import {useState} from "react";
// Components
import Disclosure from "../Disclosure";
import Info from "../Info";
import Input from "components/Common/Input";

interface RangeTypes {
  number: number;
  expirationDate: number;
}

function Wheel() {
  const [fistBox, setFirstBox] = useState({
    ratioPrizesPoints: 0,
    minPrizeSlots: 0,
    ratioPointsPrizes: 0,
    maxPrizeSlots: 0,
    numLosingSlots: 0,
    stanNumberSlots: 0,
    oddsWinningAttempts: 0,
  });

  const rangeOfNumbers = [
    "if >= 100%",
    "if <= 75%",
    "if <= 50%",
    "if <= 40%",
    "if <= 30%",
    "if <= 20%",
    "if <= 10%",
    "if <= 5%",
  ];
  const bonusesForUsers = [
    "First 24 hours of play after registration",
    "First 48 hours of play after registration",
    "Probability of getting a prize on 1 spin",
    "Probability of getting a prize on 5 spins",
    "Probability of getting a prize on 15 scrolls",
    "Probability of getting a prize on 20 spins",
    "Probability of dropping a prize after a daily game",
    "Probability of dropping a prize after a 7-day pause",
    "Probability of dropping a prize after a 14-day pause",
    "Probability of dropping a prize after a 30-day pause",
  ];

  const [secondBox, setSecondBox] = useState(
    rangeOfNumbers.map((range) => {
      return {
        range,
        number: 0,
        expirationDate: 0,
      };
    })
  );

  const [standardBonusesForUsers, setStandardBonusesForUsers] = useState(
    bonusesForUsers.map((data) => {
      return {
        data,
        defaultProbability: 0,
        duration: 0,
      };
    })
  );

  const updateFormData = (
    index: number,
    value: any,
    key: string,
    type: string
  ) => {
    const formType = type === "user" ? standardBonusesForUsers : secondBox;
    const newState: any[] = formType.map((obj, idx) => {
      if (idx === index) {
        return {...obj, [key]: value};
      }
      return obj;
    });
    if (type === "user") return setStandardBonusesForUsers(newState);
    return setSecondBox(newState);
  };

  return (
    <div className="flex flex-col">
      {/* First */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log('fistBox: ', fistBox)
            }}
          >
            <Disclosure header="Title">
              <div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={fistBox.ratioPrizesPoints}
                    placeholder="0"
                    label="Ratio of prizes to points"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({
                        ...fistBox,
                        ratioPrizesPoints: +e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={fistBox.minPrizeSlots}
                    placeholder="0"
                    label="Min prize slots on the wheel"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({...fistBox, minPrizeSlots: +e.target.value});
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={fistBox.ratioPointsPrizes}
                    placeholder="0"
                    label="Ratio of points to prizes"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({
                        ...fistBox,
                        ratioPointsPrizes: +e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={fistBox.maxPrizeSlots}
                    placeholder="0"
                    label="Max prize slots on the wheel"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({...fistBox, maxPrizeSlots: +e.target.value});
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={fistBox.numLosingSlots}
                    placeholder="0"
                    label="Number of losing slots"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({
                        ...fistBox,
                        numLosingSlots: +e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={fistBox.stanNumberSlots}
                    placeholder="0"
                    label="Standard number of slots"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({
                        ...fistBox,
                        stanNumberSlots: +e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex mb-5 flex-wrap">
                  <Input
                    value={fistBox.oddsWinningAttempts}
                    placeholder="0"
                    label="Odds of winning out of 10 attempts"
                    classes="md:mr-8 md:w-2/5 w-full"
                    classesInput="w-full"
                    onChange={(e) => {
                      setFirstBox({
                        ...fistBox,
                        oddsWinningAttempts: +e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">Ratio Of Prizes To Points</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Ratio Of Points To Prizes</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Number Of Losing Slots</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">Standard Number Of Slots</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
          <div className="mb-5">
            <p className="text-sm font-medium">
              Odds Of Winning Out Of 10 Attempts
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante.
            </p>
          </div>
        </Info>
      </div>

      {/* Second */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("secondBox: ", secondBox);
            }}
          >
            <Disclosure header="Title">
              <div>
                <p className="mb-8">
                  The probability of winning depends on the available number of
                  Prizes
                </p>
                {secondBox.map((item, index) => {
                  return (
                    <div key={item.range}>
                      <div className="flex mb-5 flex-wrap w-">
                        <Input
                          value={item.number}
                          placeholder="0"
                          label={item.range}
                          classes="md:mr-8 md:w-2/5 w-full"
                          classesInput="w-full"
                          onChange={(e) =>
                            updateFormData(
                              index,
                              e.target.value,
                              "number",
                              "default"
                            )
                          }
                        />
                        <Input
                          value={item.expirationDate}
                          placeholder="0"
                          label="Expiration date"
                          classes="md:mr-8 md:w-80 w-full"
                          classesInput="w-full"
                          onChange={(e) =>
                            updateFormData(
                              index,
                              e.target.value,
                              "expirationDate",
                              "default"
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">
              The Probability Of Winning Depends On The Available Number Of
              Prizes
            </p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante. Praesent
              lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
              Nulla elementum turpis nec velit sagittis, vitae ultrices quam
              aliquet. Etiam condimentum velit a quam tempus elementum. Donec
              dignissim varius commodo. Proin sit amet lacus non neque mollis
              venenatis vel sit amet augue.
            </p>
          </div>
        </Info>
      </div>

      {/* Third */}
      <div className="w-full pt-10 flex">
        <div className="w-full rounded bg-white p-2 h-min">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("standardBonusesForUsers: ", standardBonusesForUsers);
            }}
          >
            <Disclosure header="Standard Bonuses For Users">
              <div>
                <p className="mb-8">
                  Standard bonus section for users in the presence of various
                  kinds of conditions;
                </p>
                <div className="flex w-full py-4 bg-slate-100 mb-4">
                  <p className="w-2/5 text-gray-800 pl-4">Activity</p>
                  <p className="w-1/4 mr-4 text-gray-800">
                    Default probability
                  </p>
                  <p className="w-1/4 text-gray-800">Duration</p>
                </div>
                {standardBonusesForUsers.map((item, index) => {
                  return (
                    <div
                      key={item.data}
                      className="flex mb-5 pt-3 flex-wrap items-center"
                    >
                      <p className="mt-2 w-2/5 pl-4">{item.data}</p>
                      <Input
                        value={item.defaultProbability}
                        placeholder="0"
                        label=""
                        classes="w-1/4 mr-4"
                        classesInput="w-full"
                        onChange={(e) =>
                          updateFormData(
                            index,
                            e.target.value,
                            "defaultProbability",
                            "user"
                          )
                        }
                      />
                      <Input
                        value={item.duration}
                        placeholder="0"
                        label=""
                        classes="w-1/4"
                        classesInput="w-full"
                        onChange={(e) =>
                          updateFormData(
                            index,
                            e.target.value,
                            "duration",
                            "user"
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Disclosure>
          </form>
        </div>
        <Info classes="w-72">
          <div className="mb-5">
            <p className="text-sm font-medium">Standard Bonuses For Users</p>
            <p className="text-xs">
              Cras nec lacus id tellus tempus feugiat in non ante. Praesent
              lectus urna, mattis vitae venenatis sed, venenatis quis arcu.
              Nulla elementum turpis nec velit sagittis, vitae ultrices quam
              aliquet. Etiam condimentum velit a quam tempus elementum. Donec
              dignissim varius commodo. Proin sit amet lacus non neque mollis
              venenatis vel sit amet augue.
            </p>
          </div>
        </Info>
      </div>
    </div>
  );
}

export default Wheel;
