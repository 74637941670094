import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {
  setIdSelectedToDelete,
  setImageModal,
  setOpenModal,
  setType,
} from "store/modal/modalSlice";

function Modal({
  children,
  fnClean,
  showCloseButton = true,
  setShowCloseButton,
  onClose,
}: any) {
  const modal = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setType(""));
    dispatch(setOpenModal(false));
    dispatch(setIdSelectedToDelete(""));
    dispatch(setImageModal(""));

    if (onClose) {
      onClose();
    }
    if (fnClean) {
      fnClean();
    }
    setShowCloseButton && setShowCloseButton(true);
  };

  return (
    <Transition appear show={modal.isOpen} as={Fragment}>
      <Dialog
        open={modal.isOpen}
        onClose={() => handleCloseModal()}
        as="div"
        className={`fixed inset-0 z-50 flex justify-center items-center overflow-y-auto ${
          modal.isOpen && "bg-seconday-purple-dark bg-opacity-80"
        }
          `}
      >
        {modal.type === "showImage" || modal.type === "cropImage" ? (
          <div className="flex justify-center relative max-h-screen">
            {showCloseButton && (
              <span
                className="absolute top-2 cursor-pointer text-white font-bold scale-125"
                onClick={() => handleCloseModal()}
                style={{right: "-30px"}}
              >
                X
              </span>
            )}
            {children}
          </div>
        ) : (
          <div className="flex justify-center relative">
            <span
              className="absolute top-2 right-3 cursor-pointer text-gray-600 font-bold"
              onClick={() => handleCloseModal()}
            >
              X
            </span>
            {children}
          </div>
        )}
      </Dialog>
    </Transition>
  );
}

export default Modal;
