export const TABLE_MERCHANT_ITEMS_APPROVED_HEADER = [
    { label: "Item ID", accessor: "id", sortable: true },
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Type", accessor: "type", sortable: true },
    { label: "Merchant", accessor: "merchant_name", sortable: true },
    { label: "Merchant ID", accessor: "merchant_id", sortable: true },
    { label: "Start Qty.", accessor: "start_qty", sortable: true },
    { label: "Qty. Remaining", accessor: "remaining_qty", sortable: true },
    { label: "Claimed", accessor: "claimed", sortable: true },
    { label: "Redeemed", accessor: "redeemed", sortable: true },
    { label: "Created", accessor: "created_at", sortable: true },
    { label: "Exp. Date", accessor: "expired_at", sortable: true },
    { label: "Exp. Days", accessor: "expiring_in", sortable: true },
    { label: "Status", accessor: "state", sortable: true },
];

export const TABLE_MERCHANT_NEW_ITEMS_AND_NEED_APROVE_HEADER = [
    { label: "Item ID", accessor: "id", sortable: true },
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Type", accessor: "type", sortable: true },
    { label: "Merchant", accessor: "merchant_name", sortable: true },
    { label: "Merchant ID", accessor: "merchant_id", sortable: true },
    { label: "Start Qty.", accessor: "start_qty", sortable: true },
    { label: "Created", accessor: "created_at", sortable: true },
    { label: "Exp. Date", accessor: "expired_at", sortable: true },
    { label: "Status", accessor: "state", sortable: true },
];

export const TABLE_MERCHANT_ITEMS_ARCHIVED_HEADER = [
    { label: "Item ID", accessor: "id", sortable: true },
    { label: "Item Name", accessor: "name", sortable: true },
    { label: "Type", accessor: "status", sortable: true },
    { label: "Merchant", accessor: "type", sortable: true },
    { label: "Merchant ID", accessor: "merchant_name", sortable: true },
    { label: "Start Qty.", accessor: "start_qty", sortable: true },
    { label: "Qty. Remain.", accessor: "remaining_qty", sortable: true },
    { label: "Claimed", accessor: "claimed", sortable: true },
    { label: "Redeemed", accessor: "redeemed", sortable: true },
    { label: "Archived", accessor: "archived_at", sortable: true },
    { label: "Exp. Date", accessor: "expired_at", sortable: true },
    { label: "Exp. Days", accessor: "expiring_in", sortable: true },
];