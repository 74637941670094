import {Dialog} from "@headlessui/react";
import Archive from "assets/Archive";
import Description from "assets/Description";
import {EyeHide} from "assets/Eye";
import Button from "components/Common/Button";
import Modal from "components/Modal";
import Table from "components/Table/Table";
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {
  useArchiveCategoryLocationMutation,
  usePublishCategoryLocationMutation,
  useUnpublishCategoryLocationMutation,
} from "store/api/locationApi";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {RootState} from "store/store";
import {setResetElChecked} from "store/table/currentTableDataSlice";
import {TABLE_CONFIGURATION_PUBLISHED} from "utils/configurationHeaders";
import routes from "utils/routesByRole";

function Published({
  data,
  isLoading,
  isError,
  refetch,
  handleItemChecked,
}: any) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user.user);
  const modal: any = useSelector((state: RootState) => state.modal);

  const [typeActionCategory, setTypeActionCategory] = useState("");

  const [unpublishValue, setUnpublishValue] = useState<{
    dmaCode: string;
    categoryId: string;
    categoryName: string;
    position: number;
    id: string;
    isPublished: boolean;
  }>({
    dmaCode: "",
    categoryId: "",
    categoryName: "",
    position: 0,
    id: "",
    isPublished: false,
  });

  const [publishCategoryLocationMutation] =
    usePublishCategoryLocationMutation();
  const [unpublishCategoryLocationMutation] =
    useUnpublishCategoryLocationMutation();
  const [archiveCategoryLocationMutation] =
    useArchiveCategoryLocationMutation();

  const handlePublishCategory = async () => {
    const toastPromise = toast.promise(
      publishCategoryLocationMutation({
        dmaCode: unpublishValue.dmaCode,
        categoryId: unpublishValue.categoryId,
      }),
      {
        pending: `Publishing category ${unpublishValue.categoryName}`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>
                Error while publishing category {unpublishValue.categoryName}
              </h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category ${unpublishValue.categoryName} published`);
          dispatch(setResetElChecked(true));
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error while publishing category");
      });
  };
  const handleUnpublishCategory = async () => {
    if (unpublishValue.dmaCode === "" || unpublishValue.categoryId === "")
      return;

    const toastPromise = toast.promise(
      unpublishCategoryLocationMutation({
        dmaCode: unpublishValue?.dmaCode,
        categoryId: unpublishValue?.categoryId,
      }),
      {
        pending: `Unpublishing category ${unpublishValue.categoryName}`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>
                Error while unpublishing category {unpublishValue.categoryName}
              </h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category ${unpublishValue.categoryName} unpublished`);
          dispatch(setResetElChecked(true));
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error while unpublishing category");
      });
  };
  const handleArchiveCategory = async (e: any) => {
    const toastPromise = toast.promise(
      archiveCategoryLocationMutation({
        dmaCode: parseInt(e.dmaCode),
        data: {categoryIds: [e.categoryId]},
      }),
      {
        pending: `Archiving category`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while archiving category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category archived`);
          dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while archiving category");
      });
  };

  const handleUnpublishCategoryFn = (e: any, type: string) => {
    if (type === "unpublish" && e.isPublished === true) {
      dispatch(setType("unpublishCategoryModalIndividual"));
      dispatch(setOpenModal(true));
      setUnpublishValue(e);
    } else if (type === "publish" && e.isPublished === false) {
      dispatch(setType("unpublishCategoryModalIndividual"));
      dispatch(setOpenModal(true));
      setUnpublishValue(e);
    } else {
      toast.info(`This category is already ${type}`);
    }
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
    setUnpublishValue({
      id: "",
      dmaCode: "",
      categoryId: "",
      categoryName: "",
      position: 0,
      isPublished: false,
    });
  };

  const ITEMS = useMemo(
    () => [
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Publish",
        svg: <Description classes="mr-3" />,
        type: "unpublishCategory",
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "publish");
          setTypeActionCategory("publish");
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("publish") && {
        title: "Unpublish",
        svg: <EyeHide classes="mr-3" />,
        type: "unpublishCategory",
        onClick: (e: any) => {
          handleUnpublishCategoryFn(e, "unpublish");
          setTypeActionCategory("unpublish");
        },
      },
      // @ts-ignore
      routes[user.data.role].urlAllowed?.categories?.includes("view") && {
        title: "Archive",
        svg: <Archive classes="mr-3" />,
        type: "archiveCategory",
        onClick: (e: any) => handleArchiveCategory(e),
      },
    ],
    []
  );

  return (
    <>
      <Table
        data={data && data.length > 0 ? data : []}
        loadingData={isLoading}
        errorData={isError}
        refetch={refetch}
        results={data !== undefined ? data?.count : 1}
        headers={TABLE_CONFIGURATION_PUBLISHED}
        origin="categories_published"
        hasPagination={false}
        itemOptions={ITEMS}
        typeOptions="group"
        handleItemChecked={handleItemChecked}
        show={[
          "categoryName",
          "type",
          "position",
          "createdAt",
          "updatedAt",
          "status",
          "isPublished",
        ]}
        sort={{
          categoryName: null,
          type: null,
          position: null,
          createdAt: null,
          updatedAt: null,
          status: null,
          isPublished: null,
        }}
        // countPagination={
        //   tabsAndPage.tabs.lastVisitedPage
        //     ? +tabsAndPage.tabs.lastVisitedPage
        //     : 0
        // }
        orderingByAPI={true}
      />
      {modal.type === "unpublishCategoryModalIndividual" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Are you sure you want to {typeActionCategory} this category?
              </Dialog.Title>
              <div className="px-6 mb-5">- {unpublishValue?.categoryName}</div>
              <p className="text-sm px-6">
                This action will affect the customer application.
              </p>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>

                  {typeActionCategory === "unpublish" ? (
                    <Button
                      variant={
                        unpublishValue?.isPublished !== false
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        unpublishValue?.isPublished !== false
                          ? handleUnpublishCategory
                          : () =>
                              toast.info("This category is already unpublished")
                      }
                    >
                      <p>Unpublish</p>
                    </Button>
                  ) : (
                    <Button
                      variant={
                        unpublishValue?.isPublished !== true
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        unpublishValue?.isPublished !== true
                          ? handlePublishCategory
                          : () =>
                              toast.info("This category is already published")
                      }
                    >
                      <p>Publish</p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Published;
